<template>
  <div class="qxpnav qxpnav-menu">
    <div class="qxpnav qxpnav-menu-header">
      <div class="text-h6">{{ menu.name }}</div>
    </div>
    <div class="qxpnav qxpnav-menu-content">
      <qxp-nav-submenu
        v-for="submenu in menu.SubMenus"
        :key="submenu.id"
        :submenu="submenu"
        :showContent="isSubmenuOpen(submenu.id)"
        :portalid="portalid"
        :menuid="menu.id"
        @togglesShowContent="togglesSubMenuShowContent($event)"
      ></qxp-nav-submenu>
    </div>
  </div>
</template>

<script>
import QxpNavSubmenu from "@/components/navigation/QxpNav/QxpNavSubmenu";
export default {
  data() {
    return {
      openedSubmenuid: -1,
    };
  },
  created() {
    if (this.navidsFormQuery && this.navidsFormQuery.submenuid) {
      this.openedSubmenuid = this.navidsFormQuery.submenuid;
    }
    if (this.menu.SubMenus.length == 1) {
      this.openedSubmenuid = this.menu.SubMenus[0].id; //open Submenu if only one
    }
  },
  methods: {
    isSubmenuOpen(id) {
      return id == this.openedSubmenuid || this.showMenu;
    },
    togglesSubMenuShowContent(id) {
      if (id == this.openedSubmenuid) {
        this.openedSubmenuid = -1; //close if already open
      } else {
        this.openedSubmenuid = id;
      }
    },
  },
  props: {
    menu: {
      type: Object,
      default: function () {
        return {
          menu: {
            name: "MenuName",
          },
          SubMenus: {
            name: "subMenuName",
            Links: [
              {
                name: "TestLinkIntern",
                link: "/StandardView/116/240",
              },
              {
                name: "TestLinkExtern",
                link: "https://iprisk.ipconcept.com/QXPortal/REPORTS/IPC/QSWebPortal/ClientRisk.aspx",
                intWin: false,
              },
            ],
          },
        };
      },
    },
    navidsFormQuery: {
      type: Object,
      default: function () {
        return {};
      },
    },
    portalid: {
      type: Number,
      default: function () {
        return -1;
      },
    },
    showMenu: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  components: {
    QxpNavSubmenu,
  },
};
</script>
