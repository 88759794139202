<template>
  <div class="qxpnav">
    <div
      class="loader-spinner loader-spinner--small"
      v-show="showSpinner"
    ></div>
    <div
      v-for="portal in visiblePortals"
      :key="portal.id"
      class="qxpnav qxpnav-portal"
    >
      <div
        class="qxpnav qxpnav-portal-header"
        @click="togglesShowContent(portal.id)"
      >
        <i
          class="icon icon-013-chevron-down icon--s2"
          :class="{ 'icon--flip-vertical': isPortalOpen(portal.id) }"
        ></i>
        <div class="text-h5">{{ portal.name }}</div>
      </div>
      <div
        v-show="isPortalOpen(portal.id) || !!navSearchString"
        class="qxpnav qxpnav-portal-content"
      >
        <qxp-nav-menu
          v-for="menu in portal.Menus"
          :key="menu.id"
          :menu="menu"
          :portalid="portal.id"
          :showMenu="!!navSearchString"
          :navidsFormQuery="navidsFormQuery"
        ></qxp-nav-menu>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  isMenuActive,
  isAtLeastOneLinkActiveInSubMenu,
} from "@/components/navigation/navUtils.js";
import QxpNavMenu from "@/components/navigation/QxpNav/QxpNavMenu";

export default {
  data() {
    return {
      openedPortalid: -1,
      navidsFormQuery: {
        portalid: -1,
        menuid: -1,
        submenuid: -1,
        linkid: -1,
      },
    };
  },
  created() {
    document.title = "IP.Risk Online";
    this.$store.dispatch("wviewStore/CLEAR");
    this.$store.dispatch("nav/PUSH_SEARCH_STRING", "");
    if (
      !this.isNavLoaded &&
      !this.isNavLoading &&
      this.$store.getters.isUserTokenHereAndStillValid()
    ) {
      this.$store.dispatch("nav/LOAD");
    }
    let queries = JSON.parse(JSON.stringify(this.$route.query));
    if (queries.nav) {
      // console.log(queries.nav);
      //open portal/menu/submenu
      let navids = queries.nav.split(",");
      this.navidsFormQuery.portalid = navids[0];
      this.navidsFormQuery.menuid = navids[1];
      this.navidsFormQuery.submenuid = navids[2];
      this.navidsFormQuery.linkid = navids[3];
      this.openedPortalid = this.navidsFormQuery.portalid;
      //remove nav from queries
      delete queries.nav;
      this.$router.replace({ query: queries });
    }
  },
  computed: {
    ...mapGetters({
      isNavLoading: "nav/isDataLoading",
      isNavLoaded: "nav/isDataLoaded",
      navData: "nav/navData",
      navFilteredData: "nav/navFilteredData",
      navSearchString: "nav/navSearchString",
    }),
    visiblePortals() {
      if (this.isNavLoaded) {
        return this.navFilteredData;
      }
      return [];
    },
    showSpinner() {
      return this.isNavLoading;
    },
  },
  methods: {
    openedPortalidComp() {
      if (this.openedPortalid == -1 && this.isNavLoaded) {
        this.openedPortalid = this.navFilteredData[0].id; // first portal open
      }
      return this.openedPortalid;
    },
    showMenu(menu) {
      if (!!menu && menu.SubMenus) {
        return menu.SubMenus.filter((submenu) => {
          var ret = false;
          if (!!submenu && submenu.Links) {
            if (
              submenu.Links.filter((link) => {
                return link.intWin == true;
              }).length > 0
            ) {
              return true;
            }
          }
        });
      }
    },
    isPortalOpen(id) {
      return id == this.openedPortalidComp();
    },
    togglesShowContent(id) {
      if (id == this.openedPortalid) {
        this.openedPortalid = -2; //close if already open
      } else {
        this.openedPortalid = id;
      }
    },
    showPortal(portal) {
      if (portal.Menus.length > 0) {
        return true;
      }
    },
    getCardContent(menu, submenu) {
      return {
        menu: menu,
        subMenu: submenu,
      };
    },
    isMenuActive(menu) {
      if (!this.isNavLoaded) return false;
      return isMenuActive(menu, this.navData);
    },
    isSubMenuActive(subMenu) {
      if (!this.isNavLoaded) return false;
      return isAtLeastOneLinkActiveInSubMenu(subMenu, this.navData);
    },
  },
  components: {
    QxpNavMenu,
  },
  props: {},
};
</script>
