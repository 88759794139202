/*
https://iprisk.ipconcept.com/qsapi_i_ui/api/wview/RQS_conf_Trigger?$orderby=qsTriggerID+asc&byPassIsRequest=true
*/

import axios from "axios";
//import { initEmptyState } from "sdx";
import store from "..";

//used only for async
import AWN from "awesome-notifications";
import configAWN from "@/data/configAWN";
let notifier = new AWN(configAWN);

const state = {
  rqsTriggerData: [],
  rqsPriorTriggerData: [],
  rqsPriorDrillDownTriggerData: [],
  statusRqsTrigger: "",
  statusRqsType: "",
  statusRqsDocs: "",
  statusRqsPrioTrigger: "",
  statusRqsTriggerDrillDown: "",
  rqsDocsTriggerData: null,
  rqsDocsDrillDownTriggerData: null,
  rqsRiskVarData: [],
};

const getters = {
  isRqsTriggerLoading() {
    return state.statusRqsTrigger === "loading";
  },
  isRqsTriggerLoaded() {
    return state.statusRqsTrigger === "loaded";
  },
  rqsTriggerData() {
    return state.rqsTriggerData;
  },
  isRqsPriorTriggerLoading() {
    return state.statusRqsPrioTrigger === "loading";
  },
  isRqPriorTriggerLoaded() {
    return state.statusRqsPrioTrigger === "loaded";
  },
  rqsPriorTriggerData() {
    return state.rqsPriorTriggerData;
  },
  isRqsPriorDrillDownTriggerLoading() {
    return state.statusRqsTriggerDrillDown === "loading";
  },
  isRqPriorDrillDownTriggerLoaded() {
    return state.statusRqsTriggerDrillDown === "loaded";
  },
  rqsPriorDrillDownTriggerData() {
    return state.rqsPriorDrillDownTriggerData;
  },
  isRqsTypeLoading() {
    return state.statusRqsType === "loading";
  },
  isRqsTypeLoaded() {
    return state.statusRqsType === "loaded";
  },
  rqsTypeData() {
    return state.rqsTypeData;
  },
  isRqsDocsLoading() {
    return state.statusRqsDocs === "loading";
  },
  isRqsDocsLoaded() {
    return state.statusRqsDocs === "loaded";
  },
  rqsDocsTriggerData() {
    return state.rqsDocsTriggerData;
  },
  rqsDocsDrillDownTriggerData() {
    return state.rqsDocsDrillDownTriggerData;
  },
};

const mutations = {
  SET_STATUS_RQS_PRIORDRILLDOWN_TRIGGER_LOADING(state) {
    state.statusRqsTriggerDrillDown = "loading";
  },
  SET_STATUS_RQS_PRIORDRILLDOWN_TRIGGER_LOADED(state) {
    state.statusRqsTriggerDrillDown = "loaded";
  },
  SET_STATUS_RQS_PRIORDRILLDOWN_TRIGGER_ERROR(state) {
    state.statusRqsTriggerDrillDown = "error";
  },
  SET_DATA_RQS_PRIORDRILLDOWN_TRIGGER(state, data) {
    state.rqsPriorDrillDownTriggerData = data;
  },
  SET_STATUS_RQS_PRIOR_TRIGGER_LOADING(state) {
    state.statusRqsPrioTrigger = "loading";
  },
  SET_STATUS_RQS_PRIOR_TRIGGER_LOADED(state) {
    state.statusRqsPrioTrigger = "loaded";
  },
  SET_STATUS_RQS_PRIOR_TRIGGER_ERROR(state) {
    state.statusRqsPrioTrigger = "error";
  },
  SET_STATUS_RQS_TRIGGER_LOADING(state) {
    state.statusRqsTrigger = "loading";
  },
  SET_STATUS_RQS_TRIGGER_LOADED(state) {
    state.statusRqsTrigger = "loaded";
  },
  SET_STATUS_RQS_TRIGGER_ERROR(state) {
    state.statusRqsTrigger = "error";
  },
  SET_DATA_RQS_PRIOR_TRIGGER(state, data) {
    state.rqsPriorTriggerData = data;
  },
  SET_DATA_RQS_TRIGGER(state, data) {
    state.rqsTriggerData = data;
  },
  SET_STATUS_RQS_TYPE_LOADING(state) {
    state.statusRqsType = "loading";
  },
  SET_STATUS_RQS_TYPE_LOADED(state) {
    state.statusRqsType = "loaded";
  },
  SET_STATUS_RQS_TYPE_ERROR(state) {
    state.statusRqsType = "error";
  },
  SET_DATA_RQS_TYPE(state, data) {
    state.rqsTypeData = data;
  },
  SET_STATUS_RQS_DOCS_LOADING(state) {
    state.statusRqsDocs = "loading";
  },
  SET_STATUS_RQS_DOCS_LOADED(state) {
    state.statusRqsDocs = "loaded";
  },
  SET_STATUS_RQS_DOCS_ERROR(state) {
    state.statusRqsDocs = "error";
  },
  SET_DATA_RQS_DOCS(state, data) {
    state.rqsDocsTriggerData = {};
    state.rqsDocsTriggerData = data;
  },
  SET_DATA_RQS_VAR(state, data) {
    state.rqsRiskVarData = [];
    state.rqsRiskVarData = data;
  },
  CLEAR_DATA(state) {
    state.rqsTriggerData = [];
    state.rqsTypeData = [];
    state.rqsPriorTriggerData = {};
    state.rqsPriorDrillDownTriggerData = {};
  },
  CLEAR_DATA_PRIOR(state) {
    state.rqsPriorTriggerData = {};
    state.rqsPriorDrillDownTriggerData = {};
  },
  CLEAR_DATA_DOCS(state) {
    state.rqsDocsTriggerData = null;
    state.rqsDocsDrillDownTriggerData = null;
  },
  CLEAR_DATA_VAR(state) {
    state.rqsRiskVarData = [];
  },
  CLEAR_DATA_PRIORDRILLDOWN(state) {
    state.rqsPriorDrillDownTriggerData = {};
  },
};

const actions = {
  LOAD_RQS_VAR_TRIGGER({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CLEAR_DATA_VAR");
      console.log("@LOAD_RQS_VAR_TRIGGER");
      let data = [];
      //commit("SET_STATUS_RQS_TRIGGER_LOADING");
      axios
        .get("/api/wview/UPDATEVARVOLS_VIEW?byPassIsRequest=true")
        .then((response) => {
          let list = [...new Set(response.data.map((item) => item.VarRF))];
          console.log(list);
          console.log(response.data);
          //let data = [{ qsTriggerID: null, triggerDesc: "All" }];
          //commit("SET_DATA_RQS_VAR", [...data, ...response.data]);
          //commit("SET_STATUS_RQS_TRIGGER_LOADED");
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          //commit("SET_STATUS_RQS_TRIGGER_ERROR");
          reject(err);
        });
    });
  },
  LOAD_RQS_TRIGGER({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CLEAR_DATA");
      commit("SET_STATUS_RQS_TRIGGER_LOADING");
      axios
        .get(
          "/api/wview/RQS_conf_Trigger?$orderby=triggerDesc+asc&byPassIsRequest=true"
        )
        .then((response) => {
          let data = [{ qsTriggerID: null, triggerDesc: "All" }];
          commit("SET_DATA_RQS_TRIGGER", [...data, ...response.data]);
          commit("SET_STATUS_RQS_TRIGGER_LOADED");
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          commit("SET_STATUS_RQS_TRIGGER_ERROR");
          reject(err);
        });
    });
  },
  LOAD_RQS_TYPE({ commit }) {
    return new Promise((resolve, reject) => {
      //commit("CLEAR_DATA");
      commit("SET_STATUS_RQS_TYPE_LOADING");
      axios
        .get("/api/wview/RQS_conf_TestType?$orderby=typeDesc+asc")
        .then((response) => {
          let test_types = {};
          test_types["All"] = {};
          test_types["All"]["All"] = null;

          for (const row of response.data) {
            if (!test_types[row.triggerDesc]) {
              test_types[row.triggerDesc] = {};
              test_types[row.triggerDesc]["All"] = null;
            }

            if (!test_types[row.triggerDesc][row.typeDesc]) {
              test_types[row.triggerDesc][row.typeDesc] = row.qsTestTypeID;
            }
          }
          commit("SET_DATA_RQS_TYPE", test_types);
          commit("SET_STATUS_RQS_TYPE_LOADED");
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          commit("SET_STATUS_RQS_TYPE_ERROR");
          reject(err);
        });
    });
  },
  LOAD_PRIOR_DATA({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("CLEAR_DATA_PRIOR");
      commit("SET_STATUS_RQS_PRIOR_TRIGGER_LOADING");
      var requestConfig = {
        params: {},
      };
      for (const [key, value] of Object.entries(payload.queryParams)) {
        // console.log(`${key}' + '=' + '${value}`);
        if (!!key && !!value) {
          requestConfig.params[key] = value;
        }
      }

      axios
        .get(
          "/api/wview/" +
            payload.vwiewName +
            "?$orderby=triggerDesc+asc&byPassIsRequest=true",
          requestConfig
        )
        .then((response) => {
          let data_snapshot = {};
          let data_details = {};
          let data_details_length = 0;
          let data_snapshot_length = 0;
          for (const row of response.data) {
            if (!data_snapshot[row.triggerDesc]) {
              data_snapshot[row.triggerDesc] = {
                triggerDesc: row.triggerDesc,
                typeDesc: "All",
                prio1: 0,
                prio2: 0,
                prio3: 0,
                prio4: 0,
                prio5: 0,
              };
              data_snapshot_length++;
            }
            if (!data_details[row.triggerDesc]) {
              data_details[row.triggerDesc] = {};
              data_details_length++;
            }
            if (!data_details[row.triggerDesc][row.typeDesc]) {
              let drillDownLink =
                payload.queryParams.$filter +
                ` and (triggerDesc eq '${row.triggerDesc}') and (typeDesc eq '${row.typeDesc}')`;
              if (payload.queryParams.$filter.indexOf("triggerDesc") !== -1) {
                let drillDownLink =
                  payload.queryParams.$filter +
                  ` and (typeDesc eq '${row.typeDesc}')`;
              }
              data_details[row.triggerDesc][row.typeDesc] = {
                triggerDesc: row.triggerDesc,
                typeDesc: row.typeDesc,
                drillDownLink: drillDownLink,
                prio1: 0,
                prio2: 0,
                prio3: 0,
                prio4: 0,
                prio5: 0,
              };
            }
            data_snapshot[row.triggerDesc]["prio" + row.prio]++;
            data_details[row.triggerDesc][row.typeDesc]["prio" + row.prio]++;
          }
          let data = [];
          data.snapshot = data_snapshot;
          data.details = data_details;
          data.details_length = data_details_length;
          data.snapshot_length = data_snapshot_length;
          commit("SET_DATA_RQS_PRIOR_TRIGGER", Object.assign({}, data));
          commit("SET_STATUS_RQS_PRIOR_TRIGGER_LOADED");
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            "Error while Loading Priority Data"
          );
          commit("SET_STATUS_RQS_PRIOR_TRIGGER_ERROR");
        });
    });
  },
  LOAD_PRIOR_DRILLDOWN_DATA({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("CLEAR_DATA_PRIORDRILLDOWN");
      commit("SET_STATUS_RQS_PRIORDRILLDOWN_TRIGGER_LOADING");
      var requestConfig = {
        params: {},
      };
      for (const [key, value] of Object.entries(payload.queryParams)) {
        //console.log('Key %s, Value %s', key, value);
        // console.log(`${key}' + '=' + '${value}`);
        if (!!key && !!value) {
          requestConfig.params[key] = value;
        }
      }
      axios
        .get("/api/wview/" + payload.vwiewName, requestConfig)
        .then((response) => {
          commit("SET_DATA_RQS_PRIORDRILLDOWN_TRIGGER", response.data);
          commit("SET_STATUS_RQS_PRIORDRILLDOWN_TRIGGER_LOADED");
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            "Error while Loading Priority Data"
          );
          commit("SET_STATUS_RQS_PRIORDRILLDOWN_TRIGGER_ERROR");
        });
    });
  },
  SEND_CORRECTION({ commit, state }, payload) {
    let messageText = `correction`;
    notifier.async(
      new Promise((resolve, reject) => {
        var requestConfig = {
          params: {},
        };

        axios
          .post("/api/wview/Correction", payload, requestConfig)
          .then((resp) => {
            //store.dispatch("wviewStore/RELOAD_DATA");
            // const result = resp.data
            resolve(resp);
          })
          .catch((err) => {
            //store.dispatch("wviewStore/RELOAD_DATA");
            reject(err);
          });
      }),
      (resp) => {
        store.dispatch("notifStore/success", messageText);
      },
      (err) => {
        store.dispatch(
          "notifStore/alert",
          `${messageText} ${getResponsErrorData(err)}`
        );
      },
      `<span>Sending ${messageText}</span><div class="loader-spinner loader-spinner--small"/>`,
      {
        labels: {
          async: "correction",
        },
      }
    );
  },
  LOAD_DOCS_DATA({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("CLEAR_DATA_DOCS");
      commit("SET_STATUS_RQS_DOCS_LOADING");
      var requestConfig = {
        params: {},
      };
      for (const [key, value] of Object.entries(payload.queryParams)) {
        //console.log('Key %s, Value %s', key, value);
        // console.log(`${key}' + '=' + '${value}`);
        if (!!key && !!value) {
          requestConfig.params[key] = value;
        }
      }
      axios
        .get("/api/wview/" + payload.vwiewName, requestConfig)
        .then((response) => {
          commit("SET_DATA_RQS_DOCS", response.data);
          commit("SET_STATUS_RQS_DOCS_LOADED");
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            "Error while Loading Priority Data"
          );
          commit("SET_STATUS_RQS_DOCS_ERROR");
        });
    });
  },
  LOAD_DOCS_DRILLDOWN_DATA({ commit }, payload) {
    return new Promise((resolve, reject) => {
      //   commit("SET_STATUS_RQS_DOCS_LOADING");
      var requestConfig = {
        params: {},
      };
      for (const [key, value] of Object.entries(payload.queryParams)) {
        //console.log('Key %s, Value %s', key, value);
        // console.log(`${key}' + '=' + '${value}`);
        if (!!key && !!value) {
          requestConfig.params[key] = value;
        }
      }
      axios
        .get("/api/wview/" + payload.vwiewName, requestConfig)
        .then((response) => {
          //commit("SET_DATA_RQS_DOCS", response.data);
          //commit("SET_STATUS_RQS_DOCS_LOADED");
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            "Error while Loading Priority Data"
          );
          //commit("SET_STATUS_RQS_DOCS_ERROR");
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
