<template>
  <qsui-accordion-item>
    <p slot="header">FundNo</p>
    <div slot="collapse" class="mandatSelect">
      <div class="form" v-if="showAsCheckBoxes">
        <div
          v-for="dataRadioButton in dataRadioButtons"
          class="radio radio-input-filter"
        >
          <input
            type="radio"
            :name="dataRadioButton.name"
            :id="dataRadioButton.name"
            :value="dataRadioButton.value"
            v-model="radioSelectedValue"
          />
          <label :for="dataRadioButton.name" class="input-field">
            <p>{{ dataRadioButton.name }}</p>
          </label>
        </div>
      </div>
      <treeselect
        class="treeselect-ss"
        v-else
        v-model="valueBook"
        :clearOnSelect="true"
        :close-on-select="true"
        :clearable="true"
        :multiple="false"
        :async="true"
        :load-options="loadOptions"
        placeholder="type at least 3 characters or a space to start the search"
        loading-text="type at least 3 characters or a space to start the search"
      />
      <!-- <input type="text" v-bind:placeholder=" 'plz input a ' + col.ColType " v-bind:id="inputId"> -->
    </div>
  </qsui-accordion-item>
</template>

<script>
import QsuiAccordionItem from "@/components/ui_utilities/QsuiAccordionItem";
import Treeselect, { ASYNC_SEARCH } from "@riophae/vue-treeselect";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      filter: {
        name: "FundNo",
        binaryOperator: "or",
        criterias: [
          {
            operator: "=",
            value: "",
          },
        ],
      },
      radioSelectedValue: "",
      valueBook: null,
    };
  },
  watch: {
    valueBook: function () {
      if (this.valueBook) {
        this.filter.criterias[0].value = this.valueBook;
        this.$emit("input", this.filter);
      }
    },
    radioSelectedValue: function () {
      if (this.radioSelectedValue) {
        this.filter.criterias[0].value = this.radioSelectedValue;
        this.$emit("input", this.filter);
      }
    },
  },
  created() {
    if (
      !this.isBookLoaded &&
      !this.isBookLoading &&
      this.$store.getters.isUserTokenHereAndStillValid()
    ) {
      this.$store.dispatch("book/LOAD");
    }
  },
  computed: {
    ...mapGetters({
      isBookLoading: "book/isDataLoading",
      isBookLoaded: "book/isDataLoaded",
      bookData: "book/fundnoData",
      portalFundNoDataByPortalID: "book/portalFundNoDataByPortalID",
    }),
    books() {
      if (this.isBookLoaded) {
        if (!!this.portalID) {
          return this.portalFundNoDataByPortalID(this.portalID);
        } else {
          return this.bookData;
        }
      }
    },
    showAsCheckBoxes() {
      if (
        this.isBookLoaded &&
        this.books &&
        this.books.length > 0 &&
        this.books.length <= 9
      ) {
        return true;
      }
      return false;
    },
    dataRadioButtons() {
      var ret = [];
      if (this.showAsCheckBoxes) {
        this.books.forEach((ctBook) => {
          ret.push({
            name: ctBook.label,
            value: ctBook.id,
          });
        });
      }
      return ret;
    },
  },
  methods: {
    loadOptions({ action, searchQuery, callback }) {
      if (action === ASYNC_SEARCH) {
        if (this.isBookLoaded && !!searchQuery) {
          const query = searchQuery.toLowerCase();
          if (query.length > 2 || query.indexOf(" ") > -1) {
            const options = this.books.filter((el) => {
              return (
                (!!el.id && el.id.toLowerCase().indexOf(query) > -1) ||
                (!!el.label && el.label.toLowerCase().indexOf(query) > -1)
              );
            });
            callback(null, options);
          }
        }
      }
    },
  },
  props: {
    portalID: {
      type: String,
      default: function () {
        return "123";
      },
    },
  },
  components: {
    QsuiAccordionItem,
    Treeselect,
  },
};
</script>
