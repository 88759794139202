<template>
  <div class="qsui-admin-container">
    <div v-if="showSpinner" class="loader-spinner loader-spinner--small"></div>
    <div v-else-if="!isElemLoadedAndValid">
      <div class="qsui-admin-form col-lg-12 col-xl-10">
        <div class="text-h4">Create New Risk Benchmark</div>
        <div class="qsui-admin-subform">
          <div class="qsui-admin-subform-row">
            <sdx-textbox
              v-model="newElem.name"
              label="benchmark name"
              type="text"
              :showLabel="true"
              :regex="config.regex.notEmpty"
            />
          </div>
        </div>
      </div>
      <div class="qsui-admin-form col-lg-12 col-xl-10">
        <div class="text-h4">Benchmark Positions (indices)</div>
        <div class="text">Sum of index weight: {{ weigthSumNewElem }} %</div>
        <div class="qsui-admin-subform">
          <div
            v-for="pos in newElem.positions"
            v-bind:key="pos.id"
            class="qsui-admin-subform-row"
          >
            <!-- <select-index
              class="input-field-large"
              label="index"
              @input="onIndexChange(pos, $event)"
            /> -->
            <sdx-textbox
              class="input-field-large"
              v-model="pos.index"
              title="IndexID (cf. wview MAP_INDEXDESC_VIEW)"
              label="Index ID"
              type="text"
              :showLabel="true"
              :regex="config.regex.notEmpty"
            />
            <sdx-textbox
              class="input-field-small"
              v-model="pos.weight"
              label="weight(%)"
              type="text"
              :showLabel="true"
              :regex="config.regex.decimal"
            />
            <div class="input-field">
              <i
                class="icon icon-008-bin wvfilter-icon"
                title="Remove Index"
                aria-hidden="true"
                @click="delPosition(newElem, pos)"
              ></i>
            </div>
          </div>
          <div>
            <i
              class="icon icon-061-plus wvfilter-icon wvfilter-green-button"
              title="Add Position"
              aria-hidden="true"
              @click="addPosition(newElem)"
            ></i>
          </div>
        </div>
      </div>
      <div class="qsui-admin-form col-lg-12 col-xl-10">
        <div class="qsui-admin-buttons">
          <a
            class="button button--secondary"
            href="javascript:void(0);"
            @click="onNewCancel"
            >cancel</a
          >
          <a
            class="button button--primary"
            href="javascript:void(0);"
            :class="{ disabled: !isNewElemValid }"
            @click="onCreate"
            >create</a
          >
        </div>
      </div>
    </div>
    <div v-else>
      <div class="qsui-admin-form col-lg-12 col-xl-10">
        <div class="text-h4">Risk Benchmark: {{ elem.name.value }}</div>
      </div>
      <div class="qsui-admin-form col-lg-12 col-xl-10">
        <div class="text-h4">Benchmark Positions (indices)</div>
        <div class="text">Sum of index weight: {{ weigthSumElem }} %</div>
        <div class="qsui-admin-subform">
          <div
            v-for="pos in elem.positions"
            v-bind:key="pos.id"
            class="qsui-admin-subform-row"
          >
            <!-- <select-index
              class="input-field-large"
              label="index"
              :value="pos.index.value"
              @input="onIndexChange(pos, $event)"
            /> -->
            <sdx-textbox
              class="input-field-large"
              v-model="pos.index"
              title="IndexID (cf. wview MAP_INDEXDESC_VIEW)"
              label="Index ID"
              type="text"
              :showLabel="true"
              :regex="config.regex.notEmpty"
            />
            <sdx-textbox
              class="input-field-small"
              v-model="pos.weight"
              label="weight(%)"
              type="text"
              :showLabel="true"
              :regex="config.regex.decimal"
            />
            <div class="input-field">
              <i
                class="icon icon-008-bin wvfilter-icon"
                title="Remove Index"
                aria-hidden="true"
                @click="delPosition(elem, pos)"
              ></i>
            </div>
          </div>
          <div>
            <i
              class="icon icon-061-plus wvfilter-icon wvfilter-green-button"
              title="Add Index"
              aria-hidden="true"
              @click="addPosition(elem)"
            ></i>
          </div>
        </div>
      </div>
      <div class="qsui-admin-form col-lg-12 col-xl-10">
        <div class="qsui-admin-buttons">
          <a
            class="button button--primary"
            :class="{ disabled: !hasChanged || !isModifyElemValid }"
            href="javascript:void(0);"
            @click="onModify"
            >change</a
          >
          <a
            class="button button--primary qsui-button-red"
            href="javascript:void(0);"
            @click="onDelete"
            >delete</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import config from "@/data/config";
import SDXTextbox from "@/components/ui_utilities/SDXTextbox";
export default {
  data() {
    return {
      config: config,
      elem: {
        name: { value: "", valid: undefined },
        positions: [],
      },
      newElem: {
        name: { value: "", valid: undefined },
        positions: [],
      },
    };
  },
  watch: {
    elemData: function (value) {
      this.elem.name = { value: value.name, valid: true };
      this.elem.positions = [];
      let i = 0;
      if (value.positions) {
        value.positions.forEach((pos) => {
          this.elem.positions.push({
            id: "pos" + i,
            index: { value: pos.index, valid: true },
            weight: { value: pos.weight * 100, valid: true },
          });
          i = i + 1;
        });
      }
    },
  },
  created() {
    if (!this.isIndicesLoaded) {
      this.$store.dispatch("fmdRiskBenchmark/LOAD_INDICES_DATA");
    }
  },
  computed: {
    ...mapGetters({
      elemData: "fmdRiskBenchmark/riskBenchmarkData",
      isElemLoading: "fmdRiskBenchmark/isRiskBenchmarkLoading",
      isIndicesLoaded: "fmdRiskBenchmark/isIndicesLoaded",
    }),
    weigthSumElem() {
      let sum = 0;
      this.elem.positions.forEach((pos) => {
        sum += Number(pos.weight.value);
      });
      return sum;
    },
    weigthSumNewElem() {
      let sum = 0;
      this.newElem.positions.forEach((pos) => {
        sum += Number(pos.weight.value);
      });
      return sum;
    },
    isElemLoadedAndValid() {
      return !!this.elem && !!this.elem.name.value;
    },
    isNewElemValid() {
      if (!this.newElem.name.valid) {
        return false;
      }
      if (this.newElem.positions.length == 0) {
        return false;
      }
      let isValid = true;
      this.newElem.positions.forEach((pos) => {
        if (!pos.index.value) {
          isValid = false;
          return;
        }
        if (!pos.weight.valid) {
          isValid = false;
          return;
        }
      });
      return isValid;
    },
    isModifyElemValid() {
      if (!this.elem.name.valid) {
        return false;
      }
      if (this.elem.positions.length == 0) {
        return false;
      }
      let isValid = true;
      this.elem.positions.forEach((pos) => {
        if (!pos.index.value) {
          isValid = false;
          return;
        }
        if (!pos.weight.valid) {
          isValid = false;
          return;
        }
      });
      return isValid;
    },
    hasChanged() {
      if (this.elem.name.value != this.elemData.name) {
        return true;
      }
      if (this.elem.positions.length != this.elemData.positions.length) {
        return true;
      }
      let posChanged = false;
      this.elem.positions.forEach((pos) => {
        const found = this.elemData.positions.find(
          (posData) => posData.index == pos.index.value
        );
        if (!found) {
          posChanged = true;
          return;
        }
        if (pos.index.value != found.index) {
          posChanged = true;
          return;
        }
        if (pos.weight.value != found.weight) {
          posChanged = true;
          return;
        }
      });
      return posChanged;
    },
    showSpinner() {
      return this.isElemLoading;
    },
  },
  methods: {
    onIndexChange(pos, index) {
      pos.index.value = index;
    },
    addPosition(elem) {
      elem.positions.push({
        id: "pos" + elem.positions.length,
        index: { value: "", valid: undefined },
        weight: { value: 0, valid: undefined },
      });
    },
    delPosition(elem, pos) {
      elem.positions = elem.positions.filter((e) => e.index !== pos.index);
      let i = 0;
      elem.positions.forEach((pos) => {
        pos.id = "pos" + i;
        i = i + 1;
      });
    },
    onNewCancel() {
      this.newElem = {
        name: { value: "", valid: undefined },
        positions: [],
      };
    },
    onCreate() {
      const payload = {
        name: this.newElem.name.value,
        positions: [],
      };
      this.newElem.positions.forEach((pos) => {
        payload.positions.push({
          index: pos.index.value,
          weight: pos.weight.value / 100,
        });
      });
      this.$store.dispatch("fmdRiskBenchmark/CREATE_RISKBENCHMARK", payload);
      this.onNewCancel();
    },
    onModify() {
      const payload = {
        name: this.elem.name.value,
        positions: [],
      };
      this.elem.positions.forEach((pos) => {
        payload.positions.push({
          index: pos.index.value,
          weight: pos.weight.value / 100,
        });
      });
      this.$store.dispatch("fmdRiskBenchmark/CREATE_RISKBENCHMARK", payload);
    },
    onDelete() {
      this.$store.dispatch("fmdRiskBenchmark/DELETE_RISKBENCHMARK", {
        name: this.elem.name.value,
      });
    },
  },
  components: {
    "sdx-textbox": SDXTextbox,
  },
  created() {
    document.title = "Admin>RiskBenchmarks";
  },
};
</script>

<style></style>
