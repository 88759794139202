<template>
  <div class="cortana-side-nav bg bg--gray" :class="{ hidden: !isOpen }">
    <div class="cortana-side-nav__wrapper">
      <sdx-breadcrumb />
      <!-- View Selector -->
      <div class></div>

      <div class="viewfilter-button-row">
        <div></div>
        <!-- Button View Data -->
        <div
          class="button-group qsui-play-button-group button-group--responsive"
        >
          <div
            class="loader-spinner loader-spinner--small"
            v-show="showSpinner"
          ></div>
          <div class title="Load Data" @click="addRequestToGrid()">
            <i
              class="icon icon-060-play-filled icon--s4"
              aria-hidden="true"
            ></i>
          </div>
        </div>
      </div>

      <hr />

      <qsui-accordion>
        <pfrritem-fundno-ss
          :huCol="{ DisplayName: 'FundNo' }"
          @input="onCriteriaChange($event)"
        />
        <pfrritem-perfrepdate
          @isNiw="onInput_isNiw($event)"
          @endDate="onInput_EndDate($event)"
        />
        <pfrritem-perfperiod
          :isNiw="isNiw"
          :endDate="endDate"
          @input="onPerfPeriodsChange($event)"
        />
        <accitem-crit-checkboxes
          :dataCheckBoxes="reportDataCheckBoxesFiltered"
          :desriptor="'Reports'"
          :undisableModus="canSeeAllBook"
          @input="onReportSelectionChange($event)"
        />
        <pfrritem-environment
          v-show="canSeeAllBook"
          :value="environment"
          @input="onEnvironmentChange($event)"
        />
      </qsui-accordion>
    </div>
    <div v-if="isDebug">
      start: {{ getCritValuePerfPeriod(0) }}
      {{ getCritValuePerfPeriod_requestInput(0) }}
    </div>
    <div v-if="isDebug">
      end : {{ getCritValuePerfPeriod(1) }}
      {{ getCritValuePerfPeriod_requestInput(1) }}
    </div>
  </div>
</template>

<script>
import SdxBreadcrumb from "@/components/navigation/SdxBreadcrumb";
import moment from "moment";
import { mapGetters } from "vuex";
import WVFilterAccItem_CritCheckBoxes from "./filter_items/WVFilterAccItem_CritCheckBoxes";
import perfRRAccItem_FundNo_singleSelect from "./filter_items/perf-report-request/perfRRAccItem_Fundno_singleSelect";
import perfRRAccItem_PerfPeriod from "./filter_items/perf-report-request/perfRRAccItem_PerfPeriod";
import perfRRAccItem_PerfReportingDate from "./filter_items/perf-report-request/perfRRAccItem_PerfReportingDate";
import perfRRAccItem_Environment from "./filter_items/perf-report-request/perfRRAccItem_Environment";
import { getPRep2ReportCheckBoxes } from "./ReportRequestOption.js";
import QsuiAccordion from "@/components/ui_utilities/QsuiAccordion";
import config from "@/data/config";

export default {
  data() {
    return {
      operators: ["=", ">", ">=", "<", "<=", "%", "!%"],
      retFilter: {},
      FundNo: "",
      defDate: new Date(),
      config: config,
      initialisedFor: "",
      valueRepccy: "FundCcy",
      valueAdminReportTye: "PERF_STANDARD",
      valueOutputXMLXLSX: "XLSX",
      environment: "p",
      reportShareClass: "",
      reportcheckedNames: [],
      isNiw: true,
      endDate: this.getToday(),
      reportPeriods: {
        beginDates: {
          DTD: this.getToday(),
          MTD: this.getToday(),
          YTD: this.getToday(),
          custom: this.getToday(),
        },
        checked: [0, 0, 0, 0],
      },
      isDebug: false,
      // isWorking: false
    };
  },
  watch: {
    FundNo: function () {
      if (this.FundNo) {
        this.loadPPDates(this.isNiw, this.endDate);
        this.loadPerfDates();
      }
    },
  },
  created() {
    this.defDate = this.getWorkingday(-1);
    this.$store.dispatch("pfrrStore/INITIALISE"); //load HypergridSchema, prevent errors
    this.$store.dispatch("book/LOAD_SHC"); //Shareclasses
    this.$store.dispatch("book/LOAD_PERFREPCCY"); //ReportingCcy
    this.$store.dispatch("book/LOAD_PREP2REPTYPES"); //PRep2ReportTypes (allowed reporting)
    this.$store.dispatch("nav/LOAD");
    document.title = "PFReportRequest";
  },
  computed: {
    ...mapGetters({
      isShcDataLoading: "book/isShcDataLoading",
      shcData: "book/shcData",
      isCcyDataLoading: "book/isCcyDataLoading",
      ccyData: "book/ccyData",
      prep2RepTypesDataDistinct: "book/prep2RepTypesDataDistinct",
      prep2RepTypesData: "book/prep2RepTypesData",
      isDataLoading: "book/isDataLoading", //isWorking on sending new lines to grid
    }),
    canSeeAllBook() {
      return this.$store.getters.canSeeAllBook();
    },
    reportDataCheckBoxesFiltered() {
      return getPRep2ReportCheckBoxes(
        this.prep2RepTypesDataDistinct,
        this.prep2RepTypesData,
        this.FundNo
      );
    },
    showSpinner() {
      return this.isShcDataLoading || this.isCcyDataLoading;
    },
  },
  methods: {
    getToday() {
      var dateTime = moment();
      var dateValue = moment({
        year: dateTime.year(),
        month: dateTime.month(),
        day: dateTime.date(),
      });
      return dateValue.toDate();
    },
    getCritValuePerfPeriod(index) {
      if (this.retFilter["PerfPeriod"]) {
        if (this.retFilter["PerfPeriod"].criterias[index]) {
          return this.retFilter["PerfPeriod"].criterias[index].value.getDate();
        }
      }
    },
    getCritValuePerfPeriod_requestInput(index) {
      if (this.retFilter.PerfPeriod) {
        if (this.retFilter.PerfPeriod.criterias[index]) {
          var critVal = this.retFilter.PerfPeriod.criterias[index].value;
          return moment(critVal).toDate();
        }
      }
    },
    loadPerfDates() {
      if (this.FundNo) {
        this.$store.dispatch("book/LOAD_PERFDATES", {
          FundNo: this.FundNo,
        });
      }
    },
    loadPPDates(isNiw, date) {
      if (!this.FundNo) {
        return;
      }
      var mDate = moment(date);
      if (!mDate.isValid()) {
        return;
      }
      if (isNiw) {
        this.$store.dispatch("book/LOAD_PPDATES_FOR_NIWDATE", {
          FundNo: this.FundNo,
          NiwDate: mDate.format("YYYYMMDD"),
        });
      } else {
        this.$store.dispatch("book/LOAD_PPDATES_FOR_REFDATE", {
          FundNo: this.FundNo,
          RefDate: mDate.format("YYYYMMDD"),
        });
      }
    },
    onCriteriaChange(filter) {
      if (filter.name == "FundNo" && filter.criterias && filter.criterias[0]) {
        this.FundNo = filter.criterias[0].value;
      }

      this.retFilter[filter.name] = filter;
      //make a kind of copy to signal "computed" properties to update (needed as retfilter[retfilter.name] is a new "added" property that could not be tracked otherwise)
      this.retFilter = Object.assign({}, this.retFilter);
    },
    onInput_isNiw(value) {
      if (this.isNiw != value) {
        this.loadPPDates(value, this.endDate);
      }
      this.isNiw = value;
    },
    onInput_EndDate(value) {
      if (this.endDate != value) {
        this.loadPPDates(this.isNiw, value);
      }
      this.endDate = value;
    },
    onPerfPeriodsChange(value) {
      this.reportPeriods = Object.assign({}, value);
    },
    onReportSelectionChange(event) {
      this.reportcheckedNames = event;
    },
    onEnvironmentChange(value) {
      this.environment = value;
    },
    onReportShareClassChange(event) {
      if (!!event.criterias) {
        this.reportShareClass = event.criterias[0].value;
      }
    },
    getWorkingday(days) {
      let businessDays = Math.abs(days);
      let counter = Math.sign(days); // set to 1 to count from next business day
      let increment = Math.sign(days); // set to 1 to count from next business day
      while (businessDays > 0) {
        var tmp = new Date();
        tmp.setHours(0, 0, 0, 0);
        tmp.setDate(tmp.getDate() + counter);
        counter += increment;
        switch (tmp.getDay()) {
          case 0:
          case 6:
            break; // sunday & saturday
          default:
            businessDays--;
        }
      }
      return tmp;
    },
    isBook(colName) {
      if (colName == "book") return true;
    },
    isOutputXMLXLSX(critBox) {
      if (critBox.ColType == "PFRR_XMLXLSX") return true;
    },
    addRequestToGrid() {
      if (this.retFilter.FundNo) {
        //Loop through selected Books (normally only one)
        for (let iB = 0; iB < this.retFilter.FundNo.criterias.length; iB++) {
          const crit = this.retFilter.FundNo.criterias[iB];

          //Loop through selected Reports
          for (let iR = 0; iR < this.reportcheckedNames.length; iR++) {
            const ctReportType = this.reportcheckedNames[iR];

            //Loop through selected Periods
            for (let iP = 0; iP < this.reportPeriods.checked.length; iP++) {
              const eP = this.reportPeriods.checked[iP];
              let dateEndNoTime = moment(this.endDate).toDate();
              let dateBeginNoTime = dateEndNoTime;
              let periodDesc = "custom";
              if (eP == 1) {
                switch (iP) {
                  case 0:
                    periodDesc = "DTD";
                    dateBeginNoTime = moment(
                      this.reportPeriods.beginDates.DTD
                    ).toDate();
                    break;
                  case 1:
                    periodDesc = "MTD";
                    dateBeginNoTime = moment(
                      this.reportPeriods.beginDates.MTD
                    ).toDate();
                    break;
                  case 2:
                    periodDesc = "YTD";
                    dateBeginNoTime = moment(
                      this.reportPeriods.beginDates.YTD
                    ).toDate();
                    break;
                  case 3:
                    periodDesc = "custom";
                    dateBeginNoTime = moment(
                      this.reportPeriods.beginDates.custom
                    ).toDate();
                    break;
                }

                let pfrr = {
                  date: "",
                  FundNo: crit.value,
                  Fundname: crit.value,
                  ReportType: ctReportType,
                  ReportDescription: "QSUI",
                  REFNIW: this.isNiw ? "NIW" : "REF",
                  prefix: this.environment ? this.environment : "p",
                  d_Ccy:
                    this.ccyData[crit.value] && this.ccyData[crit.value][0]
                      ? this.ccyData[crit.value][0]
                      : "EUR",
                  d_PeriodType: periodDesc,
                  d_Begin: dateBeginNoTime,
                  d_End: dateEndNoTime,
                  d_ShareClass: null,
                  // d_Details: 0
                };

                if (
                  ctReportType.startsWith("SHC") ||
                  ctReportType.startsWith("SHX")
                ) {
                  if (this.shcData[crit.value]) {
                    let shcs = this.shcData[crit.value];
                    for (let iShc = 0; iShc < shcs.length; iShc++) {
                      const shc = shcs[iShc];
                      let copyPfrr = Object.assign({}, pfrr);
                      copyPfrr.d_ShareClass = shc;
                      this.$store.dispatch(
                        "pfrrStore/ADD_REQUEST_TO_GRID",
                        copyPfrr
                      );
                    }
                  }
                } else {
                  this.$store.dispatch(
                    "pfrrStore/ADD_REQUEST_TO_GRID",
                    Object.assign({}, pfrr)
                  );
                }
              }
            }
          }
        }
      }
    },
  },
  props: {
    isOpen: { type: Boolean, default: true },
  },
  components: {
    SdxBreadcrumb,
    "accitem-crit-checkboxes": WVFilterAccItem_CritCheckBoxes,
    "pfrritem-fundno-ss": perfRRAccItem_FundNo_singleSelect,
    "pfrritem-perfperiod": perfRRAccItem_PerfPeriod,
    "pfrritem-perfrepdate": perfRRAccItem_PerfReportingDate,
    "pfrritem-environment": perfRRAccItem_Environment,
    QsuiAccordion,
  },
};
</script>
