<template>
  <sdx-textbox
    :label="label"
    :title="title"
    :disabled="true"
    :showLabel="true"
    :value="sdx_value"
  />
</template>

<script>
import { mapGetters } from "vuex";
import SDXTextbox from "@/components/ui_utilities/SDXTextbox";
import { getVal, getValV } from "@/components/admin/adminUtils.js";
import moment from "moment";
export default {
  data() {
    return {
      getValV: getValV,
    };
  },
  computed: {
    ...mapGetters({
      fsconfmap: "fmdFund/fsconfmap",
    }),
    sdx_value() {
      var _map_type = this.map_type;
      var _value = this.value;
      if (!!this.map_type) {
        if (this.map_type == "date") {
          var momo = moment(this.value);
          if (momo._isValid) {
            if (momo > moment("1900-01-01")) {
              return getValV(momo.format("DD.MM.YYYY"));
            }
            return getValV("01.01.1900");
          }
          return getValV("01.01.1900");
        } else {
          var ret = "";
          this.fsconfmap.forEach((element) => {
            if (element.map_type == _map_type && element.class == _value) {
              ret = element.desc_en;
            }
          });
          return getValV(ret);
        }
      }
      return getValV(this.value);
    },
    ts_options() {
      var ret = [];
      this.fsconfmap.forEach((element) => {
        if (element.map_type == this.map_type) {
          ret.push({ id: element.class, label: element.desc_en });
        }
      });
      return ret;
    },
  },
  props: {
    map_type: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    value: {},
  },
  components: {
    "sdx-textbox": SDXTextbox,
  },
};
</script>

<style></style>
