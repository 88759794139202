<template>
  <div class="row StandardView DashboardView">
    <div class="result-banner-and-grid" style="margin: 0px">
      <div style="margin: 10px 10px 0px; display: flex; z-index: 1000">
        <wv-date-input
          v-model="rqsDate"
          class="radio-date-picker"
          style="display: inline-block"
        />
        <select
          placeholder="Risk Trigger"
          v-model="rqsTriggerValue"
          v-on:change="resetFilterTypes()"
          style="
            font-size: 14px;
            height: 28px;
            margin-left: 10px;
            padding: 0px 12px;
            flex: 1;
          "
        >
          <option
            v-for="(item, index) in rqsTriggerData"
            :value="item.qsTriggerID"
            :key="index"
            >{{ item.triggerDesc }}</option
          >
        </select>
        <select
          placeholder="Risk Type"
          v-model="rqsTypeValue"
          :disabled="!filteredRqsTypeData || filteredRqsTypeData.length === 0"
          style="
            font-size: 14px;
            height: 28px;
            margin-left: 10px;
            padding: 0px 12px;
            flex: 1;
          "
        >
          <option
            v-for="(item, index) in filteredRqsTypeData"
            :value="index"
            :key="item"
            >{{ index }}</option
          >
        </select>

        <div class="small banner-buttons">
          <a
            @click="loadData"
            class="
              button button__icon
              banner-button
              exprequest-button-activate
              button--primary
            "
            >Run</a
          >
        </div>
      </div>
      <div
        v-if="rqsDocsTriggerData && rqsDocsTriggerData.length === 0"
        style="text-align: center; padding: 90px"
      >
        <strong>No data currently available.</strong>
      </div>

      <!--<div class="result-banner-and-grid" style="margin:0px; display: flex;"></div>-->
      <div
        class="dashTables"
        style="margin: 0px; display: flex; flex-direction: column"
      >
        <div
          class="dashTable"
          style="min-height: 60px"
          v-if="rqsDocsTriggerData && rqsDocsTriggerData.length > 0"
        >
          <div class="qxpgrid-container">
            <table
              class="qxpgrid-table disable-hover"
              width="100%"
              cellpadding="4"
              cellspacing="0"
            >
              <thead>
                <tr>
                  <th>refDate</th>
                  <th>book</th>
                  <!--<th>drilldownType</th>-->
                  <th>fundDescription</th>
                  <th>triggerDesc</th>
                  <th>typeDesc</th>
                  <th>subTypeDesc</th>
                  <th>comment</th>
                  <th>value</th>
                  <th>eValue</th>
                  <th>change</th>
                  <th>activeBreach</th>
                  <th>disableReport</th>
                  <th>prio</th>
                  <th>Verfahren</th>
                  <th>LimitProcessType</th>
                  <th>VaRProcess</th>
                  <th>qsLvl</th>
                  <th>qsTime</th>
                  <th>mandatory</th>
                  <th>escalation</th>
                  <th>dailyReport</th>
                  <th>weeklyReport</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in rqsDocsTriggerData"
                  :key="index"
                  @click="loadDrillDownData(item)"
                  style="white-space: nowrap"
                >
                  <td>{{ formatCellValue("date", item.refDate) }}</td>
                  <td>{{ item.book }}</td>
                  <!--<td>{{ item.drilldownType }}</td>-->
                  <td>{{ item.fundDescription }}</td>
                  <td>{{ item.triggerDesc }}</td>
                  <td>{{ item.typeDesc }}</td>
                  <td>{{ item.subTypeDesc }}</td>
                  <td>
                    <input
                      type="text"
                      :key="index"
                      name="comment"
                      :value="item.comment"
                      style="border: 1px solid grey; padding: 1px 4px"
                      v-on:keyup.enter="
                        submitData(
                          null,
                          'comment',
                          item.requestID,
                          $event.target.value,
                          null
                        )
                      "
                    />
                  </td>
                  <td>{{ item.value }}</td>
                  <td>{{ item.eValue }}</td>
                  <td>{{ item.change }}</td>
                  <td style="text-align: center">
                    <input
                      type="checkbox"
                      :key="index"
                      name="activeBreach"
                      :checked="item.activeBreach === 0 ? false : true"
                      v-on:click="
                        submitData(
                          null,
                          'activeBreach',
                          item.requestID,
                          $event,
                          null
                        )
                      "
                    />
                  </td>
                  <td style="text-align: center">
                    <input
                      type="checkbox"
                      :key="index"
                      name="disableReport"
                      :checked="item.disableReport === 0 ? false : true"
                      v-on:click="
                        submitData(
                          null,
                          'disableReport',
                          item.requestID,
                          $event,
                          null
                        )
                      "
                    />
                  </td>
                  <td>{{ item.prio }}</td>
                  <td>{{ item.Verfahren }}</td>
                  <td>{{ item.LimitProcessType }}</td>
                  <td>{{ item.VaRProcess }}</td>
                  <td>{{ item.qsLvl }}</td>
                  <td>{{ item.qsTime }}</td>
                  <td>{{ item.mandatory }}</td>
                  <td>{{ item.escalation }}</td>
                  <td>{{ item.dailyReport }}</td>
                  <td>{{ item.weeklyReport }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div
          class="dashTable"
          v-if="
            rqsDocsDrillDownTriggerData &&
            rqsDocsDrillDownTriggerData.length > 0
          "
        >
          <div class="qxpgrid-container">
            <table
              class="qxpgrid-table disable-hover disable-select"
              width="100%"
              cellpadding="4"
              cellspacing="0"
            >
              <thead>
                <tr>
                  <th
                    v-for="(item, index) in rqsDocsViewStrucData.hu.colForList"
                    :key="index"
                    @click="loadDrillDownData(item)"
                    style="white-space: nowrap"
                    >{{ item.DisplayName }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in rqsDocsDrillDownTriggerData"
                  :key="index"
                  style="white-space: nowrap"
                >
                  <template
                    v-for="strucItem in rqsDocsViewStrucData.hu.colForList"
                  >
                    <td
                      v-if="strucItem.ColName === 'comment'"
                      style="white-space: nowrap"
                    >
                      <input
                        type="text"
                        :key="index"
                        name="comment"
                        :value="item.comment"
                        style="border: 1px solid grey; padding: 1px 4px"
                        v-on:keyup.enter="
                          submitData(
                            rqsDocsViewStrucData.hu.WVName,
                            'comment',
                            item.requestID,
                            $event.target.value,
                            item
                          )
                        "
                      />
                    </td>
                    <td v-else style="white-space: nowrap"
                      >{{
                        formatCellValue(
                          strucItem.ColType,
                          item[strucItem.ColName]
                        )
                      }}
                    </td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.main-wrapper {
  max-height: 100vh;
}

.small.banner-buttons .button {
  padding: 2px 18px;
  min-width: unset;
  margin: 0px 10px;
  font-size: 16px;
}

.dashTables {
  display: flex;
  /*
    margin: 250px 10px 0px 10px !important;
    max-height: 100vh;
    */
  margin: -180px 10px 0px 10px !important;
  max-height: 100vh;
  min-height: 100vh;
  padding-top: 180px;
}

.dashTable {
  margin: 0px;
  flex: 1;
  /*min-height: 200px;*/
  overflow: auto;
}

.dashTable tr td {
  height: 24px;
}

.checkbox.input-field {
  margin-left: 10px;
  margin-right: 10px;
}

.dashTable.flex2 {
  flex: 2;
}

.qxpgrid-container {
  overflow: unset !important;
}

.StandardView .result-banner-and-grid {
  height: unset !important;
}

.qxpgrid-table.disable-select td {
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.qxpgrid-table.disable-hover tr:hover {
  background: unset;
}

th {
  text-align: left;
}

.c30 {
  width: 30%;
}

.c5 {
  width: 7%;
}
</style>

<script>
import ToggleButton from "@/components/ui_utilities/ToggleButton";
import WvDateInput from "./wv_filter/date_inputs/WvDateInput";
import GridAndBanners from "@/components/datagrid/GridAndBanners";
import { mapGetters } from "vuex";
import { getQueryOption_FilterString } from "./wv_filter/WvQueryOption.js";
import config from "@/data/config";
import moment from "moment";
import G from "glob";
import { throws } from "assert";

export default {
  data() {
    return {
      retFilter: {},
      filterIsOpen: true,
      rqsTriggerValue: null,
      rqsTestType: null,
      filteredRqsTypeData: { All: null },
      rqsTypeValue: "All",
      rqsDate: new Date(),
      todayDate: new Date(),
      posts: [],
      WViewName: "RQS_TestResult",
    };
  },
  computed: {
    ...mapGetters({
      rqsTriggerData: "dashboardStore/rqsTriggerData",
      rqsTypeData: "dashboardStore/rqsTypeData",
      rqsDocsTriggerData: "dashboardStore/rqsDocsTriggerData",
      rqsDocsDrillDownTriggerData: "wviewStore/wvData",
      rqsDocsViewStrucData: "wviewStore/wvInfo",
    }),
  },
  components: {
    ToggleButton,
    WvDateInput,
    GridAndBanners,
  },
  created() {
    document.title = "QS>Documentation";
  },
  mounted() {
    this.loadOptions();
  },
  methods: {
    formatCellValue(type, value) {
      if (type === "date") {
        return moment(value).format("YYYY-MM-DD");
        //return moment(value).toISOString();
      } else if (type === "logdate") {
        return moment(value).format("YYYY-MM-DD HH:mm:ss");
      } else {
        return value;
      }
    },
    loadData() {
      this.retFilterString = "";
      if (this.rqsDate !== null) {
        this.retFilterString =
          this.retFilterString +
          ` and (refDate eq ${moment(this.rqsDate).format("YYYY-MM-DD")})`;
      }
      if (this.rqsTriggerValue !== null) {
        this.retFilterString =
          this.retFilterString +
          ` and (qsTriggerID eq ${this.rqsTriggerValue})`;
      }
      if (this.rqsTypeValue !== "All") {
        this.retFilterString =
          this.retFilterString + ` and (typeDesc eq '${this.rqsTypeValue}')`;
      }

      var queryParams = {
        $filter: this.retFilterString.substr(4),
      };
      this.$store.commit("wviewStore/CLEAR_DATA");
      this.$store.dispatch("dashboardStore/LOAD_DOCS_DATA", {
        vwiewName: this.WViewName,
        queryParams: queryParams,
      });
    },
    loadOptions() {
      this.$store.dispatch("dashboardStore/LOAD_RQS_TRIGGER", {});
      this.$store.dispatch("dashboardStore/LOAD_RQS_TYPE", {});
    },
    loadDrillDownData(item) {
      var filterString = "";
      console.log("DEBUG!!!");
      let tmpString = item.drilldownType.split("?");
      console.log(tmpString);

      if (tmpString[0] == "FOND_RISIKOKENNZAHLEN2") {
        tmpString[0] = "FOND_RISIKOKENNZAHLEN";
      }
      let regExpr = /%s(<)([0-9])\w*(>)/g;

      this.$store.dispatch("wviewStore/LOAD_INFO", {
        vwiewName: tmpString[0],
      });
      if (tmpString[1] && regExpr.test(tmpString[1]) === true) {
        let dateManipulator = tmpString[1].match(regExpr);
        if (dateManipulator[0]) {
          let newRefDate = moment(item.refDate)
            .subtract(
              parseInt(
                dateManipulator[0]
                  .replace("%s", "")
                  .replace("<", "")
                  .replace(">", "")
              ),
              "days"
            )
            .format("YYYY-MM-DD");
          var filterString = tmpString[1]
            .replace(regExpr, newRefDate)
            .replace("%s", item.book);
        }
      } else if (tmpString[1]) {
        var filterString = tmpString[1]
          .replace("%s", moment(item.refDate).format("YYYY-MM-DD"))
          .replace("%s", item.book);
      }

      var queryParams = {
        $filter: filterString.replace("$filter=", ""),
      };
      this.$store.dispatch("wviewStore/LOAD_DATA", {
        vwiewName: tmpString[0],
        queryParams: queryParams,
      });
    },
    resetFilterTypes() {
      if (this.rqsTriggerValue !== null) {
        if (this.rqsTriggerData[this.rqsTriggerValue]) {
          let triggerDesc = this.rqsTriggerData.find(
            (item) => item.qsTriggerID === this.rqsTriggerValue
          ).triggerDesc;
          if (this.rqsTypeData[triggerDesc]) {
            this.filteredRqsTypeData = this.rqsTypeData[triggerDesc];
            this.rqsTypeValue = "All";
          } else {
            this.filteredRqsTypeData = { All: null };
            this.rqsTypeValue = "All";
          }
        } else {
          this.filteredRqsTypeData = { All: null };
          this.rqsTypeValue = "All";
        }
      } else {
        this.filteredRqsTypeData = { All: null };
        this.rqsTypeValue = "All";
      }
    },
    submitData(wvname, field, requestId, value, row) {
      if (field === "activeBreach" || field === "disableReport") {
        if (value.target.checked === false) {
          var newValue = 0;
        } else {
          var newValue = 1;
        }
      } else {
        var newValue = value;
      }

      let dataRow =
        row === null
          ? this.rqsDocsTriggerData.find((item) => item.requestID === requestId)
          : row;
      if (dataRow) {
        let payload = {
          WViewName: wvname === null ? this.WViewName : wvname,
          colName: field,
          dataRow: dataRow,
          newValue: newValue,
        };
        this.$store.dispatch("dashboardStore/SEND_CORRECTION", payload);
      }
    },
  },
};
</script>
