<template>
  <div class="exprequest-banner-select-destination">
    <treeselect
      v-model="value"
      :options="Destinations"
      :clearable="false"
      :disabled="disabled"
    />
    <!-- <input type="text" v-bind:placeholder=" 'plz input a ' + col.ColType " v-bind:id="inputId"> -->
  </div>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
export default {
  data() {
    return {
      value: -1,
      // intDestinations: this.Destinations
      // treeSelectOptions: [
      //   {
      //     id: '4',
      //     label: 'IPC/Daten/WViewExportPerf'
      //   },
      //   {
      //     id: '2',
      //     label: 'Flossbach/WViewExportPerf'
      //   },
      //   {
      //     id: '1',
      //     label: 'Ethenea/WViewExportPerf'
      //   }
      // ]
    };
  },
  computed: {
    disabled() {
      if (this.Destinations.length == 1) {
        return true;
      }
    },
  },
  watch: {
    value: function () {
      if (this.value) {
        this.$emit("input", this.value);
      }
    },
    Destinations: function () {
      if (this.Destinations[0]) {
        this.value = this.Destinations[0].id;
      }
    },
  },
  props: {
    Destinations: {
      type: Array,
      default: function () {
        return [
          {
            id: "-1",
            label: "No Destinations Available",
          },
        ];
      },
    },
  },
  mounted() {
    // if (this.Destinations[0]) {
    //   this.value = this.Destinations[0].id
    // }
  },
  components: {
    Treeselect,
  },
};
</script>
