<template>
  <div class="input-field input-field-flexend">
    <ul>
      <li v-for="pos in bmPositionsDisplay" :key="pos.index">{{ pos }}</li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      bm: { name: "", positions: [] },
    };
  },
  created() {
    if (!!this.bmName) this.getBM();
  },
  watch: {
    bmName: function (value) {
      if (this.bmName != this.bm.name) {
        this.$nextTick(function () {
          this.getBM();
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      perfBenchmarksData: "fmdPerfBenchmark/benchmarksData",
    }),
    bmPositionsDisplay() {
      const ret = [];
      if (
        !this.bm.name ||
        !this.bm.periods ||
        !this.bm.periods[0] ||
        !this.bm.periods[0].positions
      )
        return ret;
      this.bm.periods[0].positions.forEach((element) => {
        ret.push(`${(element.weight * 100).toFixed(2)}% ${element.index}`);
      });
      return ret;
    },
  },
  methods: {
    getBM() {
      this.$store
        .dispatch("fmdPerfBenchmark/LOAD_BENCHMARK_DATA", {
          name: this.bmName,
        })
        .then((resp) => {
          if (!!resp.data) {
            this.bm = resp.data;
          }
        });
    },
  },
  props: {
    bmName: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
};
</script>

<style></style>
