<template>
  <qsui-accordion-item
    @removeCritBox="removeCritBox"
    :class="{ regression: isRegression }"
    :startOpen="startOpen"
    :removable="false"
  >
    <p slot="header">{{ desriptor }}{{ isRegression ? ".r" : "" }}</p>
    <div slot="collapse" class="acc-flex-crit-checkboxes qx-flex-col">
      <div class="form">
        <div
          v-for="dataCheckBox in dataCheckBoxes"
          class="checkbox input-field"
        >
          <input
            type="checkbox"
            :name="dataCheckBox.name"
            :id="dataCheckBox.name"
            :value="dataCheckBox.value"
            v-model="checkedNames"
            :disabled="dataCheckBox.disabled && !undisabled"
            @change="onChange()"
          />
          <label :for="dataCheckBox.name">{{ dataCheckBox.name }}</label>
        </div>
        <!-- <span>Checked names: {{ checkedNames }}</span> -->
      </div>
      <div v-if="undisableModus" class="form">
        <div class="checkbox input-field">
          <input
            type="checkbox"
            :name="'undisable'"
            :id="'undisable'"
            :value="'undisable'"
            v-model="undisabled"
          />
          <label :for="'undisable'">{{ "Admin Modus: un-disable" }}</label>
        </div>
      </div>
    </div>
  </qsui-accordion-item>
</template>

<script>
import QsuiAccordionItem from "@/components/ui_utilities/QsuiAccordionItem";

export default {
  data() {
    return {
      checkedNames: [],
      undisabled: false,
    };
  },
  watch: {
    dataCheckBoxes: function () {
      this.initialiseCheckNamesFromProps();
    },
  },
  methods: {
    onChange() {
      this.$emit("input", this.checkedNames);
    },
    initialiseCheckNamesFromProps() {
      let origCheckNames = this.checkedNames;
      this.checkedNames = [];
      for (let iCB = 0; iCB < this.dataCheckBoxes.length; iCB++) {
        const dataCB = this.dataCheckBoxes[iCB];

        if (!!dataCB.value) {
          if (!dataCB.disabled) {
            if (dataCB.checked) {
              this.checkedNames.push(dataCB.value);
            } else if (origCheckNames.includes(dataCB.value)) {
              this.checkedNames.push(dataCB.value);
            }
          }
        }
      }
      this.$emit("input", this.checkedNames); //emit a first time at initialisation
    },
    removeCritBox() {
      // this.resetCrit()
      this.$emit("removeCritBox", this.huCol);
    },
  },
  created() {
    this.initialiseCheckNamesFromProps();
  },
  props: {
    dataCheckBoxes: {
      type: Array,
      default: function () {
        return [
          {
            name: "Check Box A",
            value: "CheckBoxA",
            checked: true,
            disabled: false,
          },
          {
            name: "Check Box B",
            value: "CheckBoxB",
            checked: false,
            disabled: false,
          },
          {
            name: "Check Box C",
            value: "CheckBoxC",
            checked: false,
            disabled: true,
          },
        ];
      },
    },
    desriptor: {
      type: String,
      default: function () {
        return "Selection";
      },
    },
    isRegression: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    startOpen: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    undisableModus: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  components: {
    QsuiAccordionItem,
  },
};
</script>
