var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.value)?_c('div',{ref:"inputField",staticClass:"input-field",class:{
    'input-field--static-label': _vm.staticLabel,
    'input-multiline': _vm.multiline,
  }},[(_vm.showLabel)?_c('label',{class:{ active: true },attrs:{"for":_vm.id}},[_c('span',{staticClass:"label"},[_vm._v(_vm._s(_vm.label))])]):_vm._e(),(_vm.multiline)?_c('textarea',{ref:"input",class:{
      validate: _vm.regex,
      invalid: _vm.regex && _vm.value.valid === false && _vm.wasChanged,
    },attrs:{"data-min-rows":"3","data-max-rows":"10","autocomplete":"on","id":_vm.id,"placeholder":_vm.label,"disabled":_vm.disabled},domProps:{"value":_vm.value.value},on:{"input":function($event){return _vm.updateValue($event.target.value)}}}):_c('input',{ref:"input",class:{
      validate: _vm.regex,
      invalid: _vm.isInvalid,
    },attrs:{"autocomplete":"on","type":_vm.type,"name":_vm.inputName,"id":_vm.id,"placeholder":_vm.label,"disabled":_vm.disabled},domProps:{"value":_vm.value.value},on:{"input":function($event){return _vm.updateValue($event.target.value)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onEnterKey.apply(null, arguments)}}}),_c('div',{staticClass:"message",staticStyle:{"pointer-events":"none"}},[_vm._m(0),_c('div',{staticStyle:{"margin-left":"24px"},domProps:{"innerHTML":_vm._s(_vm.error)}})])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"0"}},[_c('i',{staticClass:"icon icon-026-exclamation-mark-circle",attrs:{"aria-hidden":"true"}})])}]

export { render, staticRenderFns }