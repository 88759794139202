<template>
  <div class="qxpgrid-container user-select-text">
    <table class="qxpgrid-table" v-show="showGrid">
      <tbody>
        <tr class="head">
          <th
            v-for="col in cols"
            :key="col.colName"
            :align="getCellAlign(col)"
            :title="col.TextTooltip"
            >{{ col.DisplayName }}</th
          >
        </tr>
        <qxp-row-corr
          v-for="row in rows"
          :key="row._rn"
          :cols="cols"
          :row="row"
          :nowrap="nowrap"
          :WViewName="WViewName"
          :maxlengthEditableText="maxlengthEditableText"
          :checked="isRowSelected(row._rn)"
          @toggleCheckbox="toggleRowSelection($event)"
        ></qxp-row-corr>
        <tr v-if="isMoreRows" @click="showMoreRows" style="cursor: pointer">
          <td :colspan="colsLength">
            <span class="qx-link-text">{{ additionalRowText }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import QxpRowCorr from "@/components/datagrid/QxpGrid/QxpRowCorr";
import { getCellAlign } from "./QxpFunctions.js";

export default {
  data() {
    return {
      selectedRowNr: {},
      selectedRows: {},
      checkedAllRowSelected: false,
    };
  },
  watch: {
    checkedAllRowSelected: function (value) {
      if (value) {
        this.SelectAllRows();
      } else {
        if (!this.singleRowSelectionMode) {
          this.clearRowSelection();
        }
      }
    },
  },
  computed: {
    colsLength() {
      return this.$store.getters[this.storeString + "/colsLength"];
    },
    cols() {
      if (
        this.isInfoLoaded &&
        this.$store.getters[this.storeString + "/wvInfo"]
      ) {
        return this.$store.getters[this.storeString + "/wvInfo"].hu.colForList;
      }
      return {};
    },
    rows() {
      if (
        this.isDataLoaded &&
        this.$store.getters[this.storeString + "/wvData"]
      ) {
        if (this.$store.getters[this.storeString + "/wvData"].length > 0) {
          return this.$store.getters[this.storeString + "/wvData"].slice(
            0,
            this.$store.getters[this.storeString + "/maxRows"]
          );
        }
      }
      return [];
    },
    storeString() {
      if (this.useSecondaryStore) {
        return "wviewStoreB";
      }
      return "wviewStore";
    },
    maxlengthEditableText() {
      let ret = {};
      for (const [key, col] of Object.entries(this.cols)) {
        var maxLen = 0;
        var SqlColName = col.SqlColName;
        if (["name", "text"].includes(col.ColType) && !!col.StoredProcedure) {
          this.rows.forEach((row) => {
            if (row[SqlColName]) {
              let ctLen = row[SqlColName].length;
              if (ctLen && ctLen > maxLen) {
                maxLen = ctLen;
              }
            }
          });
        }
        ret[col.ColName] = maxLen;
      }
      return ret;
    },
    wvInfo() {
      if (
        this.isInfoLoaded &&
        this.$store.getters[this.storeString + "/wvInfo"]
      ) {
        return this.$store.getters[this.storeString + "/wvInfo"];
      }
    },
    WViewName() {
      if (
        this.isInfoLoaded &&
        this.$store.getters[this.storeString + "/wvInfo"]
      ) {
        return this.$store.getters[this.storeString + "/wvInfo"].hu.WVName;
      }
    },
    isInfoLoaded() {
      return this.$store.getters[this.storeString + "/isInfoLoaded"];
    },
    isDataLoaded() {
      return this.$store.getters[this.storeString + "/isDataLoaded"];
    },
    isDataLoading() {
      if (this.$store.getters[this.storeString + "/isDataLoading"]) {
        this.clearRowSelection();
      }
      return this.$store.getters[this.storeString + "/isDataLoading"];
    },
    showGrid() {
      return this.isInfoLoaded && this.isDataLoaded;
    },
    isMoreRows() {
      if (
        this.$store.getters[this.storeString + "/rowsLength"] > 0 &&
        this.$store.getters[this.storeString + "/rowsLength"] >
          this.$store.getters[this.storeString + "/maxRows"]
      ) {
        return true;
      }
    },
    additionalRowText() {
      if (this.isMoreRows) {
        return `Only the first ${
          this.$store.getters[this.storeString + "/maxRows"]
        } (of ${
          this.$store.getters[this.storeString + "/rowsLength"]
        }) rows are displayed, click to display more`;
      }
    },
  },
  methods: {
    getCellAlign: getCellAlign,
    SelectAllRows() {
      this.showAllRows();
      this.clearRowSelection();
      this.rows.forEach((row) => {
        this.selectedRowNr[row._rn] = true;
        this.selectedRows[row._rn] = row;
      });
      this.selectedRowNr = { ...this.selectedRowNr };
      this.selectedRows = { ...this.selectedRows };
      let emit = [];
      Object.values(this.selectedRows).forEach((value) => {
        if (value) {
          emit.push(value);
        }
      });
      this.$emit("selectedRows", emit);
    },
    toggleRowSelection(row) {
      //store true/false
      if (this.selectedRowNr[row._rn] == undefined) {
        if (this.singleRowSelectionMode) {
          //row is new, so deselected everything else if in singleselect mode
          this.selectedRowNr = {};
          this.selectedRows = {};
        }
        this.selectedRowNr[row._rn] = true;
        this.selectedRowNr = { ...this.selectedRowNr };
      } else {
        this.selectedRowNr[row._rn] = !this.selectedRowNr[row._rn];
      }

      //store rows if true
      this.selectedRows[row._rn] = undefined; //remove
      if (this.selectedRowNr[row._rn]) {
        this.selectedRows[row._rn] = row;
      }
      this.selectedRows = { ...this.selectedRows };
      let emit = [];
      Object.values(this.selectedRows).forEach((value) => {
        if (value) {
          emit.push(value);
        }
      });
      this.$emit("selectedRows", emit);
    },
    isRowSelected(rn) {
      return this.selectedRowNr[rn];
    },
    clearRowSelection() {
      this.selectedRowNr = {};
      this.selectedRows = {};
      this.$emit("selectedRows", []);
    },
    clearRowSelectionAndReset() {
      //do not delete, used from outside (this.$ref...)
      this.checkedAllRowSelected = false;
      this.selectedRowNr = {};
      this.selectedRows = {};
      this.$emit("selectedRows", []);
    },
    showMoreRows() {
      this.$store.dispatch("wviewStore/SHOW_MORE");
    },
    showAllRows() {
      this.$store.dispatch("wviewStore/SHOW_MAX");
    },
  },
  // beforeUpdate() {
  //   this.$emit('qxpGridBeforeUpdate')
  // },
  // updated() {
  //   this.$emit('qxpGridUpdated')
  // },
  props: {
    showRowsInDiffOnly: { type: Boolean, default: true },
    isExpRequestModus: { type: Boolean, default: false },
    nowrap: { type: Boolean, default: false },
    singleRowSelectionMode: { type: Boolean, default: false },
    useSecondaryStore: { type: Boolean, default: false },
  },
  components: {
    QxpRowCorr,
  },
};
</script>
