<template>
  <qsui-accordion-item
    @removeCritBox="removeCritBox"
    :class="{ regression: isRegression }"
  >
    <p slot="header">{{ col.DisplayName }}{{ isRegression ? ".r" : "" }}</p>
    <div slot="collapse">
      <sdx-textbox
        v-model="oValueOperatorMin"
        label="min"
        type="number"
        :title="textBoxTitleMin"
        @input="onChange()"
        @onEnterKey="$emit('onEnterKey')"
      />
      <div class="input-field" v-html="textDisplay">
        <!-- <p ></p> -->
        <!-- <p>&nbsp;&lt;&nbsp;x&nbsp;&lt;&nbsp;</p> -->
      </div>
      <sdx-textbox
        v-model="oValueOperatorMax"
        label="max"
        type="number"
        :title="textBoxTitleMax"
        @input="onChange()"
        @onEnterKey="$emit('onEnterKey')"
      />
    </div>
  </qsui-accordion-item>
</template>

<script>
import QsuiAccordionItem from "@/components/ui_utilities/QsuiAccordionItem";
import SDXTextbox from "@/components/ui_utilities/SDXTextbox";

export default {
  data() {
    return {
      filter: {
        name: "",
        binaryOperator: "and",
        criterias: [
          {
            operator: ">=",
            value: undefined,
          },
          {
            operator: "<=",
            value: undefined,
          },
        ],
      },
      oValueOperatorMin: { value: "", valid: undefined },
      oValueOperatorMax: { value: "", valid: undefined },
      textDisplay: "&lt;&nbsp;x&nbsp;&lt;",
    };
  },
  mounted() {
    // new sdx.Select(this.$refs.dropdown);
  },
  computed: {
    col() {
      this.filter.name = this.huCol.ColName;
      return this.huCol;
    },
    textBoxTitleMax() {
      var ret = `number filter: ${this.huCol.DisplayName} `;
      if (!!this.oValueOperatorMax.value) {
        return ret + ` <= ${this.oValueOperatorMax.value}`;
      }
      return "";
    },
    textBoxTitleMin() {
      var ret = `number filter: ${this.huCol.DisplayName} `;
      if (!!this.oValueOperatorMin.value) {
        return ret + ` >= ${this.oValueOperatorMin.value}`;
      }
      return "";
    },
  },
  methods: {
    onChange() {
      var factor = this.huCol.ColType == "percmath" ? 100 : 1;
      this.filter.criterias[0].value = this.oValueOperatorMin.value / factor;
      this.filter.criterias[1].value = this.oValueOperatorMax.value / factor;
      this.$emit("input", this.filter);
    },
    removeCritBox() {
      // this.resetCrit()
      this.$emit("removeCritBox", this.huCol);
    },
  },
  props: {
    huCol: {
      type: Object,
      default: function () {
        return {
          ColName: "DefaultValue",
          ColType: "name",
        };
      },
    },
    isRegression: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  components: {
    QsuiAccordionItem,
    "sdx-textbox": SDXTextbox,
  },
};
</script>
