<template>
  <div class="cortana-side-nav bg bg--gray" :class="{ hidden: !isOpen }">
    <div class="cortana-side-nav__wrapper qsui-admin-wrapper">
      <div class="text-h4">User List:</div>
      <div class="qsui-admin-list-filerplus">
        <a
          class="button button__icon button--secondary qsui-admin-list-button"
          href="javascript:void(0);"
          @click="plusPerson()"
        >
          <i class="icon icon-061-plus" aria-hidden="true"></i>
        </a>
      </div>
      <div class="qsui-admin-list-filerplus">
        <div>
          <sdx-textbox v-model="oValueFilter" label="filter..." type="text" />
        </div>
        <div class="checkbox input-field">
          <input
            type="checkbox"
            name="dummy"
            id="active"
            value="active"
            v-model="checkedActive"
          />
          <label for="active">active only</label>
        </div>
      </div>
      <div
        v-if="showSpinner"
        class="loader-spinner loader-spinner--small"
      ></div>
      <ul v-else class="qsui-admin-list">
        <li
          v-for="person in filteredUsersData"
          class="qsui-admin-list-item qsui-admin-list-item-click"
          :class="{ inactive: person.status != 1 }"
          @click="loadPerson(person)"
        >
          {{ person.display }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SDXTextbox from "@/components/ui_utilities/SDXTextbox";
export default {
  data() {
    return {
      oValueFilter: { value: "", valid: undefined },
      checkedActive: true,
    };
  },
  created() {
    this.$store.dispatch("adminUser/LOAD_DATA_USERS");
  },
  computed: {
    ...mapGetters({
      usersData: "adminUser/usersData",
      personData: "adminUser/personData",
      isUsersLoading: "adminUser/isUsersLoading",
    }),
    filteredUsersData() {
      if (this.usersData) {
        const lowcasevalue = this.oValueFilter.value.toLowerCase();
        const activeonly = this.checkedActive;
        return this.usersData.filter((user) => {
          let statusFilter = (activeonly && user.status == 1) || !activeonly;
          let filterFilter =
            !lowcasevalue || user.display.toLowerCase().includes(lowcasevalue);
          return statusFilter && filterFilter;
        });
      }
      return [];
    },
    showSpinner() {
      return this.isUsersLoading;
    },
  },
  methods: {
    loadPerson(person) {
      this.$store.dispatch("adminUser/LOAD_DATA_PERSON", {
        UserNr: person.UserNr,
      });
      this.$store.dispatch("adminUserMembership/LOAD_UMSSFORUSER_DATA", {
        UserNr: person.UserNr,
      });
    },
    plusPerson() {
      this.$store.dispatch("adminUser/CLEAR_PERSON");
    },
  },
  props: {
    isOpen: { type: Boolean, default: true },
  },
  components: {
    "sdx-textbox": SDXTextbox,
  },
};
</script>
