const PREP2_DATA_CHECKBOXES = [
  {
    name: "STD_PE: Standard",
    value: "STD_PE",
    checked: false,
    disabled: false,
  },
  {
    name: "STD_QD: Standard Daily",
    value: "STD_QD",
    checked: false,
    disabled: false,
  },
  {
    name: "SHC_PE: ShareClass",
    value: "SHC_PE",
    checked: false,
    disabled: false,
  },
  {
    name: "SHC_QD: ShareClass Daily",
    value: "SHC_QD",
    checked: false,
    disabled: false,
  },
  {
    name: "SHC_V_QD: ShareClass Valuation Daily",
    value: "SHC_V_QD",
    checked: false,
    disabled: false,
  },
  {
    name: "PLX_PE: PL-Explanation",
    value: "PLX_PE",
    checked: false,
    disabled: false,
  },
  {
    name: "PLX_QD: PL-Explanation Daily",
    value: "PLX_QD",
    checked: false,
    disabled: false,
  },
  {
    name: "SHX_PE: ShareClass & PL-Explanation",
    value: "SHX_PE",
    checked: false,
    disabled: false,
  },
  {
    name: "OPP_PE: Open Position",
    value: "OPP_PE",
    checked: false,
    disabled: false,
  },
  {
    name: "PDC_PE: Product",
    value: "PDC_PE",
    checked: false,
    disabled: false,
  },
  {
    name: "PDC_QD: Product Daily",
    value: "PDC_QD",
    checked: false,
    disabled: false,
  },
  {
    name: "DLY_QD: Daily values for QS",
    value: "DLY_QD",
    checked: false,
    disabled: false,
  },
  {
    name: "Category",
    value: "Category",
    checked: false,
    disabled: true,
  },
  {
    name: "FX Rates",
    value: "CheckBoxA",
    checked: false,
    disabled: true,
  },
  {
    name: "TRX: Transactions",
    value: "TRX",
    checked: false,
    disabled: true,
  },
  {
    name: "Benchmark",
    value: "Benchmark",
    checked: false,
    disabled: true,
  },
];

export function getPRep2ReportCheckBoxes(RepTypes, RepTypesPerBooks, book) {
  const ret = [];
  let firstAllowedReportFound = true;
  for (const key in PREP2_DATA_CHECKBOXES) {
    let checkBox = PREP2_DATA_CHECKBOXES[key];
    if (!!RepTypes && RepTypes.includes(checkBox.value)) {
      if (
        !!book &&
        !!RepTypesPerBooks[book] &&
        RepTypesPerBooks[book].includes(checkBox.value)
      ) {
        ret.push({
          name: checkBox.name,
          value: checkBox.value,
          checked: firstAllowedReportFound ? true : false,
          disabled: false,
        });
        if (firstAllowedReportFound) {
          firstAllowedReportFound = false;
        }
      } else {
        ret.push({
          name: checkBox.name,
          value: checkBox.value,
          checked: false,
          disabled: true,
        });
      }
    }
  }
  return ret;
}
