<template>
  <qsui-accordion-item :removable="false">
    <p slot="header">Reporting Periods (BeginDates)</p>
    <div slot="collapse">
      <form class="date-period-perf-form">
        <!-- Period Start Date(s) -->
        <!-- Period Start Date DTD -->
        <div class="date-period-perf-period">
          <div class="checkbox input-field">
            <input
              type="checkbox"
              name="dummy"
              id="DTD"
              value="DTD"
              v-model="checkedDTD"
            />
            <label for="DTD"
              >DTD (BeginDate:
              {{ momentFormatDate(reportPeriods.beginDates.DTD) }})</label
            >
          </div>
        </div>
        <!-- Period Start Date MTD -->
        <div class="date-period-perf-period">
          <div class="checkbox input-field">
            <input
              type="checkbox"
              name="dummy"
              id="MTD"
              value="MTD"
              v-model="checkedMTD"
            />
            <!-- <label for="MTD">MTD ({{momentFormatDate(reportPeriods.beginDates.MTD)}})</label> -->
            <label for="MTD"
              >MTD (BeginDate:
              {{ momentFormatDate(reportPeriods.beginDates.MTD) }})</label
            >
          </div>
        </div>
        <!-- Period Start Date YTD -->
        <div class="date-period-perf-period">
          <div class="checkbox input-field">
            <input
              type="checkbox"
              name="dummy"
              id="YTD"
              value="YTD"
              v-model="checkedYTD"
            />
            <!-- <label for="YTD">YTD ({{momentFormatDate(reportPeriods.beginDates.YTD)}})</label> -->
            <label for="YTD"
              >YTD (BeginDate:
              {{ momentFormatDate(reportPeriods.beginDates.YTD) }})</label
            >
          </div>
        </div>
        <!-- Period Start Date Custom -->
        <div class="date-period-perf-period">
          <div class="checkbox input-field date-period-perf-from">
            <input
              type="checkbox"
              name="dummy"
              id="custom"
              value="custom"
              v-model="checkedCustom"
            />
            <label for="custom">Custom BeginDate:</label>
          </div>
          <div class="date-period-perf-to">
            <div class="date-period-perf-plusminus-buttons">
              <i
                class="icon icon-052-minus wvfilter-icon"
                title="One day less"
                aria-hidden="true"
                @click="onDateMinus_CustomDate()"
              ></i>
              <i
                class="
                  icon icon-071-retry
                  wvfilter-icon
                  icon
                  wvfilter-green-button
                "
                title="Reset to default date"
                aria-hidden="true"
                @click="onDateReset_CustomDate()"
              ></i>
              <i
                class="icon icon-061-plus wvfilter-icon"
                title="One day more"
                aria-hidden="true"
                @click="onDatePlus_CustomDate()"
              ></i>
            </div>
            <wv-date-input
              class="date-period-perf-picker"
              v-model="reportPeriods.beginDates.custom"
              :enabledDates="enabledDates"
            />
          </div>
        </div>
      </form>
    </div>
  </qsui-accordion-item>
</template>

<script>
import { mapGetters } from "vuex";
import QsuiAccordionItem from "@/components/ui_utilities/QsuiAccordionItem";
import Treeselect from "@riophae/vue-treeselect";
import WvDateInput from "./../../date_inputs/WvDateInput";
import moment from "moment";

export default {
  data() {
    return {
      checkedDTD: true,
      checkedMTD: false,
      checkedYTD: false,
      checkedCustom: false,
      ppValueLastAvailableDate: this.getToday(),
      // isNiw: true,
      // endDate: this.getToday(),
      reportPeriods: {
        beginDates: {
          DTD: this.getToday(),
          MTD: this.getToday(),
          YTD: this.getToday(),
          custom: this.getToday(),
        },
        checked: [0, 0, 0, 0],
      },
      format: "DD.MM.YYYY",
      isDebug: false,
    };
  },
  watch: {
    endDate: function () {
      this.onChangeEndDate();
      this.refreshPeriods();
      this.$emit("input", this.reportPeriods);
    },
    reportPeriodsCustomBeginDate: function () {
      this.refreshPeriods();
      this.$emit("input", this.reportPeriods);
    },
    isNiw: function () {
      this.refreshPeriods();
      this.$emit("input", this.reportPeriods);
    },
    checkedDTD: function () {
      this.refreshPeriods();
      this.$emit("input", this.reportPeriods);
    },
    checkedMTD: function () {
      this.refreshPeriods();
      this.$emit("input", this.reportPeriods);
    },
    checkedYTD: function () {
      this.refreshPeriods();
      this.$emit("input", this.reportPeriods);
    },
    checkedCustom: function () {
      this.refreshPeriods();
      this.$emit("input", this.reportPeriods);
    },
    ppDatesData: function () {
      this.refreshPeriods();
      this.$emit("input", this.reportPeriods);
    },
  },
  computed: {
    ...mapGetters({
      isPerfDatesDataLoading: "book/isPerfDatesDataLoading",
      isPerfDatesDataLoaded: "book/isPerfDatesDataLoaded",
      perfDatesData: "book/perfDatesData",
      isPPDatesDataLoading: "book/isPPDatesDataLoading",
      isPPDatesDataLoaded: "book/isPPDatesDataLoaded",
      ppDatesData: "book/ppDatesData",
    }),
    reportPeriodsCustomBeginDate() {
      //computed  needed for watcher
      return this.reportPeriods.beginDates.custom;
    },
    enabledDates() {
      if (this.isPerfDatesDataLoaded && this.perfDatesData) {
        var enabledDates = [];
        for (let iPD = 0; iPD < this.perfDatesData.length; iPD++) {
          const ePD = this.perfDatesData[iPD];
          if (this.isNiw) {
            if (ePD.NiwDate) {
              if (iPD == 0) {
                this.ppValueLastAvailableDate = ePD.NiwDate;
              }
              enabledDates.push(ePD.NiwDate.substring(0, 10));
            }
          } else {
            if (ePD.RefDate) {
              if (iPD == 0) {
                this.ppValueLastAvailableDate = ePD.RefDate;
              }
              enabledDates.push(ePD.RefDate.substring(0, 10));
            }
          }
        }
        return enabledDates;
      }
    },
    beginFromPPDatesDTD() {
      if (this.isPPDatesDataLoaded && this.ppDatesData && this.ppDatesData[0]) {
        return this.isNiw
          ? this.ppDatesData[0].niwDateBeginDTD
          : this.ppDatesData[0].refDateBeginDTD;
      } else {
        return this.ppValueLastAvailableDate;
      }
    },
    beginFromPPDatesMTD() {
      if (this.isPPDatesDataLoaded && this.ppDatesData && this.ppDatesData[0]) {
        return this.isNiw
          ? this.ppDatesData[0].niwDateBeginMTD
          : this.ppDatesData[0].refDateBeginMTD;
      } else {
        return this.ppValueLastAvailableDate;
      }
    },
    beginFromPPDatesYTD() {
      if (this.isPPDatesDataLoaded && this.ppDatesData && this.ppDatesData[0]) {
        return this.isNiw
          ? this.ppDatesData[0].niwDateBeginYTD
          : this.ppDatesData[0].refDateBeginYTD;
      } else {
        return this.ppValueLastAvailableDate;
      }
    },
  },
  methods: {
    getToday() {
      var dateTime = moment();
      var dateValue = moment({
        year: dateTime.year(),
        month: dateTime.month(),
        day: dateTime.date(),
      });
      return dateValue.toDate();
    },
    refreshPeriods() {
      this.reportPeriods.beginDates.DTD = this.beginFromPPDatesDTD;
      this.reportPeriods.beginDates.MTD = this.beginFromPPDatesMTD;
      this.reportPeriods.beginDates.YTD = this.beginFromPPDatesYTD;
      this.reportPeriods.checked[0] = this.checkedDTD ? 1 : 0;
      this.reportPeriods.checked[1] = this.checkedMTD ? 1 : 0;
      this.reportPeriods.checked[2] = this.checkedYTD ? 1 : 0;
      this.reportPeriods.checked[3] = this.checkedCustom ? 1 : 0;
    },
    onChangeEndDate() {
      if (this.reportPeriods.beginDates.custom > this.endDate)
        this.reportPeriods.beginDates.custom = new Date(this.endDate); //copy of the date (not pointer)
    },
    onDateMinus_CustomDate() {
      // you have to set the this.myDate again, so vue can detect it changed
      this.reportPeriods.beginDates.custom = new Date(
        this.reportPeriods.beginDates.custom.setDate(
          this.reportPeriods.beginDates.custom.getDate() - 1
        )
      );
      //this.onChangeRadio_btw()
    },
    onDatePlus_CustomDate() {
      // you have to set the this.myDate again, so vue can detect it changed
      this.reportPeriods.beginDates.custom = new Date(
        this.reportPeriods.beginDates.custom.setDate(
          this.reportPeriods.beginDates.custom.getDate() + 1
        )
      );
      //this.onChangeRadio_btw()
    },
    onDateReset_CustomDate() {
      this.reportPeriods.beginDates.custom = this.endDate;
    },
    momentFormatDate(value) {
      if (this.isPPDatesDataLoaded && this.ppDatesData) {
        return moment(value).format(this.format);
      } else {
        return "-";
      }
    },
  },
  created() {
    this.$emit("input", this.reportPeriods);
  },
  mounted() {
    //this.onChangeRadio_btw()
  },
  props: {
    isNiw: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    endDate: {
      type: Date,
      default: function () {
        var dateTime = moment();
        return moment({
          year: dateTime.year(),
          month: dateTime.month(),
          day: dateTime.date(),
        }).toDate();
      },
    },
  },
  components: {
    QsuiAccordionItem,
    Treeselect,
    "wv-date-input": WvDateInput,
  },
};
</script>

<style lang="scss">
$fontSize: 14px;
.date-period-perf-form {
  font-size: $fontSize;
  width: 100%;
  .date-period-perf-period {
    display: flex;
    justify-content: space-between;
    .date-period-perf-picker {
      flex-basis: 180px;
      min-width: 80px;
    }
    .date-period-perf-from,
    .date-period-perf-to {
      display: flex;
      justify-content: stretch;
      flex-basis: 380px;

      .date-period-perf-plusminus-buttons {
        display: flex;
        justify-content: stretch;
        flex-basis: 70px;
        .date-period-perf-plusminus-button {
          min-width: 25px;
          height: 26px;
          padding: 0px;
          flex-basis: 90px;
          border-width: 1px;
          margin-top: 1px;
          margin-right: 1px;
          margin-bottom: 0px;
          margin-left: 1px;
          i {
            margin-right: 0px;
            margin-top: 4px;
          }
        }
      }
    }
    .date-period-perf-from {
      font-size: $fontSize;
    }
    .date-period-perf-to {
      font-size: $fontSize;
    }
  }
  .date-period-perf-quick {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    .date-period-perf-quick-button {
      font-size: $fontSize;
      min-width: 10px;
      height: 26px;
      padding: 0px;
      flex-basis: 180px;
      border-width: 1px;
      margin-top: 1px;
      margin-right: 1px;
      margin-bottom: 0px;
      margin-left: 1px;
      // text-align: left;
      i {
        margin-right: 0px;
        margin-top: 7px;
        line-height: 10px;
        // display: none;
      }
    }
  }
}
</style>
