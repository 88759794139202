<template>
  <div>
    <div v-if="showBanner" class="bg bg--gray result-banner exprequest">
      <div class="result-banner-left exprequest">
        <select-format-exp-pdf
          v-show="showSelectFormat"
          @input="onFormatInput($event)"
        />
        <select-holding-period
          v-show="showSelectHoldingPeriod"
          @input="onHoldingPeriodInput($event)"
        />
        <select-language
          v-show="showSelectLanguage"
          @input="onLanguageInput($event)"
        />
        <!-- <div
        class="result-banner-icons"
        title="Clear Row Selection"
        @click="$emit('triggerClearRowSelection')"
      >
        <i class="icon icon-071-retry wvfilter-icon wvfilter-green-button" aria-hidden="true"></i>
      </div> -->
        <div class="banner-buttons">
          <a
            class="button button__icon button--primary banner-button"
            href="javascript:void(0);"
            @click="sendExportRequest()"
            >export {{ gridSelectedRowLength }} selected files</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import selectFormatExpPDF from "@/components/datagrid/inputForm/selectFormatExpPDF";
import selectHoldingPeriod from "@/components/datagrid/inputForm/selectHoldingPeriod";
import selectLanguage from "@/components/datagrid/inputForm/selectLanguage";
import moment from "moment";
export default {
  data() {
    return {
      format: 0,
      holdingPeriod: 20,
      language: "DE",
    };
  },
  computed: {
    ...mapGetters({
      _isDataLoaded: "wviewStore/isDataLoaded",
      ExpPDFRequestReportType: "wviewStore/ExpPDFRequestReportType",
      // isSentLoaded: 'pferStore/isSentLoaded',
      // pfrrSent: 'pferStore/pfrrSent'
    }),
    showSelectFormat() {
      if (this.ExpPDFRequestReportType == "LiquidityExcel") {
        this.format = 1; //xls
        return false;
      }
      if (
        this.ExpPDFRequestReportType == "AGMHRisk" ||
        this.ExpPDFRequestReportType == "CreditRisk" ||
        this.ExpPDFRequestReportType == "SpecialMARisk"
      ) {
        this.format = 0; //pdf
        return false;
      }
      return true;
    },
    showSelectHoldingPeriod() {
      return this.ExpPDFRequestReportType == "Risikobericht";
    },
    showSelectLanguage() {
      return (
        this.ExpPDFRequestReportType == "Risikobericht" ||
        this.ExpPDFRequestReportType == "LiqRisk"
      );
    },
    showBanner() {
      return this._isDataLoaded;
    },
    gridSelectedRowLength() {
      if (this.selectedRows) {
        return this.selectedRows.length;
      } else {
        return 0;
      }
    },
    distinctSelectedReport() {
      var ret = [];
      if (this.selectedRows) {
        for (let iR = 0; iR < this.selectedRows.length; iR++) {
          const ctR = this.selectedRows[iR];
          if (ctR.ReportType) {
            if (ret.includes(ctR.ReportType)) {
              continue;
            } else {
              ret.push(ctR.ReportType);
            }
          }
        }
      }
      return ret.sort();
    },
  },
  methods: {
    ReloadData() {
      this.$emit("triggerClearRowSelection");
      this.$store.dispatch("wviewStore/RELOAD_DATA");
    },
    sendExportRequest() {
      for (let iR = 0; iR < this.selectedRows.length; iR++) {
        const ctR = this.selectedRows[iR];
        let ctBook = ctR.book;
        if (this.ExpPDFRequestReportType == "LiquidityExcel") {
          ctBook = ctR.name;
        }
        const ctDate = moment(ctR.date).format("YYYY-MM-DD");
        const ctLex = ctR.lex;

        const payload = {
          reportType: this.ExpPDFRequestReportType,
          book: ctBook,
          date: ctDate,
          lex: ctLex,
          format: this.format == 0 ? "pdf" : "xlsx",
          holdingPeriodDay: this.holdingPeriod == 1 ? 1 : 20,
          language: this.language,
        };
        // console.log(payload)
        this.$store.dispatch("wviewStore/LOAD_DOCUMENT_RISK", payload);
      }
      this.$emit("triggerClearRowSelection");
    },
    onFormatInput(value) {
      this.format = value;
    },
    onHoldingPeriodInput(value) {
      this.holdingPeriod = value;
    },
    onLanguageInput(value) {
      this.language = value;
    },
  },
  props: {
    selectedRows: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  components: {
    "select-format-exp-pdf": selectFormatExpPDF,
    selectHoldingPeriod,
    selectLanguage,
  },
};
</script>
