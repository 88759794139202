<template>
  <div class="container" :style="style">
    <div class="row" :style="style">
      <!--
        This component should not have margin / padding as some embedded
        components may require full space
      -->
      <div :class="containerClass" :style="style">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    medium: { type: Number, default: 10 },
    large: { type: Number, default: 8 },
    "full-height": { type: Boolean, default: false },
  },
  computed: {
    containerClass: function () {
      let medium = parseInt(this.$props.medium);
      let offsetMedium =
        this.$props.medium < 12 ? parseInt((12 - this.$props.medium) / 2) : 0;
      let large = parseInt(this.$props.large);
      let largeOffset =
        this.$props.large < 12 ? parseInt((12 - this.$props.large) / 2) : 0;
      return `col-xs-12 col-md-${medium} offset-md-${offsetMedium} col-lg-${large} offset-lg-${largeOffset}`;
    },

    style() {
      return {
        height: this.fullHeight ? "100%" : "auto",
      };
    },
  },
};
</script>
