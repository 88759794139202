<template>
  <qsui-accordion-item>
    <p slot="header">Environment(admin)</p>
    <div slot="collapse" class="mandatSelect">
      <treeselect
        v-model="valueProxy"
        :clearable="false"
        :options="optionsFromProps"
      />
    </div>
  </qsui-accordion-item>
</template>

<script>
import QsuiAccordionItem from "@/components/ui_utilities/QsuiAccordionItem";
import Treeselect, { ASYNC_SEARCH } from "@riophae/vue-treeselect";
import { mapGetters } from "vuex";

export default {
  computed: {
    valueProxy: {
      get() {
        return this.value;
      },
      set(newSelectedValue) {
        this.$emit("input", newSelectedValue);
      },
    },
  },
  props: {
    optionsFromProps: {
      type: Array,
      default: function () {
        return [
          {
            id: "p",
            label: "p: production",
          },
          {
            id: "r",
            label: "r: regression",
          },
          {
            id: "t",
            label: "t: testing",
          },
        ];
      },
    },
    value: {
      type: String,
      default: function () {
        return null;
      },
    },
  },
  components: {
    QsuiAccordionItem,
    Treeselect,
  },
};
</script>
