<template>
  <div class="qsui-admin-container">
    <div v-if="showSpinner" class="loader-spinner loader-spinner--small"></div>
    <div v-else-if="!isElemLoadedAndValid">
      <div class="qsui-admin-form col-sm-12 col-md-12 col-lg-10 col-xl-7">
        <div class="text-h4">Create New Group</div>
        <div class="qsui-admin-subform">
          <div class="qsui-admin-subform-row">
            <sdx-textbox
              v-model="newElem.Name"
              label="Group Name"
              type="text"
              :showLabel="true"
              :regex="config.regex.notEmpty"
            />
            <sdx-textbox
              v-model="newElem.Logo"
              label="Logo"
              type="text"
              :showLabel="true"
            />
          </div>
          <div class="qsui-admin-subform-row">
            <sdx-textbox
              v-model="newElem.Description"
              label="Description"
              type="text"
              :showLabel="true"
              :regex="config.regex.notEmpty"
            />
            <select-boolean
              class="input-field-small"
              label="AllBookGroup"
              title="Member of this group can see all books if True"
              v-model="newElem.AllBookGroup"
            />
          </div>
        </div>
        <div class="qsui-admin-buttons">
          <a
            class="button button--secondary"
            href="javascript:void(0);"
            @click="onNewCancel"
            >cancel</a
          >
          <a
            class="button button--primary"
            href="javascript:void(0);"
            :class="{ disabled: !isNewElemValid }"
            @click="onCreate"
            >create</a
          >
        </div>
      </div>
    </div>
    <div v-else>
      <div class="qsui-admin-form col-sm-12 col-md-12 col-lg-10 col-xl-7">
        <div class="text-h4">Group: {{ elem.Name.value }}</div>
        <div v-show="elem.AdminGroup == 1" class="text">
          {{ messageElemType }}
        </div>
        <div class="qsui-admin-subform">
          <div class="qsui-admin-subform-row">
            <sdx-textbox
              v-model="elem.GroupID"
              label="GroupID"
              type="text"
              :showLabel="true"
              :disabled="true"
            />
            <sdx-textbox
              v-model="elem.Name"
              label="Group Name"
              type="text"
              :showLabel="true"
              :regex="config.regex.notEmpty"
            />
          </div>
          <div class="qsui-admin-subform-row">
            <sdx-textbox
              v-model="elem.Logo"
              label="Logo"
              type="text"
              :showLabel="true"
              :disabled="elem.AdminGroup == 1"
            />
            <sdx-textbox
              v-model="elem.Description"
              label="Description"
              type="text"
              :showLabel="true"
              :disabled="elem.AdminGroup == 1"
            />
            <select-boolean
              class="input-field-small"
              label="AllBookGroup"
              title="Member of this group can see all books if True"
              v-model="elem.AllBookGroup"
            />
          </div>
        </div>
        <div class="qsui-admin-buttons">
          <a
            v-show="elem.AdminGroup != 1"
            class="button button--primary"
            :class="{ disabled: !hasChanged || !isModifyElemValid }"
            href="javascript:void(0);"
            @click="onModify"
            >change</a
          >
          <a
            v-show="elem.AdminGroup != 1"
            class="button button--primary qsui-button-red"
            href="javascript:void(0);"
            @click="onDelete"
            >delete</a
          >
        </div>
      </div>
      <div
        v-if="elem.AllBookGroup"
        class="qsui-admin-form col-sm-12 col-md-10 col-lg-8 col-xl-7"
      >
        <div class="text-h4">Book Membership</div>
        <div class="text">This Group can see all Books</div>
      </div>
      <bms-form v-else></bms-form>
      <ums-form />
      <pms-form />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SDXTextbox from "@/components/ui_utilities/SDXTextbox";
import ButtonsRow from "@/components/ui_utilities/ButtonsRow";
import selectBooleanVue from "./inputForm/selectBoolean.vue";
import BMSForm from "@/components/admin/BMSForm";
import UMSForm from "@/components/admin/UMSForm";
import PMSForm from "@/components/admin/PMSForm";
import config from "@/data/config";
export default {
  data() {
    return {
      elem: {
        GroupID: { value: "", valid: undefined },
        Name: { value: "", valid: undefined },
        Logo: { value: "", valid: undefined },
        Description: { value: "", valid: undefined },
        AllBookGroup: false,
        AdminGroup: "",
        display: "",
      },
      newElem: {
        Name: { value: "", valid: undefined },
        Logo: { value: "", valid: undefined },
        Description: { value: "", valid: undefined },
        AllBookGroup: false,
      },
      config: config,
      messageElemType: "This Group can not be edited or deleted (MUser-Admin)",
    };
  },
  created() {
    document.title = "Admin>Groups";
  },

  watch: {
    elemData: function (value) {
      this.elem.GroupID = { value: value.GroupID, valid: true };
      this.elem.Name = { value: value.Name, valid: true };
      this.elem.Logo = { value: value.Logo, valid: true };
      this.elem.Description = { value: value.Description, valid: true };
      this.elem.display = value.display;
      this.elem.AdminGroup = value.AdminGroup;
      this.elem.AllBookGroup = value.AllBookGroup ? true : false;
      if (value.GroupID) {
        this.$store.dispatch("adminBookMembership/LOAD_BMSS_DATA", {
          GroupID: value.GroupID,
        });
        this.$store.dispatch("adminUserMembership/LOAD_UMSS_DATA", {
          GroupID: value.GroupID,
        });
        this.$store.dispatch("adminPortalMembership/LOAD_PMSS_DATA", {
          GroupID: value.GroupID,
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      elemData: "adminGroup/groupData",
      isElemLoading: "adminGroup/isGroupLoading",
    }),
    hasChanged() {
      return this.elem.Logo.value != this.elemData.Logo ||
        this.elem.Name.value != this.elemData.Name ||
        this.elem.Description.value != this.elemData.Description ||
        this.elem.AllBookGroup != this.elemData.AllBookGroup
        ? true
        : false;
    },
    isElemLoadedAndValid() {
      return this.elem && this.elem.GroupID.value > 0;
    },
    isNewElemValid() {
      return this.newElem.Name.valid;
    },
    isModifyElemValid() {
      return this.elem.Name.valid;
    },
    showSpinner() {
      return this.isElemLoading;
    },
  },
  methods: {
    onNewCancel() {
      this.newElem = {
        Name: { value: "", valid: undefined },
        Logo: { value: "", valid: undefined },
        Description: { value: "", valid: undefined },
        AllBookGroup: false,
      };
    },
    onCreate() {
      this.$store.dispatch("adminGroup/CREATE_GROUP", {
        Name: this.newElem.Name.value,
        Logo: this.newElem.Logo.value,
        Description: this.newElem.Description.value,
        AllBookGroup: this.newElem.AllBookGroup ? 1 : 0,
      });
      this.onNewCancel();
    },
    onModify() {
      this.$store.dispatch("adminGroup/MODIFY_GROUP", {
        GroupID: this.elem.GroupID.value,
        Name: this.elem.Name.value,
        Logo: this.elem.Logo.value,
        Description: this.elem.Description.value,
        AllBookGroup: this.elem.AllBookGroup ? 1 : 0,
      });
    },
    onDelete() {
      let text = "Delete group " + this.elem.GroupID.value + "?";
      if (confirm(text) == true) {
        this.$store.dispatch("adminGroup/DELETE_GROUP", {
          GroupID: this.elem.GroupID.value,
        });
      }
    },
  },
  components: {
    "sdx-textbox": SDXTextbox,
    "select-boolean": selectBooleanVue,
    ButtonsRow,
    "bms-form": BMSForm,
    "ums-form": UMSForm,
    "pms-form": PMSForm,
  },
};
</script>
