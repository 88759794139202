<template>
  <div
    class="qsui-admin-subform-row"
    :class="[valueProxy.is_main ? 'qsui-admin-subform-row-principle' : '']"
  >
    <div class="input-field">
      <i
        class="icon icon-008-bin wvfilter-icon"
        :class="[
          valueProxy.is_main ? 'icon-064-record-filled' : 'icon-063-record',
        ]"
        :title="
          valueProxy.is_main
            ? 'this is the Main Process'
            : 'not the Main Process, click to change'
        "
        aria-hidden="true"
        @click="onclickMain()"
      ></i>
    </div>
    <div class="input-field">
      <i
        class="icon icon-008-bin wvfilter-icon wvfilter-red-button"
        title="Remove Index"
        aria-hidden="true"
        @click="onDelete"
      ></i>
    </div>
    <sdx-textbox
      class="input-field-medium"
      label="PerfProcess name"
      title="Name"
      type="text"
      :showLabel="true"
      :regex="config.regex.notEmpty"
      v-model="valueProxy.name"
    />
    <sdx-textbox
      class="input-field-large"
      label="Description"
      title="Beschreibung"
      type="text"
      :showLabel="true"
      :regex="config.regex.notEmpty"
      v-model="valueProxy.description"
    />
    <sdx-textbox
      class="input-field-small"
      label="Shift(Days)"
      title="Shift(Tage)"
      type="text"
      :showLabel="true"
      :regex="config.regex.integer"
      v-model="valueProxy.days_shift"
    />
    <select-generic
      class="input-field-medium"
      label="Rebalancing"
      title="Rebalancing"
      :options="['daily', 'monthly']"
      v-model="valueProxy.rebalancing"
    />
    <select-boolean
      class="input-field-small"
      label="ESG"
      title="ESG"
      v-model="valueProxy.is_esg"
    />
    <select-boolean
      class="input-field-small"
      label="KIID"
      title="KIID"
      v-model="valueProxy.is_kiid"
    />
    <select-generic
      class="input-field-large"
      label="Perf Benchmark"
      title="Perf Benchmark"
      :regex="config.regex.notEmpty"
      :options="benchmarksData"
      v-model="valueProxy.benchmark_name"
    />
    <bm-list :bmName="valueProxy.benchmark_name" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SDXTextbox from "@/components/ui_utilities/SDXTextbox";
import bmIndexListVue from "./bmIndexListPerf.vue";
import config from "@/data/config";
import selectGenericVue from "./inputForm/selectGeneric.vue";
import selectConfMapVue from "./inputForm/selectConfMap.vue";
import selectBooleanVue from "./inputForm/selectBoolean.vue";
import { getLimit_marketrisk, getValV } from "./adminUtils";
export default {
  data() {
    return {
      config: config,
    };
  },
  computed: {
    ...mapGetters({
      benchmarksData: "fmdPerfBenchmark/benchmarksData",
    }),
    valueProxy: {
      get() {
        //becomming data from props
        return this.perfProcess;
      },
      set(input) {
        //becomming data from treeselect and emit
        this.$emit("input", input);
      },
    },
  },
  methods: {
    onclickMain() {
      this.$emit("is_main", true);
    },
    onDelete() {
      this.$emit("delete", this.valueProxy.id);
    },
  },
  props: {
    perfProcess: {
      type: Object,
    },
  },
  components: {
    "sdx-textbox": SDXTextbox,
    "select-generic": selectGenericVue,
    "bm-list": bmIndexListVue,
    "select-confmap": selectConfMapVue,
    "select-boolean": selectBooleanVue,
  },
};
</script>
