<template>
  <div
    class="cortana-side-nav bg bg--gray extra-width"
    :class="{ hidden: !isOpen }"
  >
    <div class="cortana-side-nav__wrapper qsui-admin-wrapper">
      <div class="text-h4">Book List:</div>
      <div class="qsui-admin-list-filerplus">
        <a
          class="button button__icon button--secondary qsui-admin-list-button"
          href="javascript:void(0);"
          @click="plusElem()"
        >
          <i class="icon icon-061-plus" aria-hidden="true"></i>
        </a>
      </div>
      <div class="qsui-admin-list-filerplus">
        <div>
          <sdx-textbox v-model="oValueFilter" label="filter..." type="text" />
        </div>
      </div>
      <div
        v-if="showSpinner"
        class="loader-spinner loader-spinner--small"
      ></div>
      <ul v-else class="qsui-admin-list">
        <div v-for="elem in filteredData">
          <li
            class="qsui-admin-list-item qsui-admin-list-item-click"
            @click="loadElem(elem)"
          >
            {{ elem.display }}
            <div class="qsui-admin-list-item-desc">{{ elem.Description }}</div>
          </li>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SDXTextbox from "@/components/ui_utilities/SDXTextbox";
export default {
  data() {
    return {
      oValueFilter: { value: "", valid: undefined },
      checkedActive: true,
    };
  },
  created() {
    this.$store.dispatch("adminBook/LOAD_BOOKS_DATA");
  },
  computed: {
    ...mapGetters({
      booksData: "adminBook/booksData",
      isBooksLoading: "adminBook/isBooksLoading",
    }),
    filteredData() {
      if (this.booksData) {
        const lowcasevalue = this.oValueFilter.value.toLowerCase();
        return this.booksData.filter((elem) => {
          let filterFilter =
            !lowcasevalue ||
            elem.display.toLowerCase().includes(lowcasevalue) ||
            (!!elem.Description &&
              elem.Description.toLowerCase().includes(lowcasevalue));
          return filterFilter;
        });
        return this.booksData;
      }
      return [];
    },
    showSpinner() {
      return this.isBooksLoading;
    },
  },
  methods: {
    loadElem(elem) {
      this.$store.dispatch("adminBook/LOAD_BOOK_DATA", {
        BookID: elem.BookID,
      });
      this.$store.dispatch("adminBookMembership/LOAD_BMSSFORBOOK_DATA", {
        BookID: elem.BookID,
      });
    },
    plusElem() {
      //if nothing is loaded, create Form appears
      this.$store.dispatch("adminBook/CLEAR_BOOK");
    },
  },
  props: {
    isOpen: { type: Boolean, default: true },
  },
  components: {
    "sdx-textbox": SDXTextbox,
  },
};
</script>
