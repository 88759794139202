<template>
  <div class="bg bg--gray result-banner">
    <div class="result-banner-left">
      <div
        class="result-banner-icons"
        title="Clear Table"
        @click="$emit('triggerClearReportRequest')"
      >
        <i
          class="icon icon-202-clear-circle wvfilter-icon wvfilter-green-button"
          aria-hidden="true"
        ></i>
      </div>
      <div
        class="result-banner-icons"
        title="Clear Row Selection"
        @click="$emit('triggerClearRowSelection')"
      >
        <i class="icon icon-008-bin wvfilter-icon" aria-hidden="true"></i>
      </div>
      <!-- <div
      class="result-banner-icons"
      title="Start Reporting using Selected Row(s)"
      @click="$emit('triggerReportRequest')"
    >
      <i class="icon icon-231-rocket wvfilter-icon" aria-hidden="true"></i>
    </div>-->
      <div class="banner-buttons">
        <a
          class="button button__icon button--primary banner-button"
          href="javascript:void(0);"
          :title="ButtonTitle"
          @click="$emit('triggerReportRequest')"
          >{{ ButtonText }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isDataLoading: "pfrrStore/isDataLoading",
    }),
    ButtonText() {
      if (this.gridSelectedRowLength == 0) {
        return "no request selected";
      } else {
        return `send ${this.gridSelectedRowLength} selected requests`;
      }
    },
    ButtonTitle() {
      if (this.gridSelectedRowLength == 0) {
        return "Plz prepare request, control and select, then send";
      } else {
        return `Send the ${this.gridSelectedRowLength} selected request(s)`;
      }
    },
  },
  props: {
    gridSelectedRowLength: {
      type: Number,
      default: function () {
        return 0;
      },
    },
  },
};
</script>
