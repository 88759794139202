const mapOperators = {
  "=": "eq",
  ">": "gt",
  ">=": "ge",
  "<": "lt",
  "<=": "le",
  "%": "like",
  "!%": "not like",
  n: "is",
  e: "eq",
};

export function getQueryOption_FilterString(oFilter, cols) {
  let rets = [];
  for (let key in oFilter) {
    // console.log(`${key}' + '=' + '${value}`);
    let ctRet = getCritRetForFilterKey(oFilter, key, cols);
    if (ctRet) {
      rets.push(ctRet);
    }
  }
  let retString = rets.join(` and `);
  // console.log(retString);
  return retString;
}
export function reg_getQueryOption_FilterString(oFilter, reg_oFilter, cols) {
  let rets = [];
  let keysInCommon = [];
  for (let key in oFilter) {
    if (cols[key] && reg_oFilter.hasOwnProperty(key)) {
      if (
        isValidFilterItem(oFilter[key]) &&
        isValidFilterItem(reg_oFilter[key])
      ) {
        keysInCommon.push(key);
      }
    }
  }
  //loop through the reg-filter
  for (let reg_key in reg_oFilter) {
    let reg_ctRet = getCritRetForFilterKey(reg_oFilter, reg_key, cols);
    if (reg_ctRet) {
      rets.push(reg_ctRet);
    }
  }
  //loop through the non-reg-filter, ignore if 'key in common'
  for (let key in oFilter) {
    if (keysInCommon.includes(key)) {
      // break //do nothing, will take the one from reg_oFilter later
    } else {
      let ctRet = getCritRetForFilterKey(oFilter, key, cols);
      if (ctRet) {
        rets.push(ctRet);
      }
    }
  }
  let retString = rets.join(` and `);
  let sKeysInCommon = "";
  if (keysInCommon.length > 0) {
    sKeysInCommon = keysInCommon.join(`,`);
  }
  // console.log(retString);
  return {
    filter: retString,
    keysInCommon: sKeysInCommon,
  };
}
export function getQueryOption_OrderByString(critBoxesSort) {
  let rets = [];
  for (let i = 0; i < critBoxesSort.length; i++) {
    const critBox = critBoxesSort[i];
    if (critBox.sorting == 1) {
      rets.push(critBox.ColName + " asc");
    } else if (critBox.sorting == -1) {
      rets.push(critBox.ColName + " desc");
    }
  }
  let retString = rets.join(`, `);
  // console.log(retString);
  return retString;
}

function getCritRetForFilterKey(oFilter, key, cols) {
  let value = oFilter[key];
  let binOperator = value.binaryOperator;
  if (cols[key]) {
    let colForList = cols[key];
    let crits = [];
    for (let item of value.criterias) {
      // console.log(item.value);
      let crit = [];
      crit.push(colForList.ColName);
      crit.push(getOperatorMap(item.operator));
      if (item.operator == "e") {
        crit.push("''");
      } else if (item.operator == "n") {
        crit.push("null");
      } else {
        switch (colForList.ColType) {
          case "date":
            if (!item.value) continue; //ignore empty criteria
            crit.push(getDateForQuery(item.value));
            break;
          case "logdate":
            if (!item.value) continue; //ignore empty criteria
            crit.push(getDateTimeForQuery(item.value));
            break;
          case "number":
            if (!item.value) continue; //ignore empty criteria
            crit.push(item.value.toString());
            break;
          case "bool":
            if (!item.value) continue; //ignore empty criteria
            crit.push(item.value.toString());
            break;
          default:
            if (!item.value) continue; //ignore empty criteria
            if (item.operator.includes("%")) {
              crit.push("'" + item.value + "'");
            } else if (
              item.operator.includes("<") ||
              item.operator.includes(">")
            ) {
              crit.push(item.value);
            } else crit.push("'" + item.value + "'");
            break;
        }
      }
      crits.push(crit.join(" "));
    }
    let ctRet = "(" + crits.join(` ${binOperator} `) + ")";
    // console.log(ctRet);
    if (crits.length > 0) {
      return ctRet;
    }
  }
  return false;
}

function isValidFilterItem(filter) {
  if (filter && filter.criterias) {
    //at least one criteria is valid
    for (let criteria of filter.criterias) {
      if (isValidCriteria(criteria)) {
        return true;
      }
    }
  }
}

function isValidCriteria(criteria) {
  if (criteria) {
    if (criteria.value) {
      return true;
    }
  }
}

function getOperatorMap(op) {
  var map = mapOperators;
  if (!map[op]) return "eq";
  return map[op];
}

function pad(number) {
  if (number < 10) {
    return "0" + number;
  }
  return number;
}

export function getDateForQuery(date) {
  let ret =
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate());
  // console.log(ret);
  return ret;
}

function getDateTimeForQuery(date) {
  let ret =
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    "T" +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds());
  return ret;
}

export function getCol(info, colName) {
  if (colName.toLowerCase() in info.hu.lookupColForListLowercase) {
    return info.hu.colForList[
      info.hu.lookupColForListLowercase[colName.toLowerCase()]
    ];
  } else if (colName.toLowerCase() in info.hu.lookupSqlColForListLowercase) {
    return info.hu.colForList[
      info.hu.lookupSqlColForListLowercase[colName.toLowerCase()]
    ];
  }
}

function getRowValue(col, row) {
  var valueFromRow = row[col.ColName];
  switch (col.ColType) {
    case "date":
      crit.push(getDateForQuery(valueFromRow));
      break;
    case "logdate":
      crit.push(getDateTimeForQuery(valueFromRow));
      break;
    case "number":
      crit.push(valueFromRow.toString());
      break;
    default:
      if (item.operator.includes("%")) {
        crit.push("'" + valueFromRow + "'");
      } else if (item.operator.includes("<") || item.operator.includes(">")) {
        crit.push(valueFromRow);
      } else crit.push("'" + valueFromRow + "'");
      break;
  }
}

export function getUrlFromLink(link) {
  if (link) {
    let addHttp = false;
    try {
      new URL(link);
    } catch (_) {
      addHttp = true;
    }
    let urlstring = (addHttp ? "http://" : "") + link;
    try {
      return new URL(urlstring);
    } catch (_) {
      return null;
    }
  }
}
export function getParamFromLink(ParamName, link) {
  let url = getUrlFromLink(link);
  if (!url) return;
  let params = new URLSearchParams(url.search.slice(1));
  if (params.has(ParamName)) {
    return params.get(ParamName);
  }
}
export function getViewNameFromNavObject(link) {
  let url = getUrlFromLink(link);
  if (!url) return;
  let params = new URLSearchParams(url.search.slice(1));
  if (params.has("WVName")) {
    return params.get("WVName");
  }
}
export function getRouteRootFromLink(link) {
  let url = getUrlFromLink(link);
  //if not a real url but only PFReportRequest for example
  if (url && url.pathname == "/") {
    return url.hostname;
  }
  return "StandardView";
}
export function getNavIdsFromLink(link) {
  let param = getParamFromLink("nav", link);
  if (param) return param.split(",");
}
export function getPortalIDFromLink(link) {
  let param = getParamFromLink("nav", link);
  if (param) return param.split(",")[0];
}
export function isTestingDBinURL(link) {
  let url = getUrlFromLink(link);
  if (!url) return;
  let params = new URLSearchParams(url.search.slice(1));
  for (var key of params.keys()) {
    if (key.toLowerCase() == "testingdb") {
      if (params.get(key).toLowerCase() == "true") {
        return true;
      }
    }
  }
}
export function isDrillDownInURL(link) {
  let url = getUrlFromLink(link);
  if (!url) return;
  let params = new URLSearchParams(url.search.slice(1));
  for (var key of params.keys()) {
    if (key.toLowerCase() == "drilldown") {
      if (params.get(key).toLowerCase() == "true") {
        return true;
      }
    }
  }
}

export function getReportRequestForSend(simpleReportRequest) {
  const ret = {
    // "date": "2019-03-15T00:00:00",
    FundNo: simpleReportRequest.FundNo,
    RequestType: "REP2",
    ReportType: "PERF_" + simpleReportRequest.ReportType,
    REFNIW: simpleReportRequest.REFNIW,
    // "ReportURL": "Report queued",
    // "Link": "Report queued",
    prefix: simpleReportRequest.prefix,
    Details: [
      {
        Period: 1,
        ReportType: simpleReportRequest.ReportDescription,
        Ccy: simpleReportRequest.d_Ccy,
        PeriodType: simpleReportRequest.d_PeriodType,
        Begin: getDateForQuery(simpleReportRequest.d_Begin),
        End: getDateForQuery(simpleReportRequest.d_End),
        Details: simpleReportRequest.d_ShareClass,
        Category: null,
      },
    ],
  };
  // console.log(ret)
  return ret;
}

export const REG_DATA_CHECKBOXES_TESTINGDB = [
  {
    name: "TestingDB",
    value: "isTestingDB",
    checked: false,
    disabled: false,
  },
];
export const REG_DATA_CHECKBOXES_PRODDB = [
  {
    name: "Prod DB",
    value: "isProdDB",
    checked: true,
    disabled: false,
  },
];
export const REG_DATA_CHECKBOXES_ACTIVATE = [
  {
    name: "Regression Mode",
    value: "isRegression",
    checked: false,
    disabled: false,
  },
];
