<template>
  <qsui-accordion-item :removable="false">
    <p slot="header">Reporting Date (EndDate)</p>
    <div slot="collapse">
      <form class="date-period-perf-form">
        <!-- checkbox Use NiwDate -->
        <div class="radio radio-date-filter date-period-perf-quick"></div>
        <!-- Period EndDate (only One) -->
        <div class="date-period-perf-period">
          <div class="checkbox input-field date-period-perf-from">
            <input
              type="checkbox"
              name="dummy"
              id="NIWREF"
              value="NIW"
              v-model="isNiw"
            />
            <label for="NIWREF">Use NIW Date</label>
          </div>
          <!-- <div class="date-period-perf-from">End Date (=ReportingDate):</div> -->
          <div class="date-period-perf-to">
            <div class="date-period-perf-plusminus-buttons">
              <i
                class="icon icon-052-minus wvfilter-icon"
                title="One day less"
                aria-hidden="true"
                @click="onDateMinus_EndDate()"
              ></i>
              <i
                class="
                  icon icon-071-retry
                  wvfilter-icon
                  icon
                  wvfilter-green-button
                "
                title="Reset to default date"
                aria-hidden="true"
                @click="onDateReset_EndDate()"
              ></i>
              <i
                class="icon icon-061-plus wvfilter-icon"
                title="One day more"
                aria-hidden="true"
                @click="onDatePlus_EndDate()"
              ></i>
            </div>
            <wv-date-input
              class="date-period-perf-picker"
              v-model="endDate"
              :enabledDates="enabledDates"
            />
          </div>
        </div>
      </form>
    </div>
  </qsui-accordion-item>
</template>

<script>
import { mapGetters } from "vuex";
import QsuiAccordionItem from "@/components/ui_utilities/QsuiAccordionItem";
import WvDateInput from "./../../date_inputs/WvDateInput";
import moment from "moment";

export default {
  data() {
    return {
      ppValueLastAvailableDate: this.getToday(),
      isNiw: true,
      endDate: this.getToday(),
      format: "DD.MM.YYYY",
      initialised: false,
    };
  },
  watch: {
    isNiw: function () {
      this.$emit("isNiw", this.isNiw);
    },
    endDate: function () {
      this.$emit("endDate", this.endDate);
    },
  },
  computed: {
    ...mapGetters({
      isPerfDatesDataLoading: "book/isPerfDatesDataLoading",
      isPerfDatesDataLoaded: "book/isPerfDatesDataLoaded",
      perfDatesData: "book/perfDatesData",
      isPPDatesDataLoading: "book/isPPDatesDataLoading",
      isPPDatesDataLoaded: "book/isPPDatesDataLoaded",
      ppDatesData: "book/ppDatesData",
    }),
    enabledDates() {
      if (this.isPerfDatesDataLoaded && this.perfDatesData) {
        var enabledDates = [];
        for (let iPD = 0; iPD < this.perfDatesData.length; iPD++) {
          const ePD = this.perfDatesData[iPD];
          if (this.isNiw) {
            if (ePD.NiwDate) {
              if (iPD == 0) {
                this.ppValueLastAvailableDate = ePD.NiwDate;
              }
              enabledDates.push(ePD.NiwDate.substring(0, 10));
            }
          } else {
            if (ePD.RefDate) {
              if (iPD == 0) {
                this.ppValueLastAvailableDate = ePD.RefDate;
              }
              enabledDates.push(ePD.RefDate.substring(0, 10));
            }
          }
        }
        if (!this.initialised) {
          if (enabledDates && enabledDates.length > 0) {
            this.endDate = new Date(this.ppValueLastAvailableDate);
            this.initialised = true;
          }
        }
        return enabledDates;
      }
    },
  },
  methods: {
    getToday() {
      var dateTime = moment();
      var dateValue = moment({
        year: dateTime.year(),
        month: dateTime.month(),
        day: dateTime.date(),
      });
      return dateValue.toDate();
    },
    onDateMinus_EndDate() {
      // you have to set the this.myDate again, so vue can detect it changed
      this.endDate = new Date(this.endDate.setDate(this.endDate.getDate() - 1));
      //this.onChangeRadio_btw()
    },
    onDatePlus_EndDate() {
      // you have to set the this.myDate again, so vue can detect it changed
      this.endDate = new Date(this.endDate.setDate(this.endDate.getDate() + 1));
      //this.onChangeRadio_btw()
    },
    onDateReset_EndDate() {
      this.endDate = new Date(this.ppValueLastAvailableDate);
    },
    momentFormatDate(value) {
      if (this.isPPDatesDataLoaded && this.ppDatesData) {
        return moment(value).format(this.format);
      } else {
        return "-";
      }
    },
  },
  created() {
    this.$emit("isNiw", this.isNiw);
    this.$emit("endDate", this.endDate);
  },
  mounted() {
    //this.onChangeRadio_btw()
  },
  props: {
    initialDate: {
      type: Date,
      default: function () {
        var dateTime = moment();
        return moment({
          year: dateTime.year(),
          month: dateTime.month(),
          day: dateTime.date(),
        }).toDate();
      },
    },
  },
  components: {
    QsuiAccordionItem,
    "wv-date-input": WvDateInput,
  },
};
</script>

<style lang="scss">
$fontSize: 14px;
.date-period-perf-form {
  font-size: $fontSize;
  width: 100%;
  .date-period-perf-period {
    display: flex;
    justify-content: space-between;
    .date-period-perf-picker {
      flex-basis: 180px;
      min-width: 80px;
    }
    .date-period-perf-from,
    .date-period-perf-to {
      display: flex;
      justify-content: stretch;
      flex-basis: 380px;

      .date-period-perf-plusminus-buttons {
        display: flex;
        justify-content: stretch;
        flex-basis: 70px;
        .date-period-perf-plusminus-button {
          min-width: 25px;
          height: 26px;
          padding: 0px;
          flex-basis: 90px;
          border-width: 1px;
          margin-top: 1px;
          margin-right: 1px;
          margin-bottom: 0px;
          margin-left: 1px;
          i {
            margin-right: 0px;
            margin-top: 4px;
          }
        }
      }
    }
    .date-period-perf-from {
      font-size: $fontSize;
    }
    .date-period-perf-to {
      font-size: $fontSize;
    }
  }
  .date-period-perf-quick {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    .date-period-perf-quick-button {
      font-size: $fontSize;
      min-width: 10px;
      height: 26px;
      padding: 0px;
      flex-basis: 180px;
      border-width: 1px;
      margin-top: 1px;
      margin-right: 1px;
      margin-bottom: 0px;
      margin-left: 1px;
      // text-align: left;
      i {
        margin-right: 0px;
        margin-top: 7px;
        line-height: 10px;
        // display: none;
      }
    }
  }
}
</style>
