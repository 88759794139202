<template>
  <div class="row StandardView">
    <benchmarks-list :isOpen="filterIsOpen" />
    <toggle-button v-model="filterIsOpen" />
    <benchmark-form />
  </div>
</template>

<script>
import fmdPerfBenchmarksList from "@/components/admin/fmdPerfBenchmarksList.vue";
import fmdPerfBenchmarkForm from "@/components/admin/fmdPerfBenchmarkForm.vue";
import ToggleButton from "@/components/ui_utilities/ToggleButton.vue";

export default {
  data() {
    return {
      filterIsOpen: true,
    };
  },
  components: {
    "benchmarks-list": fmdPerfBenchmarksList,
    "benchmark-form": fmdPerfBenchmarkForm,
    ToggleButton,
  },
};
</script>
