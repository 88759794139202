<template>
  <div class="banner-inline-filters">
    <div class="wViewSelector">
      <treeselect
        :placeholder="filterName"
        :multiple="true"
        :options="treeselectoptions"
        v-model="modelvalue"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Treeselect from "@riophae/vue-treeselect";

export default {
  data() {
    return {
      modelvalue: [],
    };
  },
  watch: {
    modelvalue: function () {
      if (this.modelvalue && this.filterName) {
        this.$store.dispatch("wviewStore/PUSH_INLINEFILTER_SELECTION", {
          name: this.filterName,
          values: this.modelvalue,
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      inlineFilterInfo: "wviewStore/inlineFilterInfo",
      inlineFilterTreeselectOptions: "wviewStore/inlineFilterTreeselectOptions",
    }),
    treeselectoptions() {
      var filterName = this.filterName;
      let options = this.inlineFilterTreeselectOptions.find((item) => {
        return item.name == filterName;
      });
      if (options) {
        return options.options;
      }
      return [];
    },
  },
  props: {
    filterName: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  components: {
    Treeselect,
  },
};
</script>
