<template>
  <div class="exprequest-banner-select-format">
    <treeselect
      v-model="value"
      :options="treeSelectOptions"
      :clearable="false"
    />
    <!-- <input type="text" v-bind:placeholder=" 'plz input a ' + col.ColType " v-bind:id="inputId"> -->
  </div>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
export default {
  data() {
    return {
      value: 20,
      treeSelectOptions: [
        {
          id: "20",
          label: "20 Days",
        },
        {
          id: "1",
          label: "1 Day",
        },
      ],
    };
  },
  watch: {
    value: function () {
      if (this.value) {
        this.$emit("input", this.value);
      }
    },
  },
  components: {
    Treeselect,
  },
};
</script>

<style></style>
