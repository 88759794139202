<template>
  <div class="qsui-admin-form col-sm-12 col-md-10 col-lg-8 col-xl-7">
    <div class="text-h4">Portal Membership</div>
    <div class="qsui-admin-list-filerplus">
      <div>
        <sdx-textbox v-model="oValueFilter" label="filter..." type="text" />
      </div>
      <div class="checkbox input-field">
        <input
          type="checkbox"
          name="dummy"
          id="PMSactive"
          value="PMSactive"
          v-model="checkedActive"
        />
        <label for="PMSactive">active only</label>
      </div>
    </div>
    <div v-if="showSpinner" class="loader-spinner loader-spinner--small"></div>
    <ul v-else class="qsui-admin-list qsui-admin-list-bms">
      <li
        v-for="elem in filteredItems"
        class="qsui-admin-list-item"
        :class="{ inactive: elem.GroupID < 0 }"
      >
        {{ elem.Description }} --> {{ elem.groupName }}
        <i
          class="icon icon-300_plus-box icon--s1"
          :class="elem.GroupID < 0 ? 'icon-300_plus-box' : 'icon-301_minus-box'"
          :title="elem.GroupID < 0 ? 'add membership' : 'remove membership'"
          @click="toggleMembership(elem)"
        ></i>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SDXTextbox from "@/components/ui_utilities/SDXTextbox";
import ButtonsRow from "@/components/ui_utilities/ButtonsRow";
import config from "@/data/config";

export default {
  data() {
    return {
      oValueFilter: { value: "", valid: undefined },
      checkedActive: true,
    };
  },
  created() {
    this.$store.dispatch("adminPortal/LOAD_PORTALS_DATA");
  },
  computed: {
    ...mapGetters({
      PMSsData: "adminPortalMembership/PMSsData",
      isPMSsLoading: "adminPortalMembership/isPMSsLoading",
      isPMSsLoaded: "adminPortalMembership/isPMSsLoaded",
      portalsData: "adminPortal/portalsData",
      isPortalsLoading: "adminPortal/isPortalsLoading",
      isPortalsLoaded: "adminPortal/isPortalsLoaded",
      groupData: "adminGroup/groupData",
      isGroupLoading: "adminGroup/isGroupLoading",
    }),
    filteredItems() {
      const lowcasevalue = this.oValueFilter.value.toLowerCase();
      const activeonly = this.checkedActive;
      return this.mergedItems.filter((elem) => {
        let statusFilter = (activeonly && elem.GroupID > 0) || !activeonly;
        let filterFilter =
          !lowcasevalue ||
          elem.Description.toLowerCase().includes(lowcasevalue) ||
          elem.groupName.toLowerCase().includes(lowcasevalue);
        return statusFilter && filterFilter;
      });
    },
    mergedItems() {
      if (
        this.PMSsData &&
        this.isPMSsLoaded &&
        this.portalsData &&
        this.isPortalsLoaded
      ) {
        var ret = [];
        this.portalsData.forEach((elem) => {
          if (elem) {
            var pushItem = {
              PortalID: elem.id,
              Description: elem.name,
              groupName: !elem.groupName ? elem.name : elem.groupName,
            };
            const found = this.PMSsData.find((ms) => {
              return ms.PortalID == elem.id;
            });
            if (found) {
              pushItem.GroupID = found.GroupID;
              pushItem.PortalMembershipID = found.PortalMembershipID;
            } else {
              pushItem.GroupID = -1;
              pushItem.PortalMembershipID = -1;
            }
            ret.push(pushItem);
          }
        });
        return ret;
      }
      return [];
    },
    showSpinner() {
      return this.isPMSsLoading || this.isPortalsLoading;
    },
  },
  methods: {
    toggleMembership(elem) {
      if (
        elem &&
        elem.GroupID &&
        elem.PortalID &&
        elem.PortalMembershipID &&
        this.groupData &&
        this.groupData.GroupID > 0
      ) {
        if (elem.GroupID > 0 && elem.PortalID > 0) {
          if (elem.PortalMembershipID > 0) {
            this.$store.dispatch("adminPortalMembership/DELETE_PMS", {
              PortalMembershipID: elem.PortalMembershipID,
              GroupID: this.groupData.GroupID,
            });
          }
        } else {
          this.$store.dispatch("adminPortalMembership/CREATE_PMS", {
            GroupID: this.groupData.GroupID,
            PortalID: elem.PortalID,
          });
        }
      }
    },
  },
  components: {
    "sdx-textbox": SDXTextbox,
  },
};
</script>

<style lang="scss">
.qsui-admin-list-bms {
  max-height: 400px;
  overflow-y: auto;
}
</style>
