<template>
  <div class="navedit navedit-link">
    <!-- <i class="icon icon-005-arrow-up" aria-hidden="true"></i>
    <i class="icon icon-002-arrow-down" aria-hidden="true"></i>-->
    <input
      class="navedit-input"
      :value="link.name"
      name="name"
      placeholder="Description"
      spellcheck="false"
      :disabled="!editMode"
      @change="onEdit($event.target)"
    />
    <input
      class="navedit-input navedit-input-short"
      :value="link.sh"
      name="sh"
      placeholder="sh"
      spellcheck="false"
      :disabled="!editMode"
      @change="onEdit($event.target)"
    />
    <i
      class="icon wvfilter-icon class-for-id-navedit-toggle-hide"
      :class="[
        { 'wvfilter-icon-disabled': !editMode },
        link.intWin ? 'icon-217-eye' : 'icon-218-eye-shut',
      ]"
      :title="link.intWin ? 'hide link' : 'unhide link'"
      aria-hidden="true"
      @click="onEdit($event.target)"
    ></i>
    <i
      class="icon icon-008-bin wvfilter-icon"
      :class="{ 'wvfilter-icon-disabled': !editMode }"
      title="Delete Link"
      aria-hidden="true"
      @click="onDelete()"
    ></i>
    <input
      class="navedit-input navedit-input-large"
      :value="link.link"
      name="link"
      placeholder="Link"
      spellcheck="false"
      :disabled="!editMode"
      @change="onEdit($event.target)"
    />
  </div>
</template>

<script>
export default {
  methods: {
    onEdit(target) {
      if (!this.editMode) {
        return;
      }
      let ret = Object.assign({}, this.link); //take a copy of the prop
      if (target.name == "name") {
        if (ret.name == target.value) {
          return;
        }
        ret.name = target.value;
      }
      if (target.name == "link") {
        if (ret.link == target.value) {
          return;
        }
        ret.link = target.value;
      }
      if (target.name == "sh") {
        if (ret.sh == target.value) {
          return;
        }
        ret.sh = target.value;
      }
      if (target.className.includes("class-for-id-navedit-toggle-hide")) {
        ret.intWin = !ret.intWin;
      }
      this.$store.dispatch("adminPortal/MODIFY_LINK", ret);
    },
    onDelete() {
      if (!this.editMode) {
        return;
      }
      if (this.link) {
        this.$store.dispatch("adminPortal/DELETE_LINK", {
          id: this.link.id,
        });
      }
    },
  },
  props: {
    editMode: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    link: {
      type: Object,
      default: function () {
        return {
          id: -1,
          name: "myName",
          link: "myLink",
          intWin: true,
          sh: 5,
        };
      },
    },
  },
};
</script>

<style></style>
