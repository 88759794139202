<template>
  <div
    v-if="value"
    ref="inputField"
    class="input-field"
    :class="{
      'input-field--static-label': staticLabel,
      'input-multiline': multiline,
    }"
  >
    <label v-if="showLabel" :class="{ active: true }" :for="id">
      <span class="label">{{ label }}</span>
      <!-- <tooltip-target v-if="help" :value="help" class="help" /> -->
    </label>
    <textarea
      v-if="multiline"
      data-min-rows="3"
      data-max-rows="10"
      autocomplete="on"
      ref="input"
      :class="{
        validate: regex,
        invalid: regex && value.valid === false && wasChanged,
      }"
      :id="id"
      :value="value.value"
      :placeholder="label"
      :disabled="disabled"
      @input="updateValue($event.target.value)"
    />
    <input
      v-else
      autocomplete="on"
      ref="input"
      :type="type"
      :name="inputName"
      :class="{
        validate: regex,
        invalid: isInvalid,
      }"
      :id="id"
      :value="value.value"
      :placeholder="label"
      :disabled="disabled"
      @input="updateValue($event.target.value)"
      @keyup.enter="onEnterKey"
    />

    <div class="message" style="pointer-events: none">
      <div style="height: 0">
        <i class="icon icon-026-exclamation-mark-circle" aria-hidden="true"></i>
      </div>
      <div v-html="error" style="margin-left: 24px" />
    </div>
  </div>
</template>

<script>
import randomstring from "randomstring";
import { trimStart } from "@/util/index.js";
import { validate } from "@/util/regex.js";

export default {
  data() {
    return {
      id: randomstring.generate(10),
      error: "",
      wasChanged: false,
    };
  },
  watch: {
    errorMessage() {
      this.setErrorMessage();
    },
  },
  computed: {
    isInvalid() {
      return this.regex && this.value.valid === false && this.wasChanged;
    },
  },
  methods: {
    updateValue(newValue) {
      this.wasChanged = true;
      this.regex
        ? this.$emit("input", {
            value: newValue,
            valid: validate(newValue, this.regex),
          })
        : this.$emit("input", { value: newValue, valid: undefined });

      this.setErrorMessage();
    },
    onEnterKey() {
      this.$emit("onEnterKey");
    },
    setErrorMessage() {
      if (this.regex) {
        if (this.$props.errorMessage) {
          this.error = this.$props.errorMessage;
        } else {
          let l = trimStart(this.$props.label, "*").trim();
          this.error = `${l} is not valid.`;
        }
      }
    },
  },
  created() {
    if (this.regex) {
      this.$emit("input", {
        value: this.value.value,
        valid: validate(this.value.value, this.regex),
      });
    }
  },
  mounted() {
    if (this.value) {
      this.setErrorMessage();
      if (this.regex && this.value.value !== "") {
        this.value.valid = validate(this.value.value, this.regex);
      }
    }
    this.$nextTick(function () {
      if (this.multiline) {
        new sdx.Textarea(this.$refs.inputField);
      }
    });
  },
  props: {
    multiline: { type: Boolean, default: false },
    type: { type: String, default: "text" },
    value: { type: Object },
    staticLabel: { type: Boolean, default: false },
    label: { type: String, default: "" },
    regex: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    errorMessage: { type: String, default: "" },
    inputName: { type: String, default: "" },
    showLabel: { type: Boolean, default: false },
  },

  // Custom "utility" functions

  toVModelFormat(what, valid) {
    if (typeof what === "function") {
      return what;
    } else if (what instanceof Array) {
      return what.map((subWhat) => this.toVModelFormat(subWhat, valid));
    } else if (what instanceof Object) {
      let object = {};
      Object.keys(what).forEach((subWhat) =>
        Object.defineProperty(object, subWhat, {
          value: this.toVModelFormat(what[subWhat], valid),
          configurable: true,
          enumerable: true,
          writable: true,
        })
      );
      return object;
    } else {
      return { value: what, valid };
    }
  },

  toValuesFormat(what) {
    if (typeof what === "function") {
      return what;
    } else if (what instanceof Array) {
      return what.map((subWhat) => this.toValuesFormat(subWhat));
    } else if (what instanceof Object) {
      if (what.hasOwnProperty("value") && what.hasOwnProperty("valid")) {
        return what.value;
      } else {
        let object = {};
        Object.keys(what).forEach((subWhat) =>
          Object.defineProperty(object, subWhat, {
            value: this.toValuesFormat(what[subWhat]),
            configurable: true,
            enumerable: true,
            writable: true,
          })
        );
        return object;
      }
    } else {
      return what;
    }
  },

  /**
   * Supports single form check:
   *    what = { root: Object, keys: [ ...String ] }
   * or multiple "forms" checks:
   *    what = [{ root: Object1, keys: [ ...String1 ] }, { root: Object2, keys: [ ...String2 ] }, ... ]
   * (useful for sub-structures, isFormValid only performs one-level deep)
   */
  isFormValid(what) {
    if (what instanceof Array) {
      return (
        this.isFormValid(what.splice(0, 1)[0]) &&
        (what.length > 0 ? this.isFormValid(what) : true)
      ); // slice modifies what
    } else if (what instanceof Object) {
      return what.keys.every((key) => {
        return what.root[key] && what.root[key].valid;
      });
    } else {
      throw new TypeError("isFormValid expects either an Object or an Array");
    }
  },
};
</script>
