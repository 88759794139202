<template>
  <div class="qsui-admin-form col-sm-12 col-md-10 col-lg-8 col-xl-7">
    <div class="text-h4">User-Group Membership</div>
    <div class="qsui-admin-list-filerplus">
      <div>
        <sdx-textbox v-model="oValueFilter" label="filter..." type="text" />
      </div>
      <div class="checkbox input-field">
        <input
          type="checkbox"
          name="dummy"
          id="UMSactive"
          value="UMSactive"
          v-model="checkedActive"
        />
        <label for="UMSactive">active only</label>
      </div>
    </div>
    <div v-if="showSpinner" class="loader-spinner loader-spinner--small"></div>
    <ul v-else class="qsui-admin-list qsui-admin-list-bms">
      <li
        v-for="elem in filteredItems"
        class="qsui-admin-list-item"
        :class="{ inactive: elem.UserNr < 0 }"
      >
        {{ elem.display }}
        <i
          class="icon icon-300_plus-box icon--s1"
          :class="elem.UserNr < 0 ? 'icon-300_plus-box' : 'icon-301_minus-box'"
          :title="elem.UserNr < 0 ? 'add membership' : 'remove membership'"
          @click="toggleMembership(elem)"
        ></i>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SDXTextbox from "@/components/ui_utilities/SDXTextbox";
import ButtonsRow from "@/components/ui_utilities/ButtonsRow";
import config from "@/data/config";

export default {
  data() {
    return {
      oValueFilter: { value: "", valid: undefined },
      checkedActive: true,
    };
  },
  created() {
    this.$store.dispatch("adminGroup/LOAD_GROUPS_DATA");
  },
  computed: {
    ...mapGetters({
      UMSsData: "adminUserMembership/UMSsData",
      isUMSsLoading: "adminUserMembership/isUMSsLoading",
      isUMSsLoaded: "adminUserMembership/isUMSsLoaded",
      groupsData: "adminGroup/groupsData",
      isGroupsLoading: "adminGroup/isGroupsLoading",
      isGroupsLoaded: "adminGroup/isGroupsLoaded",
      groupData: "adminGroup/groupData",
      isGroupLoading: "adminGroup/isGroupLoading",
    }),
    filteredItems() {
      const lowcasevalue = this.oValueFilter.value.toLowerCase();
      const activeonly = this.checkedActive;
      return this.mergedItems.filter((elem) => {
        let statusFilter = (activeonly && elem.UserNr > 0) || !activeonly;
        let filterFilter =
          !lowcasevalue || elem.display.toLowerCase().includes(lowcasevalue);
        return statusFilter && filterFilter;
      });
    },
    mergedItems() {
      if (
        this.UMSsData &&
        this.isUMSsLoaded &&
        this.groupsData &&
        this.isGroupsLoaded
      ) {
        var ret = [];
        this.groupsData.forEach((elem) => {
          if (elem) {
            var pushItem = {
              GroupID: elem.GroupID,
              display: elem.display,
            };
            const found = this.UMSsData.find((ms) => {
              return ms.GroupID == elem.GroupID;
            });
            if (found) {
              pushItem.UserNr = found.UserID;
              pushItem.UserMembershipID = found.UserMembershipID;
            } else {
              pushItem.UserNr = -1;
              pushItem.UserMembershipID = -1;
            }
            ret.push(pushItem);
          }
        });
        return ret;
      }
      return [];
    },
    showSpinner() {
      return this.isUMSsLoading || this.isGroupsLoading;
    },
  },
  methods: {
    toggleMembership(elem) {
      if (elem && elem.GroupID && elem.UserNr && elem.UserMembershipID) {
        if (elem.GroupID > 0 && elem.UserNr > 0) {
          if (elem.UserMembershipID > 0) {
            this.$store.dispatch("adminUserMembership/DELETE_UMS_FROM_USER", {
              UserMembershipID: elem.UserMembershipID,
              GroupID: elem.GroupID,
              UserID: this.UserNr,
            });
          }
        } else {
          this.$store.dispatch("adminUserMembership/CREATE_UMS_FROM_USER", {
            GroupID: elem.GroupID,
            UserID: this.UserNr,
          });
        }
      }
    },
  },
  props: {
    UserNr: {
      type: Number,
      default: function () {
        return -1;
      },
    },
  },
  components: {
    "sdx-textbox": SDXTextbox,
  },
};
</script>

<style lang="scss">
.qsui-admin-list-bms {
  max-height: 400px;
  overflow-y: auto;
}
</style>
