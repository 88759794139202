<template>
  <div class="qsui-accordion-item" :class="{ 'is-open': collapseIsOpen }">
    <div class="qsui-accordion-toggle" @click="toggleIsOpen">
      <div class="qsui-accordion-toggle-main">
        <i
          class="icon icon-013-chevron-down icon--s1"
          :class="{ 'icon--flip-vertical': collapseIsOpen }"
        ></i>
        <div class="qsui-accordion-header">
          <slot name="header">[missing header slot]</slot>
        </div>
      </div>
      <div
        class="qsui-accordion-toggle-close"
        :class="{ invisible: !showRemoveIcon }"
        @click="removeCritBox"
      >
        <i class="icon icon-022-close icon--s1"></i>
      </div>
    </div>
    <div class="qsui-accordion-collapse" :class="{ 'is-open': collapseIsOpen }">
      <slot name="collapse">[missing collapse slot]</slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      collapseIsOpen: true,
    };
  },
  computed: {
    showRemoveIcon() {
      return this.removable && !this.collapseIsOpen; //show if accordion is closed
    },
  },
  created() {
    this.collapseIsOpen = this.startOpen;
  },
  props: {
    startOpen: {
      type: Boolean,
      default: true,
    },
    removable: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    toggleIsOpen() {
      this.collapseIsOpen = !this.collapseIsOpen;
    },
    removeCritBox() {
      this.$emit("removeCritBox");
    },
  },
};
</script>
