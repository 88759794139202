<template>
  <div class="input-field-select input-field-medium">
    <label :for="id"
      ><span class="label">{{ label }}</span></label
    >
    <wv-date-input class="dp-div-standalone" :id="id" v-model="valueProxy" />
  </div>
</template>

<script>
import randomstring from "randomstring";
import WvDateInput from "@/components/wv_filter/date_inputs/WvDateInput";
export default {
  data() {
    return {
      id: randomstring.generate(10),
    };
  },
  computed: {
    valueProxy: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    WvDateInput,
  },
};
</script>

<style></style>
