import axios from "axios";
import store from "..";

const state = {
  indicesData: [],
  statusIndices: "",
  benchmarksData: [],
  statusBenchmarks: "",
  benchmarkData: {},
  statusBenchmark: "",
};

const getters = {
  isIndicesLoading() {
    return state.statusIndices === "loading";
  },
  isIndicesLoaded() {
    return state.statusIndices === "loaded";
  },
  indicesData() {
    return state.indicesData;
  },
  isBenchmarksLoading() {
    return state.statusBenchmarks === "loading";
  },
  isBenchmarksLoaded() {
    return state.statusBenchmarks === "loaded";
  },
  benchmarksData() {
    return state.benchmarksData;
  },
  isBenchmarkLoading() {
    return state.statusBenchmark === "loading";
  },
  isBenchmarkLoaded() {
    return state.statusBenchmark === "loaded";
  },
  benchmarkData() {
    return state.benchmarkData;
  },
};

const mutations = {
  SET_INDICES_DATA(state, data) {
    state.indicesData = data;
  },
  SET_STATUS_INDICES_LOADING(state) {
    state.statusIndices = "loading";
  },
  SET_STATUS_INDICES_LOADED(state) {
    state.statusIndices = "loaded";
  },
  SET_STATUS_INDICES_ERROR(state) {
    state.statusIndices = "error";
  },
  SET_BENCHMARKS_DATA(state, data) {
    // data.forEach((elem) => {
    //   elem.display = `${elem.Name}, ${elem.ExternalID}`;
    // });
    state.benchmarksData = data;
  },
  SET_STATUS_BENCHMARKS_LOADING(state) {
    state.statusBenchmarks = "loading";
  },
  SET_STATUS_BENCHMARKS_LOADED(state) {
    state.statusBenchmarks = "loaded";
  },
  SET_STATUS_BENCHMARKS_ERROR(state) {
    state.statusBenchmarks = "error";
  },
  SET_BENCHMARK_DATA(state, data) {
    // data.display = `${data.Name}, ${data.ExternalID}`;
    state.benchmarkData = data;
  },
  SET_STATUS_BENCHMARK_LOADING(state) {
    state.statusBenchmark = "loading";
  },
  SET_STATUS_BENCHMARK_LOADED(state) {
    state.statusBenchmark = "loaded";
  },
  SET_STATUS_BENCHMARK_ERROR(state) {
    state.statusBenchmark = "error";
  },
  CLEAR_DATA(state) {
    state.indicesData = [];
    state.statusIndices = "";
    state.benchmarksData = [];
    state.statusBenchmarks = "";
    state.benchmarkData = [];
    state.statusBenchmark = "";
  },
  CLEAR_BENCHMARK_DATA(state) {
    state.benchmarkData = [];
    state.statusBenchmark = "";
  },
};

const actions = {
  CREATE_BENCHMARK: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_BENCHMARK_LOADING");
      axios
        .post("/api/fmdperfbenchmark", payload)
        .then((resp) => {
          commit("SET_BENCHMARK_DATA", resp.data);
          commit("SET_STATUS_BENCHMARK_LOADED");
          store.dispatch("fmdPerfBenchmark/LOAD_BENCHMARKS_DATA");
          store.dispatch("notifStore/successCreated", "Benchmark created.");
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_BENCHMARK_ERROR");
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          reject(err);
        });
    });
  },
  LOAD_BENCHMARKS_DATA({ commit }) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_BENCHMARKS_LOADING");
      axios
        .get("/api/fmdperfbenchmark")
        .then((response) => {
          commit("SET_BENCHMARKS_DATA", response.data);
          commit("SET_STATUS_BENCHMARKS_LOADED");
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          commit("SET_STATUS_BENCHMARKS_ERROR");
          reject(err);
        });
    });
  },
  LOAD_INDICES_DATA({ commit }) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_INDICES_LOADING");
      axios
        .get("/api/fmdperfbenchmark/index")
        .then((response) => {
          commit("SET_INDICES_DATA", response.data);
          commit("SET_STATUS_INDICES_LOADED");
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          commit("SET_STATUS_INDICES_ERROR");
          reject(err);
        });
    });
  },
  LOAD_BENCHMARK_DATA({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_BENCHMARK_LOADING");
      axios
        .get("/api/fmdperfbenchmark/" + payload.name)
        .then((resp) => {
          commit("SET_BENCHMARK_DATA", resp.data);
          commit("SET_STATUS_BENCHMARK_LOADED");
          resolve(resp);
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          commit("SET_STATUS_BENCHMARK_ERROR");
          reject(err);
        });
    });
  },
  DELETE_BENCHMARK({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("CLEAR_BENCHMARK_DATA");
      axios
        .post("/api/fmdperfbenchmark/delete/" + payload.name)
        .then((response) => {
          store.dispatch("notifStore/successDeleted", "Benchmark deleted.");
          store.dispatch("fmdPerfBenchmark/LOAD_BENCHMARKS_DATA");
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          reject(err);
        });
    });
  },
  CLEAR_STORE({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CLEAR_DATA");
    });
  },
  CLEAR_BENCHMARK({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CLEAR_BENCHMARK_DATA");
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
