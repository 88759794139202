<template>
  <qsui-accordion-item
    @removeCritBox="removeCritBox"
    :class="{ regression: isRegression }"
  >
    <p slot="header">{{ col.DisplayName }}{{ isRegression ? ".r" : "" }}</p>
    <div slot="collapse" class="acc-flex-crit-string">
      <crit-string
        v-for="crit in filter.criterias"
        :key="crit.key"
        :crit="crit"
        :huCol="huCol"
        :isOr="isOr"
        @input="onInput($event)"
        @addCrit="addCrit"
        @toggleAndOr="toggleAndOr"
        @onEnterKey="$emit('onEnterKey')"
      ></crit-string>
    </div>
  </qsui-accordion-item>
</template>

<script>
import QsuiAccordionItem from "@/components/ui_utilities/QsuiAccordionItem";
import CritString from "@/components/wv_filter/filter_items/CritString";

export default {
  data() {
    return {
      filter: {
        name: "",
        binaryOperator: "and",
        criterias: [
          {
            key: 0,
            isLast: true,
            operator: "=",
            value: undefined,
          },
        ],
      },
    };
  },
  computed: {
    col() {
      this.filter.name = this.huCol.ColName;
      return this.huCol;
    },
    isOr() {
      return this.filter.binaryOperator == "or" ? true : false;
    },
  },
  methods: {
    onInput(crit) {
      this.filter.criterias[crit.key].value = crit.value;
      this.filter.criterias[crit.key].operator = crit.operator;
      this.$emit("input", this.filter);
    },
    addCrit() {
      //reset isLast
      this.filter.criterias.forEach((crit) => {
        crit.isLast = false;
      });
      let cnt = this.filter.criterias.length;
      this.filter.criterias.push({
        key: cnt,
        isLast: true,
        operator: "=",
        value: undefined,
      });
    },
    toggleAndOr() {
      if (this.filter.binaryOperator == "and") {
        this.filter.binaryOperator = "or";
      } else {
        this.filter.binaryOperator = "and";
      }
    },
    removeCritBox() {
      this.$emit("removeCritBox", this.huCol);
    },
  },
  props: {
    huCol: {
      type: Object,
      default: function () {
        return {
          ColName: "DefaultValue",
          ColType: "name",
        };
      },
    },
    isRegression: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  components: {
    QsuiAccordionItem,
    CritString,
  },
};
</script>
