<template>
  <div class="qxpgrid-container user-select-text">
    <div
      v-if="isDataLoading"
      class="loader-spinner loader-spinner--small"
    ></div>
    <table class="qxpgrid-table" v-show="showGrid">
      <tbody>
        <tr class="head">
          <th
            v-for="col in cols"
            :align="getCellAlign(col)"
            :class="regHeaderClass(col)"
          >{{ col.DisplayName }}</th>
        </tr>
        <tr v-for="row in rows">
          <td
            v-for="col in cols"
            :align="getCellAlign(col)"
            :class="regCellClass(col, row)"
            :nowrap="nowrap"
          >{{ getCellValue(row, col) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getCellAlign, reg_getCellValue } from "./QxpFunctions.js";

export default {
  computed: {
    ...mapGetters({
      isDataLoading: "wviewStore/reg_isLoading",
      isDataLoaded: "wviewStore/reg_isLoaded",
      reg_wvData: "wviewStore/reg_wvData",
      reg_wvInfo: "wviewStore/reg_wvInfo",
      reg_Messages: "wviewStore/reg_Messages",
      reg_isSuccesfull: "wviewStore/reg_isSuccesfull",
      showReg: "wviewStore/showReg",
    }),
    cols() {
      if (this.isDataLoaded && this.reg_wvInfo) {
        return this.reg_wvInfo.hu.colForList;
      }
      return {};
    },
    rows() {
      if (this.isDataLoaded && this.reg_wvData) {
        if (this.reg_wvData.length > 0) {
          if (this.showRowsInDiffOnly) {
            return this.reg_wvData.filter((row) => {
              return row.rrInDiff && row.rrInDiff > 0;
            });
          } else {
            return this.reg_wvData; //.slice(0, this.maxRows)
          }
        }
      }
      return [];
    },
    showGrid() {
      return this.isDataLoaded;
    },
  },
  methods: {
    getCellValue: reg_getCellValue,
    getCellAlign: getCellAlign,
    regHeaderClass(col) {
      if (col.regType) {
        switch (col.regType) {
          case 1:
            return "cellHeadDiffR";
            // column.properties.backgroundColor = '#a2cdf4'
            // column.properties.columnHeaderBackgroundColor = '#a2cdf4'
            // column.properties.columnHeaderBackgroundSelectionColor = '#a2cdf4'
            // column.properties.color = '#a2cdf4'
            break;
          case 2: //regression diff
            return "cellHeadDiffD";
            //column.properties.backgroundColor = '#D2EAFF'
            // column.properties.columnHeaderBackgroundColor = '#D2EAFF'
            // column.properties.columnHeaderBackgroundSelectionColor = '#D2EAFF'
            // column.properties.color = '#D2EAFF'
            break;
          case 3: //regression percent
            return "cellHeadDiffP";
            // column.properties['format'] = 'FinancePerc'
            // column.properties.backgroundColor = '#D2EAFF'
            // column.properties.columnHeaderBackgroundColor = '#D2EAFF'
            // column.properties.columnHeaderBackgroundSelectionColor = '#D2EAFF'
            // column.properties.color = '#D2EAFF'
            break;
        }
      }
    },
    regCellClass(col, row) {
      if (row.rrInDiff && row.rrInDiff > 0) {
        if (col.regType) {
          switch (col.regType) {
            case 1:
              return "cellDiffR";
              // column.properties.backgroundColor = '#a2cdf4'
              // column.properties.columnHeaderBackgroundColor = '#a2cdf4'
              // column.properties.columnHeaderBackgroundSelectionColor = '#a2cdf4'
              // column.properties.color = '#a2cdf4'
              break;
            case 2: //regression diff
              return "cellDiffD";
              //column.properties.backgroundColor = '#D2EAFF'
              // column.properties.columnHeaderBackgroundColor = '#D2EAFF'
              // column.properties.columnHeaderBackgroundSelectionColor = '#D2EAFF'
              // column.properties.color = '#D2EAFF'
              break;
            case 3: //regression percent
              return "cellDiffP";
              // column.properties['format'] = 'FinancePerc'
              // column.properties.backgroundColor = '#D2EAFF'
              // column.properties.columnHeaderBackgroundColor = '#D2EAFF'
              // column.properties.columnHeaderBackgroundSelectionColor = '#D2EAFF'
              // column.properties.color = '#D2EAFF'
              break;
          }
        }
      } else {
        return "cellDiffNoDiff";
      }
      return "";
    },
  },
  beforeUpdate() {
    this.$emit("qxpGridBeforeUpdate");
  },
  updated() {
    this.$emit("qxpGridUpdated");
  },
  props: {
    showRowsInDiffOnly: { type: Boolean, default: true },
    nowrap: { type: Boolean, default: false },
  },
};
</script>
