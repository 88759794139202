<template>
  <div class="row StandardView DashboardView">
    <div class="result-banner-and-grid" style="margin: 0px">
      <div style="margin: 10px 10px 0px; display: flex; z-index: 1000">
        <wv-date-input
          v-model="rqsDate"
          class="radio-date-picker"
          style="display: inline-block"
        />
        <select
          placeholder="Risk Trigger"
          v-model="rqsTriggerValue"
          style="
            font-size: 14px;
            height: 28px;
            margin-left: 10px;
            padding: 0px 12px;
            flex: 1;
          "
        >
          <option
            v-for="(item, index) in rqsTriggerData"
            :value="item.qsTriggerID"
            :key="index"
            >{{ item.triggerDesc }}</option
          >
        </select>
        <div class="checkbox input-field">
          <input
            type="checkbox"
            id="is_mandatory"
            value="0"
            v-model="rqsIsMandatory"
          />
          <label for="is_mandatory">Mandatory</label>
        </div>
        <div class="checkbox input-field">
          <input
            type="checkbox"
            id="is_escalated"
            value="0"
            v-model="rqsIsEscalated"
          />
          <label for="is_escalated">Escalated</label>
        </div>
        <div class="checkbox input-field">
          <input
            type="checkbox"
            id="is_daily_report"
            value="0"
            v-model="rqsIsDaily"
          />
          <label for="is_daily_report">Daily Reports</label>
        </div>
        <div class="checkbox input-field">
          <input
            type="checkbox"
            id="is_weekly_report"
            value="0"
            v-model="rqsIsWeekly"
          />
          <label for="is_weekly_report">Weekly Reports</label>
        </div>
        <input
          v-model="rqsBookNo"
          type="text"
          placeholder="Book No"
          style="
            font-size: 14px;
            height: 28px;
            margin-left: 10px;
            padding: 0px 12px;
            width: 100px;
            text-align: center;
            border: 1px solid #d6d6d6;
            border-radius: 5px;
          "
        />
        <div class="small banner-buttons">
          <a
            @click="loadData"
            class="
              button button__icon
              banner-button
              exprequest-button-activate
              button--primary
            "
            >Run</a
          >
        </div>
      </div>
      <div
        v-if="
          rqsPriorTriggerData.snapshot &&
          rqsPriorTriggerData.snapshot_length === 0
        "
        style="text-align: center; padding: 90px"
      >
        <strong>No data currently available.</strong>
      </div>

      <!--<div class="result-banner-and-grid" style="margin:0px; display: flex;"></div>-->
      <div
        class="dashTables"
        style="margin: 0px; display: flex; flex-direction: column"
      >
        <div
          class="dashTable"
          style="min-height: 60px"
          v-if="
            rqsPriorTriggerData.snapshot &&
            rqsPriorTriggerData.snapshot_length > 0
          "
        >
          <div class="qxpgrid-container">
            <table
              class="qxpgrid-table disable-hover disable-select"
              width="100%"
              cellpadding="4"
              cellspacing="0"
            >
              <thead>
                <tr>
                  <th class="c30">triggerDesc</th>
                  <th>typeDesc</th>
                  <th class="c5 prio">prio1</th>
                  <th class="c5 prio">prio2</th>
                  <th class="c5 prio">prio3</th>
                  <th class="c5 prio">prio4</th>
                  <th class="c5 prio">prio5</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in rqsPriorTriggerData.snapshot"
                  :key="index"
                >
                  <td>{{ item.triggerDesc }}</td>
                  <td>{{ item.typeDesc }}</td>
                  <td class="prio prio1" :data-vclass="item.prio1">{{
                    item.prio1
                  }}</td>
                  <td class="prio prio2" :data-vclass="item.prio2">{{
                    item.prio2
                  }}</td>
                  <td class="prio prio3" :data-vclass="item.prio3">{{
                    item.prio3
                  }}</td>
                  <td class="prio prio4" :data-vclass="item.prio4">{{
                    item.prio4
                  }}</td>
                  <td class="prio prio5" :data-vclass="item.prio5">{{
                    item.prio5
                  }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div
          class="dashTable flex2"
          v-if="
            rqsPriorTriggerData.details &&
            rqsPriorTriggerData.details_length > 0
          "
        >
          <div class="qxpgrid-container">
            <table
              class="qxpgrid-table"
              width="100%"
              cellpadding="4"
              cellspacing="0"
            >
              <thead>
                <tr>
                  <th class="c30">triggerDesc</th>
                  <th>typeDesc</th>
                  <th class="c5 prio">prio1</th>
                  <th class="c5 prio">prio2</th>
                  <th class="c5 prio">prio3</th>
                  <th class="c5 prio">prio4</th>
                  <th class="c5 prio">prio5</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="item2 in rqsPriorTriggerData.details">
                  <tr
                    v-for="(item, index) in item2"
                    :key="index"
                    @click="loadDrillDownData(item)"
                    style="cursor: pointer"
                  >
                    <td>{{ item.triggerDesc }}</td>
                    <td>{{ item.typeDesc }}</td>
                    <td class="prio prio1" :data-vclass="item.prio1">{{
                      item.prio1
                    }}</td>
                    <td class="prio prio2" :data-vclass="item.prio2">{{
                      item.prio2
                    }}</td>
                    <td class="prio prio3" :data-vclass="item.prio3">{{
                      item.prio3
                    }}</td>
                    <td class="prio prio4" :data-vclass="item.prio4">{{
                      item.prio4
                    }}</td>
                    <td class="prio prio5" :data-vclass="item.prio5">{{
                      item.prio5
                    }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>

        <div class="dashTable" v-if="rqsPriorDrillDownTriggerData.length > 0">
          <div class="qxpgrid-container">
            <table
              class="qxpgrid-table disable-hover disable-select"
              width="100%"
              cellpadding="4"
              cellspacing="0"
            >
              <thead>
                <tr>
                  <th>refDate</th>
                  <th>book</th>
                  <th>fundDescription</th>
                  <th>triggerDesc</th>
                  <th>typeDesc</th>
                  <th>subTypeDesc</th>
                  <th>comment</th>
                  <th>value</th>
                  <th>eValue</th>
                  <th>change</th>
                  <th>activeBreach</th>
                  <th>disableReport</th>
                  <th>prio</th>
                  <th>Verfahren</th>
                  <th>LimitProcessType</th>
                  <th>VaRProcess</th>
                  <th>qsLvl</th>
                  <th>qsTime</th>
                  <th>mandatory</th>
                  <th>escalation</th>
                  <th>dailyReport</th>
                  <th>weeklyReport</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in rqsPriorDrillDownTriggerData"
                  :key="index"
                  style="white-space: nowrap"
                >
                  <td>{{ formatCellValue("date", item.refDate) }}</td>
                  <td>{{ item.book }}</td>
                  <td>{{ item.fundDescription }}</td>
                  <td>{{ item.triggerDesc }}</td>
                  <td>{{ item.typeDesc }}</td>
                  <td>{{ item.subTypeDesc }}</td>
                  <td>
                    <input
                      type="text"
                      :key="index"
                      name="comment"
                      :value="item.comment"
                      style="border: 1px solid grey; padding: 1px 4px"
                      v-on:keyup.enter="
                        submitData(
                          'comment',
                          item.requestID,
                          $event.target.value
                        )
                      "
                    />
                  </td>
                  <td>{{ item.value }}</td>
                  <td>{{ item.eValue }}</td>
                  <td>{{ item.change }}</td>
                  <td style="text-align: center">
                    <input
                      type="checkbox"
                      :key="index"
                      name="activeBreach"
                      :checked="item.activeBreach === 0 ? false : true"
                      v-on:click="
                        submitData('activeBreach', item.requestID, $event)
                      "
                    />
                  </td>
                  <td style="text-align: center">
                    <input
                      type="checkbox"
                      :key="index"
                      name="disableReport"
                      :checked="item.disableReport === 0 ? false : true"
                      v-on:click="
                        submitData('disableReport', item.requestID, $event)
                      "
                    />
                  </td>
                  <td>{{ item.prio }}</td>
                  <td>{{ item.Verfahren }}</td>
                  <td>{{ item.LimitProcessType }}</td>
                  <td>{{ item.VaRProcess }}</td>
                  <td>{{ item.qsLvl }}</td>
                  <td>{{ item.qsTime }}</td>
                  <td>{{ item.mandatory }}</td>
                  <td>{{ item.escalation }}</td>
                  <td>{{ item.dailyReport }}</td>
                  <td>{{ item.weeklyReport }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.main-wrapper {
  max-height: 100vh;
}

.small.banner-buttons .button {
  padding: 2px 18px;
  min-width: unset;
  margin: 0px 10px;
  font-size: 16px;
}

.dashTables {
  display: flex;
  /*
    margin: 250px 10px 0px 10px !important;
    max-height: 100vh;
    */
  margin: -180px 10px 0px 10px !important;
  max-height: 100vh;
  min-height: 100vh;
  padding-top: 180px;
}

.dashTable {
  margin: 0px;
  flex: 1;
  /*min-height: 200px;*/
  overflow: auto;
}

.checkbox.input-field {
  margin-left: 10px;
  margin-right: 10px;
}

.dashTable.flex2 {
  flex: 2;
}

.qxpgrid-container {
  overflow: unset !important;
}

.StandardView .result-banner-and-grid {
  height: unset !important;
}

.qxpgrid-table.disable-select td {
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.qxpgrid-table.disable-hover tr:hover {
  background: unset;
}

th {
  text-align: left;
}

.prio {
  text-align: center;
  font-weight: bold;
}

.c30 {
  width: 30%;
}

.c5 {
  width: 7%;
}

.prio2:not([data-vclass="0"]),
.prio3:not([data-vclass="0"]) {
  background: yellow;
}

.prio4:not([data-vclass="0"]),
.prio5:not([data-vclass="0"]) {
  background: grey;
}

.prio1:not([data-vclass="0"]) {
  background: red;
}

.prio1[data-vclass="0"],
.prio2[data-vclass="0"],
.prio3[data-vclass="0"],
.prio4[data-vclass="0"],
.prio5[data-vclass="0"] {
  background: green;
}
</style>

<script>
import ToggleButton from "@/components/ui_utilities/ToggleButton";
import WvDateInput from "./wv_filter/date_inputs/WvDateInput";
import GridAndBanners from "@/components/datagrid/GridAndBanners";
import { mapGetters } from "vuex";
import { getQueryOption_FilterString } from "./wv_filter/WvQueryOption.js";
import config from "@/data/config";
import moment from "moment";

export default {
  data() {
    return {
      retFilter: {},
      filterIsOpen: true,
      rqsTriggerValue: null,
      rqsDate: new Date(),
      rqsBookNo: null,
      bookNo: "",
      todayDate: new Date(),
      posts: [],
      WViewName: "RQS_TestResult",
      rqsIsMandatory: false,
      rqsIsEscalated: false,
      rqsIsDaily: false,
      rqsIsWeekly: false,
      checkedActive: false,
    };
  },
  created() {
    document.title = "QS>Priority";
  },
  computed: {
    ...mapGetters({
      rqsTriggerData: "dashboardStore/rqsTriggerData",
      rqsPriorTriggerData: "dashboardStore/rqsPriorTriggerData",
      rqsPriorDrillDownTriggerData:
        "dashboardStore/rqsPriorDrillDownTriggerData",
    }),
  },
  components: {
    ToggleButton,
    WvDateInput,
    GridAndBanners,
  },
  mounted() {
    this.loadOptions();
  },
  methods: {
    formatCellValue(type, value) {
      if (type === "date") {
        return moment(value).format("YYYY-MM-DD");
        //return moment(value).toISOString();
      } else {
        return value;
      }
    },
    loadData() {
      this.retFilterString = "";

      if (this.rqsBookNo !== null) {
        this.retFilterString =
          this.retFilterString + ` and (book eq ${this.rqsBookNo})`;
      }
      if (this.rqsDate !== null) {
        this.retFilterString =
          this.retFilterString +
          ` and (refDate eq ${moment(this.rqsDate).format("YYYY-MM-DD")})`;
      }
      if (this.rqsTriggerValue !== null) {
        this.retFilterString =
          this.retFilterString +
          ` and (qsTriggerID eq ${this.rqsTriggerValue})`;
      }

      /* And additional checkbox filters */
      if (this.rqsIsMandatory !== false) {
        this.retFilterString =
          this.retFilterString + ` and (mandatory eq 'true')`;
      }
      if (this.rqsIsEscalated !== false) {
        this.retFilterString =
          this.retFilterString + ` and (escalation eq 'true')`;
      }
      if (this.rqsIsDaily !== false) {
        this.retFilterString =
          this.retFilterString + ` and (dailyReport eq 'true')`;
      }
      if (this.rqsIsWeekly !== false) {
        this.retFilterString =
          this.retFilterString + ` and (weeklyReport eq 'true')`;
      }
      /* End Additional filters */

      var queryParams = {
        $filter: this.retFilterString.substr(4),
      };

      this.$store.dispatch("dashboardStore/LOAD_PRIOR_DATA", {
        vwiewName: this.WViewName,
        queryParams: queryParams,
      });
    },
    loadOptions() {
      this.$store.dispatch("dashboardStore/LOAD_RQS_TRIGGER", {});
    },
    loadDrillDownData(item) {
      var queryParams = {
        $filter: item.drillDownLink,
      };
      this.$store.dispatch("dashboardStore/LOAD_PRIOR_DRILLDOWN_DATA", {
        vwiewName: this.WViewName,
        queryParams: queryParams,
      });
    },
    submitData(field, requestId, value) {
      if (field === "activeBreach" || field === "disableReport") {
        if (value.target.checked === false) {
          var newValue = 0;
        } else {
          var newValue = 1;
        }
      } else {
        var newValue = value;
      }

      let dataRow = this.rqsPriorDrillDownTriggerData.find(
        (item) => item.requestID === requestId
      );

      if (dataRow) {
        let payload = {
          WViewName: this.WViewName,
          colName: field,
          dataRow: dataRow,
          newValue: newValue,
        };
        this.$store.dispatch("dashboardStore/SEND_CORRECTION", payload);
      }
    },
  },
};
</script>
