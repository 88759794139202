<template>
  <div class="result-banner-and-grid result-banner-and-grid-pfrr">
    <banner-perf-request
      :gridSelectedRowLength="gridSelectedRowLength"
      @triggerClearRowSelection="$refs.qxpgridpfrr.clearRowSelection()"
      @triggerReportRequest="$refs.qxpgridpfrr.startReportingUsingSelectedRow()"
      @triggerClearReportRequest="$refs.qxpgridpfrr.clearTable()"
    />
    <qxp-grid-pfrr
      ref="qxpgridpfrr"
      @gridSelectedRowLength="gridSelectedRowLength = $event"
    ></qxp-grid-pfrr>
    <banner-standard
      :showExpRequest="isExpRequestActive"
      :nowrap="nowrap"
      showToggleGridType="false"
      @ToggleExpRequest="isExpRequestActive = !isExpRequestActive"
      @triggerToggleNoWrap="nowrap = !nowrap"
    />
    <banner-exp-request
      v-if="isExpRequestActive"
      :selectedRows="expSelectedRows"
      @triggerClearRowSelection="$refs.qxpgridsel.clearRowSelection()"
    />
    <qxp-grid-sel
      v-if="isExpRequestActive"
      ref="qxpgridsel"
      :nowrap="nowrap"
      @selectedRows="onSelectedRows($event)"
    ></qxp-grid-sel>
    <qxp-grid v-else :nowrap="nowrap"></qxp-grid>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import QxpGridPfrr from "@/components/datagrid/QxpGrid/QxpGridPfrr";
import QxpGridSel from "@/components/datagrid/QxpGrid/QxpGridSel";
import QxpGrid from "@/components/datagrid/QxpGrid/QxpGrid";
import BannerPerfRequest from "@/components/datagrid/BannerPerfRequest";
import BannerStandard from "@/components/datagrid/BannerStandard";
import BannerExpRequest from "@/components/datagrid/BannerExpRequest";

export default {
  data() {
    return {
      isExpRequestActive: true,
      gridSelectedRowLength: 0,
      nowrap: true,
      expSelectedRows: [],
    };
  },
  methods: {
    onSelectedRows(selectedRows) {
      if (selectedRows) {
        this.expSelectedRows = selectedRows;
      } else {
        this.expSelectedRows = [];
      }
    },
  },
  components: {
    BannerPerfRequest,
    BannerStandard,
    BannerExpRequest,
    QxpGridPfrr,
    QxpGridSel,
    QxpGrid,
  },
};
</script>
