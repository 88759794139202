<template>
  <div :class="`row ${className}`">
    <div
      v-if="submitting || deleting"
      class="col loader-spinner loader-spinner--large margin-v-4"
    ></div>
    <div v-else class>
      <div
        class="
          flex-responsive
          flex-items-xs-center
          flex-items-xs-middle
          flex-items-md-left
        "
      >
        <div v-if="$slots.default" class="col-xs-auto">
          <slot></slot>
        </div>
        <div v-if="hasCancel" class="col-xs-auto">
          <a
            class="button button--secondary"
            href="javascript:void(0);"
            @click="onCancel"
          >
            {{ cancelText }}
          </a>
        </div>

        <div v-if="hasDelete" class="col-xs-auto">
          <a
            class="button button--danger"
            :class="{ disabled: deleteDisabled }"
            href="javascript:void(0);"
            @click="onDelete"
          >
            {{ deleteText }}
          </a>
        </div>
        <div v-if="hasSubmit" class="col-xs-auto">
          <!-- <button class="button button--primary" :class="{ disabled: submitDisabled }" @click="onSubmit">{{ submitText
            }}
          </button>-->
          <a
            class="button button--primary"
            :class="{ disabled: submitDisabled }"
            href="javascript:void(0);"
            @click="onSubmit"
          >
            {{ submitText }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    className: { type: String, default: "margin-v-4" },
    hasCancel: { type: Boolean, default: false },
    cancelText: { type: String, default: "Cancel" },
    hasToggle: { type: Boolean, default: false },
    hasDelete: { type: Boolean, default: false },
    deleteText: { type: String, default: "Delete" },
    deleteDisabled: { type: Boolean, default: false },
    deleting: { type: Boolean, default: false },
    hasSubmit: { type: Boolean, default: true },
    submitText: { type: String, default: "Submit" },
    submitDisabled: { type: Boolean, default: false },
    submitting: { type: Boolean, default: false },
  },
  methods: {
    onSubmit() {
      this.$emit("onSubmit");
    },
    onCancel() {
      this.$emit("onCancel");
    },
    onDelete() {
      this.$emit("onDelete");
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-responsive {
  flex-direction: row;

  > * + * {
    margin-top: 8px;
  }

  @media (min-width: breakpoint-min("md")) {
    flex-direction: row;

    > * + * {
      margin-top: 0px;
    }
  }
}

col {
  align-content: left;
}

.button {
  // TODO ButtonsRow is not responsive yet
  margin-top: 2px;
  padding: 5px 8px;
  height: 36px;
  min-width: 120px;
}
</style>
