<template>
  <div>
    <page-container :medium="6" :large="6" class="padding-v-4">
      <div class="row">
        <div class="col-xs-12">
          <h2 class="sc-navy">Welcome!</h2>
          <span>Please log in.</span>
        </div>
      </div>
      <form class="row">
        <div class="col-xs-12">
          <div class="row">
            <div class="col-xs-12 padding-v-2">
              <div class="row">
                <div class="col-xs-12">
                  <sdx-textbox
                    v-model="email"
                    label="User Name"
                    type="text"
                    inputName="fname"
                    class="sdx-login-username"
                    :regex="config.regex.notEmpty"
                    :disabled="loginPending"
                    @onEnterKey="onLogin"
                  />
                  <sdx-textbox
                    v-model="password"
                    label="Password"
                    type="password"
                    :regex="config.regex.notEmpty"
                    :disabled="loginPending"
                    @onEnterKey="onLogin"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="row">
            <div class="col-xs-12">
              <buttons-row
                class="margin-v-1 loginButton"
                submitText="Log in"
                :submitDisabled="!formValid"
                :submitting="loginPending"
                @onSubmit="onLogin"
              />
            </div>
          </div>
        </div>
      </form>
    </page-container>
    <footer class="version-info" v-if="get_qsui_version">
      Rev. {{ get_qsui_version }}
    </footer>
  </div>
</template>

<script>
import PageContainer from "@/components/ui_utilities/PageContainer";
import ButtonsRow from "@/components/ui_utilities/ButtonsRow";
import SDXTextbox from "@/components/ui_utilities/SDXTextbox";

import { mapActions, mapGetters } from "vuex";
import router from "@/router";
import config from "@/data/config";

export default {
  components: {
    PageContainer,
    ButtonsRow,
    "sdx-textbox": SDXTextbox,
  },
  data() {
    return {
      email: { value: "", valid: undefined },
      password: { value: "", valid: undefined },
      loginPending: false,

      config: config,
    };
  },
  computed: {
    get_qsui_version() {
      if (
        process.env.VUE_APP_CI_COMMIT_SHORT_SHA &&
        process.env.VUE_APP_CI_MERGE_REQUEST_IID
      ) {
        return `${process.env.VUE_APP_CI_COMMIT_SHORT_SHA}-${process.env.VUE_APP_CI_MERGE_REQUEST_IID}`;
      } else if (
        process.env.VUE_APP_CI_COMMIT_SHORT_SHA &&
        !process.env.VUE_APP_CI_MERGE_REQUEST_IID
      ) {
        return process.env.VUE_APP_CI_COMMIT_SHORT_SHA;
      }
      return null;
    },
    user: {
      cache: false,
      get() {
        return this.$store.getters.isUserTokenHereAndStillValid();
      },
    },
    formValid() {
      return this.email.valid && this.password.valid;
    },
  },
  methods: {
    ...mapActions({
      signout: "AUTH_LOGOUT",
    }),
    pushHome() {
      router.push("/");
      //router.push({ name: 'HelloWorld' })
    },
    onLogin() {
      this.loginPending = true;

      this.$store
        .dispatch("AUTH_REQUEST", {
          username: this.email.value,
          password: this.password.value,
        })
        .then(() => {
          this.loginPending = false;
          if (this.$route.query.redirect) {
            router.push(this.$route.query.redirect);
          } else {
            router.push("/");
          }
          // }
        })
        .catch((err) => {
          this.loginPending = false;
          this.$store.dispatch(
            "notifStore/alert",
            'Forgot password? Please contact your client manager or IPConcept risk management <a href="mailto:ipcrmv@ipconcept.com">ipcrmv@ipconcept.com</a>.'
          );
        });

      // let api = new Api();
      // api
      //   .post("/auth_login/login", {
      //     email: this.email.value,
      //     password: this.password.value
      //   })
      //   .then(response => {
      //     this.loginPending = false;
      //     this.setUser(response.data.user);
      //     if (this.$store.state.route.query.returnUrl) {
      //       router.push(this.$store.state.route.query.returnUrl);
      //     } else {
      //       router.push("/catalog");
      //     }
      //   })
      //   .catch(err => {
      //     this.loginPending = false;
      //     this.$store.dispatch('notifStore/alert', err)
      //   });
    },
  },
  created() {
    // If user is already authenticated and returnUrl query parameter is present,
    // just forward to this URL
    // if (this.user && this.$store.state.route.query.returnUrl) {
    //   router.push(this.$store.state.route.query.returnUrl);
    // }
  },
};
</script>

<style lang="scss" scoped>
.loginButton {
  min-width: 200px;
}
.container {
  max-width: 600px;
}
</style>
