<template>
  <div v-if="showBanner" class="bg bg--gray result-banner">
    <div class="result-banner-left">
      <div
        v-show="!isSecondaryBanner && !showExpPDFRequestButton"
        class="result-banner-icons"
        title="Reload Data"
        @click="ReloadData()"
      >
        <div
          v-if="isDataLoading"
          class="loader-spinner loader-spinner--small"
        ></div>
        <i
          v-else
          class="icon icon-071-retry wvfilter-icon wvfilter-green-button"
          aria-hidden="true"
        ></i>
      </div>
      <div
        v-show="
          !showExpRequest &&
          !showExpPDFRequest &&
          !showIRSRequest &&
          !isSecondaryBanner
        "
        class="result-banner-icons"
        :class="{
          placonly: showExpRequest || showExpPDFRequest || showIRSRequest,
        }"
        title="toggle Grid Type"
      >
        <i class="icon icon-220-list wvfilter-icon" aria-hidden="true"></i>
      </div>
      <div
        class="result-banner-icons"
        :title="nowrap ? 'Wrap text' : 'No Wrap'"
        @click="toggleNoWrap()"
      >
        <i
          class="icon wvfilter-icon"
          :class="
            nowrap
              ? 'icon-035-fullscreen-enter-2'
              : 'icon-037-fullscreen-leave-2'
          "
          aria-hidden="true"
        ></i>
      </div>
      <div v-if="showExpRequestButton" class="banner-buttons">
        <a
          class="button button__icon banner-button exprequest-button-activate"
          :class="{
            'button--primary': !showExpRequest,
            'button--secondary': showExpRequest,
          }"
          href="javascript:void(0);"
          @click="$emit('ToggleExpRequest')"
          >{{ showExpRequest ? "hide" : "show" }} export dialog</a
        >
      </div>
      <!-- <div v-if="showExpPDFRequestButton" class="banner-buttons">
      <a
        class="button button__icon banner-button exprequest-button-activate"
        :class="{'button--primary': !showExpPDFRequest, 'button--secondary': showExpPDFRequest}"
        href="javascript:void(0);"
        @click="$emit('ToggleExpPDFRequest')"
      >{{showExpPDFRequest?'hide':'show'}} export dialog</a>
    </div> -->
      <div v-if="showSecondaryGridButton" class="banner-buttons">
        <a
          class="button button__icon banner-button exprequest-button-activate"
          :class="{
            'button--primary': !showSecondaryGrid,
            'button--secondary': showSecondaryGrid,
          }"
          href="javascript:void(0);"
          @click="$emit('ToggleSecondaryGrid')"
          >{{ showSecondaryGrid ? "hide" : "show" }} drill down mode</a
        >
      </div>
      <template v-if="!isSecondaryBanner">
        <inlinefilter-treeselect
          v-for="item in inlineFilterInfo"
          :key="item.name"
          :filterName="item.name"
        />
      </template>
    </div>
    <div class="result-banner-right">
      <div class="result-banner-messages" :title="numberOfRowMessage.tooltip">
        <ul>
          <li>
            <p class="font font--light">
              {{ numberOfRowMessage.text }}
              <span v-if="isMoreRows" class="qx-link-text" @click="showAllRows"
                >(show all)</span
              >
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import inlinefilter_treeselect from "@/components/datagrid/inlinefilter/inlinefilter_treeselect";
import { isDrillDownInURL } from "@/components/wv_filter/WvQueryOption.js";

export default {
  computed: {
    ...mapGetters({
      isWViewEligibleForExpRequest: "wviewStore/isWViewEligibleForExpRequest",
      isWViewEligibleForExpPDFRequest:
        "wviewStore/isWViewEligibleForExpPDFRequest",
      hasAuthorisationExpRequest: "nav/hasAuthorisationExpRequest",
      isWViewEligibleForSecondaryGrid:
        "wviewStore/isWViewEligibleForSecondaryGrid",
      isDataLoading: "wviewStore/isDataLoading",
      inlineFilterInfo: "wviewStore/inlineFilterInfo",
    }),
    showReg() {
      return this.$store.getters[this.storeString + "/showReg"];
    },
    wvData() {
      return this.$store.getters[this.storeString + "/wvData"];
    },
    isDataLoaded() {
      return this.$store.getters[this.storeString + "/isDataLoaded"];
    },
    maxRows() {
      return this.$store.getters[this.storeString + "/maxRows"];
    },
    rowsLength() {
      return this.$store.getters[this.storeString + "/rowsLength"];
    },
    storeString() {
      if (this.isSecondaryBanner) {
        return "wviewStoreB";
      }
      return "wviewStore";
    },
    numberOfRowMessage() {
      if (this.isDataLoaded && this.wvData) {
        let displayed = Math.min(this.maxRows, this.wvData.length);
        let loaded = this.rowsLength;
        if (displayed == loaded) {
          return {
            text: `${loaded}`,
            tooltip: `Rows Loaded: ${loaded}`,
          };
        } else {
          return {
            text: `${displayed}/${loaded}`,
            tooltip: `Rows displayed: ${displayed}, loaded: ${loaded}`,
          };
        }
      }
    },
    isMoreRows() {
      if (this.rowsLength > 0 && this.rowsLength > this.maxRows) {
        return true;
      }
    },
    showBanner() {
      return !this.showReg && this.isDataLoaded;
    },
    showExpRequestButton() {
      return (
        this.isWViewEligibleForExpRequest && this.hasAuthorisationExpRequest
      );
    },
    showExpPDFRequestButton() {
      return this.isWViewEligibleForExpPDFRequest;
    },
    showSecondaryGridButton() {
      return (
        (this.isWViewEligibleForSecondaryGrid ||
          isDrillDownInURL(this.$route.fullPath)) &&
        !this.isSecondaryBanner
      );
    },
  },
  methods: {
    showAllRows() {
      this.$store.dispatch("wviewStore/SHOW_MAX");
    },
    ReloadData() {
      // this.$emit('triggerReloadData')
      this.$store.dispatch("wviewStore/RELOAD_DATA");
    },
    toggleNoWrap() {
      this.$emit("triggerToggleNoWrap");
    },
  },
  props: {
    showExpRequest: { type: Boolean, default: false },
    showExpPDFRequest: { type: Boolean, default: false },
    showIRSRequest: { type: Boolean, default: false },
    showSecondaryGrid: { type: Boolean, default: false },
    nowrap: { type: Boolean, default: false },
    isSecondaryBanner: { type: Boolean, default: false },
  },
  components: {
    "inlinefilter-treeselect": inlinefilter_treeselect,
  },
};
</script>
