<template>
  <div class="qsui-admin-container">
    <div v-if="showSpinner" class="loader-spinner loader-spinner--small"></div>
    <div v-else-if="!isElemLoadedAndValid">
      <div class="qsui-admin-form col-lg-12 col-xl-10">
        <div class="text-h4">Create New Book</div>
        <div class="qsui-admin-subform">
          <div class="qsui-admin-subform-row">
            <sdx-textbox
              v-model="newElem.Name"
              label="Book Name (UFS Nr)"
              type="text"
              :showLabel="true"
              :regex="config.regex.notEmpty"
            />
            <sdx-textbox
              v-model="newElem.ExternalID"
              label="ExternalID (OpusNr)"
              type="text"
              :showLabel="true"
              :regex="config.regex.notEmpty"
            />
            <sdx-textbox
              v-model="newElem.Description"
              label="Description"
              type="text"
              :showLabel="true"
              :regex="config.regex.notEmpty"
            />
          </div>
        </div>
        <div class="qsui-admin-buttons">
          <a
            class="button button--secondary"
            href="javascript:void(0);"
            @click="onNewCancel"
            >cancel</a
          >
          <a
            class="button button--primary"
            href="javascript:void(0);"
            :class="{ disabled: !isNewElemValid }"
            @click="onCreate"
            >create</a
          >
        </div>
      </div>
    </div>
    <div v-else>
      <div class="qsui-admin-form col-lg-12 col-xl-10">
        <div class="text-h4">Book: Q{{ elem.BookID.value }}</div>
        <div class="qsui-admin-subform">
          <div class="qsui-admin-subform-row">
            <sdx-textbox
              class="input-field-small"
              v-model="elem.BookID"
              label="QBookID"
              type="text"
              :showLabel="true"
              :disabled="true"
            />
            <sdx-textbox
              class="input-field-medium"
              v-model="elem.Name"
              label="Book Name (UFS Nr)"
              type="text"
              :showLabel="true"
              :regex="config.regex.notEmpty"
            />
            <sdx-textbox
              class="input-field-medium"
              v-model="elem.ExternalID"
              label="ExternalID (OpusNr)"
              type="text"
              :showLabel="true"
              :regex="config.regex.notEmpty"
            />
            <sdx-textbox
              class="input-field-large"
              v-model="elem.Description"
              label="Description"
              type="text"
              :showLabel="true"
              :regex="config.regex.notEmpty"
            />
          </div>
        </div>
        <div class="qsui-admin-buttons">
          <a
            class="button button--primary qsui-button-red"
            href="javascript:void(0);"
            @click="onDelete"
            >delete</a
          >
          <a
            class="button button--primary"
            :class="{ disabled: !hasChanged || !isModifyElemValid }"
            href="javascript:void(0);"
            @click="onModify"
            >change</a
          >
        </div>
      </div>
      <bms-form-for-book :BookID="elemData.BookID" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SDXTextbox from "@/components/ui_utilities/SDXTextbox";
import ButtonsRow from "@/components/ui_utilities/ButtonsRow";
import BMSFormForBook from "@/components/admin/BMSFormForBook";
import config from "@/data/config";
import AWN from "awesome-notifications";
import configAWN from "@/data/configAWN";
export default {
  data() {
    return {
      elem: {
        BookID: { value: "", valid: undefined },
        Name: { value: "", valid: undefined },
        ExternalID: { value: "", valid: undefined },
        Description: { value: "", valid: undefined },
        display: "",
      },
      newElem: {
        Name: { value: "", valid: undefined },
        ExternalID: { value: "", valid: undefined },
        Description: { value: "", valid: undefined },
      },
      config: config,
      configAWN: configAWN,
    };
  },
  created() {
    document.title = "Admin>Books";
  },
  watch: {
    elemData: function (value) {
      this.elem.BookID = { value: value.BookID, valid: true };
      this.elem.Name = { value: value.Name, valid: true };
      this.elem.ExternalID = { value: value.ExternalID, valid: true };
      this.elem.Description = { value: value.Description, valid: true };
      this.elem.display = value.display;
    },
  },
  computed: {
    ...mapGetters({
      elemData: "adminBook/bookData",
      isElemLoading: "adminBook/isBookLoading",
    }),
    hasChanged() {
      return (
        this.elem.ExternalID.value != this.elemData.ExternalID ||
        this.elem.Name.value != this.elemData.Name ||
        this.elem.Description.value != this.elemData.Description
      );
    },
    isElemLoadedAndValid() {
      return this.elem && this.elem.BookID.value > 0;
    },
    isNewElemValid() {
      return (
        this.newElem.Name.valid &&
        this.newElem.ExternalID.valid &&
        this.newElem.Description.valid &&
        this.newElem.Name.valid
      );
    },
    isModifyElemValid() {
      return (
        this.elem.ExternalID.valid &&
        this.elem.Description.valid &&
        this.elem.Name.valid
      );
    },
    showSpinner() {
      return this.isElemLoading;
    },
  },
  methods: {
    onNewCancel() {
      this.newElem = {
        Name: { value: "", valid: undefined },
        ExternalID: { value: "", valid: undefined },
        Description: { value: "", valid: undefined },
      };
    },
    onCreate() {
      this.$store.dispatch("adminBook/CREATE_BOOK", {
        Name: this.newElem.Name.value,
        ExternalID: this.newElem.ExternalID.value,
        Description: this.newElem.Description.value,
      });
      this.onNewCancel();
    },
    onModify() {
      this.$store.dispatch("adminBook/MODIFY_BOOK", {
        BookID: this.elem.BookID.value,
        Name: this.elem.Name.value,
        ExternalID: this.elem.ExternalID.value,
        Description: this.elem.Description.value,
      });
    },
    onDelete() {
      let text = "Delete book Q" + this.elem.BookID.value + "?";
      if (confirm(text) == true) {
        this.$store.dispatch("adminBook/DELETE_BOOK", {
          BookID: this.elem.BookID.value,
        });
      }
    },
  },
  components: {
    "sdx-textbox": SDXTextbox,
    ButtonsRow,
    "bms-form-for-book": BMSFormForBook,
  },
};
</script>
