<template>
  <div class="qxpgrid-container user-select-text">
    <div
      v-if="isDataLoading"
      class="loader-spinner loader-spinner--small"
    ></div>
    <table class="qxpgrid-table" v-show="showGrid">
      <tbody>
        <tr class="head">
          <th>
            <input type="checkbox" v-model="checkedAllRowSelected" />
          </th>
          <th v-for="col in cols">{{ col.DisplayName }}</th>
        </tr>
        <qxp-row-sel
          v-for="row in rows"
          :key="row._rn"
          :cols="cols"
          :row="row"
          :checked="isRowSelected(row._rn)"
          @toggleCheckbox="toggleRowSelection($event)"
        ></qxp-row-sel>
      </tbody>
    </table>
    <div class="sentList-container">
      <p v-show="showListSent" class>Sent Requests:</p>
      <ul class="user-select-text" v-show="showListSent">
        <li v-for="sent in sentItems" class="single-line">
          {{ getLiTextForSent(sent) }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import QxpRowSel from "@/components/datagrid/QxpGrid/QxpRowSel";
import { getReportRequestForSend } from "@/components/wv_filter/WvQueryOption.js";
export default {
  data() {
    return {
      selectedRowNr: {},
      selectedRows: {},
      checkedAllRowSelected: false,
    };
  },
  watch: {
    checkedAllRowSelected: function (value) {
      if (value) {
        this.SelectAllRows();
      } else {
        this.clearRowSelection();
      }
    },
  },
  computed: {
    ...mapGetters({
      isSentLoading: "pfrrStore/isSentLoading",
      isSentLoaded: "pfrrStore/isSentLoaded",
      pfrrSent: "pfrrStore/pfrrSent",
      isInfoLoaded: "pfrrStore/isSchemaLoaded",
      colForList: "pfrrStore/colForList",
      isDataLoading: "pfrrStore/isDataLoading",
      isDataLoaded: "pfrrStore/isDataLoaded",
      pfrrData: "pfrrStore/pfrrData",
    }),
    cols() {
      if (this.isInfoLoaded && this.colForList) {
        return this.colForList;
      }
      return {};
    },
    rows() {
      if (this.isDataLoaded && this.pfrrData) {
        if (this.pfrrData.length > 0) {
          return this.pfrrData; //.slice(0, this.maxRows)
        }
      }
      return [];
    },
    showGrid() {
      return this.isInfoLoaded && this.isDataLoaded;
    },
    sentItems() {
      if (this.isSentLoaded) {
        return this.pfrrSent.slice(0, this.maxRows);
      }
    },
    showListSent() {
      if (this.isSentLoaded) {
        return true;
      }
      return false;
    },
  },
  methods: {
    SelectAllRows() {
      this.clearRowSelection();
      this.rows.forEach((row) => {
        this.selectedRowNr[row._rn] = true;
        this.selectedRows[row._rn] = row;
      });
      this.selectedRowNr = { ...this.selectedRowNr };
      this.selectedRows = { ...this.selectedRows };
      let emit = [];
      Object.values(this.selectedRows).forEach((value) => {
        if (value) {
          emit.push(value);
        }
      });
      this.$emit("gridSelectedRowLength", emit.length);
    },
    toggleRowSelection(row) {
      //store true/false
      if (this.selectedRowNr[row._rn] == undefined) {
        this.selectedRowNr[row._rn] = true;
        this.selectedRowNr = { ...this.selectedRowNr };
      } else {
        this.selectedRowNr[row._rn] = !this.selectedRowNr[row._rn];
      }

      //store rows if true
      this.selectedRows[row._rn] = undefined; //remove
      if (this.selectedRowNr[row._rn]) {
        this.selectedRows[row._rn] = row;
      }
      this.selectedRows = { ...this.selectedRows };
      let emit = [];
      Object.values(this.selectedRows).forEach((value) => {
        if (value) {
          emit.push(value);
        }
      });
      this.$emit("gridSelectedRowLength", emit.length);
    },
    isRowSelected(rn) {
      return this.selectedRowNr[rn];
    },
    clearRowSelection() {
      this.selectedRowNr = {};
      this.selectedRows = {};
      this.$emit("gridSelectedRowLength", 0);
    },
    clearTable() {
      this.clearRowSelection();
      this.$store.dispatch("pfrrStore/CLEAR");
    },
    startReportingUsingSelectedRow() {
      Object.values(this.selectedRows).forEach((value) => {
        if (value) {
          const transformedForSend = getReportRequestForSend(value);
          this.$store.dispatch(
            "pfrrStore/SEND_REPORTREQUEST",
            transformedForSend
          );
        }
      });
      this.clearRowSelection();
      let WViewName = "PF_RR_Overview";
      this.$store.dispatch("wviewStore/LOAD_INFO", {
        vwiewName: WViewName,
      });
      var queryParams = {
        $filter: `(date_inserted ge ${moment().format("YYYY-MM-DD")})`,
        $orderby: "PFReqID desc",
        byPassIsRequest: true,
      };
      this.$store.dispatch("wviewStore/LOAD_DATA", {
        vwiewName: WViewName,
        queryParams: queryParams,
      });
    },
    getLiTextForSent(sent) {
      let ret = "";
      let payload = {};
      if (sent.status === 201) {
        payload = sent.data; //returned successfully
        ret = "Request sent.";
      } else {
        payload = JSON.parse(sent.config.data); //err, so consider the payload sent
        ret = "Error sending request.";
      }
      ret += ` pfReqID: ${payload.id}, 
      FundNo: ${payload.FundNo}, 
      ReportType: ${payload.ReportType}, 
      PeriodDesc: ${payload.Details[0].PeriodType},
      Begin: ${moment(payload.Details[0].Begin).format("DD.MM.YYYY")},
      End: ${moment(payload.Details[0].End).format("DD.MM.YYYY")}`;
      return ret;
    },
  },
  beforeUpdate() {
    this.$emit("qxpGridBeforeUpdate");
  },
  updated() {
    this.$emit("qxpGridUpdated");
  },
  props: {
    showRowsInDiffOnly: { type: Boolean, default: true },
    isExpRequestModus: { type: Boolean, default: false },
  },
  components: {
    QxpRowSel,
  },
};
</script>
