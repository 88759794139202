import * as types from "../actions/ui";

// state
const state = {
  clientWidth: document.documentElement.clientWidth,
  clientHeight:
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight,
  scrollTop:
    window.pageYOffset ||
    document.documentElement.scrollTop ||
    document.body.scrollTop ||
    0,
  // theme: localStorage.getItem('theme')
};

const getters = {
  clientWidth: (state) => state.clientWidth,
  clientHeight: (state) => state.clientHeight,
  scrollTop: (state) => state.scrollTop,
  theme: (state) => () => {
    let theme = localStorage.getItem("theme");
    if (!theme) {
      localStorage.setItem("theme", "dark");
    }
    return localStorage.getItem("theme");
  },
};

const mutations = {
  [types.SET_CLIENT_WIDTH](state) {
    state.clientWidth = document.documentElement.clientWidth;
  },
  [types.SET_CLIENT_HEIGHT](state) {
    state.clientHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;
  },
  [types.SET_SCROLL_TOP](state) {
    state.scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
  },
  SET_THEME: (state, data) => {
    localStorage.setItem("theme", data);
  },
  TOGGLE_THEME: (state, data) => {
    let htmlElement = document.documentElement;
    if (localStorage.getItem("theme") == "light") {
      localStorage.setItem("theme", "dark");
      htmlElement.setAttribute("theme", "dark");
    } else {
      localStorage.setItem("theme", "light");
      htmlElement.setAttribute("theme", "light");
    }
  },
};

const actions = {
  SET_THEME({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_THEME", payload);
    });
  },
  TOGGLE_THEME({ commit }) {
    return new Promise((resolve, reject) => {
      commit("TOGGLE_THEME");
      resolve();
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
