<template>
  <div class="qxpgrid-container user-select-text">
    <table class="qxpgrid-table" v-show="showGrid">
      <tbody>
        <tr class="head">
          <th
            v-for="col in cols"
            :key="col.ColName"
            :align="getCellAlign(col)"
            :title="col.TextTooltip"
            >{{ col.DisplayName }}</th
          >
        </tr>
        <tr v-for="row in rows" :key="row._rn">
          <td
            v-for="col in cols"
            :key="col.ColName"
            :class="{ nowrap: nowrap || getCellNowrap(col) }"
            :align="getCellAlign(col)"
            >{{ getCellValue(row, col) }}</td
          >
        </tr>
        <tr v-if="isMoreRows" @click="showMoreRows" style="cursor: pointer">
          <td :colspan="colsLength">
            <span class="qx-link-text">{{ additionalRowText }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getCellAlign, getCellValue, getCellNowrap } from "./QxpFunctions.js";

export default {
  computed: {
    isInfoLoaded() {
      return this.$store.getters[this.storeString + "/isInfoLoaded"];
    },
    wvInfo() {
      return this.$store.getters[this.storeString + "/wvInfo"];
    },
    isDataLoaded() {
      return this.$store.getters[this.storeString + "/isDataLoaded"];
    },
    wvData() {
      return this.$store.getters[this.storeString + "/wvData"];
    },
    maxRows() {
      return this.$store.getters[this.storeString + "/maxRows"];
    },
    rowsLength() {
      return this.$store.getters[this.storeString + "/rowsLength"];
    },
    colsLength() {
      return this.$store.getters[this.storeString + "/colsLength"];
    },
    storeString() {
      if (this.useSecondaryStore) {
        return "wviewStoreB";
      }
      return "wviewStore";
    },
    cols() {
      if (this.isInfoLoaded && this.wvInfo) {
        return this.wvInfo.hu.colForList;
      }
      return {};
    },
    colsLength() {
      return Object.keys(this.cols).length;
    },
    rows() {
      if (this.isDataLoaded && this.wvData) {
        if (this.wvData.length > 0) {
          return this.wvData.slice(0, this.maxRows);
        }
      }
      return [];
    },
    isMoreRows() {
      if (this.rowsLength > 0 && this.rowsLength > this.maxRows) {
        return true;
      }
    },
    additionalRowText() {
      if (this.isMoreRows) {
        return `Only the first ${this.maxRows} (of ${this.rowsLength}) rows are displayed, click to display more`;
      }
    },
    showGrid() {
      return this.isInfoLoaded && this.isDataLoaded;
    },
  },
  methods: {
    getCellValue: getCellValue,
    getCellAlign: getCellAlign,
    getCellNowrap: getCellNowrap,
    showMoreRows() {
      this.$store.dispatch("wviewStore/SHOW_MORE");
    },
  },
  props: {
    nowrap: { type: Boolean, default: false },
    useSecondaryStore: { type: Boolean, default: false },
  },
  // beforeUpdate() {
  //   this.$emit('qxpGridBeforeUpdate')
  // },
  // updated() {
  //   this.$emit('qxpGridUpdated')
  // }
};
</script>
