<template>
  <div
    class="qsui-accordion"
    :class="`accordion--arrow-${arrow}`"
    ref="accordion"
  >
    <slot></slot>
  </div>
</template>

<script>
/**
 * VueJS accordion component.
 * Works together with the SDXAccordionItem component.
 *
 * Markup example:
 *   <sdx-accordion arrow="flat">
 *     <sdx-accordion-item>
 *       <p slot="header">Trigger 1</p>
 *       <p slot="collapse">Content section 1</p>
 *     </sdx-accordion-item>
 *     <sdx-accordion-item is-open>
 *       <p slot="header">Trigger 2</p>
 *       <p slot="collapse">Content section 2</p>
 *     </sdx-accordion-item>
 *     <sdx-accordion-item>
 *       <p slot="header">Trigger 3</p>
 *       <p slot="collapse">Content section 3</p>
 *     </sdx-accordion-item>
 *   </sdx-accordion>
 */
export default {
  props: {
    arrow: {
      type: String,
      default: "normal",
      validator: (value) => ["normal", "flat"].indexOf(value) !== -1,
    },
  },

  data() {
    return { sdxObject: null };
  },

  mounted() {
    // const accordion = this.$refs.accordion;
    // if (accordion) {
    //   const el = accordion.$el ? accordion.$el : accordion;
    //   this.sdxObject = new window.sdx.Accordion(el); // eslint-disable-line no-new
    // } else {
    //   console.warn("No accordion found!");
    // }
  },

  updated() {
    // This is performed by the constructor, when initializing the component
    // But if content changes, no update is performed, and the new items are
    // not reactive. So re-initialize, once the content is updated.
    // for (let toggle of this.sdxObject.element.querySelectorAll(
    //   ".accordion__toggle"
    // )) {
    //   // Since not using anonymous function as listener, extra binding of the
    //   // same listener get discarded automatically
    //   toggle.addEventListener("click", this.sdxObject._sectionClickHandler);
    // }
  },
};
</script>
