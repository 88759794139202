<template>
  <div class="navedit navedit-submenu">
    <div class="navedit navedit-submenu-header">
      <i
        class="icon icon-013-chevron-down icon--s2 navedit-toggle-icon"
        :class="{ 'icon--flip-vertical': isOpen }"
        @click="toggleOpen"
      ></i>
      <input
        class="navedit-input"
        :value="submenu.name"
        name="name"
        placeholder="Name"
        :disabled="!editMode"
        @change="onEdit($event.target)"
      />
      <input
        class="navedit-input navedit-input-short"
        :value="submenu.sh"
        name="sh"
        placeholder="sh"
        spellcheck="false"
        :disabled="!editMode"
        @change="onEdit($event.target)"
      />
      <i
        class="icon wvfilter-icon placeonly icon-063-record"
        aria-hidden="true"
      ></i>
      <i
        class="icon icon-008-bin wvfilter-icon"
        :class="{ 'wvfilter-icon-disabled': hasChild || !editMode }"
        title="Delete Submenu (delete child(s) first!)"
        aria-hidden="true"
        @click="onDelete()"
      ></i>
      <i
        class="icon icon-061-plus wvfilter-icon wvfilter-green-button"
        :class="{ 'wvfilter-icon-disabled': !editMode }"
        title="Add Link"
        aria-hidden="true"
        @click="addLink()"
      ></i>
    </div>
    <div v-if="isOpen" class="navedit navedit-submenu-content">
      <edit-link
        v-for="link in submenu.Links"
        :key="link.id"
        :link="link"
        :editMode="editMode"
      />
    </div>
  </div>
</template>

<script>
import QxpNavEditLink from "@/components/admin/QxpNav/QxpNavEditLink";
import { mapGetters } from "vuex";
export default {
  created() {
    this.$store.dispatch("adminPortal/INIT_SUBMENU_OPEN", this.submenu.id);
  },
  computed: {
    ...mapGetters({
      openSubmenuData: "adminPortal/openSubmenuData",
    }),
    isOpen() {
      return this.openSubmenuData[this.submenu.id];
    },
    hasChild() {
      return (
        this.submenu && this.submenu.Links && this.submenu.Links.length > 0
      );
    },
  },
  methods: {
    toggleOpen() {
      this.$store.dispatch("adminPortal/TOGGLE_SUBMENU_OPEN", this.submenu.id);
    },
    addLink() {
      if (!this.editMode) {
        return;
      }
      if (this.submenu.id > 0) {
        this.$store.dispatch("adminPortal/CREATE_LINK", {
          ParentID: this.submenu.id,
          name: "new Link",
        });
        if (!this.isOpen) {
          this.toggleOpen();
        }
      }
    },
    onEdit(target) {
      if (!this.editMode) {
        return;
      }
      let ret = this.cloneProp();
      if (target.name == "name") {
        if (ret.name == target.value) {
          return;
        }
        ret.name = target.value;
      }
      if (target.name == "sh") {
        if (ret.sh == target.value) {
          return;
        }
        ret.sh = target.value;
      }
      this.$store.dispatch("adminPortal/MODIFY_SUBMENU", ret);
    },
    onDelete() {
      if (!this.editMode) {
        return;
      }
      if (this.submenu) {
        if (this.submenu.Links && this.submenu.Links.length > 0) {
          this.$store.dispatch(
            "setError",
            "Could not delete, plz delete child(s) first"
          );
        } else {
          this.$store.dispatch("adminPortal/DELETE_SUBMENU", {
            id: this.submenu.id,
          });
        }
      }
    },
    cloneProp() {
      return {
        name: this.submenu.name,
        id: this.submenu.id,
        sh: this.submenu.sh,
      };
    },
  },
  props: {
    editMode: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    submenu: {
      type: Object,
      default: function () {
        return {
          name: "subMenuName",
          id: -1,
          sh: 4,
          Links: [
            {
              id: 2,
              name: "myName",
              link: "myLink",
              intWin: true,
              sh: 5,
            },
            {
              id: 3,
              name: "myLinkNameB",
              link: "myLinkB",
              intWin: false,
              sh: 4,
            },
          ],
        };
      },
    },
  },
  components: {
    "edit-link": QxpNavEditLink,
  },
};
</script>

<style></style>
