<template>
  <div
    class="cortana-side-nav bg bg--gray extra-width"
    :class="{ hidden: !isOpen }"
  >
    <div class="cortana-side-nav__wrapper qsui-admin-wrapper">
      <div class="text-h4">RiskBenchmark List:</div>
      <div class="qsui-admin-list-filerplus">
        <a
          class="button button__icon button--secondary qsui-admin-list-button"
          href="javascript:void(0);"
          @click="plusElem()"
        >
          <i class="icon icon-061-plus" aria-hidden="true"></i>
        </a>
      </div>
      <div class="qsui-admin-list-filerplus">
        <div>
          <sdx-textbox v-model="oValueFilter" label="filter..." type="text" />
        </div>
      </div>
      <div
        v-if="showSpinner"
        class="loader-spinner loader-spinner--small"
      ></div>
      <ul v-else class="qsui-admin-list">
        <div v-for="elem in filteredData" v-bind:key="elem">
          <li
            class="qsui-admin-list-item qsui-admin-list-item-click"
            @click="loadElem(elem)"
          >
            {{ elem }}
          </li>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SDXTextbox from "@/components/ui_utilities/SDXTextbox";
export default {
  data() {
    return {
      oValueFilter: { value: "", valid: undefined },
      checkedActive: true,
    };
  },
  created() {
    this.$store.dispatch("fmdRiskBenchmark/LOAD_RISKBENCHMARKS_DATA");
  },
  computed: {
    ...mapGetters({
      riskBenchmarksData: "fmdRiskBenchmark/riskBenchmarksData",
      isRiskBenchmarksLoading: "fmdRiskBenchmark/isRiskBenchmarksLoading",
    }),
    filteredData() {
      if (this.riskBenchmarksData) {
        const lowcasevalue = this.oValueFilter.value.toLowerCase();
        return this.riskBenchmarksData.filter((elem) => {
          let filterFilter =
            !lowcasevalue || elem.toLowerCase().includes(lowcasevalue);
          return filterFilter;
        });
        return this.riskBenchmarksData;
      }
      return [];
    },
    showSpinner() {
      return this.isRiskBenchmarksLoading;
    },
  },
  methods: {
    loadElem(elem) {
      this.$store.dispatch("fmdRiskBenchmark/LOAD_RISKBENCHMARK_DATA", {
        name: elem,
      });
    },
    plusElem() {
      //if nothing is loaded, create Form appears
      this.$store.dispatch("fmdRiskBenchmark/CLEAR_RISKBENCHMARK");
    },
  },
  props: {
    isOpen: { type: Boolean, default: true },
  },
  components: {
    "sdx-textbox": SDXTextbox,
  },
};
</script>
