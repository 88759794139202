<template>
  <tr
    :class="{ 'qxgrid-selected': checked }"
    :data-row-key="row._rn"
    @click="toggleCheckbox"
  >
    <td>
      <input type="checkbox" v-model="checked" />
    </td>
    <template v-for="col in cols">
      <qxp-cell-corr-number
        v-if="isCellEditableNumber(col)"
        v-bind:key="col.ColName"
        :value="getCellRawValue(row, col)"
        :col="col"
        @input="onEditCell($event, col, row)"
        @focus="focus($event)"
      />
      <qxp-cell-corr-string
        v-else-if="isCellEditableString(col)"
        v-bind:key="col.ColName"
        :value="getCellRawValue(row, col)"
        :col="col"
        :width="getWidthForEditableString(col)"
        @input="onEditCell($event, col, row)"
        @focus="focus($event)"
      />
      <qxp-cell-corr-text
        v-else-if="isCellEditableText(col)"
        v-bind:key="col.ColName"
        :value="getCellRawValue(row, col)"
        :col="col"
        :nowrap="nowrap"
        @input="onEditCell($event, col, row)"
        @focus="focus($event)"
      />
      <qxp-cell-corr-date
        v-else-if="isCellEditableDate(col)"
        v-bind:key="col.ColName"
        :value="getCellRawValue(row, col)"
        :col="col"
        @input="onEditCell($event, col, row)"
        @focus="focus($event)"
      />
      <qxp-cell-corr-bool
        v-else-if="isCellEditableBool(col)"
        v-bind:key="col.ColName"
        :value="getCellRawValue(row, col)"
        :col="col"
        @input="onEditCell($event, col, row)"
        @focus="focus($event)"
      />
      <td
        v-else
        v-bind:key="col.ColName"
        :class="{ nowrap: nowrap || getCellNowrap(col) }"
        :align="getCellAlign(col)"
        >{{ getCellValue(row, col) }}</td
      >
    </template>
  </tr>
</template>

<script>
import QxpCellCorrNumber from "@/components/datagrid/QxpGrid/QxpCellCorrNumber";
import QxpCellCorrBool from "@/components/datagrid/QxpGrid/QxpCellCorrBool";
import QxpCellCorrString from "@/components/datagrid/QxpGrid/QxpCellCorrString";
import QxpCellCorrText from "@/components/datagrid/QxpGrid/QxpCellCorrText";
import QxpCellCorrDate from "@/components/datagrid/QxpGrid/QxpCellCorrDate";
import {
  getCellAlign,
  getCellValue,
  getCellRawValue,
  getCellNowrap,
} from "./QxpFunctions.js";
export default {
  methods: {
    getCellValue: getCellValue,
    getCellAlign: getCellAlign,
    getCellRawValue: getCellRawValue,
    getCellNowrap: getCellNowrap,
    focus(ev) {
      if (
        ev.target &&
        ev.target.parentNode &&
        ev.target.parentNode.parentNode &&
        ev.target.parentNode.parentNode.dataset["rowKey"]
      ) {
        let currentKey = parseInt(
          ev.target.parentNode.parentNode.dataset["rowKey"]
        );
        var searchKey = ev.direction < 0 ? currentKey - 1 : currentKey + 1;

        let element = Array.from(
          ev.target.parentNode.parentNode.parentNode.rows
        ).find(
          (obj) => obj.dataset && parseInt(obj.dataset.rowKey) === searchKey
        );
        if (!element) return;
        for (let item of element.children) {
          if (item.children && item.children.length > 0) {
            let ele = Array.from(item.children).find(
              (obj) => obj && obj.name === ev.target.name
            );
            if (ele) {
              ele.focus();
              this.$emit("focusChange", searchKey);
              break;
            }
          }
        }
      }
    },
    toggleCheckbox() {
      this.$emit("toggleCheckbox", this.row);
    },
    isCellEditable(col) {
      return !!col && !!col.StoredProcedure;
    },
    isCellEditableNumber(col) {
      if (!!col && !!col.StoredProcedure) {
        switch (col.ColType) {
          case "number":
          case "perc":
          case "percmath":
            return true;
        }
      }
    },
    isCellEditableBool(col) {
      if (!!col && !!col.StoredProcedure) {
        switch (col.ColType) {
          case "bool":
            return true;
        }
      }
    },
    isCellEditableString(col) {
      if (!!col && !!col.StoredProcedure) {
        switch (col.ColType) {
          case "name":
            return true;
        }
      }
    },
    isCellEditableText(col) {
      if (!!col && !!col.StoredProcedure) {
        switch (col.ColType) {
          case "text":
            return true;
        }
      }
    },
    isCellEditableDate(col) {
      if (!!col && !!col.StoredProcedure) {
        switch (col.ColType) {
          case "date":
            return true;
        }
      }
    },
    onEditCell(event, col, row) {
      let payload = {
        WViewName: this.WViewName,
        colName: col.ColName,
        newValue: event,
        dataRow: row,
      };
      if (col.ColType == "percmath") {
        payload.newValue = Number(payload.newValue) / 100;
      }
      // console.log(payload);
      this.$store.dispatch("wviewStore/SEND_CORRECTION", payload);
    },
    getWidthForEditableString(col) {
      if (!!this.maxlengthEditableText[col.ColName]) {
        return Math.min(this.maxlengthEditableText[col.ColName] * 8 + 20, 1000);
      }
      return 200;
    },
  },
  props: {
    cols: { type: Object },
    row: { type: Object },
    checked: { type: Boolean, default: false },
    nowrap: { type: Boolean, default: false },
    WViewName: { type: String, default: "" },
    maxlengthEditableText: { type: Object },
  },
  components: {
    QxpCellCorrNumber,
    QxpCellCorrString,
    QxpCellCorrText,
    QxpCellCorrDate,
    QxpCellCorrBool,
  },
};
</script>

<style></style>
