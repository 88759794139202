<template>
  <div class="navedit navedit-portal">
    <div class="navedit navedit-portal-header">
      <i
        class="icon icon-013-chevron-down icon--s2 navedit-toggle-icon"
        :class="{ 'icon--flip-vertical': isOpen }"
        @click="toggleOpen"
      ></i>
      <input
        class="navedit-input"
        :value="portal.name"
        name="name"
        placeholder="Name"
        :disabled="!editMode"
        @change="onEdit($event.target)"
      />
      <input
        class="navedit-input navedit-input-short"
        :value="portal.sh"
        name="sh"
        placeholder="sh"
        spellcheck="false"
        :disabled="!editMode"
        @change="onEdit($event.target)"
      />
      <!-- <i
        class="icon wvfilter-icon class-for-id-navedit-toggle-qsuionly"
        :class="[
          { 'wvfilter-icon-disabled': !editMode },
          isQsuiOnly ? 'icon-064-record-filled' : 'icon-063-record',
        ]"
        :title="
          isQsuiOnly
            ? 'actually only visible in QSUI, click to change'
            : 'actually visible in QSUI and in QxPortal, click to change'
        "
        aria-hidden="true"
        @click="onEdit($event.target)"
      ></i> -->
      <i
        class="icon icon-008-bin wvfilter-icon"
        :class="{ 'wvfilter-icon-disabled': hasChild || !editMode }"
        title="Delete Portal (delete child(s) first!)"
        aria-hidden="true"
        @click="onDelete()"
      ></i>
      <i
        class="icon icon-061-plus wvfilter-icon wvfilter-green-button"
        :class="{ 'wvfilter-icon-disabled': !editMode }"
        title="Add Menu"
        aria-hidden="true"
        @click="addMenu()"
      ></i>
      <input
        class="navedit-input"
        :value="portal.groupName"
        name="groupName"
        placeholder="DisplayName"
        title="DisplayName is used to Display Menus from different Portals into a same DisplayName"
        spellcheck="false"
        :disabled="!editMode"
        @change="onEdit($event.target)"
      />
      <i
        class="icon wvfilter-icon"
        :class="
          editMode
            ? 'icon-226-reprovision'
            : 'icon-025-edit wvfilter-green-button'
        "
        title="toggle EditMode for Portal (Experts Only, this is dangerous)"
        aria-hidden="true"
        @click="toggleEditMode()"
      ></i>
    </div>
    <div v-if="isOpen" class="navedit navedit-portal-content">
      <edit-menu
        v-for="menu in portal.Menus"
        :key="menu.id"
        :menu="menu"
        :editMode="editMode"
        @input="onMenuInput($event)"
      />
    </div>
  </div>
</template>

<script>
import QxpNavEditMenu from "@/components/admin/QxpNav/QxpNavEditMenu";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      editMode: false,
    };
  },
  created() {
    this.$store.dispatch("adminPortal/INIT_PORTAL_OPEN", this.portal.id);
  },
  computed: {
    ...mapGetters({
      openPortalData: "adminPortal/openPortalData",
    }),
    isOpen() {
      return this.openPortalData[this.portal.id];
    },
    isQsuiOnly() {
      return this.portal.title && this.portal.title.toLowerCase() == "qsui";
    },
    hasChild() {
      return this.portal && this.portal.Menus && this.portal.Menus.length > 0;
    },
  },
  methods: {
    toggleOpen() {
      this.$store.dispatch("adminPortal/TOGGLE_PORTAL_OPEN", this.portal.id);
    },
    addMenu() {
      if (!this.editMode) {
        return;
      }
      if (this.portal.id > 0) {
        this.$store.dispatch("adminPortal/CREATE_MENU", {
          ParentID: this.portal.id,
          name: "new Menu",
        });
      }
    },
    onEdit(target) {
      if (!this.editMode) {
        return;
      }
      let ret = this.cloneProp();
      if (target.name == "name") {
        if (ret.name == target.value) {
          return;
        }
        ret.name = target.value;
      }
      if (target.name == "sh") {
        if (ret.sh == target.value) {
          return;
        }
        ret.sh = target.value;
      }
      if (target.name == "groupName") {
        if (ret.groupName == target.value) {
          return;
        }
        ret.groupName = target.value;
      }
      if (target.className.includes("class-for-id-navedit-toggle-qsuionly")) {
        if (ret.title && ret.title.toLowerCase() == "qsui") {
          ret.title = "";
        } else {
          ret.title = "QSUI";
        }
      }
      this.$store.dispatch("adminPortal/MODIFY_PORTAL", ret);
    },
    onDelete() {
      if (!this.editMode) {
        return;
      }
      if (this.portal) {
        if (this.portal.Menus && this.portal.Menus.length > 0) {
          this.$store.dispatch(
            "setError",
            "Could not delete, plz delete child(s) first"
          );
        } else {
          this.$store.dispatch("adminPortal/DELETE_PORTAL", {
            id: this.portal.id,
          });
        }
      }
    },
    toggleEditMode() {
      this.editMode = !this.editMode;
    },
    cloneProp() {
      return {
        name: this.portal.name,
        title: this.portal.title,
        id: this.portal.id,
        sh: this.portal.sh,
      };
    },
  },
  props: {
    portal: {
      type: Object,
      default: function () {
        return {
          name: "PortalName",
          title: "QSUI",
          id: -1,
          sh: 4,
          Menus: [
            {
              name: "MenuName",
              text: "QSUI",
              id: -1,
              sh: 4,
              SubMenus: [
                {
                  name: "subMenuName",
                  id: -1,
                  sh: 4,
                  Links: [
                    {
                      id: 2,
                      name: "myName",
                      link: "myLink",
                      intWin: true,
                      sh: 5,
                    },
                    {
                      id: 3,
                      name: "myLinkNameB",
                      link: "myLinkB",
                      intWin: false,
                      sh: 4,
                    },
                  ],
                },
                {
                  name: "subMenuNameB",
                  id: 5,
                  sh: 4,
                  Links: [
                    {
                      id: 2,
                      name: "myName",
                      link: "myLink",
                      intWin: true,
                      sh: 5,
                    },
                    {
                      id: 3,
                      name: "myLinkNameB",
                      link: "myLinkB",
                      intWin: false,
                      sh: 4,
                    },
                  ],
                },
              ],
            },
          ],
        };
      },
    },
  },
  components: {
    "edit-menu": QxpNavEditMenu,
  },
};
</script>

<style></style>
