<template>
  <div class="input-field-select">
    <label :for="id"
      ><span class="label">{{ label }}</span></label
    >
    <treeselect
      v-model="valueProxy"
      :options="ts_options"
      :clearable="false"
      :disabled="disabled"
      :id="id"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import randomstring from "randomstring";
import Treeselect from "@riophae/vue-treeselect";
export default {
  data() {
    return {
      id: randomstring.generate(10),
    };
  },
  computed: {
    ...mapGetters({
      fsconfmap: "fmdFund/fsconfmap",
    }),
    ts_options() {
      var ret = [];
      this.fsconfmap.forEach((element) => {
        if (element.map_type == this.map_type) {
          ret.push({ id: element.class, label: element.desc_en });
        }
      });
      return ret;
    },
    valueProxy: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  props: {
    map_type: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    value: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Treeselect,
  },
};
</script>

<style></style>
