<template>
  <td>
    <input
      class="qxgrid-corr qxgrid-corr-date"
      :class="{ 'qxgrid-corr-invalid': !isValid }"
      v-model="valueData"
      placeholder="null"
      spellcheck="false"
      :name="col.ColName"
      @keydown.up="$emit('focus', { direction: -1, target: $event.target })"
      @keydown.down="$emit('focus', { direction: 1, target: $event.target })"
      @keydown.esc="reset($event.target)"
      @change="onChange($event.target)"
    />
  </td>
</template>

<script>
import moment from "moment";
import { getCellAlign, getCellValue } from "./QxpFunctions.js";
import config from "@/data/config";
import { validate } from "@/util/regex.js";

export default {
  data() {
    return {
      valueData: moment(this.value)
        .format("DD.MM.YYYY")
        .replace("Invalid date", ""),
      isValid: true,
      format: "DD.MM.YYYY",
    };
  },
  watch: {
    valueData(newValue, oldValue) {
      if (!this.isInputWorthSending(newValue)) {
        this.isValid = true;
      } else if (this.isInputValid(newValue)) {
        this.isValid = true;
      } else {
        this.isValid = false;
      }
    },
    value(value) {
      this.valueData = moment(this.value)
        .format(this.format)
        .replace("Invalid date", "");
    },
  },
  computed: {
    regexPattern() {
      return config.regex.stringInput;
    },
  },
  methods: {
    getCellValue: getCellValue,
    getCellAlign: getCellAlign,
    onChange(target) {
      if (
        this.isInputWorthSending(this.valueData) &&
        this.isInputValid(this.valueData)
      ) {
        this.$emit("input", moment(this.valueData, this.format, true).toDate());
        //reset to original value, making sure the value is not cached
        this.valueData = moment(this.value)
          .format(this.format)
          .replace("Invalid date", "");
        target.blur();
      }
    },
    reset(target) {
      this.valueData = moment(this.value).format(this.format);
      target.blur();
    },
    isInputValid(value) {
      return moment(value, this.format, true).isValid();
    },
    isInputWorthSending(value) {
      return value != moment(this.value).format("DD.MM.YYYY") && !!value;
    },
    validate: validate,
  },
  props: {
    value: [String, Date],
    col: Object,
  },
};
</script>

<style></style>
