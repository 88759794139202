import axios from "axios";
import store from "..";

const state = {
  data: [],
};

const getters = {
  data() {
    return state.data;
  },
};

const mutations = {
  SET_DATA(state, data) {
    state.data = data;
  },
};

const actions = {
  LOAD({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/wview/samplerequests")
        .then((response) => {
          commit("SET_DATA", response.data);
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            "Error while Loading Sample Requests"
          );
        });
    });
  },
  LOAD_IRSDESTINATIONS({ commit }) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_IRSDESTINATIONS_DATA_LOADING");
      axios
        .get("/api/irsrequest/Destinations")
        .then((response) => {
          commit("SET_IRSDESTINATIONS_DATA", response.data);
          commit("SET_STATUS_IRSDESTINATIONS_DATA_LOADED");
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_IRSDESTINATIONS_DATA_ERROR");
        });
    });
  },
  LOAD_IRSTYPES({ commit }) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_IRSTYPES_DATA_LOADING");
      axios
        .get("/api/irsrequest/types")
        .then((response) => {
          commit("SET_IRSTYPES_DATA", response.data);
          commit("SET_STATUS_IRSTYPES_DATA_LOADED");
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_IRSTYPES_DATA_ERROR");
        });
    });
  },
  SEND_IRSREQUEST: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      var requestConfig = {
        params: {},
      };
      axios
        .post("/api/irsrequest", payload, requestConfig)
        .then((resp) => {
          const result = resp.data;
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  GET_IRSREQUEST({ commit }, irs_id) {
    return new Promise((resolve, reject) => {
      var requestConfig = {
        params: {},
      };
      axios
        .get("/api/irsrequest/" + irs_id, requestConfig)
        .then((resp) => {
          const result = resp.data;
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  DOWNLOAD_DOCUMENT({ commit }, payload) {
    return new Promise((resolve, reject) => {
      var requestConfig = {
        responseType: "blob",
        params: {},
      };
      axios
        .get("/api/irsrequest/" + payload.IRSId + "/doc", requestConfig)
        .then((response) => {
          const downloadUrl = window.URL.createObjectURL(
            new Blob([response.data])
          );
          const link = document.createElement("a");
          link.href = downloadUrl;
          let fileName = "";
          if (!!response.headers["content-disposition"]) {
            fileName =
              response.headers["content-disposition"].split("filename=")[1];
          }
          if (!fileName) {
            fileName = "File.pdf";
          }
          link.setAttribute("download", fileName); //any other extension
          document.body.appendChild(link);
          link.click();
          setTimeout(() => {
            URL.revokeObjectURL(downloadUrl);
            link.remove();
          }, 15000);

          resolve(fileName);
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            "Error while Loading IRS Document"
          );
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
