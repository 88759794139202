export function AddParamAcridToLink(link, ACRID) {
  try {
    let url = new URL(link);
    if (!url.searchParams.has("ACRID") && ACRID) {
      url.searchParams.append(
        "ACRID",
        // 'ACR_{102ABA9C-DBB2-4280-A0E6-4F431E03548A}'
        ACRID
      );
    }
    return url.toString();
  } catch (e) {
    return link;
  }
}

export function isMenuActive(menu, navData) {
  if (!!menu && !!navData && !!menu.AuthorizationPortalIds) {
    var found = false;
    for (let i = 0; i < menu.AuthorizationPortalIds.length; i++) {
      var ctIndex = navData.findIndex(function (obj) {
        return obj.id === menu.AuthorizationPortalIds[i];
      });
      if (ctIndex > -1) {
        found = true;
        break;
      }
    }
    return found;
  }
  return false;
}

export function isLinkActive(link, navData) {
  if (
    !!link &&
    !!navData &&
    (!!link.AuthorizationPortalId || link.AuthorizationPortalId === 0)
  ) {
    return (
      navData.findIndex(function (obj) {
        return obj.id === link.AuthorizationPortalId;
      }) > -1
    );
  }
  return true;
}

export function isAtLeastOneLinkActiveInSubMenu(subMenu, navData) {
  for (let iLink = 0; iLink < subMenu.Links.length; iLink++) {
    const link = subMenu.Links[iLink];
    if (isLinkActive(link, navData)) {
      return true;
    }
  }
  return false;
}

export function getBreadcrumbsFromNavData(navData, navids) {
  var ret = [];
  if (!!navids && !!navData) {
    const portalid = navids[0];
    const portal = navData.find((x) => x.id == portalid);
    const menuid = navids[1];
    if (!!portal && !!portal.Menus && !!menuid) {
      let url = new URL("http://dummy");
      url.searchParams.append("nav", navids);
      ret.push({
        id: portal.id,
        name: portal.name,
        link: "/" + url.search,
      });
      const menu = portal.Menus.find((x) => x.id == menuid);
      if (!!menu) {
        ret.push({
          id: menu.id,
          name: menu.name,
          link: "/" + url.search,
        });
      }
      const submenuid = navids[2];
      const submenu = menu.SubMenus.find((x) => x.id == submenuid);
      if (!!submenu) {
        ret.push({
          id: submenu.id,
          name: submenu.name,
          link: "/" + url.search,
        });
      }
      const linkid = navids[3];
      const link = submenu.Links.find((x) => x.id == linkid);
      if (!!submenu) {
        ret.push({
          id: link.id,
          name: link.name,
          link: "/" + url.search,
        });
      }
      return ret;
    }
  }
}
