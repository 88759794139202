<template>
  <div>
    <div v-if="showBanner" class="bg bg--gray result-banner exprequest">
      <div class="result-banner-left exprequest">
        <select-format @input="onFormatInput($event)" />
        <select-destination
          :Destinations="expDestinationsData"
          @input="onDestinationInput($event)"
        />
        <div
          class="result-banner-icons"
          title="Clear Row Selection"
          @click="$emit('triggerClearRowSelection')"
        >
          <i class="icon icon-008-bin wvfilter-icon" aria-hidden="true"></i>
        </div>
        <div class="banner-buttons">
          <a
            class="button button__icon button--primary banner-button"
            href="javascript:void(0);"
            @click="sendExportRequest()"
            >export {{ gridSelectedRowLength }} selected files</a
          >
        </div>
        <div class="result-banner-messages">
          <ul>
            <li v-for="message in messages">
              <p class="font font--light">{{ message }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      v-if="showBanner"
      class="bg bg--gray result-banner exprequest exprequest-wviews"
    >
      <div class="result-banner-left exprequest">
        <template v-for="rep in neededInfoWViewSelects">
          <select-exp-wview
            :ReportName="rep.Report"
            :Wviews="rep.Wviews"
            :value="rep.value"
            @input="onWviewInput($event)"
          ></select-exp-wview>
        </template>
        <!-- {{this.wviewReportMapping}} -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import selectDestination from "@/components/datagrid/inputForm/selectDestination";
import selectFormat from "@/components/datagrid/inputForm/selectFormat";
import selectExpWview from "@/components/datagrid/inputForm/selectExpWview";
export default {
  data() {
    return {
      wviewReportMapping: [],
      format: 1,
      destination: -1,
    };
  },
  computed: {
    ...mapGetters({
      _isDataLoaded: "wviewStore/isDataLoaded",
      _wvData: "wviewStore/wvData",
      _isInfoLoaded: "wviewStore/isInfoLoaded",
      _wvInfo: "wviewStore/wvInfo",
      isRtwDataLoaded: "pferStore/isRtwDataLoaded",
      rtwData: "pferStore/rtwData",
      isExpDestinationsDataLoaded: "pferStore/isExpDestinationsDataLoaded",
      expDestinationsData: "pferStore/expDestinationsData",
      isSentLoaded: "pferStore/isSentLoaded",
      pfrrSent: "pferStore/pfrrSent",
    }),
    messages() {
      if (this.isSentLoaded && this.pfrrSent) {
        let messages = [];
        messages.push("Total of valid Requests sent: " + this.pfrrSent.length);
        return messages;
      }
    },
    showBanner() {
      return this._isDataLoaded;
    },
    gridSelectedRowLength() {
      if (this.selectedRows) {
        return this.selectedRows.length;
      } else {
        return 0;
      }
    },
    distinctSelectedReport() {
      var ret = [];
      if (this.selectedRows) {
        for (let iR = 0; iR < this.selectedRows.length; iR++) {
          const ctR = this.selectedRows[iR];
          if (ctR.ReportType) {
            if (ret.includes(ctR.ReportType)) {
              continue;
            } else {
              ret.push(ctR.ReportType);
            }
          }
        }
      }
      return ret.sort();
    },
    neededInfoWViewSelects() {
      var ret = [];
      if (this.isRtwDataLoaded && this.rtwData) {
        for (let iR = 0; iR < this.distinctSelectedReport.length; iR++) {
          const ctR = this.distinctSelectedReport[iR];
          var ctRtw = this.rtwData[ctR];
          var value = this.getMappedWview(ctR);

          //initialise with default if nothing found
          if (
            !value &&
            this.wviewReportMapping &&
            !!ctRtw &&
            ctRtw.length > 0
          ) {
            value = ctRtw[0]; //default to first one in the list
            this.onWviewInput({ report: ctR, value: value }); //write the value into wviewReportMapping (same as $emit from dropdown )
          }
          // ret.push(ctRtw)
          ret.push({ Report: ctR, Wviews: ctRtw, value: value });
        }
      }
      return ret;
    },
  },
  methods: {
    ReloadData() {
      this.$emit("triggerClearRowSelection");
      this.$store.dispatch("wviewStore/RELOAD_DATA");
    },
    sendExportRequest() {
      for (let iR = 0; iR < this.selectedRows.length; iR++) {
        const ctR = this.selectedRows[iR];
        //Todo, dispatch
        const ctPrepID = ctR.PFReqID;
        const ctReport = ctR.ReportType;
        const ctBook = ctR.FundNo;
        const ctWview = this.getMappedWview(ctReport);
        if (this.destination > -1) {
          const transformedForSend = {
            FundNo: ctBook,
            PFReqID: ctPrepID,
            WViewName: ctWview,
            FormatId: this.format,
            DestinationId: this.destination,
          };
          if (transformedForSend.WViewName) {
            console.log("dispatch download for " + ctPrepID);
            this.$store.dispatch(
              "pferStore/SEND_EXPORTREQUEST",
              transformedForSend
            );
          }
        } else {
          if (ctWview) {
            var queryParams = {
              $filter: `(PFReqID eq '${ctPrepID}') and (FundNo eq '${ctBook}')`,
            };
            if (this.format == 1) {
              console.log("dispatch xls for " + ctPrepID);
              this.$store.dispatch("wviewStore/LOAD_HUP_EXCEL", {
                vwiewName: ctWview,
                queryParams: queryParams,
              });
            } else {
              console.log("dispatch xml for " + ctPrepID);
              this.$store.dispatch("wviewStore/LOAD_HUP_QSXML", {
                vwiewName: ctWview,
                queryParams: queryParams,
              });
            }
          } else {
            console.log("WView not mapped for report " + ctReport);
          }
        }
      }
      // this.$emit('triggerClearRowSelection')
    },
    onWviewInput(mapping) {
      var newMapping = [];
      if (this.wviewReportMapping) {
        for (let iWRP = 0; iWRP < this.wviewReportMapping.length; iWRP++) {
          const ctWRP = this.wviewReportMapping[iWRP];
          if (
            ctWRP.report != mapping.report
            //  &&            this.distinctSelectedReport.includes(ctWRP.report)
          ) {
            newMapping.push(ctWRP);
          }
        }
        if (mapping.value) {
          newMapping.push(mapping);
        }
      }
      this.wviewReportMapping = newMapping;
    },
    onFormatInput(format) {
      this.format = format;
    },
    onDestinationInput(destination) {
      this.destination = destination;
    },
    getMappedWview(report) {
      if (this.wviewReportMapping) {
        for (let iWRP = 0; iWRP < this.wviewReportMapping.length; iWRP++) {
          const ctWRP = this.wviewReportMapping[iWRP];
          if (ctWRP.report == report) {
            return ctWRP.value;
          }
        }
      }
    },
  },
  props: {
    selectedRows: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  created() {
    if (!this.isRtwDataLoaded) {
      this.$store.dispatch("pferStore/LOAD_RTW");
    }
    if (!this.isExpDestinationsDataLoaded) {
      this.$store.dispatch("pferStore/LOAD_EXPDESTINATIONS");
    }
  },
  components: {
    selectDestination,
    selectFormat,
    selectExpWview,
  },
};
</script>
