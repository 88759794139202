import axios from "axios";
import store from "..";

const state = {
  groupsData: [],
  statusGroups: "",
  groupData: {},
  statusGroup: "",
};

const getters = {
  isGroupsLoading() {
    return state.statusGroups === "loading";
  },
  isGroupsLoaded() {
    return state.statusGroups === "loaded";
  },
  groupsData() {
    return state.groupsData;
  },
  isGroupLoading() {
    return state.statusGroup === "loading";
  },
  isGroupLoaded() {
    return state.statusGroup === "loaded";
  },
  groupData() {
    return state.groupData;
  },
};

const mutations = {
  SET_GROUPS_DATA(state, data) {
    data.forEach((elem) => {
      elem.display = `${elem.Name}`;
    });
    state.groupsData = data;
  },
  SET_STATUS_GROUPS_LOADING(state) {
    state.statusGroups = "loading";
  },
  SET_STATUS_GROUPS_LOADED(state) {
    state.statusGroups = "loaded";
  },
  SET_STATUS_GROUPS_ERROR(state) {
    state.statusGroups = "error";
  },
  SET_GROUP_DATA(state, data) {
    data.display = `${data.Name}`;
    state.groupData = data;
  },
  SET_STATUS_GROUP_LOADING(state) {
    state.statusGroup = "loading";
  },
  SET_STATUS_GROUP_LOADED(state) {
    state.statusGroup = "loaded";
  },
  SET_STATUS_GROUP_ERROR(state) {
    state.statusGroup = "error";
  },
  CLEAR_DATA(state) {
    state.groupsData = [];
    state.statusGroups = "";
    state.groupData = [];
    state.statusGroup = "";
  },
  CLEAR_GROUP_DATA(state) {
    state.groupData = [];
    state.statusGroup = "";
  },
};

const actions = {
  CREATE_GROUP: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("CLEAR_GROUP_DATA");
      commit("SET_STATUS_GROUP_LOADING");
      axios
        .post("/api/admingroup", payload)
        .then((response) => {
          commit("SET_GROUP_DATA", response.data);
          commit("SET_STATUS_GROUP_LOADED");
          store.dispatch("adminGroup/LOAD_GROUPS_DATA");
          store.dispatch("notifStore/successCreated", "Group created.");
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_GROUP_ERROR");
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          reject(err);
        });
    });
  },
  MODIFY_GROUP: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("CLEAR_GROUP_DATA");
      commit("SET_STATUS_GROUP_LOADING");
      axios
        .post("/api/admingroup/modify", payload)
        .then((response) => {
          commit("SET_GROUP_DATA", response.data);
          commit("SET_STATUS_GROUP_LOADED");
          store.dispatch("adminGroup/LOAD_GROUPS_DATA");
          store.dispatch("notifStore/successModified", "Group modified.");
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_GROUP_ERROR");
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          reject(err);
        });
    });
  },
  LOAD_GROUPS_DATA({ commit }) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_GROUPS_LOADING");
      axios
        .get("/api/admingroup")
        .then((response) => {
          commit("SET_GROUPS_DATA", response.data);
          commit("SET_STATUS_GROUPS_LOADED");
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          commit("SET_STATUS_GROUPS_ERROR");
          reject(err);
        });
    });
  },
  LOAD_GROUP_DATA({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_GROUP_LOADING");
      commit("CLEAR_GROUP_DATA");
      axios
        .get("/api/admingroup/" + payload.GroupID)
        .then((response) => {
          commit("SET_GROUP_DATA", response.data);
          commit("SET_STATUS_GROUP_LOADED");
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          commit("SET_STATUS_GROUP_ERROR");
          reject(err);
        });
    });
  },
  DELETE_GROUP({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("CLEAR_GROUP_DATA");
      axios
        .post("/api/admingroup/delete/" + payload.GroupID)
        .then((response) => {
          store.dispatch("notifStore/successDeleted", "Group deleted.");
          store.dispatch("adminGroup/LOAD_GROUPS_DATA");
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          reject(err);
        });
    });
  },
  CLEAR_STORE({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CLEAR_DATA");
    });
  },
  CLEAR_GROUP({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CLEAR_GROUP_DATA");
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
