import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import book from "./modules/book";
import ui from "./modules/ui";
import wviewStore from "./modules/wviewStore";
import pfrrStore from "./modules/pfrrStore";
import pferStore from "./modules/pferStore";
import irsStore from "./modules/irsStore";
import nav from "./modules/nav";
import adminUser from "./modules/adminUser";
import adminBook from "./modules/adminBook";
import adminPortal from "./modules/adminPortal";
import adminGroup from "./modules/adminGroup";
import fmdPerfBenchmark from "./modules/fmdPerfBenchmark";
import fmdRiskBenchmark from "./modules/fmdRiskBenchmark";
import fmdFund from "./modules/fmdFund";
import adminBookMembership from "./modules/adminBookMembership";
import adminUserMembership from "./modules/adminUserMembership";
import adminPortalMembership from "./modules/adminPortalMembership";
import notifStore from "./modules/notifStore";
import dashboardStore from "./modules/dashboardStore";
import samplerequests from "./modules/samplerequests";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    auth,
    book,
    ui,
    wviewStore,
    wviewStoreB: wviewStore,
    pfrrStore,
    pferStore,
    irsStore,
    adminUser,
    adminBook,
    adminGroup,
    adminPortal,
    fmdPerfBenchmark,
    fmdRiskBenchmark,
    fmdFund,
    adminBookMembership,
    adminUserMembership,
    adminPortalMembership,
    notifStore,
    nav,
    dashboardStore,
    samplerequests,
  },
  strict: debug,
});
