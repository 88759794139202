<template>
  <page-container :medium="6" :large="6" class="padding-v-4">
    <div class="row">
      <div class="col-xs-12">
        <h2 class="sc-navy">IP Risk Login for registered apps</h2>
        <span>Please log in.</span>
      </div>
    </div>
    <form class="row">
      <div class="col-xs-12">
        <div class="row">
          <div class="col-xs-12 padding-v-2">
            <div class="row">
              <div class="col-xs-12">
                <sdx-textbox
                  v-model="email"
                  label="User Name"
                  type="text"
                  inputName="fname"
                  class="sdx-login-username"
                  :regex="config.regex.notEmpty"
                  :disabled="loginPending"
                  @onEnterKey="onLogin"
                />
                <sdx-textbox
                  v-model="password"
                  label="Password"
                  type="password"
                  :regex="config.regex.notEmpty"
                  :disabled="loginPending"
                  @onEnterKey="onLogin"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12">
        <div class="row">
          <div class="col-xs-12">
            <buttons-row
              class="margin-v-1 loginButton"
              submitText="Log in"
              :submitDisabled="!formValid"
              :submitting="loginPending"
              @onSubmit="onLogin"
            />
          </div>
        </div>
      </div>
    </form>
  </page-container>
</template>

<script>
import PageContainer from "@/components/ui_utilities/PageContainer";
import ButtonsRow from "@/components/ui_utilities/ButtonsRow";
import SDXTextbox from "@/components/ui_utilities/SDXTextbox";

import { mapActions, mapGetters } from "vuex";
import router from "@/router";
import config from "@/data/config";

export default {
  components: {
    PageContainer,
    ButtonsRow,
    "sdx-textbox": SDXTextbox,
  },
  data() {
    return {
      email: { value: "", valid: undefined },
      password: { value: "", valid: undefined },
      loginPending: false,
      config: config,
    };
  },
  computed: {
    formValid() {
      return this.email.valid && this.password.valid;
    },
    q_response_type() {
      return this.$route.query.response_type;
    },
    q_scope() {
      return this.$route.query.scope;
    },
    q_client_id() {
      return this.$route.query.client_id;
    },
    q_state() {
      return this.$route.query.state;
    },
    q_redirect_uri() {
      return this.$route.query.redirect_uri;
    },
    q_nonce() {
      return this.$route.query.nonce;
    },
  },
  methods: {
    onLogin() {
      this.loginPending = true;
      this.$store
        .dispatch("AUTH_OPENID_REQUEST", {
          username: this.email.value,
          password: this.password.value,
          scope: this.q_scope,
          response_type: this.q_response_type,
          client_id: this.q_client_id,
          redirect_uri: this.q_redirect_uri,
          nonce: this.q_nonce,
        })
        .then((resp) => {
          this.loginPending = false;
          const url = new URL(this.q_redirect_uri);

          //query params, only used to build the string for the hash (params to be cleared afterwoods)
          url.searchParams.append("access_token", resp.data.Token);
          url.searchParams.append("state", this.q_state);
          // const hash = url.searchParams.toString();
          // url.hash = hash;
          // url.searchParams.delete("access_token");
          // url.searchParams.delete("state");

          //console.log(url.href);
          window.location = url.href;
        })
        .catch((err) => {
          this.loginPending = false;
          this.$store.dispatch(
            "notifStore/alert",
            'Forgot password? Please contact your client manager or IPConcept risk management <a href="mailto:ipcrmv@ipconcept.com">ipcrmv@ipconcept.com</a>.'
          );
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.loginButton {
  min-width: 200px;
}
.container {
  max-width: 600px;
}
</style>
