import config from "@/data/config";

/**
 * Validates a text based on a regular expression. Return true if any matches are found.
 * @param {String} text The text to validate
 * @param {String} pattern The RegExp pattern
 */
export function validate(text = "", pattern) {
  let regexp = new RegExp(pattern, "g");
  let match = text.toString().match(regexp);
  return match !== null && match.length > 0;
}
export function validatemultiline(text = "", pattern) {
  let regexp = new RegExp(pattern, "gm");
  let match = text.toString().match(regexp);
  return match !== null && match.length > 0;
}
/**
 * Gets a regex from an attribute configuration
 * @param {Object} attribute The attribute
 */
export function getAttributeRegex(attribute) {
  let r = "";
  if (attribute.required) {
    switch (attribute.dataType) {
      case config.dataType.Text:
        if (attribute.displayType === config.displayType.Text) {
          r = config.regex.notEmpty;
        }
        if (attribute.displayType === config.displayType.MultilineText) {
          r = config.regex.notEmptyMultiline;
        }
        break;
      case config.dataType.Email:
        r = config.regex.email;
        break;
      case config.dataType.Number:
        r = config.regex.decimal;
        break;
      case config.dataType.Date:
        r = config.regex.date;
        break;
    }
  }
  return r;
}

/**
 * Gets a query parameter
 * @param {String} name The name of the parameter
 * @param {String} url The url to search agains
 */
export function getParameterByName(name = "", url) {
  url = url || window.location.href;
  name = name.replace(/[\\[\]]/g, "\\$&");
  let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
  let results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function trimStart(text, c) {
  text = text ? text.toString() : "";
  let s = 0;

  if (text.length > 0) {
    while (text[s] === c) {
      ++s;
    }
  }

  return text.substr(s);
}
