import Vue from "vue";
import Router from "vue-router";
import store from "@/store";
import QxpNav from "@/components/QxpNav";
import StandardView from "@/components/StandardView";
import Login from "@/components/auth_login/SDXLogin";
import Authorize from "@/components/auth_login/Authorize";
import Logout from "@/components/auth_login/Logout";
import NotFound from "@/components/NotFound";
import PFReportRequest from "@/components/PFReportRequest";
import adminUsers from "@/components/adminUsers";
import adminBooks from "@/components/adminBooks";
import fmdPerfBenchmarks from "@/components/fmdPerfBenchmarks";
import fmdRiskBenchmarks from "@/components/fmdRiskBenchmarks";
import fmdFunds from "@/components/fmdFunds";
import adminGroups from "@/components/adminGroups";
import adminPortals from "@/components/adminPortals";
import DashboardPriorityView from "@/components/DashboardPriorityView";
import DashboardDocumentationView from "@/components/DashboardDocumentationView";
// import DashboardVolatilityView from "@/components/DashboardVolatilityView";
import backendtest from "@/components/backendtest";

Vue.use(Router);

// const ifNotAuthenticated = (to, from, next) => {
//   if (!localStorage.getItem('user-token')) {
//     next()
//     return
//   }
//   next('/')
// }

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isUserTokenHereAndStillValid()) {
    next();
    return;
  }
  next({
    name: "Login",
    query: { redirect: to.fullPath },
    // query: {
    //   returnUrl: to.path
    // }
  });
};

export default new Router({
  routes: [
    {
      path: "/",
      name: "QxpNav",
      component: QxpNav,
      beforeEnter: ifAuthenticated,
      meta: {
        showPageHeader: true,
      },
    },
    {
      path: "/Login",
      name: "Login",
      component: Login,
      // beforeEnter: ifNotAuthenticated,
      meta: {
        showPageHeader: true,
      },
    },
    {
      path: "/authorize",
      name: "authorize",
      component: Authorize,
      meta: {
        showPageHeader: false,
      },
    },
    {
      path: "/Logout",
      name: "Logout",
      component: Logout,
      meta: {
        showPageHeader: true,
      },
    },
    {
      path: "/StandardView",
      component: StandardView,
      name: "StandardView",
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/SpecificView",
      component: StandardView,
      name: "SpecificView",
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/PFReportRequest",
      component: PFReportRequest,
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/adminUsers",
      component: adminUsers,
      beforeEnter: ifAuthenticated,
      meta: {
        showPageHeader: true,
      },
    },
    {
      path: "/adminBooks",
      component: adminBooks,
      beforeEnter: ifAuthenticated,
      meta: {
        showPageHeader: true,
      },
    },
    {
      path: "/fmdPerfBenchmarks",
      component: fmdPerfBenchmarks,
      beforeEnter: ifAuthenticated,
      meta: {
        showPageHeader: true,
      },
    },
    {
      path: "/fmdRiskBenchmarks",
      component: fmdRiskBenchmarks,
      beforeEnter: ifAuthenticated,
      meta: {
        showPageHeader: true,
      },
    },
    {
      path: "/fmdFunds",
      component: fmdFunds,
      beforeEnter: ifAuthenticated,
      meta: {
        showPageHeader: true,
      },
    },
    {
      path: "/adminPortals",
      component: adminPortals,
      beforeEnter: ifAuthenticated,
      meta: {
        showPageHeader: true,
      },
    },
    {
      path: "/adminGroups",
      component: adminGroups,
      beforeEnter: ifAuthenticated,
      meta: {
        showPageHeader: true,
      },
    },
    {
      path: "/DashboardPriorityView",
      component: DashboardPriorityView,
      beforeEnter: ifAuthenticated,
      meta: {
        showPageHeader: true,
      },
    },
    {
      path: "/DashboardDocumentationView",
      component: DashboardDocumentationView,
      beforeEnter: ifAuthenticated,
      meta: {
        showPageHeader: true,
      },
    },
    // {
    //   path: "/DashboardVolatilityView",
    //   component: DashboardVolatilityView,
    //   beforeEnter: ifAuthenticated,
    //   meta: {
    //     showPageHeader: true,
    //   },
    // },
    {
      path: "/backendtest",
      component: backendtest,
      beforeEnter: ifAuthenticated,
      meta: {
        showPageHeader: true,
      },
    },
    {
      path: "*",
      component: NotFound,
    },
  ],
});
