import axios from "axios";
import store from "..";

const state = {
  portalsData: [],
  statusPortals: "",
  portalData: {},
  statusPortal: "",
  openPortalData: {},
  openMenuData: {},
  openSubmenuData: {},
};

const getters = {
  openPortalData() {
    return state.openPortalData;
  },
  openMenuData() {
    return state.openMenuData;
  },
  openSubmenuData() {
    return state.openSubmenuData;
  },
  openLinkData() {
    return state.openLinkData;
  },
  isPortalsLoading() {
    return state.statusPortals === "loading";
  },
  isPortalsLoaded() {
    return state.statusPortals === "loaded";
  },
  portalsData() {
    return state.portalsData;
  },
  isPortalLoading() {
    return state.statusPortal === "loading";
  },
  isPortalLoaded() {
    return state.statusPortal === "loaded";
  },
  portalData() {
    return state.portalData;
  },
};

const mutations = {
  TOGGLE_PORTAL_OPEN(state, id) {
    if (state.openPortalData[id] == undefined) {
      state.openPortalData[id] = false;
      state.openPortalData = Object.assign({}, state.openPortalData); //make a copy to make sure changes to new object.properties get noticed
    } else {
      state.openPortalData[id] = !state.openPortalData[id];
    }
  },
  INIT_PORTAL_OPEN(state, id) {
    if (state.openPortalData[id] == undefined) {
      state.openPortalData[id] = false;
      state.openPortalData = Object.assign({}, state.openPortalData); //make a copy to make sure changes to new object.properties get noticed
    }
  },
  TOGGLE_MENU_OPEN(state, id) {
    if (state.openMenuData[id] == undefined) {
      state.openMenuData[id] = false;
      state.openMenuData = Object.assign({}, state.openMenuData); //make a copy to make sure changes to new object.properties get noticed
    } else {
      state.openMenuData[id] = !state.openMenuData[id];
    }
  },
  INIT_MENU_OPEN(state, id) {
    if (state.openMenuData[id] == undefined) {
      state.openMenuData[id] = false;
      state.openMenuData = Object.assign({}, state.openMenuData); //make a copy to make sure changes to new object.properties get noticed
    }
  },
  TOGGLE_SUBMENU_OPEN(state, id) {
    if (state.openSubmenuData[id] == undefined) {
      state.openSubmenuData[id] = false;
      state.openSubmenuData = Object.assign({}, state.openSubmenuData); //make a copy to make sure changes to new object.properties get noticed
    } else {
      state.openSubmenuData[id] = !state.openSubmenuData[id];
    }
  },
  INIT_SUBMENU_OPEN(state, id) {
    if (state.openSubmenuData[id] == undefined) {
      state.openSubmenuData[id] = false;
      state.openSubmenuData = Object.assign({}, state.openSubmenuData); //make a copy to make sure changes to new object.properties get noticed
    }
  },
  SET_PORTALS_DATA(state, data) {
    data.forEach((elem) => {
      elem.display = `${elem.name}`;
    });
    state.portalsData = data;
  },
  SET_STATUS_PORTALS_LOADING(state) {
    state.statusPortals = "loading";
  },
  SET_STATUS_PORTALS_LOADED(state) {
    state.statusPortals = "loaded";
  },
  SET_STATUS_PORTALS_ERROR(state) {
    state.statusPortals = "error";
  },
  SET_PORTAL_DATA(state, data) {
    data.display = `${data.Name}, ${data.ExternalID}`;
    state.portalData = data;
  },
  SET_STATUS_PORTAL_LOADING(state) {
    state.statusPortal = "loading";
  },
  SET_STATUS_PORTAL_LOADED(state) {
    state.statusPortal = "loaded";
  },
  SET_STATUS_PORTAL_ERROR(state) {
    state.statusPortal = "error";
  },
  CLEAR_DATA(state) {
    state.portalsData = [];
    state.statusPortals = "";
    state.portalData = [];
    state.statusPortal = "";
  },
  CLEAR_PORTAL_DATA(state) {
    state.portalData = [];
    state.statusPortal = "";
  },
};

const actions = {
  TOGGLE_PORTAL_OPEN({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("TOGGLE_PORTAL_OPEN", id);
    });
  },
  INIT_PORTAL_OPEN({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("INIT_PORTAL_OPEN", id);
    });
  },
  TOGGLE_MENU_OPEN({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("TOGGLE_MENU_OPEN", id);
    });
  },
  INIT_MENU_OPEN({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("INIT_MENU_OPEN", id);
    });
  },
  TOGGLE_SUBMENU_OPEN({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("TOGGLE_SUBMENU_OPEN", id);
    });
  },
  INIT_SUBMENU_OPEN({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("INIT_SUBMENU_OPEN", id);
    });
  },
  LOAD_PORTALS_DATA({ commit }) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_PORTALS_LOADING");
      axios
        .get("/api/adminportal")
        .then((response) => {
          commit("SET_PORTALS_DATA", response.data);
          commit("SET_STATUS_PORTALS_LOADED");
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          commit("SET_STATUS_PORTALS_ERROR");
          reject(err);
        });
    });
  },
  LOAD_PORTAL_DATA({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_PORTAL_LOADING");
      commit("CLEAR_PORTAL_DATA");
      axios
        .get("/api/adminportal/" + payload.PortalID)
        .then((response) => {
          commit("SET_PORTAL_DATA", response.data);
          commit("SET_STATUS_PORTAL_LOADED");
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          commit("SET_STATUS_PORTAL_ERROR");
          reject(err);
        });
    });
  },
  CREATE_PORTAL: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("CLEAR_PORTAL_DATA");
      commit("SET_STATUS_PORTAL_LOADING");
      axios
        .post("/api/adminportal", payload)
        .then((response) => {
          commit("SET_PORTAL_DATA", response.data);
          commit("SET_STATUS_PORTAL_LOADED");
          // store.dispatch('adminPortal/LOAD_PORTALS_DATA')
          store.dispatch("nav/LOAD");
          store.dispatch("notifStore/successCreated", "Portal created.");
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_PORTAL_ERROR");
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          reject(err);
        });
    });
  },
  MODIFY_PORTAL: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("CLEAR_PORTAL_DATA");
      commit("SET_STATUS_PORTAL_LOADING");
      axios
        .post("/api/adminportal/modify", payload)
        .then((response) => {
          commit("SET_PORTAL_DATA", response.data);
          commit("SET_STATUS_PORTAL_LOADED");
          // store.dispatch('adminPortal/LOAD_PORTALS_DATA')
          store.dispatch("nav/LOAD");
          store.dispatch("notifStore/successModified", "Portal modified.");
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_PORTAL_ERROR");
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          reject(err);
        });
    });
  },
  DELETE_PORTAL({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/adminportal/delete/" + payload.id)
        .then((response) => {
          store.dispatch("notifStore/successDeleted", "Portal deleted.");
          // store.dispatch('adminPortal/LOAD_PORTALS_DATA')
          store.dispatch("nav/LOAD");
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          reject(err);
        });
    });
  },

  CREATE_MENU: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_PORTAL_LOADING");
      axios
        .post("/api/adminmenu", payload)
        .then((response) => {
          commit("SET_STATUS_PORTAL_LOADED");
          store.dispatch("nav/LOAD");
          store.dispatch("notifStore/successCreated", "Menu created.");
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_PORTAL_ERROR");
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          reject(err);
        });
    });
  },
  MODIFY_MENU: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_PORTAL_LOADING");
      axios
        .post("/api/adminmenu/modify", payload)
        .then((response) => {
          commit("SET_STATUS_PORTAL_LOADED");
          store.dispatch("nav/LOAD");
          store.dispatch("notifStore/successModified", "Menu modified.");
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_PORTAL_ERROR");
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          reject(err);
        });
    });
  },
  DELETE_MENU({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/adminmenu/delete/" + payload.id)
        .then((response) => {
          store.dispatch("notifStore/successDeleted", "Menu deleted.");
          // store.dispatch('adminPortal/LOAD_PORTALS_DATA')
          store.dispatch("nav/LOAD");
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          reject(err);
        });
    });
  },

  CREATE_SUBMENU: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_PORTAL_LOADING");
      axios
        .post("/api/adminsubmenu", payload)
        .then((response) => {
          commit("SET_STATUS_PORTAL_LOADED");
          store.dispatch("nav/LOAD");
          store.dispatch("notifStore/successCreated", "Submenu created.");
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_PORTAL_ERROR");
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          reject(err);
        });
    });
  },
  MODIFY_SUBMENU: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_PORTAL_LOADING");
      axios
        .post("/api/adminsubmenu/modify", payload)
        .then((response) => {
          commit("SET_STATUS_PORTAL_LOADED");
          store.dispatch("nav/LOAD");
          store.dispatch("notifStore/successModified", "Submenu modified.");
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_PORTAL_ERROR");
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          reject(err);
        });
    });
  },
  DELETE_SUBMENU({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/adminsubmenu/delete/" + payload.id)
        .then((response) => {
          store.dispatch("notifStore/successDeleted", "Submenu deleted.");
          // store.dispatch('adminPortal/LOAD_PORTALS_DATA')
          store.dispatch("nav/LOAD");
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          reject(err);
        });
    });
  },

  CREATE_LINK: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_PORTAL_LOADING");
      axios
        .post("/api/adminlink", payload)
        .then((response) => {
          commit("SET_STATUS_PORTAL_LOADED");
          store.dispatch("nav/LOAD");
          store.dispatch("notifStore/successCreated", "Link created.");
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_PORTAL_ERROR");
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          reject(err);
        });
    });
  },
  MODIFY_LINK: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_PORTAL_LOADING");
      axios
        .post("/api/adminlink/modify", payload)
        .then((response) => {
          commit("SET_STATUS_PORTAL_LOADED");
          store.dispatch("nav/LOAD");
          store.dispatch("notifStore/successModified", "Link modified.");
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_PORTAL_ERROR");
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          reject(err);
        });
    });
  },
  DELETE_LINK({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/adminlink/delete/" + payload.id)
        .then((response) => {
          store.dispatch("notifStore/successDeleted", "Link deleted.");
          // store.dispatch('adminPortal/LOAD_PORTALS_DATA')
          store.dispatch("nav/LOAD");
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          reject(err);
        });
    });
  },

  CLEAR_STORE({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CLEAR_DATA");
    });
  },
  CLEAR_PORTAL({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CLEAR_PORTAL_DATA");
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
