import axios from "axios";

const state = {
  pfrrData: [],
  statusData: "",
  pfrrSent: [],
  statusSent: "",
  statusSchema: true,
  colForList: {},
};

const getters = {
  isDataLoading() {
    return state.statusData === "loading";
  },
  isDataLoaded() {
    return state.statusData === "loaded";
  },
  pfrrData() {
    return state.pfrrData;
  },
  isSentLoading() {
    return state.statusSent === "loading";
  },
  isSentLoaded() {
    return state.statusSent === "loaded";
  },
  pfrrSent() {
    return state.pfrrSent;
  },
  isSchemaLoading() {
    return state.statusSchema === "loading";
  },
  isSchemaLoaded() {
    return state.statusSchema === "loaded";
  },
  colForList() {
    return state.colForList;
  },
};

const mutations = {
  PUSH_DATA_TO_GRID(state, data) {
    data._rn = state.pfrrData.length; //add row number
    state.pfrrData.push(data);
  },
  SET_STATUS_DATA_LOADING(state) {
    state.statusData = "loading";
  },
  SET_STATUS_DATA_LOADED(state) {
    state.statusData = "loaded";
  },
  SET_STATUS_DATA_ERROR(state) {
    state.statusData = "error";
  },
  INITIALISE_COLFORLIST(state) {
    state.colForList = {
      PFReqID: {
        SqlColName: "PFReqID",
        DisplayName: "PFReqID",
        ColType: "name",
      },
      FundNo: {
        SqlColName: "FundNo",
        DisplayName: "PFReqID",
        ColType: "name",
      },
      ReportType: {
        SqlColName: "ReportType",
        DisplayName: "Report",
        ColType: "name",
      },
      ReportDescription: {
        SqlColName: "ReportDescription",
        DisplayName: "Desc",
        ColType: "name",
      },
      prefix: {
        SqlColName: "prefix",
        DisplayName: "e",
        ColType: "name",
      },
      d_Ccy: {
        SqlColName: "d_Ccy",
        DisplayName: "Ccy",
        ColType: "name",
      },
      d_PeriodType: {
        SqlColName: "d_PeriodType",
        DisplayName: "PeriodDesc",
        ColType: "name",
      },
      REFNIW: {
        SqlColName: "REFNIW",
        DisplayName: "Ref/Niw",
        ColType: "name",
      },
      d_Begin: {
        SqlColName: "d_Begin",
        DisplayName: "Begin",
        ColType: "date",
      },
      d_End: {
        SqlColName: "d_End",
        DisplayName: "End",
        ColType: "date",
      },
      d_ShareClass: {
        SqlColName: "d_ShareClass",
        DisplayName: "SHC",
        ColType: "name",
      },
      d_Details: {
        SqlColName: "d_Details",
        DisplayName: "Details",
        ColType: "name",
      },
    };
  },
  SET_STATUS_SCHEMA_LOADING(state) {
    state.statusSchema = "loading";
  },
  SET_STATUS_SCHEMA_LOADED(state) {
    state.statusSchema = "loaded";
  },
  SET_STATUS_SCHEMA_ERROR(state) {
    state.statusSchema = "error";
  },
  PUSH_SENT_TO_GRID(state, data) {
    state.pfrrSent.push(data);
  },
  SET_STATUS_SENT_LOADING(state) {
    state.statusSent = "loading";
  },
  SET_STATUS_SENT_LOADED(state) {
    state.statusSent = "loaded";
  },
  CLEAR_DATA(state) {
    state.pfrrData = [];
    state.statusData = "";
    state.pfrrSent = [];
    state.statusSent = "";
  },
};

const actions = {
  ADD_REQUEST_TO_GRID({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_DATA_LOADING");
      commit("PUSH_DATA_TO_GRID", payload);
      commit("SET_STATUS_DATA_LOADED");
      resolve();
    });
  },
  INITIALISE({ commit }) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_SCHEMA_LOADING");
      commit("INITIALISE_COLFORLIST");
      commit("SET_STATUS_SCHEMA_LOADED");
      resolve();
    });
  },
  SEND_REPORTREQUEST: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      // commit('SET_STATUS_SENT_LOADING')
      var requestConfig = {
        params: {},
      };
      if (process.env.NODE_ENV === "development") {
        requestConfig.params["TestingDB"] = "True"; //do not send request to prod if in development
      }
      axios
        .post("/api/PFRequest", payload, requestConfig)
        .then((resp) => {
          const result = resp.data;
          console.log("send report request successfully: resulting object");
          console.log(result);
          commit("PUSH_SENT_TO_GRID", resp);
          commit("SET_STATUS_SENT_LOADED");
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  CLEAR({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CLEAR_DATA");
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
