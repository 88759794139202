<template>
  <div class="qsui-accordion-item" :class="{ 'is-open': isOpen }">
    <div class="qsui-accordion-toggle" @click="toggleIsOpen">
      <div class="qsui-accordion-toggle-main">
        <i
          class="icon icon-013-chevron-down icon--s1"
          :class="{ 'icon--flip-vertical': isOpen }"
        ></i>
        <div class="qsui-accordion-header">
          <p slot="header">sort...</p>
        </div>
      </div>
    </div>
    <div class="qsui-accordion-collapse" :class="{ 'is-open': isOpen }">
      <div>
        <draggable
          class="list-group"
          tag="ul"
          :list="critBoxes"
          @start="isDragging = true"
          @end="isDragging = false"
          @change="onDraggableChange($event)"
        >
          <transition-group
            type="transition"
            :name="!isDragging ? 'flip-list' : null"
          >
            <li v-for="critBox in critBoxes" :key="critBox.ColName">
              <a @click="onclick(critBox)" style="cursor: pointer">
                <i class="icon icon--s1" :class="classObject(critBox)"></i>
                <span style="cursor: move">{{ critBox.DisplayName }}</span>
              </a>
              <i
                class="icon icon-202-clear-circle icon--s1"
                :class="classCritBoxBinInvisible(critBox)"
                @click="onCritBoxClear(critBox)"
                style="cursor: pointer"
              ></i>
            </li>
          </transition-group>
        </draggable>
        <div>
          <i
            :class="{
              'icon icon-071-retry wvfilter-icon wvfilter-green-button': isOpen,
            }"
            @click="$emit('reinitialiseSorting')"
          ></i>
          <i
            :class="{ 'icon icon-008-bin wvfilter-icon': isOpen }"
            @click="$emit('clearSorting')"
          ></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
export default {
  data() {
    return {
      isOpen: false,
      isDragging: false,
    };
  },
  methods: {
    classObject(critBox) {
      return {
        "icon-005-arrow-up": critBox.sorting == 1,
        "icon-002-arrow-down": critBox.sorting == -1,
        "icon-052-minus": critBox.sorting == 0,
      };
    },
    classCritBoxBinInvisible(critBox) {
      return {
        invisible: critBox.sorting == 0,
      };
    },
    onDraggableChange(payload) {
      // console.log('onDraggableChange' + payload)
      const critBox = payload.moved.element;
      if (critBox.sorting == 0) critBox.sorting = 1;
      this.$emit("changeSorting", critBox);
    },
    onCritBoxClear(critBox) {
      critBox.sorting = 0;
      this.$emit("changeSorting", critBox);
    },
    onclick(critBox) {
      if (critBox.sorting == 1) critBox.sorting = -1;
      else critBox.sorting = 1;
      this.$emit("changeSorting", critBox);
    },
    toggleIsOpen() {
      this.isOpen = !this.isOpen;
    },
  },
  props: {
    critBoxes: {
      type: Array,
      default: function () {
        return [
          {
            ColName: "npv",
            DisplayName: "npv",
            sorting: 1,
          },
          {
            ColName: "limit",
            DisplayName: "limit",
            sorting: -1,
          },
          {
            ColName: "ISIN",
            DisplayName: "ISIN",
            sorting: 0,
          },
          {
            ColName: "var",
            DisplayName: "var",
            sorting: 0,
          },
        ];
      },
    },
  },
  components: {
    draggable,
  },
};
</script>
