<template>
  <div id="filterViewToggle">
    <div class="toggle-button" @click="toggleClick">
      <i
        class="icon icon-030-forward icon--s2"
        :class="dataValue ? 'icon--flip-horizontal' : ''"
      ></i>
    </div>
    <!-- <button @click="toggleClick">
      <i class="icon icon-030-forward icon--s2" :class="dataValue ? 'icon--flip-horizontal' : ''"></i>
    </button>-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataValue: this.value,
    };
  },
  props: {
    value: true,
  },
  methods: {
    toggle() {
      this.dataValue = !this.dataValue;
      this.$emit("input", this.dataValue);
    },
    toggleHover() {
      //hover only to open (if it is closed)
      if (!this.dataValue) {
        this.toggle();
      }
    },
    toggleClick() {
      this.toggle();
    },
  },
};
</script>
