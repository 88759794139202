<template>
  <div
    class="exprequest-banner-select-destination exprequest-banner-select-view"
  >
    <treeselect
      v-model="valueProxy"
      :options="optionsFromProps"
      :placeholder="ReportName"
      :class="{ invalid: !value }"
    />
  </div>
</template>

<script>
import QsuiAccordionItem from "@/components/ui_utilities/QsuiAccordionItem";
import Treeselect, { ASYNC_SEARCH } from "@riophae/vue-treeselect";
import { mapGetters } from "vuex";

export default {
  computed: {
    valueProxy: {
      get() {
        return this.value;
      },
      set(newSelectedValue) {
        this.$emit("input", {
          report: this.ReportName,
          value: newSelectedValue,
        });
      },
    },
    optionsFromProps() {
      var ret = [];
      if (this.Wviews) {
        for (let iW = 0; iW < this.Wviews.length; iW++) {
          const ctW = this.Wviews[iW];
          ret.push({
            id: ctW,
            label: ctW,
          });
        }
      }
      return ret;
    },
  },
  props: {
    ReportName: {
      type: String,
      default: function () {
        return "myReport";
      },
    },
    Wviews: {
      type: Array,
      default: function () {
        return ["no view available for this table"];
      },
    },
    value: {
      type: String,
      default: function () {
        return undefined;
      },
    },
  },
  components: {
    QsuiAccordionItem,
    Treeselect,
  },
};
</script>
