<template>
  <div class="input-field-select">
    <label :for="id"
      ><span class="label">{{ label }}</span></label
    >
    <treeselect
      v-model="valueProxy"
      :options="ts_options"
      :clearable="false"
      :disabled="disabled"
      :id="id"
    />
  </div>
</template>

<script>
import randomstring from "randomstring";
import Treeselect from "@riophae/vue-treeselect";
export default {
  data() {
    return {
      id: randomstring.generate(10),
    };
  },
  computed: {
    ts_options() {
      var ret = [];
      this.options.forEach((element) => {
        ret.push({ id: element, label: element });
      });
      return ret;
    },
    valueProxy: {
      get() {
        return this.value ? "True" : "False";
      },
      set(value) {
        this.$emit("input", value == "True");
      },
    },
  },
  props: {
    options: {
      type: Array,
      default: function () {
        return ["True", "False"];
      },
    },
    label: {
      type: String,
      default: "",
    },
    value: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Treeselect,
  },
};
</script>

<style></style>
