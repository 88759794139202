import axios from "axios";
import store from "..";
import moment from "moment";
import { getResponsErrorData } from "@/util/index.js";
import { getParamFromLink } from "@/components/wv_filter/WvQueryOption.js";
import router from "@/router";

//used only for async
import AWN from "awesome-notifications";
import configAWN from "@/data/configAWN";
let notifier = new AWN(configAWN);

const state = () => ({
  wvData: [],
  isDataLoading: false,
  isDataLoaded: false,
  lastRequestParams_Data: {},
  wvInfo: {},
  statusInfo: "",
  statusHUP: "",
  reg: {},
  reg_status: "",
  showReg: false,
  maxRows: 500,
  inlinefilterSelection: [],
  SecondaryWViewMapping: {
    BACKTESTING_DAILY_PVP: {
      wviewName: "BACKTESTING_ALL_PVP",
      filterMapping: [
        ["book", "book"],
        ["date", "date"],
      ],
    },
    LIMIT_PVP: {
      wviewName: "VAR_ALL_PVP",
      filterMapping: [
        ["book", "book"],
        ["date", "date"],
        ["requestIDShort", "requestIDShort"],
        ["VaRProcess", "VaRProcess"],
      ],
    },
    STRESS_UCITS_DAILY: {
      wviewName: "STRESS_UCITS_ALL",
      filterMapping: [
        ["book", "book"],
        ["date", "date"],
        ["additionalfilterforsecondary", "(rank eq 1)"],
      ],
    },
    PORTFOLIOKENNZAHLEN_F_NoFuture_NoCash: {
      wviewName: "POSITION2_ALL",
      filterMapping: [
        ["book", "book"],
        ["date", "date"],
      ],
    },
    PORTFOLIOKENNZAHLEN_F_NoFuture_WithCash: {
      wviewName: "POSITION2_ALL",
      filterMapping: [
        ["book", "book"],
        ["date", "date"],
      ],
    },
    PORTFOLIOKENNZAHLEN_F_WithFuture_NoCash: {
      wviewName: "POSITION2_ALL",
      filterMapping: [
        ["book", "book"],
        ["date", "date"],
      ],
    },
    PORTFOLIOKENNZAHLEN_F_WithFuture_WithCash: {
      wviewName: "POSITION2_ALL",
      filterMapping: [
        ["book", "book"],
        ["date", "date"],
      ],
    },
    PORTFOLIOKENNZAHLEN_FUND: {
      wviewName: "POSITION2_ALL",
      filterMapping: [
        ["book", "book"],
        ["date", "date"],
      ],
    },
    PORTFOLIOKENNZAHLEN_F_NoFuture_NoCash_NoConv: {
      wviewName: "POSITION2_ALL",
      filterMapping: [
        ["book", "book"],
        ["date", "date"],
      ],
    },
    PORTFOLIOKENNZAHLEN_F_WithFuture_NoCash_NoConv: {
      wviewName: "POSITION2_ALL",
      filterMapping: [
        ["book", "book"],
        ["date", "date"],
      ],
    },
    PORTFOLIOKENNZAHLEN_CCY_F_NoFuture_NoCash: {
      wviewName: "POSITION2_ALL",
      filterMapping: [
        ["book", "book"],
        ["date", "date"],
      ],
    },
    PORTFOLIOKENNZAHLEN_CCY_F_NoFuture_WithCash: {
      wviewName: "POSITION2_ALL",
      filterMapping: [
        ["book", "book"],
        ["date", "date"],
      ],
    },
    PORTFOLIOKENNZAHLEN_CCY_F_WithFuture_NoCash: {
      wviewName: "POSITION2_ALL",
      filterMapping: [
        ["book", "book"],
        ["date", "date"],
      ],
    },
    PORTFOLIOKENNZAHLEN_CCY_F_WithFuture_WithCash: {
      wviewName: "POSITION2_ALL",
      filterMapping: [
        ["book", "book"],
        ["date", "date"],
      ],
    },
    PORTFOLIOKENNZAHLEN_CCY_FUND: {
      wviewName: "POSITION2_ALL",
      filterMapping: [
        ["book", "book"],
        ["date", "date"],
      ],
    },
    PORTFOLIOKENNZAHLEN_PRODUCT_F: {
      wviewName: "POSITION2_ALL",
      filterMapping: [
        ["book", "book"],
        ["date", "date"],
      ],
    },
    PORTFOLIOKENNZAHLEN_SECTOR_F: {
      wviewName: "POSITION2_ALL",
      filterMapping: [
        ["book", "book"],
        ["date", "date"],
      ],
    },
    SRRIRep_Uebersicht_view: {
      wviewName: "SRRIRep_ProxyMix_view",
      filterMapping: [
        ["Book", "Book"],
        ["Date", "Date"],
        ["ShareClass", "ShareClass"],
      ],
    },
    PFReq_Memory_Backtesting: {
      wviewName: "PFReq_Memory_Backtesting",
      filterMapping: [
        ["FundNo", "FundNo"],
        ["RequestType", "RequestType"],
        ["ReportType", "ReportType"],
        ["PeriodDesc", "PeriodDesc"],
      ],
    },
    PF_BVI_YTD_PE: {
      wviewName: "PF_BVI_YTD_QD",
      filterMapping: [["PFReqID", "PFReqID"]],
    },
    PF_BVI_PE: {
      wviewName: "PF_BVI_QD",
      filterMapping: [["PFReqID", "PFReqID"]],
    },
    PF_BVI_DiffWithQx_PE: {
      wviewName: "PF_BVI_DiffWithQx_QD",
      filterMapping: [["PFReqID", "PFReqID"]],
    },
    PF_BVI_DiffWithQx_YTD_PE: {
      wviewName: "PF_BVI_DiffWithQx_YTD_QD",
      filterMapping: [["PFReqID", "PFReqID"]],
    },
  },
  ExpPDFRequestWViewMapping: {
    KUNDEN_PDFList_Risikobericht: "Risikobericht",
    KUNDEN_PDFList_CreditRisk: "CreditRisk",
    KUNDEN_PDFList_MARisk: "MARisk",
    KUNDEN_PDFList_MARisk_NoDelay: "MARisk",
    KUNDEN_PDFList_AGMHRisk: "AGMHRisk",
    KUNDEN_PDFList_SpecialMARisk: "SpecialMARisk",
    KUNDEN_PDFList_LiquidityExcel: "LiquidityExcel",
    KUNDEN_PDFList_LiqRisk: "LiqRisk",
    KUNDEN_PDFList_ESG: "ESG",
  },
});

const getters = {
  inlineFilterInfo: (state, getters) => {
    if (state.statusInfo != "loaded" || !state.isDataLoaded) return [];
    let param = getParamFromLink("inlineFilter", router.currentRoute.fullPath);
    if (!param) return [];
    let ret = [];
    param.split(",").forEach((filter) => {
      var cols = [];
      filter.split(":").forEach((fCol) => {
        let col = {};
        if (fCol.toLowerCase() in getters.wvInfo.hu.lookupColForListLowercase) {
          col =
            getters.wvInfo.hu.colForList[
              getters.wvInfo.hu.lookupColForListLowercase[fCol.toLowerCase()]
            ];
          cols.push(col);
        } else {
          return;
        }
      });
      ret.push({
        name: filter,
        cols: cols.reverse(),
      });
    });
    return ret;
  },
  inlineFilterTreeselectOptions: (state, getters) => {
    if (state.statusInfo != "loaded") return [];
    var ret = [];
    getters.inlineFilterInfo.forEach((filter) => {
      var cols = filter.cols;
      var curOptions = [];
      var dict = {};
      state.wvData.forEach((ctRow) => {
        var values = [];
        cols.forEach((col) => {
          values.push(ctRow[col.SqlColName]);
        });
        if (!dict.hasOwnProperty(values[0])) {
          dict[values[0]] = {};
        }
        if (values.length > 1 && !dict[values[0]].hasOwnProperty(values[1])) {
          dict[values[0]][values[1]] = {};
        }
      });
      var keys = Object.keys(dict);
      keys.sort();
      keys.forEach((key) => {
        var children = [];
        var subkeys = Object.keys(dict[key]);
        subkeys.sort();
        subkeys.forEach((subkey) => {
          children.push({
            id: subkey,
            label: subkey,
          });
        });
        if (children.length > 0) {
          curOptions.push({
            id: key,
            label: key,
            children: children,
          });
        } else {
          curOptions.push({
            id: key,
            label: key,
          });
        }
      });
      ret.push({
        name: filter.name,
        options: curOptions,
      });
    });
    return ret;
  },
  maxRows: (state) => {
    return state.maxRows;
  },
  rowsLength: (state) => {
    if (state.isDataLoaded && state.wvData) {
      return state.wvData.length;
    }
    return 0;
  },
  colsLength: (state) => {
    if (state.isDataLoaded && state.wvInfo) {
      return Object.keys(state.wvInfo.hu.colForList).length;
    }
    return 0;
  },
  isDataLoading: (state) => {
    return state.isDataLoading;
  },
  isDataLoaded: (state) => {
    return state.isDataLoaded;
  },
  wvData: (state, getters) => {
    var filterFunctions = [];
    state.inlinefilterSelection.forEach((filterSelection) => {
      var infoIndex = getters.inlineFilterInfo.findIndex((info) => {
        return info.name == filterSelection.name;
      });
      var optionIndex = getters.inlineFilterTreeselectOptions.findIndex(
        (options) => {
          return options.name == filterSelection.name;
        }
      );
      var fFuncs = [];
      if (infoIndex > -1 && optionIndex > -1) {
        var info = getters.inlineFilterInfo[infoIndex];
        var options =
          getters.inlineFilterTreeselectOptions[optionIndex].options;
        options.forEach((option) => {
          filterSelection.values.forEach((value) => {
            if (value == option.id) {
              let compare = option.id == "undefined" ? undefined : option.id;
              var fFunc = function (row) {
                return row[info.cols[0].SqlColName] == compare;
              };
              fFuncs.push(fFunc);
            } else if (
              option.hasOwnProperty("children") &&
              option.children &&
              option.children.length > 0 &&
              info.cols.length > 1
            ) {
              option.children.forEach((child) => {
                if (value == child.id) {
                  let compare = child.id == "undefined" ? undefined : child.id;
                  var fFunc = function (row) {
                    return row[info.cols[1].SqlColName] == compare;
                  };
                  fFuncs.push(fFunc);
                }
              });
            }
          });
        });
        if (fFuncs.length > 0) {
          filterFunctions.push(fFuncs);
        }
      }
    });
    if (filterFunctions.length == 0) {
      // no filter if no functions!
      return state.wvData;
    }
    var evaluateRowWithOr = function (row, fFuncs) {
      var fFuncsLength = fFuncs.length;
      for (var i = 0; i < fFuncsLength; i++) {
        if (fFuncs[i](row)) {
          // return true on any match, do not evaluate further (= using OR)
          return true;
        }
      }
      return false;
    };
    return state.wvData.filter((row) => {
      // first loop over filter
      var filterLength = filterFunctions.length;
      for (var j = 0; j < filterLength; j++) {
        // second loop over functions for filter
        var fFuncs = filterFunctions[j];
        if (!evaluateRowWithOr(row, fFuncs)) {
          // return false if any of the filter does not match, do not evaluate further (= using AND)
          return false;
        }
      }
      return true;
    });
  },
  isInfoLoading: (state) => {
    return state.statusInfo === "loading";
  },
  isInfoLoaded: (state) => {
    return state.statusInfo === "loaded";
  },
  wvInfo: (state) => {
    return state.wvInfo;
  },
  isWViewEligibleForExpRequest: (state) => {
    if (state.statusInfo === "loaded" && state.wvInfo) {
      return state.wvInfo.hu.WVName == "PF_RR_Overview";
    }
  },
  isWViewEligibleForSecondaryGrid: (state) => {
    if (state.statusInfo === "loaded" && state.wvInfo) {
      return state.SecondaryWViewMapping.hasOwnProperty(state.wvInfo.hu.WVName);
    }
  },
  SecondaryViewName: (state) => {
    if (state.statusInfo === "loaded" && state.wvInfo) {
      if (
        state.SecondaryWViewMapping.hasOwnProperty(state.wvInfo.hu.WVName) &&
        !!state.SecondaryWViewMapping[state.wvInfo.hu.WVName].wviewName
      ) {
        return state.SecondaryWViewMapping[state.wvInfo.hu.WVName].wviewName;
      }
    }
  },
  SecondaryFilterMapping: (state) => {
    if (state.statusInfo === "loaded" && state.wvInfo) {
      if (
        state.SecondaryWViewMapping.hasOwnProperty(state.wvInfo.hu.WVName) &&
        !!state.SecondaryWViewMapping[state.wvInfo.hu.WVName].filterMapping
      ) {
        return state.SecondaryWViewMapping[state.wvInfo.hu.WVName]
          .filterMapping;
      } else {
        return {};
      }
    }
  },
  isWViewEligibleForExpPDFRequest: (state) => {
    if (state.statusInfo === "loaded" && state.wvInfo) {
      return state.ExpPDFRequestWViewMapping.hasOwnProperty(
        state.wvInfo.hu.WVName
      );
    }
  },
  isWViewEligibleForIRSRequest: (state) => {
    if (state.statusInfo === "loaded" && state.wvInfo) {
      return state.wvInfo.hu.irsRequestType ? true : false;
    }
  },
  ExpPDFRequestReportType: (state) => {
    if (state.statusInfo === "loaded" && state.wvInfo) {
      if (
        state.ExpPDFRequestWViewMapping.hasOwnProperty(state.wvInfo.hu.WVName)
      )
        return state.ExpPDFRequestWViewMapping[state.wvInfo.hu.WVName];
    }
  },
  irsRequestReportType: (state) => {
    if (state.statusInfo === "loaded" && state.wvInfo) {
      return state.wvInfo.hu.irsRequestType;
    }
  },
  isWViewEligibleForCorrection: (state) => {
    if (state.statusInfo === "loaded" && state.wvInfo) {
      return state.wvInfo.hu.isCorrection;
    }
  },
  isHUPLoading: (state) => {
    return state.statusHUP === "loading";
  },
  reg_isLoading: (state) => {
    return state.reg_status === "loading";
  },
  reg_isLoaded: (state) => {
    return state.reg_status === "loaded";
  },
  reg_wvData: (state) => {
    return state.reg.RegressionResultTable;
  },
  reg_wvInfo: (state) => {
    return state.reg.huTable;
  },
  reg_Messages: (state) => {
    return state.reg.RegressionMessages;
  },
  reg_isSuccesfull: (state) => {
    return state.reg.regressionIsSuccesfull;
  },
  showReg: (state) => {
    return state.showReg;
  },
};

const mutations = {
  STORE_REQUESTPARAMS_GETDATA(state, axiosRequestParams) {
    state.lastRequestParams_Data = axiosRequestParams;
  },
  SET_DATA(state, data) {
    state.showReg = false;
    if (state.statusInfo === "loaded") {
      //format date columns to javascript date
      const dateCols = [];
      const boolCols = [];
      for (const [key, value] of Object.entries(state.wvInfo.hu.colForList)) {
        switch (value.ColType) {
          case "date":
          case "logdate":
            dateCols.push(value.SqlColName);
            break;
          case "bool":
            boolCols.push(value.SqlColName);
            break;
        }
      }
      for (let i = 0; i < data.length; i++) {
        data[i]._rn = i; //add row number
        dateCols.forEach((element) => {
          //do not convert null
          if (data[i][element]) {
            data[i][element] = new Date(data[i][element]);
          }
        });
        boolCols.forEach((element) => {
          // bool: 0 if null, 1 if > 0
          if (data[i][element]) {
            data[i][element] = data[i][element] > 0 ? 1 : 0;
          } else {
            data[i][element] = 0;
          }
        });
      }
    } else {
      for (let i = 0; i < data.length; i++) {
        data[i]._rn = i;
        data[i].date = new Date(data[i].date);
        data[i].calcStart = new Date(data[i].calcStart);
      }
    }
    //sort data in javascript example
    //data.sort((a, b) => a.pv - b.pv)
    state.wvData = data;
  },
  SET_STATUS_DATA_LOADING(state) {
    state.isDataLoading = true;
  },
  SET_STATUS_DATA_LOADED(state, value) {
    state.isDataLoading = false;
    state.isDataLoaded = value;
  },
  SET_INFO(state, data) {
    state.wvInfo = data;
    if (state.wvInfo.hu) {
      // create hypergrid schema based on info.
      let lookup = {};
      let lookupSqlcolName = {};
      for (const [key, value] of Object.entries(state.wvInfo.hu.colForList)) {
        let keyLC = key.toLowerCase();
        if (!lookup.hasOwnProperty(keyLC)) {
          lookup[keyLC] = key;
        }
        let KeyLCSqlColName = value.SqlColName.toLowerCase();
        if (!lookupSqlcolName.hasOwnProperty(KeyLCSqlColName)) {
          lookupSqlcolName[KeyLCSqlColName] = key;
        }
      }

      state.wvInfo.hu.lookupColForListLowercase = lookup;
      state.wvInfo.hu.lookupSqlColForListLowercase = lookupSqlcolName;
    }
  },
  SET_STATUS_INFO_LOADING(state) {
    state.statusInfo = "loading";
    //reset Data to prevent mismatch between info and Data
    // reload info --> clear data
    state.isDataLoaded = false;
    state.wvData = [];
  },
  SET_STATUS_INFO_LOADED(state) {
    state.statusInfo = "loaded";
  },
  SET_STATUS_HUP_LOADING(state) {
    state.statusHUP = "loading";
  },
  SET_STATUS_HUP_LOADED(state) {
    state.statusHUP = "loaded";
  },
  SET_REG_STATUS_LOADING(state) {
    state.reg_status = "loading";
  },
  SET_REG_STATUS_LOADED(state) {
    state.reg_status = "loaded";
  },
  SET_REG(state, data) {
    state.showReg = true;
    if (data.huTable && data.huTable.hu) {
      // create hypergrid schema based on info.
      let lookup = {};
      let lookupSqlcolName = {};

      for (const [key, value] of Object.entries(data.huTable.hu.colForList)) {
        let keyLC = key.toLowerCase();
        if (!lookup.hasOwnProperty(keyLC)) {
          lookup[keyLC] = key;
        }
        let KeyLCSqlColName = value.SqlColName.toLowerCase();
        if (!lookupSqlcolName.hasOwnProperty(KeyLCSqlColName)) {
          lookupSqlcolName[KeyLCSqlColName] = key;
        }
        let headerText = value.DisplayName;
        if (value.DisplayName.endsWith(".%")) {
          headerText = "r.%";
        } else if (value.DisplayName.endsWith(".-")) {
          headerText = "b-r";
        }
        data.huTable.hu.lookupColForListLowercase = lookup;
        state.wvInfo.hu.lookupSqlColForListLowercase = lookupSqlcolName;
      }

      //format date columns to javascript date
      const dateCols = [];
      for (const [key, value] of Object.entries(data.huTable.hu.colForList)) {
        switch (value.ColType) {
          case "date":
          case "logdate":
            dateCols.push(value.SqlColName);
            break;
        }
      }
      for (var i in data.RegressionResultTable) {
        dateCols.forEach((element) => {
          //do not convert null
          if (data.RegressionResultTable[i][element]) {
            data.RegressionResultTable[i][element] = new Date(
              data.RegressionResultTable[i][element]
            );
          }
        });
      }
    }
    state.reg = data;
  },
  DOUBLE_MAXROWS(state) {
    state.maxRows *= 2;
  },
  SETMAX_MAXROWS(state) {
    state.maxRows = 10000;
  },
  RESET_MAXROWS(state) {
    state.maxRows = 200;
  },
  SET_INLINEFILTER_SELECTION(state, filter) {
    const itemIndex = state.inlinefilterSelection.findIndex((item) => {
      return item.name == filter.name;
    });
    // remove if already exists
    // update would not work as javascript does not notice the change and the filtered wvdata are not updated
    if (itemIndex > -1) {
      state.inlinefilterSelection.splice(itemIndex, 1);
    }
    state.inlinefilterSelection.push(filter);
  },
  CLEAR_DATA(state) {
    state.wvData = [];
    state.isDataLoaded = false;
    state.isDataLoading = false;
    state.lastRequestParams_Data = {};
    state.wvInfo = {};
    state.statusInfo = "";
    state.statusHUP = "";
    state.reg = {};
    state.reg_status = "";
    state.showReg = false;
    state.inlinefilterSelection = [];
  },
};

const actions = {
  LOAD_DATA({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_DATA_LOADING");
      var requestConfig = {
        params: {},
      };
      for (const [key, value] of Object.entries(payload.queryParams)) {
        if (!!key && !!value) {
          requestConfig.params[key] = value;
        }
      }
      var axiosRequestParams = {
        url: "/api/wview/" + payload.vwiewName,
        requestConfig: requestConfig,
      };
      commit("STORE_REQUESTPARAMS_GETDATA", axiosRequestParams);
      axios
        .get(axiosRequestParams.url, axiosRequestParams.requestConfig)
        .then((response) => {
          commit("SET_DATA", response.data);
          commit("SET_STATUS_DATA_LOADED", true);
          resolve();
        })
        .catch((err) => {
          store.dispatch("notifStore/alert", "Error while Loading Data");
          commit("SET_STATUS_DATA_LOADED", false);
        });
    });
  },
  RELOAD_DATA({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_DATA_LOADING");
      let lastRequestParams_Data = state.lastRequestParams_Data;
      axios
        .get(lastRequestParams_Data.url, lastRequestParams_Data.requestConfig)
        .then((response) => {
          commit("SET_DATA", response.data);
          commit("SET_STATUS_DATA_LOADED", true);
          resolve();
        })
        .catch((err) => {
          store.dispatch("notifStore/alert", "Error while Loading Data");
          commit("SET_STATUS_DATA_LOADED", false);
        });
    });
  },
  LOAD_REG({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_REG_STATUS_LOADING");
      var requestConfig = {
        params: {},
      };
      for (const [key, value] of Object.entries(payload.queryParams)) {
        if (!!key && !!value) {
          requestConfig.params[key] = value;
        }
      }
      axios
        .get("/api/wview/" + payload.vwiewName + "/Regression", requestConfig)
        .then((response) => {
          commit("SET_REG", response.data);
          commit("SET_REG_STATUS_LOADED");
          resolve();
        })
        .catch((err) => {
          store.dispatch("notifStore/alert", "Error while Loading Regression");
        });
    });
  },
  LOAD_INFO({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_INFO_LOADING");
      axios
        .get("/api/wview/" + payload.vwiewName + "/info")
        .then((response) => {
          commit("SET_INFO", response.data);
          commit("SET_STATUS_INFO_LOADED");
          resolve();
        })
        .catch((err) => {
          store.dispatch("notifStore/alert", "Error while Loading WView.Info");
        });
    });
  },
  LOAD_HUP_EXCEL({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_HUP_LOADING");
      var requestConfig = {
        // headers: { Accept: 'application/xml' },
        headers: {
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "blob",
        params: {},
      };
      for (const [key, value] of Object.entries(payload.queryParams)) {
        if (!!key && !!value) {
          requestConfig.params[key] = value;
        }
      }
      axios
        .get("/api/wview/" + payload.vwiewName + "/HUP", requestConfig)
        .then((response) => {
          const downloadUrl = window.URL.createObjectURL(
            new Blob([response.data])
          );
          const link = document.createElement("a");
          link.href = downloadUrl;
          let fileName = "";
          if (!!response.headers["content-disposition"]) {
            fileName =
              response.headers["content-disposition"].split("filename=")[1];
          }
          if (!fileName) {
            fileName = "File.xlsx";
          }
          link.setAttribute("download", fileName); //any other extension
          document.body.appendChild(link);
          link.click();
          setTimeout(() => {
            URL.revokeObjectURL(downloadUrl);
            link.remove();
          }, 15000);

          commit("SET_STATUS_HUP_LOADED");
          resolve();
        })
        .catch((err) => {
          store.dispatch("notifStore/alert", "Error while Loading Excel");
        });
    });
  },
  LOAD_CSV({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_DATA_LOADING");
      var requestConfig = {
        headers: {
          Accept: "text/csv",
        },
        // responseType: 'blob',
        params: {},
      };
      for (const [key, value] of Object.entries(payload.queryParams)) {
        if (!!key && !!value) {
          requestConfig.params[key] = value;
        }
      }
      var axiosRequestParams = {
        url: "/api/wview/" + payload.vwiewName,
        requestConfig: requestConfig,
      };
      axios
        .get(axiosRequestParams.url, axiosRequestParams.requestConfig)
        .then((response) => {
          const downloadUrl = window.URL.createObjectURL(
            new Blob([response.data])
          );
          const link = document.createElement("a");
          link.href = downloadUrl;
          let fileName = "file.csv";
          link.setAttribute("download", fileName); //any other extension
          document.body.appendChild(link);
          link.click();
          setTimeout(() => {
            URL.revokeObjectURL(downloadUrl);
            link.remove();
          }, 15000);

          commit("SET_STATUS_DATA_LOADED", true);
          resolve();
        })
        .catch((err) => {
          store.dispatch("notifStore/alert", "Error while Loading csv");
          commit("SET_STATUS_DATA_LOADED", false);
        });
    });
  },
  LOAD_HUP_QSXML({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_HUP_LOADING");
      var requestConfig = {
        // headers: { Accept: 'application/xml' },
        headers: {
          Accept: "application/xml",
        },
        // responseType: 'blob',
        params: {},
      };
      for (const [key, value] of Object.entries(payload.queryParams)) {
        if (!!key && !!value) {
          requestConfig.params[key] = value;
        }
      }
      axios
        .get("/api/wview/" + payload.vwiewName + "/HUP", requestConfig)
        .then((response) => {
          const downloadUrl = window.URL.createObjectURL(
            new Blob([response.data])
          );
          const link = document.createElement("a");
          link.href = downloadUrl;
          let fileName = "";
          if (!!response.headers["content-disposition"]) {
            fileName =
              response.headers["content-disposition"].split("filename=")[1];
          }
          if (!fileName) {
            fileName = "File.xml";
          }
          link.setAttribute("download", fileName); //any other extension
          document.body.appendChild(link);
          link.click();
          setTimeout(() => {
            URL.revokeObjectURL(downloadUrl);
            link.remove();
          }, 15000);
          commit("SET_STATUS_HUP_LOADED");
          resolve();
        })
        .catch((err) => {
          store.dispatch("notifStore/alert", "Error while Loading XML");
        });
    });
  },
  LOAD_DOCUMENT_RISK({ commit, state }, payload) {
    //notifier.async requires a promise (see https://f3oall.github.io/awesome-notifications/docs/toasts/async)
    let messageText = `document download`;
    let fileName = "";
    notifier.async(
      new Promise((resolve, reject) => {
        // commit('SET_STATUS_SENT_LOADING')
        var requestConfig = {
          responseType: "blob",
          params: {},
        };
        if (
          !!state.lastRequestParams_Data &&
          !!state.lastRequestParams_Data.requestConfig &&
          !!state.lastRequestParams_Data.requestConfig.params &&
          !!state.lastRequestParams_Data.requestConfig.params.TestingDB
        ) {
          requestConfig.params["TestingDB"] = "True"; //if Load Data was with TestingDB, then use TestingDB
        }
        var url = "/api/document/risk";
        if (payload.reportType == "LiquidityExcel") {
          url = "/api/document/liquidity";
        }
        axios
          .post(url, payload, requestConfig)
          .then((resp) => {
            const downloadUrl = window.URL.createObjectURL(
              new Blob([resp.data])
            );
            const link = document.createElement("a");
            link.href = downloadUrl;
            if (!!resp.headers["content-disposition"]) {
              fileName =
                resp.headers["content-disposition"].split("filename=")[1];
            }
            if (!fileName) {
              fileName = "File.xlsx";
            }
            link.setAttribute("download", fileName); //any other extension
            document.body.appendChild(link);
            link.click();
            setTimeout(() => {
              URL.revokeObjectURL(downloadUrl);
              link.remove();
            }, 15000);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      }),
      (resp) => {
        store.dispatch("notifStore/success", fileName); //onResolve (empty string to ommit)
      },
      (err) => {
        store.dispatch(
          "notifStore/alert",
          `${messageText} ${getResponsErrorData(err)}`
        ); //onReject (empty string to ommit)
      },
      `<span>${messageText}</span><div class="loader-spinner loader-spinner--small"/>`,
      {
        labels: {
          async: "Document",
        },
      }
    );
  },
  LOAD_DOCUMENTDOWNLOAD({ commit, state }, payload) {
    //notifier.async requires a promise (see https://f3oall.github.io/awesome-notifications/docs/toasts/async)
    let messageText = `document download`;
    let fileName = "";
    notifier.async(
      new Promise((resolve, reject) => {
        // commit('SET_STATUS_SENT_LOADING')
        var requestConfig = {
          responseType: "blob",
          params: {},
        };
        var url = "/api/document/download";
        axios
          .post(url, payload, requestConfig)
          .then((resp) => {
            const downloadUrl = window.URL.createObjectURL(
              new Blob([resp.data])
            );
            const link = document.createElement("a");
            link.href = downloadUrl;
            if (!!resp.headers["content-disposition"]) {
              fileName =
                resp.headers["content-disposition"].split("filename=")[1];
            }
            if (!fileName) {
              fileName = "File.xlsx";
            }
            link.setAttribute("download", fileName); //any other extension
            document.body.appendChild(link);
            link.click();
            setTimeout(() => {
              URL.revokeObjectURL(downloadUrl);
              link.remove();
            }, 15000);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      }),
      (resp) => {
        store.dispatch("notifStore/success", fileName); //onResolve (empty string to ommit)
      },
      (err) => {
        store.dispatch(
          "notifStore/alert",
          `${messageText} ${getResponsErrorData(err)}`
        ); //onReject (empty string to ommit)
      },
      `<span>${messageText}</span><div class="loader-spinner loader-spinner--small"/>`,
      {
        labels: {
          async: "Document",
        },
      }
    );
  },
  SEND_CORRECTION({ commit, state }, payload) {
    //notifier.async requires a promise (see https://f3oall.github.io/awesome-notifications/docs/toasts/async)
    let messageText = `correction`;
    notifier.async(
      new Promise((resolve, reject) => {
        // commit('SET_STATUS_SENT_LOADING')
        var requestConfig = {
          params: {},
        };
        if (
          !!state.lastRequestParams_Data &&
          !!state.lastRequestParams_Data.requestConfig &&
          !!state.lastRequestParams_Data.requestConfig.params &&
          !!state.lastRequestParams_Data.requestConfig.params.TestingDB
        ) {
          requestConfig.params["TestingDB"] = "True"; //if Load Data was with TestingDB, then use TestingDB
        }
        // else if (process.env.NODE_ENV === "development") {
        //   requestConfig.params["TestingDB"] = "True"; //do not send request to prod if in development
        // }

        axios
          .post("/api/wview/Correction", payload, requestConfig)
          .then((resp) => {
            store.dispatch("wviewStore/RELOAD_DATA");
            // const result = resp.data
            resolve(resp);
          })
          .catch((err) => {
            store.dispatch("wviewStore/RELOAD_DATA");
            reject(err);
          });
      }),
      (resp) => {
        // store.dispatch("notifStore/success", messageText); //onResolve (empty string to ommit)
      },
      (err) => {
        store.dispatch(
          "notifStore/alert",
          `${messageText} ${getResponsErrorData(err)}`
        ); //onReject (empty string to ommit)
      },
      `<span>Sending ${messageText}</span><div class="loader-spinner loader-spinner--small"/>`,
      {
        labels: {
          async: "correction",
        },
      }
    );
  },
  SHOW_MORE({ commit }) {
    return new Promise((resolve, reject) => {
      commit("DOUBLE_MAXROWS");
    });
  },
  SHOW_MAX({ commit }) {
    return new Promise((resolve, reject) => {
      commit("SETMAX_MAXROWS");
    });
  },
  PUSH_INLINEFILTER_SELECTION({ commit }, filter) {
    commit("SETMAX_MAXROWS");
    commit("SET_INLINEFILTER_SELECTION", filter);
  },
  CLEAR({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CLEAR_DATA");
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
