<template>
  <div class="qsui-admin-form col-sm-12 col-md-10 col-lg-8 col-xl-7">
    <div class="text-h4">Book-Group Membership</div>
    <div class="qsui-admin-list-filerplus">
      <div>
        <sdx-textbox v-model="oValueFilter" label="filter..." type="text" />
      </div>
      <div class="checkbox input-field">
        <input
          type="checkbox"
          name="dummy"
          id="BMSactive"
          value="BMSactive"
          v-model="checkedActive"
        />
        <label for="BMSactive">active only</label>
      </div>
    </div>
    <div v-if="showSpinner" class="loader-spinner loader-spinner--small"></div>
    <ul v-else class="qsui-admin-list qsui-admin-list-bms">
      <li
        v-for="elem in filteredItems"
        class="qsui-admin-list-item"
        :class="{ inactive: elem.BookID < 0 }"
      >
        {{ elem.display }}
        <i
          class="icon icon-300_plus-box icon--s1"
          :class="elem.BookID < 0 ? 'icon-300_plus-box' : 'icon-301_minus-box'"
          :title="elem.BookID < 0 ? 'add membership' : 'remove membership'"
          @click="toggleMembership(elem)"
        ></i>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SDXTextbox from "@/components/ui_utilities/SDXTextbox";
import ButtonsRow from "@/components/ui_utilities/ButtonsRow";
import config from "@/data/config";

export default {
  data() {
    return {
      oValueFilter: { value: "", valid: undefined },
      checkedActive: true,
    };
  },
  created() {
    this.$store.dispatch("adminGroup/LOAD_GROUPS_DATA");
  },
  computed: {
    ...mapGetters({
      BMSsData: "adminBookMembership/BMSsData",
      isBMSsLoading: "adminBookMembership/isBMSsLoading",
      isBMSsLoaded: "adminBookMembership/isBMSsLoaded",
      groupsData: "adminGroup/groupsData",
      isGroupsLoading: "adminGroup/isGroupsLoading",
      isGroupsLoaded: "adminGroup/isGroupsLoaded",
      groupData: "adminGroup/groupData",
      isGroupLoading: "adminGroup/isGroupLoading",
    }),
    filteredItems() {
      const lowcasevalue = this.oValueFilter.value.toLowerCase();
      const activeonly = this.checkedActive;
      return this.mergedItems.filter((elem) => {
        let statusFilter = (activeonly && elem.BookID > 0) || !activeonly;
        let filterFilter =
          !lowcasevalue || elem.display.toLowerCase().includes(lowcasevalue);
        return statusFilter && filterFilter;
      });
    },
    mergedItems() {
      if (
        this.BMSsData &&
        this.isBMSsLoaded &&
        this.groupsData &&
        this.isGroupsLoaded
      ) {
        var ret = [];
        this.groupsData.forEach((elem) => {
          if (elem) {
            var pushItem = {
              GroupID: elem.GroupID,
              display: elem.display,
            };
            const found = this.BMSsData.find((ms) => {
              return ms.GroupID == elem.GroupID;
            });
            if (found) {
              pushItem.BookID = found.BookID;
              pushItem.BookMembershipID = found.BookMembershipID;
            } else {
              pushItem.BookID = -1;
              pushItem.BookMembershipID = -1;
            }
            ret.push(pushItem);
          }
        });
        return ret;
      }
      return [];
    },
    showSpinner() {
      return this.isBMSsLoading || this.isBooksLoading;
    },
  },
  methods: {
    toggleMembership(elem) {
      if (elem && elem.GroupID && elem.BookID && elem.BookMembershipID) {
        if (elem.GroupID > 0 && elem.BookID > 0) {
          if (elem.BookMembershipID > 0) {
            this.$store.dispatch("adminBookMembership/DELETE_BMS_FROM_BOOK", {
              BookMembershipID: elem.BookMembershipID,
              GroupID: elem.GroupID,
              BookID: this.BookID,
            });
          }
        } else {
          this.$store.dispatch("adminBookMembership/CREATE_BMS_FROM_BOOK", {
            GroupID: elem.GroupID,
            BookID: this.BookID,
          });
        }
      }
    },
  },
  props: {
    BookID: {
      type: Number,
      default: function () {
        return -1;
      },
    },
  },
  components: {
    "sdx-textbox": SDXTextbox,
  },
};
</script>

<style lang="scss">
.qsui-admin-list-bms {
  max-height: 400px;
  overflow-y: auto;
}
</style>
