import axios from "axios";

const state = {
  bookData: [],
  fundnoData: [],
  statusData: "",
  portalBookData: {}, //books pro portal (security at portal level)
  portalFundNoData: {}, //books pro portal (security at portal level)
  shcData: {},
  statusShcData: "",
  ccyData: {},
  statusCcyData: "",
  prep2RepTypesData: {},
  prep2RepTypesDataDistinct: [],
  statusPRep2RepTypesData: "",
  perfDatesData: {},
  statusPerfDatesData: "",
  ppDatesData: {},
  statusPPDatesData: "",
};

const getters = {
  isDataLoading() {
    return state.statusData === "loading";
  },
  isDataLoaded() {
    return state.statusData === "loaded";
  },
  bookData() {
    return state.bookData;
  },
  fundnoData() {
    return state.fundnoData;
  },
  portalBookData() {
    return state.portalBookData;
  },
  portalBookDataByPortalID: (state) => (PortalID) => {
    if (state.portalBookData.hasOwnProperty(PortalID)) {
      return state.portalBookData[PortalID];
    }
    return state.bookData;
  },
  portalFundNoData() {
    return state.portalFundNoData;
  },
  portalFundNoDataByPortalID: (state) => (PortalID) => {
    if (state.portalFundNoData.hasOwnProperty(PortalID)) {
      return state.portalFundNoData[PortalID];
    }
    return state.fundnoData;
  },
  isShcDataLoading() {
    return state.statusShcData === "loading";
  },
  isShcDataLoaded() {
    return state.statusShcData === "loaded";
  },
  shcData() {
    return state.shcData;
  },
  isCcyDataLoading() {
    return state.statusCcyData === "loading";
  },
  isCcyDataLoaded() {
    return state.statusCcyData === "loaded";
  },
  ccyData() {
    return state.ccyData;
  },
  isPRep2RepTypesDataLoading() {
    return state.statusPRep2RepTypesData === "loading";
  },
  isPRep2RepTypesDataLoaded() {
    return state.statusPRep2RepTypesData === "loaded";
  },
  prep2RepTypesData() {
    return state.prep2RepTypesData;
  },
  prep2RepTypesDataDistinct() {
    return state.prep2RepTypesDataDistinct;
  },
  isPerfDatesDataLoading() {
    return state.statusPerfDatesData === "loading";
  },
  isPerfDatesDataLoaded() {
    return state.statusPerfDatesData === "loaded";
  },
  perfDatesData() {
    return state.perfDatesData;
  },
  isPPDatesDataLoading() {
    return state.statusPPDatesData === "loading";
  },
  isPPDatesDataLoaded() {
    return state.statusPPDatesData === "loaded";
  },
  ppDatesData() {
    return state.ppDatesData;
  },
};

const mutations = {
  SET_DATA(state, data) {
    const result = [];
    const resultFundNo = [];
    const map = new Map();
    const mapPortalExternalID = new Map();
    const mapPortalTitle = new Map();
    for (const item of data) {
      if (!!item.PortalID) {
        //Risk
        if (!!item.ExternalID) {
          var portalExternalID = item.PortalID + "|" + item.ExternalID;
          if (!mapPortalExternalID.has(portalExternalID)) {
            mapPortalExternalID.set(portalExternalID, true); // set any value to Map
            if (!state.portalBookData.hasOwnProperty(item.PortalID)) {
              state.portalBookData[item.PortalID] = []; // set any value to Map
            }
            state.portalBookData[item.PortalID].push({
              id: item.ExternalID,
              label: `${item.ExternalID} ${item.Description} (${item.Title})`,
            });
          }
        }
        //Perf
        if (!!item.Title) {
          var portalTitle = item.PortalID + "|" + item.Title;
          if (!mapPortalTitle.has(portalTitle)) {
            mapPortalTitle.set(portalTitle, true); // set any value to Map
            if (!state.portalFundNoData.hasOwnProperty(item.PortalID)) {
              state.portalFundNoData[item.PortalID] = []; // set any value to Map
            }
            state.portalFundNoData[item.PortalID].push({
              id: item.Title,
              label: `${item.Title} ${item.Description} (${item.ExternalID})`,
            });
          }
        }
      }
      if (!!item.ExternalID && !map.has(item.ExternalID)) {
        map.set(item.ExternalID, true); // set any value to Map
        result.push({
          id: item.ExternalID,
          label: `${item.ExternalID} ${item.Description} (${item.Title})`,
        });
        resultFundNo.push({
          id: item.Title,
          label: `${item.Title} ${item.Description} (${item.ExternalID})`,
        });
      }
    }
    // console.log(result)

    state.bookData = result;
    state.fundnoData = resultFundNo;
  },
  SET_STATUS_DATA_LOADING(state) {
    state.statusData = "loading";
  },
  SET_STATUS_DATA_LOADED(state) {
    state.statusData = "loaded";
  },
  SET_STATUS_DATA_ERROR(state) {
    state.statusData = "error";
  },
  SET_SHC_DATA(state, data) {
    state.shcData = data;
  },
  SET_STATUS_SHC_DATA_LOADING(state) {
    state.statusShcData = "loading";
  },
  SET_STATUS_SHC_DATA_LOADED(state) {
    state.statusShcData = "loaded";
  },
  SET_STATUS_SHC_DATA_ERROR(state) {
    state.statusShcData = "error";
  },
  SET_CCY_DATA(state, data) {
    state.ccyData = data;
  },
  SET_STATUS_CCY_DATA_LOADING(state) {
    state.statusCcyData = "loading";
  },
  SET_STATUS_CCY_DATA_LOADED(state) {
    state.statusCcyData = "loaded";
  },
  SET_STATUS_CCY_DATA_ERROR(state) {
    state.statusCcyData = "error";
  },
  SET_PREP2REPTYPES_DATA(state, data) {
    state.prep2RepTypesData = data;
    for (const key in data) {
      let value = data[key];
      if (!!value) {
        for (const reportType of value) {
          if (
            !!reportType &&
            !state.prep2RepTypesDataDistinct.includes(reportType)
          ) {
            state.prep2RepTypesDataDistinct.push(reportType);
          }
        }
      }
    }
  },
  SET_STATUS_PREP2REPTYPES_DATA_LOADING(state) {
    state.statusPRep2RepTypesData = "loading";
  },
  SET_STATUS_PREP2REPTYPES_DATA_LOADED(state) {
    state.statusPRep2RepTypesData = "loaded";
  },
  SET_STATUS_PREP2REPTYPES_DATA_ERROR(state) {
    state.statusPRep2RepTypesData = "error";
  },
  SET_PERFDATES_DATA(state, data) {
    state.perfDatesData = data;
  },
  SET_STATUS_PERFDATES_DATA_LOADING(state) {
    state.statusPerfDatesData = "loading";
  },
  SET_STATUS_PERFDATES_DATA_LOADED(state) {
    state.statusPerfDatesData = "loaded";
  },
  SET_STATUS_PERFDATES_DATA_ERROR(state) {
    state.statusPerfDatesData = "error";
  },
  SET_PPDATES_DATA(state, data) {
    state.ppDatesData = data;
  },
  SET_STATUS_PPDATES_DATA_LOADING(state) {
    state.statusPPDatesData = "loading";
  },
  SET_STATUS_PPDATES_DATA_LOADED(state) {
    state.statusPPDatesData = "loaded";
  },
  SET_STATUS_PPDATES_DATA_ERROR(state) {
    state.statusPPDatesData = "error";
  },
  CLEAR_DATA(state, data) {
    state.bookData = [];
    state.statusData = "";
    state.fundnoData = [];
    state.portalBookData = {};
    state.portalFundNoData = {};
    state.perfDatesData = {};
    state.ppDatesData = {};
  },
};

const actions = {
  LOAD({ commit }) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_DATA_LOADING");
      axios
        .get("/api/book/")
        .then((response) => {
          commit("SET_DATA", response.data);
          commit("SET_STATUS_DATA_LOADED");
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_DATA_ERROR");
        });
    });
  },
  LOAD_SHC({ commit }) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_SHC_DATA_LOADING");
      axios
        .get("/api/book/shc")
        .then((response) => {
          commit("SET_SHC_DATA", response.data);
          commit("SET_STATUS_SHC_DATA_LOADED");
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_SHC_DATA_ERROR");
        });
    });
  },
  LOAD_PERFREPCCY({ commit }) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_CCY_DATA_LOADING");
      axios
        .get("/api/book/reportingCcy")
        .then((response) => {
          commit("SET_CCY_DATA", response.data);
          commit("SET_STATUS_CCY_DATA_LOADED");
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_CCY_DATA_ERROR");
        });
    });
  },
  LOAD_PREP2REPTYPES({ commit }) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_PREP2REPTYPES_DATA_LOADING");
      axios
        .get("/api/book/PRep2ReportTypes")
        .then((response) => {
          commit("SET_PREP2REPTYPES_DATA", response.data);
          commit("SET_STATUS_PREP2REPTYPES_DATA_LOADED");
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_PREP2REPTYPES_DATA_ERROR");
        });
    });
  },
  LOAD_PERFDATES({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_PERFDATES_DATA_LOADING");
      axios
        .get("/api/book/" + payload.FundNo + "/perfDates")
        .then((response) => {
          commit("SET_PERFDATES_DATA", response.data);
          commit("SET_STATUS_PERFDATES_DATA_LOADED");
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_PERFDATES_DATA_ERROR");
        });
    });
  },
  LOAD_PPDATES_FOR_NIWDATE({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_PPDATES_DATA_LOADING");
      axios
        .get(
          "/api/book/" +
            payload.FundNo +
            "/perfPeriodDatesForNiwDate/" +
            payload.NiwDate
        )
        .then((response) => {
          commit("SET_PPDATES_DATA", response.data);
          commit("SET_STATUS_PPDATES_DATA_LOADED");
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_PPDATES_DATA_ERROR");
        });
    });
  },
  LOAD_PPDATES_FOR_REFDATE({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_PPDATES_DATA_LOADING");
      axios
        .get(
          "/api/book/" +
            payload.FundNo +
            "/perfPeriodDatesForRefDate/" +
            payload.RefDate
        )
        .then((response) => {
          commit("SET_PPDATES_DATA", response.data);
          commit("SET_STATUS_PPDATES_DATA_LOADED");
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_PPDATES_DATA_ERROR");
        });
    });
  },
  CLEAR({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CLEAR_DATA");
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
