import store from "..";

import AWN from "awesome-notifications";
import configAWN from "@/data/configAWN";
let notifier = new AWN(configAWN);

// state
const state = {
  notifications: [],
};

// getters
const getters = {
  //All notifications, we are returning only the raw notification objects
  // Notifications: state => state.notifications.map(n => n.Raw)
  Notifications: (state) => state.notifications,
};

// mutations
const mutations = {
  mSuccess(state, notification) {
    state.notifications.push(notification);
    notifier.success(notification.message, notification.options);
  },
  mAlert(state, notification) {
    state.notifications.push(notification);
    notifier.alert(notification.message, notification.options);
  },
};

// actions
const actions = {
  success({ commit }, message) {
    let notification = {
      type: "success",
      message: message,
      options: {},
    };
    commit("mSuccess", notification);
  },
  successDeleted({ commit }, message) {
    let notification = {
      type: "success",
      message: message,
      options: {
        labels: {
          success: "Deleted",
        },
      },
    };
    commit("mSuccess", notification);
  },
  successCreated({ commit }, message) {
    let notification = {
      type: "success",
      message: message,
      options: {
        labels: {
          success: "Created",
        },
      },
    };
    commit("mSuccess", notification);
  },
  successModified({ commit }, message) {
    let notification = {
      type: "success",
      message: message,
      options: {
        labels: {
          success: "Modified",
        },
      },
    };
    commit("mSuccess", notification);
  },
  successAdded({ commit }, message) {
    let notification = {
      type: "success",
      message: message,
      options: {
        labels: {
          success: "Added",
        },
      },
    };
    commit("mSuccess", notification);
  },
  alert({ commit }, message) {
    let notification = {
      type: "alert",
      message: message,
      options: {
        durations: {
          // alert: 0
          alert: 20000,
        },
      },
    };
    commit("mAlert", notification);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
