<template>
  <div class="input-field-select">
    <label :for="id"
      ><span class="label">{{ label }}</span></label
    >
    <treeselect
      :class="{ invalid: !isValid }"
      v-model="valueProxy"
      :options="ts_options"
      :clearable="false"
      :disabled="disabled"
      :disableFuzzyMatching="true"
      :id="id"
    />
  </div>
</template>

<script>
import randomstring from "randomstring";
import Treeselect from "@riophae/vue-treeselect";
import { validate } from "@/util/regex.js";
export default {
  data() {
    return {
      id: randomstring.generate(10),
    };
  },
  computed: {
    ts_options() {
      var ret = [];
      this.options.forEach((element) => {
        ret.push({ id: element, label: element });
      });
      return ret;
    },
    valueProxy: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    isValid() {
      if (!!this.regex) {
        return validate(this.valueProxy, this.regex);
      }
      return true;
    },
  },
  props: {
    options: {
      type: Array,
      default: function () {
        return ["A", "B", "C"];
      },
    },
    label: {
      type: String,
      default: "",
    },
    value: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    regex: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  components: {
    Treeselect,
  },
};
</script>

<style></style>
