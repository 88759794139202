import axios from "axios";
import store from "..";

const state = {
  indicesData: [],
  statusIndices: "",
  riskBenchmarksData: [],
  statusRiskBenchmarks: "",
  riskBenchmarkData: {},
  statusRiskBenchmark: "",
};

const getters = {
  isIndicesLoading() {
    return state.statusIndices === "loading";
  },
  isIndicesLoaded() {
    return state.statusIndices === "loaded";
  },
  indicesData() {
    return state.indicesData;
  },
  isRiskBenchmarksLoading() {
    return state.statusRiskBenchmarks === "loading";
  },
  isRiskBenchmarksLoaded() {
    return state.statusRiskBenchmarks === "loaded";
  },
  riskBenchmarksData() {
    return state.riskBenchmarksData;
  },
  isRiskBenchmarkLoading() {
    return state.statusRiskBenchmark === "loading";
  },
  isRiskBenchmarkLoaded() {
    return state.statusRiskBenchmark === "loaded";
  },
  riskBenchmarkData() {
    return state.riskBenchmarkData;
  },
};

const mutations = {
  SET_INDICES_DATA(state, data) {
    state.indicesData = data;
  },
  SET_STATUS_INDICES_LOADING(state) {
    state.statusIndices = "loading";
  },
  SET_STATUS_INDICES_LOADED(state) {
    state.statusIndices = "loaded";
  },
  SET_STATUS_INDICES_ERROR(state) {
    state.statusIndices = "error";
  },
  SET_RISKBENCHMARKS_DATA(state, data) {
    // data.forEach((elem) => {
    //   elem.display = `${elem.Name}, ${elem.ExternalID}`;
    // });
    state.riskBenchmarksData = data;
  },
  SET_STATUS_RISKBENCHMARKS_LOADING(state) {
    state.statusRiskBenchmarks = "loading";
  },
  SET_STATUS_RISKBENCHMARKS_LOADED(state) {
    state.statusRiskBenchmarks = "loaded";
  },
  SET_STATUS_RISKBENCHMARKS_ERROR(state) {
    state.statusRiskBenchmarks = "error";
  },
  SET_RISKBENCHMARK_DATA(state, data) {
    // data.display = `${data.Name}, ${data.ExternalID}`;
    state.riskBenchmarkData = data;
  },
  SET_STATUS_RISKBENCHMARK_LOADING(state) {
    state.statusRiskBenchmark = "loading";
  },
  SET_STATUS_RISKBENCHMARK_LOADED(state) {
    state.statusRiskBenchmark = "loaded";
  },
  SET_STATUS_RISKBENCHMARK_ERROR(state) {
    state.statusRiskBenchmark = "error";
  },
  CLEAR_DATA(state) {
    state.indicesData = [];
    state.statusIndices = "";
    state.riskBenchmarksData = [];
    state.statusRiskBenchmarks = "";
    state.riskBenchmarkData = [];
    state.statusRiskBenchmark = "";
  },
  CLEAR_RISKBENCHMARK_DATA(state) {
    state.riskBenchmarkData = [];
    state.statusRiskBenchmark = "";
  },
};

const actions = {
  CREATE_RISKBENCHMARK: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("CLEAR_RISKBENCHMARK_DATA");
      commit("SET_STATUS_RISKBENCHMARK_LOADING");
      axios
        .post("/api/fmdriskbenchmark", payload)
        .then((resp) => {
          commit("SET_RISKBENCHMARK_DATA", resp.data);
          commit("SET_STATUS_RISKBENCHMARK_LOADED");
          store.dispatch("fmdRiskBenchmark/LOAD_RISKBENCHMARKS_DATA");
          store.dispatch("notifStore/successCreated", "RiskBenchmark created.");
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_RISKBENCHMARK_ERROR");
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          reject(err);
        });
    });
  },
  LOAD_RISKBENCHMARKS_DATA({ commit }) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_RISKBENCHMARKS_LOADING");
      axios
        .get("/api/fmdriskbenchmark")
        .then((response) => {
          commit("SET_RISKBENCHMARKS_DATA", response.data);
          commit("SET_STATUS_RISKBENCHMARKS_LOADED");
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          commit("SET_STATUS_RISKBENCHMARKS_ERROR");
          reject(err);
        });
    });
  },
  LOAD_INDICES_DATA({ commit }) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_INDICES_LOADING");
      axios
        .get("/api/fmdriskbenchmark/index")
        .then((response) => {
          commit("SET_INDICES_DATA", response.data);
          commit("SET_STATUS_INDICES_LOADED");
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          commit("SET_STATUS_INDICES_ERROR");
          reject(err);
        });
    });
  },
  LOAD_RISKBENCHMARK_DATA({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_RISKBENCHMARK_LOADING");
      commit("CLEAR_RISKBENCHMARK_DATA");
      axios
        .get("/api/fmdriskbenchmark/" + payload.name)
        .then((resp) => {
          commit("SET_RISKBENCHMARK_DATA", resp.data);
          commit("SET_STATUS_RISKBENCHMARK_LOADED");
          resolve(resp);
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          commit("SET_STATUS_RISKBENCHMARK_ERROR");
          reject(err);
        });
    });
  },
  DELETE_RISKBENCHMARK({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("CLEAR_RISKBENCHMARK_DATA");
      axios
        .post("/api/fmdriskbenchmark/delete/" + payload.name)
        .then((response) => {
          store.dispatch("notifStore/successDeleted", "RiskBenchmark deleted.");
          store.dispatch("fmdRiskBenchmark/LOAD_RISKBENCHMARKS_DATA");
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          reject(err);
        });
    });
  },
  CLEAR_STORE({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CLEAR_DATA");
    });
  },
  CLEAR_RISKBENCHMARK({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CLEAR_RISKBENCHMARK_DATA");
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
