import axios from "axios";
import { getUrlFromLink } from "@/components/wv_filter/WvQueryOption.js";

const state = {
  navData: [],
  navFilteredData: [],
  statusData: "",
  hasAuthorisationExpRequest: false,
  navSearchString: "",
};

const getters = {
  isDataLoading() {
    return state.statusData === "loading";
  },
  isDataLoaded() {
    return state.statusData === "loaded";
  },
  navData() {
    return state.navData;
  },
  navFilteredData() {
    if (state.navSearchString) {
      var ret = [];
      state.navFilteredData.forEach((portal) => {
        let portalId = portal.id;
        let fPortal = {
          Menus: [],
          id: portal.id,
          name: portal.name,
          title: portal.title,
          groupName: portal.groupName,
        };
        portal.Menus.forEach((menu) => {
          let fMenu = {
            SubMenus: [],
            realPortalId: menu.realPortalId,
            id: menu.id,
            name: menu.name,
            text: menu.text,
          };
          menu.SubMenus.forEach((submenu) => {
            let fSubmenu = {
              Links: [],
              id: submenu.id,
              name: submenu.name,
            };
            submenu.Links.forEach((link) => {
              if (!!link && !!link.link) {
                let url = getUrlFromLink(link.link);
                let WVName = "";
                if (url) {
                  let params = new URLSearchParams(url.search.slice(1));
                  if (params && params.has("WVName")) {
                    WVName = params.get("WVName");
                  }
                }
                if (
                  (WVName &&
                    WVName.toLowerCase().includes(
                      state.navSearchString.toLowerCase()
                    )) ||
                  link.name
                    .toLowerCase()
                    .includes(state.navSearchString.toLowerCase())
                ) {
                  let fLink = {
                    id: link.id,
                    name: link.name,
                    link: link.link,
                  };
                  fSubmenu.Links.push(fLink);
                }
              }
            });
            if (fSubmenu.Links.length > 0) {
              //only if Links to display
              fMenu.SubMenus.push(fSubmenu);
            }
          });
          if (fMenu.SubMenus.length > 0) {
            //only if something to display
            fPortal.Menus.push(fMenu);
          }
        });
        if (fPortal.Menus.length > 0) {
          //only if something to display
          ret.push(fPortal);
        }
      });
      return ret;
    } else {
      return state.navFilteredData;
    }
  },
  hasAuthorisationExpRequest() {
    return state.hasAuthorisationExpRequest;
  },
  navSearchString() {
    return state.navSearchString;
  },
};

const mutations = {
  SET_DATA(state, data) {
    state.navData = data;
    state.navFilteredData = [];
    data.forEach((portal) => {
      let portalId = portal.id;
      let fPortal = {
        Menus: [],
        id: portal.id,
        name: portal.name,
        title: portal.title,
        groupName: portal.groupName,
      };
      portal.Menus.forEach((menu) => {
        let fMenu = {
          SubMenus: [],
          realPortalId: portalId,
          id: menu.id,
          name: menu.name,
          text: menu.text,
        };
        menu.SubMenus.forEach((submenu) => {
          let fSubmenu = {
            Links: [],
            id: submenu.id,
            name: submenu.name,
          };
          submenu.Links.forEach((link) => {
            if (!!link && !!link.link) {
              if (
                !state.hasAuthorisationExpRequest &&
                link.link.includes("PFRequestController_Post")
              ) {
                state.hasAuthorisationExpRequest = true;
              }
              if (link.intWin) {
                //add internal link only
                let fLink = {
                  id: link.id,
                  name: link.name,
                  link: link.link,
                };
                fSubmenu.Links.push(fLink);
              }
            }
          });
          if (fSubmenu.Links.length > 0) {
            //only if Links to display
            fMenu.SubMenus.push(fSubmenu);
          }
        });
        if (
          fMenu.SubMenus.length > 0 &&
          (fMenu.text == "QSUI" || fMenu.text == "v1" || !fMenu.text)
        ) {
          //only if something to display
          //"Display filter for qsui version: v1:qsui1 only, v2:qsui2 only, 'empty': no filter, qsui1 und qsui2 (default)"
          fPortal.Menus.push(fMenu);
        }
      });
      if (fPortal.Menus.length > 0) {
        //only if something to display
        if (!!fPortal.groupName) {
          const fi = state.navFilteredData.findIndex((ctP) => {
            return !!ctP.groupName && ctP.groupName == fPortal.groupName; //look if an other Portal has the same groupName
          });
          if (fi == -1) {
            //first in group, set the name to GroupName and push
            fPortal.name = fPortal.groupName;
            state.navFilteredData.push(fPortal);
          } else {
            //not first in group, add my menus to the group
            fPortal.Menus.forEach((menu) => {
              state.navFilteredData[fi].Menus.push(menu);
            });
          }
        } else {
          state.navFilteredData.push(fPortal);
        }
      }
    });
  },
  SET_STATUS_DATA_LOADING(state) {
    state.statusData = "loading";
  },
  SET_STATUS_DATA_LOADED(state) {
    state.statusData = "loaded";
  },
  SET_STATUS_DATA_ERROR(state) {
    state.statusData = "error";
  },
  CLEAR_DATA(state, data) {
    state.navData = [];
    state.statusData = "";
  },
  SET_SEARCH_STRING(state, searchstring) {
    state.navSearchString = searchstring;
  },
};

const actions = {
  LOAD({ commit }) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_DATA_LOADING");
      axios
        .get("/api/navigation/")
        .then((response) => {
          commit("SET_DATA", response.data);
          commit("SET_STATUS_DATA_LOADED");
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_DATA_ERROR");
        });
    });
  },
  CLEAR({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CLEAR_DATA");
    });
  },
  PUSH_SEARCH_STRING({ commit }, searchstring) {
    commit("SET_SEARCH_STRING", searchstring);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
