<template>
  <div class="navedit">
    <edit-portal v-for="portal in portals" :key="portal.id" :portal="portal" />
    <div class="navedit navedit-portal">
      <div class="navedit navedit-portal-header">
        <i
          class="icon icon-061-plus wvfilter-icon wvfilter-green-button"
          title="Add Portal"
          aria-hidden="true"
          @click="addPortal()"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import QxpNavEditPortal from "@/components/admin/QxpNav/QxpNavEditPortal";
export default {
  data() {
    return {};
  },
  created() {
    document.title = "Admin>Portals";
  },
  computed: {
    ...mapGetters({
      isNavLoading: "nav/isDataLoading",
      isNavLoaded: "nav/isDataLoaded",
      navData: "nav/navData",
      navFilteredData: "nav/navFilteredData",
    }),
    portals() {
      // if (this.isNavLoaded) {
      return this.navData;
      // }
      // return []
    },
  },
  methods: {
    addPortal() {
      this.$store.dispatch("adminPortal/CREATE_PORTAL", {
        name: "new Portal",
      });
    },
  },
  components: {
    "edit-portal": QxpNavEditPortal,
  },
};
</script>
