<template>
  <div class="qsui-admin-container">
    <div v-if="showSpinner" class="loader-spinner loader-spinner--small"></div>
    <div v-else-if="!isPersonLoadedAndValid">
      <div class="qsui-admin-form col-sm-12 col-md-12 col-lg-10 col-xl-7">
        <div class="text-h4">Create New User</div>
        <div class="qsui-admin-subform">
          <div class="qsui-admin-subform-row">
            <sdx-textbox
              v-model="newPerson.Firstname"
              label="First Name"
              type="text"
              :showLabel="true"
              :regex="config.regex.notEmpty"
            />
            <sdx-textbox
              v-model="newPerson.Surname"
              label="Last Name"
              type="text"
              :showLabel="true"
              :regex="config.regex.notEmpty"
            />
            <sdx-textbox
              v-model="newPerson.geno_id"
              label="Geno ID"
              type="text"
              :showLabel="true"
            />
            <sdx-textbox
              class="input-field-large"
              v-model="newPerson.email_address"
              label="Email Address"
              type="text"
              :showLabel="true"
            />
          </div>
          <div class="qsui-admin-subform-row">
            <sdx-textbox
              v-model="newPerson.Username"
              label="Username"
              type="text"
              :showLabel="true"
              :regex="config.regex.notEmpty"
            />
            <sdx-textbox
              v-model="newPerson.Password"
              label="Password"
              type="password"
              :showLabel="true"
              :regex="config.regex.opspassword"
              :errorMessage="errormessage"
            />
          </div>
        </div>
        <div class="qsui-admin-buttons">
          <a
            class="button button--secondary"
            href="javascript:void(0);"
            @click="onNewCancel"
            >cancel</a
          >
          <a
            class="button button--primary"
            href="javascript:void(0);"
            :class="{ disabled: !isNewUserValid }"
            @click="onCreate"
            >create</a
          >
        </div>
      </div>
    </div>
    <div v-else>
      <div class="qsui-admin-form col-sm-12 col-md-12 col-lg-10 col-xl-7">
        <div class="text-h4">User: {{ person.Username.value }}</div>
        <div v-show="person.status > 1" class="text">
          {{ messageDeactivated }}
        </div>
        <div class="qsui-admin-subform">
          <div class="qsui-admin-subform-row">
            <sdx-textbox
              v-model="person.UserNr"
              label="UserNr"
              type="text"
              :showLabel="true"
              :disabled="true"
            />
            <sdx-textbox
              v-model="person.Username"
              label="Username"
              type="text"
              :showLabel="true"
              :disabled="true"
              :regex="config.regex.notEmpty"
            />
          </div>
          <div class="qsui-admin-subform-row">
            <sdx-textbox
              v-model="person.Firstname"
              label="First Name"
              type="text"
              :showLabel="true"
              :disabled="person.status > 1"
              :regex="config.regex.notEmpty"
            />
            <sdx-textbox
              v-model="person.Surname"
              label="Last Name"
              type="text"
              :showLabel="true"
              :disabled="person.status > 1"
              :regex="config.regex.notEmpty"
            />
            <sdx-textbox
              v-model="person.geno_id"
              label="Geno ID"
              type="text"
              :showLabel="true"
              :disabled="person.status > 1"
            />
            <sdx-textbox
              class="input-field-large"
              v-model="person.email_address"
              label="Email Address"
              type="text"
              :showLabel="true"
              :disabled="person.status > 1"
            />
          </div>
        </div>
        <div class="qsui-admin-buttons">
          <a
            v-show="person.status == 1"
            class="button button--primary"
            :class="{ disabled: !hasChanged || !isModifyUserValid }"
            href="javascript:void(0);"
            @click="onModifyPerson"
            >change</a
          >
          <a
            v-if="person.status == 1"
            class="button button--primary qsui-button-red"
            :class="{ disabled: false }"
            href="javascript:void(0);"
            @click="onDeactivate"
            >deactivate</a
          >
          <a
            v-else
            class="button button--primary"
            :class="{ disabled: false }"
            href="javascript:void(0);"
            @click="onReactivate"
            >re-activate</a
          >
          <a
            v-show="person.status > 1"
            class="button button--primary qsui-button-red"
            href="javascript:void(0);"
            @click="onDelete"
            >delete</a
          >
        </div>
      </div>
      <div class="qsui-admin-form col-sm-12 col-md-12 col-lg-10 col-xl-7">
        <div class="text-h4">Reset Password</div>
        <div class="qsui-admin-subform">
          <div class="qsui-admin-subform-row">
            <sdx-textbox
              v-model="oPW"
              label="new password"
              type="password"
              :showLabel="true"
              :regex="config.regex.opspassword"
              :errorMessage="errormessage"
            />
          </div>
        </div>
        <div class="qsui-admin-buttons">
          <a
            class="button button--primary"
            :class="{ disabled: !isResetPasswordValid }"
            href="javascript:void(0);"
            @click="onResetPassword"
            >reset PW</a
          >
        </div>
      </div>
      <div
        v-show="showSwitchRole"
        class="qsui-admin-form col-sm-12 col-md-12 col-lg-10 col-xl-7"
      >
        <div class="text-h4">Login as User</div>
        <div class="text">
          See what other user (non-admin) can see.. this will log you out!!
        </div>
        <div class="qsui-admin-buttons">
          <a
            class="button button--primary"
            :class="{ disabled: false }"
            href="javascript:void(0);"
            @click="switchRole"
            >{{ "Login as user" }}</a
          >
        </div>
      </div>
      <ums-form-for-user :UserNr="personData.UserNr" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SDXTextbox from "@/components/ui_utilities/SDXTextbox";
import ButtonsRow from "@/components/ui_utilities/ButtonsRow";
import config from "@/data/config";
import UMSFormForUser from "@/components/admin/UMSFormForUser";
export default {
  data() {
    return {
      person: {
        UserNr: { value: "", valid: undefined },
        Surname: { value: "", valid: undefined },
        Firstname: { value: "", valid: undefined },
        Username: { value: "", valid: undefined },
        geno_id: { value: "", valid: undefined },
        email_address: { value: "", valid: undefined },
        status: -1,
        display: "",
        isAdmin: -1,
      },
      oPW: { value: "", valid: undefined },
      newPerson: {
        Surname: { value: "", valid: undefined },
        Firstname: { value: "", valid: undefined },
        Username: { value: "", valid: undefined },
        Password: { value: "", valid: undefined },
        geno_id: { value: "", valid: undefined },
        email_address: { value: "", valid: undefined },
      },
      config: config,
      errormessage:
        "Password must be 10 chars long, 1 lowercase, 1 uppercase, 1 digit and 1 special chars: @#$%^&+=*.!?",
      messageDeactivated: "Status: deactivated",
    };
  },
  watch: {
    personData: function (value) {
      this.person.UserNr = { value: value.UserNr, valid: true };
      this.person.Surname = { value: value.Surname, valid: true };
      this.person.Firstname = { value: value.Firstname, valid: true };
      this.person.Username = { value: value.Username, valid: true };
      this.person.geno_id = { value: value.geno_id, valid: true };
      this.person.email_address = { value: value.email_address, valid: true };
      this.person.status = value.status;
      this.person.display = value.display;
      this.person.isAdmin = value.isAdmin;
    },
  },
  created() {
    document.title = "Admin>Users";
  },
  computed: {
    ...mapGetters({
      personData: "adminUser/personData",
      isPersonLoading: "adminUser/isPersonLoading",
    }),
    hasChanged() {
      return (
        this.person.Surname.value != this.personData.Surname ||
        this.person.Firstname.value != this.personData.Firstname ||
        this.person.geno_id.value != this.personData.geno_id ||
        this.person.email_address.value != this.personData.email_address
      );
    },
    isPersonLoadedAndValid() {
      return this.person && this.person.UserNr.value > 0;
    },
    isNewUserValid() {
      return (
        this.newPerson.Firstname.valid &&
        this.newPerson.Surname.valid &&
        this.newPerson.Username.valid &&
        this.newPerson.Password.valid
      );
    },
    isModifyUserValid() {
      return this.person.Firstname.valid && this.person.Surname.valid;
    },
    isResetPasswordValid() {
      return this.oPW.valid;
    },
    showSpinner() {
      return this.isPersonLoading;
    },
    showSwitchRole() {
      return this.person && this.person.isAdmin == 0; //only switch ot person without admin right
    },
  },
  methods: {
    onNewCancel() {
      this.newPerson = {
        Firstname: { value: "", valid: undefined },
        Surname: { value: "", valid: undefined },
        Username: { value: "", valid: undefined },
        Password: { value: "", valid: undefined },
        geno_id: { value: "", valid: undefined },
        email_address: { value: "", valid: undefined },
      };
    },
    onCreate() {
      this.$store.dispatch("adminUser/CREATE_NEW_PERSON", {
        Firstname: this.newPerson.Firstname.value,
        Surname: this.newPerson.Surname.value,
        Username: this.newPerson.Username.value,
        Password: this.newPerson.Password.value,
        geno_id: this.newPerson.geno_id.value,
        email_address: this.newPerson.email_address.value,
      });
      this.onNewCancel();
    },
    onModifyPerson() {
      this.$store.dispatch("adminUser/MODIFY_PERSON", {
        UserNr: this.person.UserNr.value,
        Firstname: this.person.Firstname.value,
        Surname: this.person.Surname.value,
        geno_id: this.person.geno_id.value,
        email_address: this.person.email_address.value,
      });
    },
    onResetPassword() {
      this.$store.dispatch("adminUser/RESET_PASSWORD", {
        key_ACR_Persons: this.person.UserNr.value,
        Password: this.oPW.value,
      });
      this.oPW.value = "";
    },
    onDeactivate() {
      let text = "Deactivate user " + this.person.UserNr.value + "?";
      if (confirm(text) == true) {
        this.$store.dispatch("adminUser/DEACTIVATE_PERSON", {
          UserNr: this.person.UserNr.value,
        });
      }
    },
    onReactivate() {
      this.$store.dispatch("adminUser/REACTIVATE_PERSON", {
        UserNr: this.person.UserNr.value,
      });
    },
    onDelete() {
      let text = "Delete user " + this.person.UserNr.value + "?";
      if (confirm(text) == true) {
        this.$store.dispatch("adminUser/DELETE_PERSON", {
          UserNr: this.person.UserNr.value,
        });
      }
    },
    switchRole() {
      if (this.person.UserNr.value) {
        this.$store.dispatch("AUTH_OTHERUSER", this.person.UserNr.value);
        setTimeout(() => {
          this.$router.push("/");
          location.reload();
        }, 250);
      }
    },
  },
  components: {
    "sdx-textbox": SDXTextbox,
    ButtonsRow,
    "ums-form-for-user": UMSFormForUser,
  },
};
</script>
