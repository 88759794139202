import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
} from "../actions/auth";
import axios from "axios";
import { stat } from "fs";
import store from "..";
import jwtDecode from "jwt-decode";

const state = {
  token: sessionStorage.getItem("user-token"),
  ACRID: localStorage.getItem("ACRID"),
  flyoutIsOpen: false,
};

const getters = {
  //strange getter as function (prevent caching)
  isUserTokenHereAndStillValid: (state) => () => {
    var jwt = sessionStorage.getItem("user-token");
    if (jwt) {
      var jwtDecoded = jwtDecode(jwt);
      return assertAlive(jwtDecoded);
    }
    return false;
  },
  isTokenExpired: (state) => () => {
    var jwt = sessionStorage.getItem("user-token");
    if (jwt) {
      var jwtDecoded = jwtDecode(jwt);
      return !assertAlive(jwtDecoded);
    }
    return false;
  },
  myNonCachedToken: (state) => () => {
    return sessionStorage.getItem("user-token");
  },
  ACRID() {
    return state.ACRID;
  },
  UserNr: (state) => () => {
    var jwt = sessionStorage.getItem("user-token");
    if (jwt) {
      var jwtDecoded = jwtDecode(jwt);
      return getUserNr(jwtDecoded);
    }
    return false;
  },
  UserName: (state) => () => {
    var jwt = sessionStorage.getItem("user-token");
    if (jwt) {
      var jwtDecoded = jwtDecode(jwt);
      return getUserName(jwtDecoded);
    }
    return false;
  },
  isUserAdmin: (state) => () => {
    var jwt = sessionStorage.getItem("user-token");
    if (jwt) {
      var jwtDecoded = jwtDecode(jwt);
      return getIsUserAdmin(jwtDecoded);
    }
    return false;
  },
  canSeeAllBook: (state) => () => {
    var jwt = sessionStorage.getItem("user-token");
    if (jwt) {
      var jwtDecoded = jwtDecode(jwt);
      return getCanSeeAllBook(jwtDecoded);
    }
    return false;
  },
  flyoutIsOpen() {
    return state.flyoutIsOpen;
  },
};

const mutations = {
  CLOSE_FLYOUT(state) {
    state.flyoutIsOpen = false;
  },
  TOGGLE_FLYOUT(state) {
    state.flyoutIsOpen = !state.flyoutIsOpen;
  },
  AUTH_OTHERUSER_REQUEST(state) {
    // localStorage.removeItem('user-token')
    // store.dispatch('nav/CLEAR')
    // store.dispatch('book/CLEAR')
  },
  AUTH_OTHERUSER_SUCCESS: (state, resp) => {
    sessionStorage.setItem("user-token", resp.data.Token);
    store.dispatch("nav/LOAD");
    store.dispatch("book/LOAD");
  },
  AUTH_OTHERUSER_ERROR: (state) => {
    sessionStorage.removeItem("user-token");
    store.dispatch("nav/CLEAR");
    store.dispatch("book/CLEAR");
  },
  [AUTH_REQUEST]: (state) => {
    // store.dispatch('nav/CLEAR')
    // store.dispatch('book/CLEAR')
  },
  [AUTH_SUCCESS]: (state, resp) => {
    sessionStorage.setItem("user-token", resp.data.Token);
    store.dispatch("nav/LOAD");
    store.dispatch("book/LOAD");
  },
  [AUTH_ERROR]: (state) => {
    sessionStorage.removeItem("user-token");
    store.dispatch("nav/CLEAR");
    store.dispatch("book/CLEAR");
  },
  [AUTH_LOGOUT]: (state) => {
    sessionStorage.removeItem("user-token");
    store.dispatch("nav/CLEAR");
    store.dispatch("book/CLEAR");
  },
};

const actions = {
  closeFlyout({ dispatch, commit }) {
    commit("CLOSE_FLYOUT");
  },
  toggleFlyout({ dispatch, commit }) {
    commit("TOGGLE_FLYOUT");
  },
  [AUTH_REQUEST]: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      // delete axios.defaults.headers.common['Authorization']
      axios
        .post("/api/users/token", {
          Username: user.username,
          Password: user.password,
        })
        .then((resp) => {
          const dataToken = resp.data.Token;
          //console.log('token request successfull')
          // Here set the header of your ajax library to the token value.
          // example with axios
          // axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
          commit(AUTH_SUCCESS, resp);
          // dispatch(USER_REQUEST)
          resolve(resp);
        })
        .catch((err) => {
          commit(AUTH_ERROR, err);
          reject(err);
        });
    });
  },
  AUTH_OTHERUSER: ({ commit, dispatch }, otherUserNr) => {
    return new Promise((resolve, reject) => {
      commit("AUTH_OTHERUSER_REQUEST");
      // delete axios.defaults.headers.common['Authorization']
      axios
        .get("/api/adminuser/otherusertoken/" + otherUserNr)
        .then((resp) => {
          const dataToken = resp.data.Token;
          //console.log('token request successfull')
          // Here set the header of your ajax library to the token value.
          // example with axios
          // axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
          commit("AUTH_OTHERUSER_SUCCESS", resp);
          store.dispatch(
            "notifStore/success",
            "You are now log in as a other user, please refresh existing pages..."
          );
          resolve(resp);
        })
        .catch((err) => {
          commit("AUTH_OTHERUSER_ERROR", err);
          reject(err);
        });
    });
  },
  AUTH_OPENID_REQUEST: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/users/token", {
          Username: payload.username,
          Password: payload.password,
          scope: payload.scope,
          response_type: payload.response_type,
          client_id: payload.client_id,
          redirect_uri: payload.redirect_uri,
          nonce: payload.nonce,
        })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          commit(AUTH_ERROR, err);
          reject(err);
        });
    });
  },
  [AUTH_LOGOUT]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_LOGOUT);
      resolve();
    });
  },
};

function assertAlive(decoded) {
  const now = Date.now().valueOf() / 1000;
  if (typeof decoded.exp !== "undefined" && decoded.exp < now) {
    return false;
    // throw new Error(`token expired: ${JSON.stringify(decoded)}`)
  }
  if (typeof decoded.nbf !== "undefined" && decoded.nbf > now) {
    return false;
    // throw new Error(`token not yet valid: ${JSON.stringify(decoded)}`)
  }
  return true;
}

function getUserNr(decoded) {
  if (decoded && decoded.sub) {
    return decoded.sub;
  }
}

function getUserName(decoded) {
  if (decoded && decoded.sub) {
    return decoded.username;
  }
}

function getIsUserAdmin(decoded) {
  if (decoded && decoded.admin) {
    return decoded.admin == "1";
  }
}
function getCanSeeAllBook(decoded) {
  if (decoded && decoded.allbook) {
    return decoded.allbook;
  }
}
export default {
  state,
  getters,
  actions,
  mutations,
};
