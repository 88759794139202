<template>
  <div class="row flex-items-xs-center">
    <div class="row margin-v-4">
      <div class="col-xs-12">
        <h2 class="sc-navy">Oops, Page not Found!</h2>
        <buttons-row submitText="Home" @onSubmit="pushHome" />
      </div>
    </div>
    <!-- <div class="bg">
      <h1 class="text-d1 navy">Page not Found!</h1>
      <buttons-row submitText="Sign out" @onSubmit="signout" />
      <router-link class="nav-link" v-bind:to="'/'">
        <span class="text-underline">Home</span>
      </router-link>
    </div>-->
  </div>
</template>

<script>
import ButtonsRow from "@/components/ui_utilities/ButtonsRow";
import router from "@/router";
export default {
  components: {
    ButtonsRow,
  },
  methods: {
    pushHome() {
      router.push("/");
      //router.push({ name: 'HelloWorld' })
    },
  },
};
</script>
