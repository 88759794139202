<template>
  <div>
    <div v-if="showBanner" class="bg bg--gray result-banner exprequest">
      <div class="result-banner-left exprequest">
        <select-format
          v-if="!!irsTypeFormatOptions"
          v-show="irsTypeFormatOptions.length > 1"
          :selectOptions="irsTypeFormatOptions"
          @input="onFormatInput($event)"
        />
        <select-holding-period
          v-if="!!irsTypeHoldingPeriodOptions"
          v-show="irsTypeHoldingPeriodOptions.length > 1"
          @input="onHoldingPeriodInput($event)"
        />
        <select-language
          v-if="!!irsTypeLanguageOptions"
          v-show="irsTypeLanguageOptions.length > 1"
          @input="onLanguageInput($event)"
        />
        <!-- <select-destination
        :Destinations="irsDestinationsData"
        @input="onDestinationInput($event)"
      /> -->
        <!-- <div
        class="result-banner-icons"
        title="Clear Row Selection"
        @click="$emit('triggerClearRowSelection')"
      >
        <i class="icon icon-071-retry wvfilter-icon wvfilter-green-button" aria-hidden="true"></i>
      </div> -->
        <div class="banner-buttons">
          <a
            class="button button__icon button--primary banner-button"
            :class="{ disabled: disable_button }"
            href="javascript:void(0);"
            @click="get_docs_for_selection()"
            >{{ button_text }}</a
          >
        </div>
        <div
          v-if="showSpinner"
          class="loader-spinner loader-spinner--small"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import selectDestination from "@/components/datagrid/inputForm/selectDestination";
import selectFormat from "@/components/datagrid/inputForm/selectFormat";
import selectHoldingPeriod from "@/components/datagrid/inputForm/selectHoldingPeriod";
import selectLanguage from "@/components/datagrid/inputForm/selectLanguage";
import moment from "moment";

export default {
  data() {
    return {
      format: 0,
      holdingPeriod: 20,
      language: "DE",
      irs_load_counter: 0,
    };
  },
  computed: {
    ...mapGetters({
      _isDataLoaded: "wviewStore/isDataLoaded",
      isIrsDestinationsDataLoaded: "irsStore/isIrsDestinationsDataLoaded",
      irsDestinationsData: "irsStore/irsDestinationsData",
      isIrsTypesDataLoaded: "irsStore/isIrsTypesDataLoaded",
      irsTypesData: "irsStore/irsTypesData",
      irsRequestWViewMapping: "wviewStore/irsRequestReportType",
      wvInfo: "wviewStore/wvInfo",
      isInfoLoaded: "wviewStore/isInfoLoaded",
      // isSentLoaded: 'pferStore/isSentLoaded',
      // pfrrSent: 'pferStore/pfrrSent'
    }),
    showSpinner() {
      return this.irs_load_counter > 0;
    },
    disable_button() {
      return this.irs_load_counter > 0 || this.gridSelectedRowLength <= 0;
    },
    button_text() {
      if (this.irs_load_counter > 0) {
        return "getting your document(s)";
      }
      return `get ${this.gridSelectedRowLength} select. document(s)`;
    },
    showBanner() {
      return this._isDataLoaded;
    },
    irsType() {
      if (
        this.irsTypesData &&
        this.isInfoLoaded &&
        this.wvInfo.hu.irsRequestType
      ) {
        let typeFromInfo = this.wvInfo.hu.irsRequestType;
        var ctIndex = this.irsTypesData.findIndex(function (obj) {
          return obj.IRSTypeId === typeFromInfo;
        });
        if (ctIndex) {
          return this.irsTypesData[ctIndex];
        }
      }
    },
    irsTypeFormatOptions() {
      if (this.irsType && this.irsType.format) {
        let arr = this.irsType.format.split(",");
        let ret = [];
        arr.forEach((element) => {
          let id = element.trim();
          var label = "";
          switch (id) {
            case "0":
              label = "xml";
              break;
            case "1":
              label = "xlxs";
              break;
            case "2":
              label = "pdf";
              break;
            case "3":
              label = "csv";
              break;
            case "4":
              label = "txt";
              break;
          }
          ret.push({
            id: id,
            label: label,
          });
        });
        return ret;
      }
    },
    irsTypeLanguageOptions() {
      if (this.irsType && this.irsType.language) {
        let arr = this.irsType.language.split(",");
        let ret = [];
        arr.forEach((element) => {
          let id = element.trim();
          ret.push({
            id: id,
            label: id,
          });
        });
        return ret;
      }
    },
    irsTypeHoldingPeriodOptions() {
      if (this.irsType && this.irsType.holdingPeriod) {
        let arr = this.irsType.holdingPeriod.split(",");
        let ret = [];
        arr.forEach((element) => {
          let id = element.trim();
          ret.push({
            id: id,
            label: id,
          });
        });
        return ret;
      }
    },
    gridSelectedRowLength() {
      if (this.selectedRows) {
        return this.selectedRows.length;
      } else {
        return 0;
      }
    },
    distinctSelectedReport() {
      var ret = [];
      if (this.selectedRows) {
        for (let iR = 0; iR < this.selectedRows.length; iR++) {
          const ctR = this.selectedRows[iR];
          if (ctR.ReportType) {
            if (ret.includes(ctR.ReportType)) {
              continue;
            } else {
              ret.push(ctR.ReportType);
            }
          }
        }
      }
      return ret.sort();
    },
  },
  methods: {
    ReloadData() {
      this.$emit("triggerClearRowSelection");
      this.$store.dispatch("wviewStore/RELOAD_DATA");
    },
    get_irs_payload(ctR) {
      let payload = {
        IRSTypeId: this.wvInfo.hu.irsRequestType,
        DestinationId: 7,
        FormatId: this.format,
        Language: this.language,
        HoldingPeriodDay: this.holdingPeriod,
      };
      for (const elem in this.wvInfo.hu.colForList) {
        let item = this.wvInfo.hu.colForList[elem];
        if (item.irsRequestFieldMap && item.irsRequestFieldMap !== "") {
          var value = ctR[item.ColName];
          if (item.ColType === "date") {
            var value = moment(ctR[item.ColName]).format("YYYY-MM-DD");
          }
          payload[item.irsRequestFieldMap] = value;
        }
      }
      console.log(payload);
      return payload;
    },
    async get_docs_for_selection() {
      for (let iR = 0; iR < this.selectedRows.length; iR++) {
        this.irs_load_counter++;
        const ctR = this.selectedRows[iR];
        let payload = this.get_irs_payload(ctR);
        this.$store
          .dispatch("irsStore/SEND_IRSREQUEST", payload)
          .then((result) => {
            // console.log("hello from send promise");
            // console.log(result);
            this.getIrsStatus(result.id).then((status) => {
              if (status == 0) {
                this.$store
                  .dispatch("irsStore/DOWNLOAD_DOCUMENT", {
                    IRSId: result.id,
                  })
                  .then((filename) => {
                    // console.log(
                    //   "document downloaded for " + result.id + " " + filename
                    // );
                  });
              } else {
                this.$store.dispatch(
                  "notifStore/alert",
                  `Error while getting Document (IRSId: ${result.id})`
                );
              }
              this.irs_load_counter--;
              this.$store.dispatch("wviewStore/RELOAD_DATA");
            });
          });
      }
      this.$emit("triggerClearRowSelection");
    },
    async getIrsStatus(irs_id) {
      var ret = 100;
      let count = 0;
      while (count < 120) {
        await this.$store
          .dispatch("irsStore/GET_IRSREQUEST", irs_id)
          .then((result) => {
            ret = result.StatusId;
          });
        if (ret == 0) {
          // console.log("status ok");
          break;
        } else if (ret < 0) {
          // console.log("status error");
          break;
        } else {
          await new Promise((resolve) => setTimeout(resolve, 3000));
        }
        count++;
      }
      return ret;
    },
    onFormatInput(value) {
      this.format = value;
    },
    onHoldingPeriodInput(value) {
      this.holdingPeriod = value;
    },
    onLanguageInput(value) {
      this.language = value;
    },
  },
  props: {
    selectedRows: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  created() {
    if (!this.isIrsDestinationsDataLoaded) {
      this.$store.dispatch("irsStore/LOAD_IRSDESTINATIONS");
    }
    if (!this.isIrsTypesDataLoaded) {
      this.$store.dispatch("irsStore/LOAD_IRSTYPES");
    }
  },
  components: {
    selectDestination,
    "select-format": selectFormat,
    selectHoldingPeriod,
    selectLanguage,
  },
};
</script>
