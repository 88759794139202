import axios from "axios";
import store from "..";

const state = {
  UMSsData: [],
  statusUMSs: "",
  statusUMS: "",
};

const getters = {
  isUMSsLoading() {
    return state.statusUMSs === "loading";
  },
  isUMSsLoaded() {
    return state.statusUMSs === "loaded";
  },
  UMSsData() {
    return state.UMSsData;
  },
  isBMSLoading() {
    return state.statusUMS === "loading";
  },
  isBMSLoaded() {
    return state.statusUMS === "loaded";
  },
};

const mutations = {
  SET_UMSS_DATA(state, data) {
    state.UMSsData = data;
  },
  SET_STATUS_UMSS_LOADING(state) {
    state.statusUMSs = "loading";
  },
  SET_STATUS_UMSS_LOADED(state) {
    state.statusUMSs = "loaded";
  },
  SET_STATUS_UMSS_ERROR(state) {
    state.statusUMSs = "error";
  },
  SET_STATUS_UMS_LOADING(state) {
    state.statusUMS = "loading";
  },
  SET_STATUS_UMS_LOADED(state) {
    state.statusUMS = "loaded";
  },
  SET_STATUS_UMS_ERROR(state) {
    state.statusUMS = "error";
  },
  CLEAR_DATA(state) {
    state.UMSsData = [];
    state.statusUMSs = "";
    state.statusUMS = "";
  },
};

const actions = {
  LOAD_UMSSFORUSER_DATA({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_UMSS_LOADING");
      axios
        .get("/api/adminusermembership/allforuser/" + payload.UserNr)
        .then((response) => {
          commit("SET_UMSS_DATA", response.data);
          commit("SET_STATUS_UMSS_LOADED");
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          commit("SET_STATUS_UMSS_ERROR");
          reject(err);
        });
    });
  },
  LOAD_UMSS_DATA({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_UMSS_LOADING");
      axios
        .get("/api/adminusermembership/all/" + payload.GroupID)
        .then((response) => {
          commit("SET_UMSS_DATA", response.data);
          commit("SET_STATUS_UMSS_LOADED");
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          commit("SET_STATUS_UMSS_ERROR");
          reject(err);
        });
    });
  },
  CREATE_UMS: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_UMS_LOADING");
      axios
        .post("/api/adminusermembership", payload)
        .then((response) => {
          store.dispatch("notifStore/successAdded", "User membership added.");
          store.dispatch("adminUserMembership/LOAD_UMSS_DATA", {
            GroupID: payload.GroupID,
          });
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_UMS_ERROR");
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          reject(err);
        });
    });
  },
  DELETE_UMS({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_UMS_LOADING");
      axios
        .post("/api/adminusermembership/delete/" + payload.UserMembershipID)
        .then((response) => {
          store.dispatch(
            "notifStore/successDeleted",
            "User membership deleted."
          );
          store.dispatch("adminUserMembership/LOAD_UMSS_DATA", {
            GroupID: payload.GroupID,
          });
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          reject(err);
        });
    });
  },
  CREATE_UMS_FROM_USER: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_UMS_LOADING");
      axios
        .post("/api/adminusermembership", payload)
        .then((response) => {
          store.dispatch("notifStore/successAdded", "User membership added.");
          store.dispatch("adminUserMembership/LOAD_UMSSFORUSER_DATA", {
            UserNr: payload.UserID,
          });
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_UMS_ERROR");
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          reject(err);
        });
    });
  },
  DELETE_UMS_FROM_USER({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_UMS_LOADING");
      axios
        .post("/api/adminusermembership/delete/" + payload.UserMembershipID)
        .then((response) => {
          store.dispatch(
            "notifStore/successDeleted",
            "User membership deleted."
          );
          store.dispatch("adminUserMembership/LOAD_UMSSFORUSER_DATA", {
            UserNr: payload.UserID,
          });
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          reject(err);
        });
    });
  },
  CLEAR_STORE({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CLEAR_DATA");
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
