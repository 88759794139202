<template>
  <div>
    <div id="app" class="main-wrapper">
      <page-header v-if="this.$route.meta.showPageHeader" />
      <div class="page">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
// import '../local_modules/sdx/dist/js/sdx.js'
import PageHeader from "@/components/navigation/PageHeader";
import { mapGetters } from "vuex";
export default {
  name: "App",
  computed: {
    theme: {
      cache: false,
      get() {
        return this.$store.getters.theme();
      },
    },
  },
  mounted() {
    // check for active theme
    let htmlElement = document.documentElement;
    if (this.theme == "light") {
      htmlElement.setAttribute("theme", "light");
    } else {
      htmlElement.setAttribute("theme", "dark");
    }
  },
  components: {
    PageHeader,
  },
};
</script>
