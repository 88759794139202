import axios from "axios";
import store from "..";

const state = {
  BMSsData: [],
  statusBMSs: "",
  statusBMS: "",
};

const getters = {
  isBMSsLoading() {
    return state.statusBMSs === "loading";
  },
  isBMSsLoaded() {
    return state.statusBMSs === "loaded";
  },
  BMSsData() {
    return state.BMSsData;
  },
  isBMSLoading() {
    return state.statusBMS === "loading";
  },
  isBMSLoaded() {
    return state.statusBMS === "loaded";
  },
};

const mutations = {
  SET_BMSS_DATA(state, data) {
    state.BMSsData = data;
  },
  SET_STATUS_BMSS_LOADING(state) {
    state.statusBMSs = "loading";
  },
  SET_STATUS_BMSS_LOADED(state) {
    state.statusBMSs = "loaded";
  },
  SET_STATUS_BMSS_ERROR(state) {
    state.statusBMSs = "error";
  },
  SET_STATUS_BMS_LOADING(state) {
    state.statusBMS = "loading";
  },
  SET_STATUS_BMS_LOADED(state) {
    state.statusBMS = "loaded";
  },
  SET_STATUS_BMS_ERROR(state) {
    state.statusBMS = "error";
  },
  CLEAR_DATA(state) {
    state.BMSsData = [];
    state.statusBMSs = "";
    state.statusBMS = "";
  },
};

const actions = {
  LOAD_BMSSFORBOOK_DATA({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_BMSS_LOADING");
      axios
        .get("/api/adminbookmembership/allforbook/" + payload.BookID)
        .then((response) => {
          commit("SET_BMSS_DATA", response.data);
          commit("SET_STATUS_BMSS_LOADED");
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          commit("SET_STATUS_BMSS_ERROR");
          reject(err);
        });
    });
  },
  LOAD_BMSS_DATA({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_BMSS_LOADING");
      axios
        .get("/api/adminbookmembership/all/" + payload.GroupID)
        .then((response) => {
          commit("SET_BMSS_DATA", response.data);
          commit("SET_STATUS_BMSS_LOADED");
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          commit("SET_STATUS_BMSS_ERROR");
          reject(err);
        });
    });
  },
  CREATE_BMS: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_BMS_LOADING");
      axios
        .post("/api/adminbookmembership", payload)
        .then((response) => {
          store.dispatch("notifStore/successAdded", "Book membership added.");
          store.dispatch("adminBookMembership/LOAD_BMSS_DATA", {
            GroupID: payload.GroupID,
          });
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_BMS_ERROR");
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          reject(err);
        });
    });
  },
  DELETE_BMS({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_BMS_LOADING");
      axios
        .post("/api/adminbookmembership/delete/" + payload.BookMembershipID)
        .then((response) => {
          store.dispatch(
            "notifStore/successDeleted",
            "Book membership deleted."
          );
          store.dispatch("adminBookMembership/LOAD_BMSS_DATA", {
            GroupID: payload.GroupID,
          });
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          reject(err);
        });
    });
  },
  CREATE_BMS_FROM_BOOK: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_BMS_LOADING");
      axios
        .post("/api/adminbookmembership", payload)
        .then((response) => {
          store.dispatch("notifStore/successAdded", "Book membership added.");
          store.dispatch("adminBookMembership/LOAD_BMSSFORBOOK_DATA", {
            BookID: payload.BookID,
          });
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_BMS_ERROR");
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          reject(err);
        });
    });
  },
  DELETE_BMS_FROM_BOOK({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_BMS_LOADING");
      axios
        .post("/api/adminbookmembership/delete/" + payload.BookMembershipID)
        .then((response) => {
          store.dispatch(
            "notifStore/successDeleted",
            "Book membership deleted."
          );
          store.dispatch("adminBookMembership/LOAD_BMSSFORBOOK_DATA", {
            BookID: payload.BookID,
          });
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          reject(err);
        });
    });
  },
  CLEAR_STORE({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CLEAR_DATA");
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
