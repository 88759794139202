<template>
  <div
    class="qsui-admin-subform-row"
    :class="[valueProxy.isLegal ? 'qsui-admin-subform-row-principle' : '']"
  >
    <div class="input-field">
      <!-- <i
        class="icon icon-008-bin wvfilter-icon"
        :class="[
          valueProxy.isLegal ? 'icon-064-record-filled' : 'icon-063-record',
        ]"
        :title="
          valueProxy.isLegal
            ? 'this is the Legal Process'
            : 'not the Legal Process, click to change'
        "
        aria-hidden="true"
        @click="onclickLegal()"
      ></i> -->
      <i
        class="icon icon-008-bin wvfilter-icon wvfilter-icon-disabled"
        :class="[
          valueProxy.isLegal ? 'icon-064-record-filled' : 'icon-063-record',
        ]"
        :title="
          valueProxy.isLegal
            ? 'this is the Legal Process'
            : 'not the Legal Process'
        "
        aria-hidden="true"
      ></i>
    </div>
    <div class="input-field">
      <i
        class="icon icon-008-bin wvfilter-icon wvfilter-red-button"
        title="Remove VaR Process"
        aria-hidden="true"
        @click="onDelete"
      ></i>
      <!-- <i
        class="icon icon-008-bin wvfilter-icon wvfilter-icon-disabled"
        title="Remove VaR Process"
        aria-hidden="true"
      ></i> -->
    </div>
    <!-- <sdx-textbox
      class="input-field-medium"
      label="VarProcess name"
      title="Name"
      type="text"
      :showLabel="true"
      :regex="config.regex.notEmpty"
      v-model="valueProxy.name"
    /> -->
    <readonly-ersatz
      class="input-field-medium"
      label="VarProcess name"
      title="Name"
      :value="valueProxy.name.value"
    />
    <!-- <sdx-textbox
      class="input-field-large"
      label="Description"
      title="Beschreibung"
      type="text"
      :showLabel="true"
      :regex="config.regex.notEmpty"
      v-model="valueProxy.description"
    /> -->
    <readonly-ersatz
      class="input-field-large"
      label="Description"
      title="Beschreibung"
      :value="valueProxy.description.value"
    />

    <!-- <select-generic
      class="input-field-medium"
      label="VaR Method"
      title="VaR Methode"
      :options="['VCV', 'MC_NORMAL', 'MC_STUDENT']"
      v-model="valueProxy.method"
    /> -->
    <readonly-ersatz
      class="input-field-medium"
      label="VaR Method"
      title="VaR Methode"
      :value="valueProxy.method"
    />

    <!-- <sdx-textbox
      class="input-field-small"
      :disabled="!is_mc_student"
      label="Deg. Freedom"
      title="Anzahl Freiheitsgrade"
      type="text"
      :showLabel="true"
      :regex="config.regex.numberInputOrEmpty"
      v-model="valueProxy.degreesFreedom"
    /> -->
    <readonly-ersatz
      class="input-field-small"
      label="Deg. Freedom"
      title="Anzahl Freiheitsgrade"
      :value="valueProxy.degreesFreedom.value"
    />

    <!-- <sdx-textbox
      class="input-field-small"
      label="Conf. lvl"
      title="Konfidenzniveau"
      type="text"
      :showLabel="true"
      :regex="config.regex.decimal"
      v-model="valueProxy.confidenceLevel"
    /> -->
    <readonly-ersatz
      class="input-field-small"
      label="Conf. lvl"
      title="Konfidenzniveau"
      :value="valueProxy.confidenceLevel.value"
    />

    <!-- <sdx-textbox
      class="input-field-small"
      label="Horizon"
      title="Haltedauer"
      type="text"
      :showLabel="true"
      :regex="config.regex.decimal"
      v-model="valueProxy.holdingPeriod"
    /> -->
    <readonly-ersatz
      class="input-field-small"
      label="Horizon"
      title="Haltedauer"
      :value="valueProxy.holdingPeriod.value"
    />

    <select-confmap
      class="input-field-medium"
      label="Riskclass MarketRisk"
      title="Risikoklasse Marktpreisrisiko"
      map_type="riskclass_marketrisk"
      v-model="valueProxy.riskclass_marketrisk"
    />
    <div class="input-field">
      <i
        class="icon icon-060-play-filled wvfilter-icon"
        title="Calculate and fill Limits (abs, rel and stress) based on Adm.Ctry, LawType and Riskclass MarketRisk"
        aria-hidden="true"
        @click="setLimits"
      ></i>
    </div>
    <sdx-textbox
      class="input-field-medium"
      label="Market Stress Limit"
      title="Stresstest-Limit Marktpreis"
      type="text"
      :showLabel="true"
      :regex="config.regex.decimal"
      v-model="valueProxy.stresstest_limit"
    />
    <!-- <sdx-textbox
      class="input-field-small"
      label="Abs. limit(%)"
      title="Absolutes Limit "
      type="text"
      :showLabel="true"
      :regex="config.regex.decimal"
      v-model="valueProxy.limitAbs"
    /> -->
    <readonly-ersatz
      class="input-field-small"
      label="Abs. limit(%)"
      title="Absolutes Limit "
      :value="valueProxy.limitAbs.value"
    />
    <!-- <sdx-textbox
      class="input-field-small"
      label="Rel. limit(%)"
      title="Relatives Limit"
      type="text"
      :showLabel="true"
      :regex="config.regex.decimal"
      v-model="valueProxy.limitRel"
    /> -->
    <readonly-ersatz
      class="input-field-small"
      label="Rel. limit(%)"
      title="Relatives Limit"
      :value="valueProxy.limitRel.value"
    />
    <sdx-textbox
      class="input-field-small"
      label="VaR Factor"
      title="VaR Faktor"
      type="text"
      :showLabel="true"
      :regex="config.regex.decimal"
      v-model="valueProxy.var_factor"
    />
    <!-- <readonly-ersatz
      class="input-field-small"
      label="VaR Factor"
      title="VaR Faktor"
      :value="valueProxy.var_factor.value"
    /> -->
    <!-- <select-generic
      class="input-field-large"
      label="Benchmark"
      :regex="config.regex.notEmpty"
      :options="riskBenchmarksData"
      v-model="valueProxy.riskBenchmark"
    /> -->
    <readonly-ersatz
      class="input-field-large"
      label="Benchmark"
      :value="valueProxy.riskBenchmark"
    />
    <bm-list :bmName="valueProxy.riskBenchmark" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SDXTextbox from "@/components/ui_utilities/SDXTextbox";
import bmIndexListVue from "./bmIndexList.vue";
import config from "@/data/config";
import selectGenericVue from "./inputForm/selectGeneric.vue";
import selectConfMapVue from "./inputForm/selectConfMap.vue";
import readonlyErsatzVue from "./inputForm/readonlyErsatz.vue";
import { getLimit_marketrisk, getValV } from "./adminUtils";
export default {
  data() {
    return {
      config: config,
      // cache_riskclass_marketrisk: -1,
      // cache_admission_country: "unknown",
      // cache_law_type: -1,
    };
  },
  watch: {},
  computed: {
    ...mapGetters({
      riskBenchmarksData: "fmdRiskBenchmark/riskBenchmarksData",
    }),
    valueProxy: {
      get() {
        //becomming data from props
        return this.varProcess;
      },
      set(input) {
        //becomming data from treeselect and emit
        this.$emit("input", input);
      },
    },
    is_mc_student() {
      return this.valueProxy.method == "MC_STUDENT";
    },
  },
  methods: {
    setLimits() {
      let limits = getLimit_marketrisk(
        this.admission_country,
        this.law_type,
        this.varProcess.riskclass_marketrisk
      );
      // commented out as limitRel and LimitAbs
      // this.varProcess.limitRel = getValV(limits.rel_limit);
      // this.varProcess.limitAbs = getValV(limits.abs_limit);
      this.varProcess.stresstest_limit = getValV(limits.stress_limit);
    },
    onclickLegal() {
      this.$emit("isLegal", true);
    },
    onDelete() {
      this.$emit("delete", this.valueProxy.id);
    },
  },
  props: {
    varProcess: {
      type: Object,
    },
    admission_country: {
      type: String,
    },
    law_type: {
      type: Number,
    },
  },
  components: {
    "sdx-textbox": SDXTextbox,
    "select-generic": selectGenericVue,
    "bm-list": bmIndexListVue,
    "select-confmap": selectConfMapVue,
    "readonly-ersatz": readonlyErsatzVue,
  },
};
</script>
