<template>
  <div class="row StandardView">
    <users-list :isOpen="filterIsOpen" />
    <toggle-button v-model="filterIsOpen" />
    <userForm />
  </div>
</template>

<script>
import usersList from "@/components/admin/usersList";
import userForm from "@/components/admin/userForm";
import ToggleButton from "@/components/ui_utilities/ToggleButton";

export default {
  data() {
    return {
      filterIsOpen: true,
    };
  },
  components: {
    usersList,
    userForm,
    ToggleButton,
  },
};
</script>
