<template>
  <div class="exprequest-banner-select-format">
    <treeselect v-model="value" :options="selectOptions" :clearable="false" />
    <!-- <input type="text" v-bind:placeholder=" 'plz input a ' + col.ColType " v-bind:id="inputId"> -->
  </div>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
export default {
  data() {
    return {
      value: this.selectOptions[0].id,
    };
  },
  watch: {
    value: function () {
      if (this.value) {
        this.$emit("input", this.value);
      }
    },
  },
  created() {
    this.$emit("input", this.value);
  },
  props: {
    selectOptions: {
      type: Array,
      default: function () {
        return [
          {
            id: "1",
            label: "xlsx",
          },
          {
            id: "0",
            label: "xml",
          },
        ];
      },
    },
  },
  components: {
    Treeselect,
  },
};
</script>

<style></style>
