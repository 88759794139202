<template>
  <div class="qsui-admin-container">
    <div class="qsui-admin-subform">
      <div class="qsui-admin-form col-lg-12 col-xl-10">
        <treeselect
          v-model="value"
          :options="test_types_options"
          :multiple="true"
        />
        <div class="qsui-admin-buttons">
          <a
            class="button button--primary"
            href="javascript:void(0);"
            @click="start_tests"
            >start selected test(s)</a
          >
        </div>
      </div>
      <div class="qsui-admin-subform">
        <div class="qxpgrid-container user-select-text">
          <table class="qxpgrid-table">
            <tbody>
              <tr class="head">
                <th v-for="col in cols" :key="col">{{ col }}</th>
              </tr>
              <tr v-for="row in tests_odata_results_sorted">
                <td v-for="col in cols" :key="col" :class="{ nowrap: false }">{{
                  row[col]
                }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapGetters } from "vuex";
import Treeselect from "@riophae/vue-treeselect";
import { reg_getQueryOption_FilterString } from "./wv_filter/WvQueryOption";
export default {
  data() {
    return {
      cols: ["source", "response", "ts", "wvname", "filter", "orderby"],
      value: [],
      tests_odata_results: [],
    };
  },
  created() {
    document.title = "Admin>BackendTest";
    this.$store.dispatch("samplerequests/LOAD");
  },
  computed: {
    ...mapGetters({
      odata_requests: "samplerequests/data",
    }),
    test_types() {
      let ret = [];
      this.odata_requests.forEach((element) => {
        if (!ret.includes(element.test_type)) {
          ret.push(element.test_type);
        }
      });
      return ret;
      s;
    },
    test_types_options() {
      let ret = [];
      this.test_types.forEach((element) => {
        ret.push({ id: element, label: element });
      });
      return ret;
    },
    tests_odata_results_sorted() {
      return this.tests_odata_results.sort((a, b) => {
        if (a.source == b.source) {
          return a.response - b.response;
        } else {
          return a.source - b.source;
        }
      });
    },
  },
  methods: {
    start_tests() {
      this.tests_odata_results = [];
      this.odata_requests.forEach((element) => {
        if (this.value.includes(element.test_type)) {
          this.test_get2(element, element.test_type);
        }
      });
    },
    test_get2(qq, source) {
      var axiosRequestParams = {
        url: "/api/wview/" + qq.wvname,
        requestConfig: {
          params: {
            $filter: qq.filter,
            $orderby: qq.orderby,
            toprows: qq.toprows,
            byPassIsRequest: true,
          },
        },
      };
      axios
        .get(axiosRequestParams.url, axiosRequestParams.requestConfig)
        .then((response) => {
          console.log(response);
          this.tests_odata_results.push({
            wvname: qq.wvname,
            filter: qq.filter,
            orderby: qq.orderby,
            response: true,
            source: source,
            ts: moment().toISOString(),
          });
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status == 500) {
              this.tests_odata_results.push({
                wvname: qq.wvname,
                filter: qq.filter,
                orderby: qq.orderby,
                response: true,
                source: source,
                ts: moment().toISOString(),
              });
            } else {
              this.tests_odata_results.push({
                wvname: qq.wvname,
                filter: qq.filter,
                orderby: qq.orderby,
                response: true,
                source: source,
                ts: moment().toISOString(),
              });
            }
          } else {
            this.tests_odata_results.push({
              wvname: qq.wvname,
              filter: qq.filter,
              orderby: qq.orderby,
              response: false,
              source: source,
              ts: moment().toISOString(),
            });
          }
        });
    },
  },
  components: {
    Treeselect,
  },
};
</script>
