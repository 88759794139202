import axios from "axios";
import store from ".";
import router from "../router";

export default function setup() {
  const myApiBaseURL = process.env.VUE_APP_ROUTER_BASE; //'https://iprisk.ipconcept.com/u_QSAPI'
  axios.defaults.baseURL = myApiBaseURL;
  axios.interceptors.request.use(
    function (config) {
      if (config.baseURL === myApiBaseURL) {
        if (
          !config.headers.Authorization &&
          !config.url.includes("/api/users/token") //do not send token if aksing for a new one
        ) {
          // console.log('request intercepted from interceptors')
          const token = sessionStorage.getItem("user-token");
          if (token) {
            config.headers.Authorization = `Bearer ${token}`;
          }
        }
        config.headers.common.QSUI = "qsui";
      }
      return config;
    },
    function (err) {
      return Promise.reject(err);
    }
  );
  axios.interceptors.response.use(undefined, function (err) {
    return new Promise(function (resolve, reject) {
      // console.log('response intercepted from interceptors')
      if (
        err.response &&
        err.response.status === 401 &&
        err.config &&
        !err.config.__isRetryRequest
      ) {
        // if you ever get an unauthorized, logout the user
        // you can also redirect to /login if needed !
        if (err.config.url.includes("/api/users/token")) {
          sessionStorage.removeItem("user-token");
          store.dispatch("AUTH_LOGOUT");
          throw err;
        } else if (err.config.url.includes("/api/navigation")) {
          // console.log("ignore navigation 401");
        } else {
          if (store.getters.isTokenExpired()) {
            store.dispatch(
              "notifStore/alert",
              "Token expired - please login again"
            );
          } else if (err && err.response && err.response.statusText) {
            store.dispatch(
              "notifStore/alert",
              err.response.statusText + " - try to login again"
            );
          } else {
            store.dispatch(
              "notifStore/alert",
              "Unauthorized, try to login again"
            );
          }
          router.push({
            name: "Login",
          });
          throw err;
        }
      } else if (
        err.request.responseType === "blob" &&
        err.response.data instanceof Blob &&
        err.response.data.type &&
        err.response.data.type.toLowerCase().indexOf("json") != -1
      ) {
        let reader = new FileReader();
        reader.onload = () => {
          err.response.data = JSON.parse(reader.result);
          resolve(Promise.reject(err));
        };
        reader.readAsText(err.response.data);
        throw err;
      } else {
        throw err;
      }
    });
  });
}
