import axios from "axios";
import store from "..";

const state = {
  booksData: [],
  statusBooks: "",
  bookData: {},
  statusBook: "",
};

const getters = {
  isBooksLoading() {
    return state.statusBooks === "loading";
  },
  isBooksLoaded() {
    return state.statusBooks === "loaded";
  },
  booksData() {
    return state.booksData;
  },
  isBookLoading() {
    return state.statusBook === "loading";
  },
  isBookLoaded() {
    return state.statusBook === "loaded";
  },
  bookData() {
    return state.bookData;
  },
};

const mutations = {
  SET_BOOKS_DATA(state, data) {
    data.forEach((elem) => {
      elem.display = `Q${elem.BookID}, ${elem.Name}, ${elem.ExternalID}`;
    });
    state.booksData = data;
  },
  SET_STATUS_BOOKS_LOADING(state) {
    state.statusBooks = "loading";
  },
  SET_STATUS_BOOKS_LOADED(state) {
    state.statusBooks = "loaded";
  },
  SET_STATUS_BOOKS_ERROR(state) {
    state.statusBooks = "error";
  },
  SET_BOOK_DATA(state, data) {
    data.display = `${data.Name}, ${data.ExternalID}`;
    state.bookData = data;
  },
  SET_STATUS_BOOK_LOADING(state) {
    state.statusBook = "loading";
  },
  SET_STATUS_BOOK_LOADED(state) {
    state.statusBook = "loaded";
  },
  SET_STATUS_BOOK_ERROR(state) {
    state.statusBook = "error";
  },
  CLEAR_DATA(state) {
    state.booksData = [];
    state.statusBooks = "";
    state.bookData = [];
    state.statusBook = "";
  },
  CLEAR_BOOK_DATA(state) {
    state.bookData = [];
    state.statusBook = "";
  },
};

const actions = {
  CREATE_BOOK: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("CLEAR_BOOK_DATA");
      commit("SET_STATUS_BOOK_LOADING");
      axios
        .post("/api/adminbook", payload)
        .then((response) => {
          commit("SET_BOOK_DATA", response.data);
          commit("SET_STATUS_BOOK_LOADED");
          store.dispatch("adminBook/LOAD_BOOKS_DATA");
          store.dispatch("notifStore/successCreated", "Book created.");
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_BOOK_ERROR");
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          reject(err);
        });
    });
  },
  MODIFY_BOOK: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("CLEAR_BOOK_DATA");
      commit("SET_STATUS_BOOK_LOADING");
      axios
        .post("/api/adminbook/modify", payload)
        .then((response) => {
          commit("SET_BOOK_DATA", response.data);
          commit("SET_STATUS_BOOK_LOADED");
          store.dispatch("adminBook/LOAD_BOOKS_DATA");
          store.dispatch("notifStore/successModified", "Book modified.");
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_BOOK_ERROR");
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          reject(err);
        });
    });
  },
  LOAD_BOOKS_DATA({ commit }) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_BOOKS_LOADING");
      axios
        .get("/api/adminbook")
        .then((response) => {
          commit("SET_BOOKS_DATA", response.data);
          commit("SET_STATUS_BOOKS_LOADED");
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          commit("SET_STATUS_BOOKS_ERROR");
          reject(err);
        });
    });
  },
  LOAD_BOOK_DATA({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_BOOK_LOADING");
      commit("CLEAR_BOOK_DATA");
      axios
        .get("/api/adminbook/" + payload.BookID)
        .then((response) => {
          commit("SET_BOOK_DATA", response.data);
          commit("SET_STATUS_BOOK_LOADED");
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          commit("SET_STATUS_BOOK_ERROR");
          reject(err);
        });
    });
  },
  DELETE_BOOK({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("CLEAR_BOOK_DATA");
      axios
        .post("/api/adminbook/delete/" + payload.BookID)
        .then((response) => {
          store.dispatch("notifStore/successDeleted", "Book deleted.");
          store.dispatch("adminBook/LOAD_BOOKS_DATA");
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          reject(err);
        });
    });
  },
  CLEAR_STORE({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CLEAR_DATA");
    });
  },
  CLEAR_BOOK({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CLEAR_BOOK_DATA");
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
