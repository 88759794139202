<template></template>

<script>
export default {
  created() {
    // localStorage.removeItem('user-token')
    this.$store.dispatch("AUTH_LOGOUT");
    this.$router.push("/");
  },
};
</script>
