import moment from "moment";
export function getCellAlign(col) {
  switch (col.ColType) {
    case "name":
    case "text":
    case "date":
    case "logdate":
      return "left";
    case "number":
    case "perc":
    case "percmath":
      return "right";
    case "bool":
      return "center";
  }
}
export function getCellNowrap(col) {
  switch (col.ColType) {
    case "logdate":
      return true;
    default:
      return false;
  }
}

export function getCellValue(item, col) {
  switch (col.ColType) {
    case "name":
    case "text":
      if (!item || !col || item[col.SqlColName] == undefined) return "-";
      return item[col.SqlColName];
    case "date":
      if (!item || !col || item[col.SqlColName] == undefined) return "-";
      var momentValue = moment(item[col.SqlColName]);
      if (momentValue.isValid()) {
        return momentValue.format("DD.MM.YYYY");
      } else return "-";
    case "logdate":
      if (!item || !col || item[col.SqlColName] == undefined) return "-";
      var momentValue = moment(item[col.SqlColName]);
      if (momentValue.isValid()) {
        return momentValue.format("DD.MM.YYYY HH:mm:ss");
      } else return "-";
    case "number":
      if (!item || !col || item[col.SqlColName] == undefined) return "-";
      var decimal = 0;
      if (col.Decimal) {
        decimal = col.Decimal;
      }
      return Number(item[col.SqlColName]).toLocaleString(undefined, {
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal,
      });
    case "perc":
    case "percmath":
      if (!item || !col || item[col.SqlColName] == undefined) return "-";
      var decimal = 0;
      if (col.Decimal) {
        decimal = col.Decimal;
      }
      var factor = col.ColType == "percmath" ? 100 : 1;
      return (
        (Number(item[col.SqlColName]) * factor).toLocaleString(undefined, {
          minimumFractionDigits: decimal,
          maximumFractionDigits: decimal,
        }) + "%"
      );
    case "bool":
      if (!item || !col || item[col.SqlColName] == undefined) {
        return "0";
      } else {
        return item[col.SqlColName];
      }
    // return item[col.SqlColName];
  }
}
export function getCellRawValue(item, col) {
  if (!item || !col || item[col.SqlColName] == undefined) {
    return null;
  }
  return item[col.SqlColName];
}

export function reg_getCellValue(item, col) {
  switch (col.ColType) {
    case "name":
    case "text":
      if (!item || !col || item[col.SqlColName] == undefined) return "-";
      return item[col.SqlColName];
    case "date":
      if (!item || !col || item[col.SqlColName] == undefined) return "-";
      var momentValue = moment(item[col.SqlColName]);
      if (momentValue.isValid()) {
        return momentValue.format("DD.MM.YYYY");
      } else return "-";
    case "logdate":
      if (!item || !col || item[col.SqlColName] == undefined) return "-";
      var momentValue = moment(item[col.SqlColName]);
      if (momentValue.isValid()) {
        return momentValue.format("DD.MM.YYYY HH:mm:ss");
      } else return "-";
    case "number":
      if (!item || !col || item[col.SqlColName] == undefined) return "-";
      var decimal = 0;
      if (col.Decimal) {
        decimal = col.Decimal;
      }
      if (col.regType == 3) {
        return Number(item[col.SqlColName] * 100).toLocaleString(undefined, {
          minimumFractionDigits: decimal,
          maximumFractionDigits: decimal,
        });
      }
      return Number(item[col.SqlColName]).toLocaleString(undefined, {
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal,
      });
    case "perc":
    case "percmath":
      if (!item || !col || item[col.SqlColName] == undefined) return "-";
      var decimal = 0;
      if (col.Decimal) {
        decimal = col.Decimal;
      }
      if (col.regType == 3) {
        return Number(item[col.SqlColName] * 100).toLocaleString(undefined, {
          minimumFractionDigits: decimal,
          maximumFractionDigits: decimal,
        });
      }
      var factor = col.ColType == "percmath" ? 100 : 1;
      return (
        (Number(item[col.SqlColName]) * 100).toLocaleString(undefined, {
          minimumFractionDigits: decimal,
          maximumFractionDigits: decimal,
        }) + "%"
      );
    case "bool":
      if (!item || !col || item[col.SqlColName] == undefined) {
        return "0";
      } else if (item[col.SqlColName]) {
        {
          return "1";
        }
      }
  }
}
