<template>
  <div class="row StandardView">
    <w-view-report-request :isOpen="filterIsOpen" />
    <toggle-button v-model="filterIsOpen" />
    <pfrr-grid-and-banners />
  </div>
</template>

<script>
import WViewReportRequest from "@/components/wv_filter/WViewReportRequest";
import pfrrGridAndBanners from "@/components/datagrid/pfrrGridAndBanners.vue";
import ToggleButton from "@/components/ui_utilities/ToggleButton";

export default {
  data() {
    return {
      filterIsOpen: true,
    };
  },
  components: {
    WViewReportRequest,
    pfrrGridAndBanners,
    ToggleButton,
  },
  computed: {},
};
</script>
