<template>
  <div class="input-field text" :class="{ invalid: invalid }">{{
    displayText
  }}</div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  computed: {
    ...mapGetters({
      usersData: "adminUser/usersData",
      isUsersLoaded: "adminUser/isUsersLoaded",
    }),
    invalid() {
      if (!this.UserMod && !this.lastChanged) return true;
    },
    displayText() {
      var userName = "";
      if (!this.UserMod && !this.lastChanged) {
        return "No FundMasterData found for this admin book, please edit fund_status and save";
      }
      if (this.isUsersLoaded) {
        const found = this.usersData.find(
          (element) => element.UserNr == this.UserMod
        );
        if (!!found) {
          userName = found.display;
        }
      }
      if (!userName) {
        userName = this.UserMod == 0 ? "unknow/import" : this.UserMod;
      }
      //using a copy to set locale (don't mess up moment globally)
      var localMoment = moment(this.lastChanged);
      var navigatorlocale =
        window.navigator.userLanguage || window.navigator.language;
      localMoment.locale(navigatorlocale);
      return `${
        this.objectName
      } last modified by ${userName}, ${localMoment.format("lll")}`;
    },
  },
  created() {
    if (!this.isUsersLoaded) {
      this.$store.dispatch("adminUser/LOAD_DATA_USERS");
    }
  },
  props: {
    UserMod: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    lastChanged: {},
    objectName: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
};
</script>
