<template>
  <div class="acc-flex-crit-string-single">
    <treeselect
      class="select-operator"
      v-model="operator"
      :options="operators"
      :clearable="false"
    />
    <input
      type="text"
      class="input-field input-text"
      spellcheck="false"
      :title="textBoxTitle"
      :disabled="istextBoxDisabled"
      :placeholder="textBoxLabel"
      v-model="proxyValue"
      @keyup.enter="$emit('onEnterKey')"
    />
    <i
      v-if="showAndOrButton"
      class="icon wvfilter-icon wvfilter-green-button wvfilter-text-button"
      :title="andOrTitle"
      aria-hidden="true"
      @click="$emit('toggleAndOr')"
      >{{ charAndOr }}</i
    >
    <i
      v-else
      class="icon icon-061-plus wvfilter-icon"
      :class="[
        { placeonly: !crit.isLast },
        { 'wvfilter-icon-disabled': huCol.ColType == 'bool' },
      ]"
      title="Add filter criteria"
      aria-hidden="true"
      @click="$emit('addCrit')"
    ></i>
  </div>
</template>

<script>
import SDXTextbox from "@/components/ui_utilities/SDXTextbox";
import Treeselect from "@riophae/vue-treeselect";
export default {
  data() {
    return {
      equalOnlyOperators: [
        {
          id: "=",
          label: "=",
        },
      ],
      baseOperators: [
        {
          id: "=",
          label: "=",
        },
        {
          id: "%",
          label: "%",
        },
        {
          id: "!%",
          label: "!%",
        },
        {
          id: "n",
          label: "N",
        },
        {
          id: "e",
          label: "E",
        },
      ],
      extendedOperators: [
        {
          id: "=",
          label: "=",
        },
        {
          id: ">=",
          label: ">=",
        },
        {
          id: "<=",
          label: "<=",
        },
        {
          id: "%",
          label: "%",
        },
        {
          id: "!%",
          label: "!%",
        },
        {
          id: "n",
          label: "N",
        },
        {
          id: "e",
          label: "E",
        },
      ],
    };
  },
  mounted() {
    //Default Value if specified in colForSearch (only for first crit)
    if (this.huCol.SearchDefault && this.crit.key == 0) {
      let crit = { ...this.crit };
      if (this.huCol.SearchDefault.toLowerCase() == "currentuser") {
        crit.value = this.$store.getters.UserNr();
        crit.operator = "=";
      } else if (this.huCol.SearchDefault.toLowerCase().startsWith("like")) {
        crit.value = this.huCol.SearchDefault.toLowerCase().replace("like", "");
        crit.operator = "%";
      } else if (this.huCol.SearchDefault.toLowerCase().startsWith("notlike")) {
        crit.value = this.huCol.SearchDefault.toLowerCase().replace(
          "notlike",
          ""
        );
        crit.operator = "!%";
      } else {
        crit.value = this.huCol.SearchDefault;
        crit.operator = "=";
      }
      this.$emit("input", crit);
    }
  },
  computed: {
    proxyValue: {
      get() {
        return this.crit.value;
      },
      set(value) {
        let crit = { ...this.crit };
        crit.value = value;
        this.$emit("input", crit);
      },
    },
    operator: {
      get() {
        return this.crit.operator;
      },
      set(value) {
        let crit = { ...this.crit };
        crit.operator = value;
        if (crit.operator == "n" || crit.operator == "e") {
          crit.value = "";
        }
        this.$emit("input", crit);
      },
    },
    operators() {
      if (this.showExtendedOperator) {
        return this.extendedOperators;
      } else if (this.huCol.ColType == "bool") {
        return this.equalOnlyOperators;
      }
      return this.baseOperators;
    },
    charAndOr() {
      return this.isOr ? "|" : "&";
    },
    showAndOrButton() {
      return !this.crit.isLast && this.crit.key == 0;
    },
    textBoxLabel() {
      if (this.crit.operator == "n") {
        return "is null";
      } else if (this.crit.operator == "e") {
        return "is empty";
      }
      // return this.huCol.DisplayName
    },
    textBoxTitle() {
      var ret = `string filter: ${this.huCol.DisplayName} `;
      if (this.crit.operator == "n") {
        return ret + "is null";
      } else if (this.crit.operator == "e") {
        return ret + "is empty";
      } else {
        if (!!this.proxyValue) {
          if (this.crit.operator == "=") {
            return ret + ` = "${this.proxyValue}"`;
          } else if (this.crit.operator == "%") {
            return ret + ` LIKE "${this.proxyValue}"`;
          } else if (this.crit.operator == "!%") {
            return ret + ` NOT LIKE "${this.proxyValue}"`;
          } else if (this.crit.operator == ">=") {
            return ret + ` >= "${this.proxyValue}"`;
          } else if (this.crit.operator == "<=") {
            return ret + ` <= "${this.proxyValue}"`;
          }
        }
        return "";
      }
      return "";
    },
    andOrTitle() {
      return this.isOr ? "OR" : "AND";
    },
    istextBoxDisabled() {
      if (this.crit.operator == "n" || this.crit.operator == "e") {
        return true;
      }
      return false;
    },
  },
  props: {
    huCol: {
      type: Object,
      default: function () {
        return {
          ColName: "DefaultValue",
          ColType: "name",
        };
      },
    },
    crit: {
      type: Object,
      default: function () {
        return {
          key: 0,
          isLast: true,
          operator: "=",
          value: undefined,
        };
      },
    },
    isOr: true,
    showExtendedOperator: false,
  },
  components: {
    "sdx-textbox": SDXTextbox,
    Treeselect,
  },
};
</script>
