import axios from "axios";
import store from "..";

const state = {
  usersData: [],
  statusUsers: "",
  personData: {},
  statusPerson: "",
};

const getters = {
  isUsersLoading() {
    return state.statusUsers === "loading";
  },
  isUsersLoaded() {
    return state.statusUsers === "loaded";
  },
  usersData() {
    return state.usersData;
  },
  isPersonLoading() {
    return state.statusPerson === "loading";
  },
  isPersonLoaded() {
    return state.statusPerson === "loaded";
  },
  personData() {
    return state.personData;
  },
};

const mutations = {
  SET_DATA_USERS(state, data) {
    data.forEach((user) => {
      user.display = `${user.Surname}, ${user.Firstname} (${user.Username})`;
    });
    state.usersData = data;
  },
  SET_STATUS_USERS_LOADING(state) {
    state.statusUsers = "loading";
  },
  SET_STATUS_USERS_LOADED(state) {
    state.statusUsers = "loaded";
  },
  SET_STATUS_USERS_ERROR(state) {
    state.statusUsers = "error";
  },
  SET_DATA_PERSON(state, data) {
    data.display = `${data.Surname}, ${data.Firstname} (${data.Username})`;
    state.personData = data;
  },
  SET_STATUS_PERSON_LOADING(state) {
    state.statusPerson = "loading";
  },
  SET_STATUS_PERSON_LOADED(state) {
    state.statusPerson = "loaded";
  },
  SET_STATUS_PERSON_ERROR(state) {
    state.statusPerson = "error";
  },
  CLEAR_DATA(state) {
    state.usersData = [];
    state.personData = [];
    state.statusPerson = "";
    state.statusUsers = "";
  },
  CLEAR_PERSON_DATA(state) {
    state.personData = [];
    state.statusPerson = "";
  },
};

const actions = {
  CREATE_NEW_PERSON: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("CLEAR_PERSON_DATA");
      commit("SET_STATUS_PERSON_LOADING");
      axios
        .post("/api/adminuser/person", payload)
        .then((response) => {
          commit("SET_DATA_PERSON", response.data);
          commit("SET_STATUS_PERSON_LOADED");
          store.dispatch("adminUser/LOAD_DATA_USERS");
          store.dispatch("notifStore/successCreated", "User created.");
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_PERSON_ERROR");
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          reject(err);
        });
    });
  },
  MODIFY_PERSON: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("CLEAR_PERSON_DATA");
      commit("SET_STATUS_PERSON_LOADING");
      axios
        .post("/api/adminuser/person/modify", payload)
        .then((response) => {
          commit("SET_DATA_PERSON", response.data);
          commit("SET_STATUS_PERSON_LOADED");
          store.dispatch("adminUser/LOAD_DATA_USERS");
          store.dispatch("notifStore/successModified", "User modified.");
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_PERSON_ERROR");
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          reject(err);
        });
    });
  },
  RESET_PASSWORD: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      // commit('CLEAR_PERSON_DATA')
      commit("SET_STATUS_PERSON_LOADING");
      axios
        .post("/api/adminuser/password", payload)
        .then((response) => {
          // commit('SET_DATA_PERSON', response.data)
          commit("SET_STATUS_PERSON_LOADED");
          store.dispatch("notifStore/success", "Password reset done.");
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_PERSON_ERROR");
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          reject(err);
        });
    });
  },
  LOAD_DATA_USERS({ commit }) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_USERS_LOADING");
      axios
        .get("/api/adminuser")
        .then((response) => {
          commit("SET_DATA_USERS", response.data);
          commit("SET_STATUS_USERS_LOADED");
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          commit("SET_STATUS_USERS_ERROR");
          reject(err);
        });
    });
  },
  LOAD_DATA_PERSON({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_PERSON_LOADING");
      commit("CLEAR_PERSON_DATA");
      axios
        .get("/api/adminuser/" + payload.UserNr)
        .then((response) => {
          commit("SET_DATA_PERSON", response.data);
          commit("SET_STATUS_PERSON_LOADED");
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          commit("SET_STATUS_PERSON_ERROR");
          reject(err);
        });
    });
  },
  DEACTIVATE_PERSON({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_PERSON_LOADING");
      commit("CLEAR_PERSON_DATA");
      axios
        .post("/api/adminuser/deactivate/" + payload.UserNr)
        .then((response) => {
          commit("SET_DATA_PERSON", response.data);
          commit("SET_STATUS_PERSON_LOADED");
          store.dispatch("notifStore/success", "User deactivated.");
          store.dispatch("adminUser/LOAD_DATA_USERS");
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          commit("SET_STATUS_PERSON_ERROR");
          reject(err);
        });
    });
  },
  REACTIVATE_PERSON({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_PERSON_LOADING");
      commit("CLEAR_PERSON_DATA");
      axios
        .post("/api/adminuser/reactivate/" + payload.UserNr)
        .then((response) => {
          commit("SET_DATA_PERSON", response.data);
          commit("SET_STATUS_PERSON_LOADED");
          store.dispatch("notifStore/success", "User re-activated.");
          store.dispatch("adminUser/LOAD_DATA_USERS");
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          commit("SET_STATUS_PERSON_ERROR");
          reject(err);
        });
    });
  },
  DELETE_PERSON({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("CLEAR_PERSON");
      axios
        .post("/api/adminuser/delete/" + payload.UserNr)
        .then((response) => {
          store.dispatch("notifStore/successDeleted", "User deleted.");
          store.dispatch("adminUser/LOAD_DATA_USERS");
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          reject(err);
        });
    });
  },
  CLEAR_ADMINUSER_STORE({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CLEAR_DATA");
    });
  },
  CLEAR_PERSON({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CLEAR_PERSON_DATA");
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
