<template>
  <td>
    <textarea
      class="qxgrid-corr qxgrid-corr-text"
      :class="{ 'qxgrid-corr-invalid': !isValid }"
      ref="myarea"
      v-model="valueData"
      placeholder="null"
      spellcheck="false"
      :style="{ height: elemHeight + 'px' }"
      :name="col.ColName"
      @keydown.up="$emit('focus', { direction: -1, target: $event.target })"
      @keydown.down="$emit('focus', { direction: 1, target: $event.target })"
      @keydown.esc="reset($event.target)"
      @change="onChange($event.target)"
    />
  </td>
</template>

<script>
import { getCellAlign, getCellValue } from "./QxpFunctions.js";
import config from "@/data/config";
import { validate } from "@/util/regex.js";

export default {
  data() {
    return {
      valueData: this.value,
      isValid: true,
      elemHeight: 20,
    };
  },
  watch: {
    valueData(newValue, oldValue) {
      if (!this.isInputWorthSending(newValue)) {
        this.isValid = true;
      } else if (this.isInputValid(newValue)) {
        this.isValid = true;
      } else {
        this.isValid = false;
      }
    },
    nowrap() {
      this.optimizedHeight();
    },
    value(value) {
      this.valueData = value;
    },
  },
  mounted() {
    this.optimizedHeight();
  },
  computed: {
    regexPattern() {
      return config.regex.textInput;
    },
  },
  methods: {
    getCellValue: getCellValue,
    getCellAlign: getCellAlign,
    optimizedHeight() {
      if (this.nowrap) {
        this.elemHeight = 20;
      } else if (!!this.valueData && !!this.$refs.myarea) {
        const textlen = this.valueData.length;
        const cwidth = this.$refs.myarea.clientWidth;
        const cplines = cwidth / 6;
        const lneeded = textlen / cplines;
        const lneededr = Math.round(lneeded + 1);
        // console.log(
        //   `text ${textlen}, cwidth/6 ${
        //     cwidth / 6
        //   }, lines ${lneeded}, needed ${lneededr}`
        // );
        this.elemHeight = Math.max(lneededr * 20, 20);
      }
    },
    onChange(target) {
      if (
        this.isInputWorthSending(this.valueData) &&
        this.isInputValid(this.valueData)
      ) {
        this.$emit("input", this.valueData);
        //reset to original value, making sure the value is not cached
        this.valueData = this.value;
        target.blur();
      }
    },
    reset(target) {
      this.valueData = this.value;
      target.blur();
    },
    isInputValid(value) {
      return this.validate(value, this.regexPattern);
    },
    isInputWorthSending(value) {
      return value != this.value && !!value;
    },
    validate: validate,
  },
  props: {
    value: [String, Number],
    col: Object,
    width: { type: Number, default: 200 },
    height: { type: Number, default: 20 },
    nowrap: { type: Boolean, default: false },
  },
};
</script>

<style></style>
