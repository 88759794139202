export function host(url) {
  const host = url.replace(/^https?:\/\//, "").replace(/\/.*$/, "");
  const parts = host.split(".").slice(-3);
  if (parts[0] === "www") parts.shift();
  return parts.join(".");
}

export function trim(text, n, useWordBoundary) {
  text = text ? text.toString() : "";
  if (text.length <= n) {
    return text;
  }
  let subString = text.substr(0, n - 1);

  return (
    (useWordBoundary
      ? subString.substr(0, subString.lastIndexOf(" "))
      : subString) + "..."
  );
}

export function trimHtml(text, n, useWordBoundary) {
  text = text ? text.toString().replace(/(<([^>]+)>)/gi, " ") : "";
  return trim(text, n, useWordBoundary);
}

export function trimStart(text, c) {
  text = text ? text.toString() : "";
  let s = 0;

  if (text.length > 0) {
    while (text[s] === c) {
      ++s;
    }
  }

  return text.substr(s);
}

export function trimFileExtension(text) {
  text = text ? text.toString() : "";
  let index = text.lastIndexOf(".");
  if (index > 0) {
    return text.substring(0, index);
  } else {
    return text;
  }
}

export function startsWith(text, searchString, position) {
  text = text ? text.toString() : "";
  position = position || 0;
  return text.indexOf(searchString, position) === position;
}

export function capital(text) {
  text = text ? text.toString() : "";
  return text.toUpperCase();
}

export function lower(text) {
  text = text ? text.toString() : "";
  return text.toLowerCase();
}

export function required(text) {
  return `* ${text}`;
}

export function toShortDate(date) {
  let d = new Date(date);
  if (isNaN(d.getTime())) {
    return "";
  }

  return `${d.getDate()}.${d.getMonth() + 1}`;
}

export function toLongDate(date) {
  let d = new Date(date);
  if (isNaN(d.getTime())) {
    return "";
  }

  return `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`;
}

export function toDateTime(date) {
  let d = new Date(date);
  if (isNaN(d.getTime())) {
    return "";
  }

  let minutes = d.getMinutes();
  if (minutes < 10) {
    minutes = "0" + minutes;
  }

  return `${d.getDate()}.${
    d.getMonth() + 1
  }.${d.getFullYear()} ${d.getHours()}:${minutes}`;
}

export function roundNumber(value, n) {
  let v = parseFloat(value);
  if (isNaN(v)) {
    return "N/A";
  } else {
    return v.toFixed(n);
  }
}

export function regexParam(regex, map) {
  let result = regex;

  Object.keys(map).forEach((key) => {
    result = result.replace(new RegExp(`<${key}>`, "g"), map[key]);
  });

  return result;
}

export function getResponsErrorData(err) {
  let ret = "";
  if (!!err && !!err.response) {
    ret += "resp:" + err.response.status;
    //Try to Use the Response Message
    if (!!err.response.data && !!err.response.data.Message) {
      ret += " " + err.response.data.Message;
    }
  }
  return ret;
}
