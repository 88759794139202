<template>
  <div class="qxpnav qxpnav-submenu">
    <div
      class="qxpnav qxpnav-submenu-header"
      @click="togglesShowContent(submenu.id)"
    >
      <i
        class="icon icon-013-chevron-down icon--s1"
        :class="{ 'icon--flip-vertical': showContent }"
      ></i>
      <div>{{ submenu.name }}</div>
    </div>
    <div v-if="showContent" class="qxpnav qxpnav-submenu-content">
      <div
        v-for="link in submenu.Links"
        :key="link.id"
        class="qxpnav qxpnav-link"
      >
        <router-link
          v-if="isRouterLink(link)"
          class="nav-link"
          :to="getRouterTo(link)"
        >
          <span class="text-underline">{{ link.name }}</span>
        </router-link>
        <a v-else class="nav-link" @click.stop="callLinkNoRouter(link)">
          <!-- <a> -->
          <span class="text-underline">{{ link.name }}</span>
          <!-- </a> -->
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getRouteRootFromLink,
  getUrlFromLink,
} from "@/components/wv_filter/WvQueryOption.js";

export default {
  methods: {
    togglesShowContent(id) {
      this.$emit("togglesShowContent", id);
    },
    isRouterLink(link) {
      const RouteRoot = getRouteRootFromLink(link.link);
      if (RouteRoot.toLowerCase().includes("documentdownload")) {
        return false;
      }
      return true;
    },
    getRouterTo(link) {
      let ret = getUrlFromLink(link.link);
      let navids = [];
      navids.push(this.portalid);
      navids.push(this.menuid);
      navids.push(this.submenu.id);
      navids.push(link.id);
      ret.searchParams.append("nav", navids);
      return ret.host + ret.search;
    },
    callLinkNoRouter(link) {
      const RouteRoot = getRouteRootFromLink(link.link);
      if (RouteRoot.toLowerCase().includes("documentdownload")) {
        const payload = {
          reportType: link.link
            .toLowerCase()
            .replace("documentdownload?doc=", ""),
        };
        this.$store.dispatch("wviewStore/LOAD_DOCUMENTDOWNLOAD", payload);
      }
    },
  },
  props: {
    showContent: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    submenu: {
      type: Object,
      default: function () {
        return {
          name: "subMenuName",
          id: -1,
          Links: [
            {
              name: "link-a",
              link: "/",
            },
            {
              name: "link-b",
              link: "/",
            },
          ],
        };
      },
    },
    portalid: {
      type: Number,
      default: function () {
        return -1;
      },
    },
    menuid: {
      type: Number,
      default: function () {
        return -1;
      },
    },
  },
};
</script>
