<template>
  <div
    id="flyout-user"
    class="flyout flyout--info js-flyout"
    :class="{ 'is-open': flyoutIsOpen }"
    style="top: 66px; right: 20px"
    v-click-outside="closeEvent"
  >
    <div v-if="!user">
      <ul class="flyout__user">
        <li class="nav-item js-nav-item">
          <router-link class="nav-link" @click.native="close()" :to="loginUrl">
            <i class="icon icon-047-key" aria-hidden="true"></i>
            <span class="text-underline">Login</span>
          </router-link>
        </li>
      </ul>
    </div>
    <div v-if="user">
      <ul class="flyout__user">
        <li class="nav-item js-nav-item">
          <div>
            <span>UserName: {{ UserName }}</span>
          </div>
          <div>
            <span>UserNr: {{ UserNr }}</span>
          </div>
        </li>
        <li class="nav-item js-nav-item">
          <router-link class="nav-link" @click.native="onSignout()" :to="'/'">
            <i class="icon icon-047-key" aria-hidden="true"></i>
            <span class="text-underline">Sign out</span>
          </router-link>
        </li>
      </ul>
      <ul class="flyout__user">
        <li class="nav-item js-nav-item">
          <span class="text-underline">
            Theme: {{ theme }}
            <a @click="toggleTheme()">(switch)</a>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import router from "@/router";

export default {
  computed: {
    ...mapGetters({
      flyoutIsOpen: "flyoutIsOpen",
    }),
    theme: {
      cache: false,
      get() {
        let theme = this.$store.getters.theme();
        return theme;
      },
    },
    UserNr: {
      cache: false,
      get() {
        return this.$store.getters.UserNr();
      },
    },
    UserName: {
      cache: false,
      get() {
        return this.$store.getters.UserName();
      },
    },
    user: {
      cache: false,
      get() {
        return this.$store.getters.isUserTokenHereAndStillValid();
      },
    },
    isUserAdmin() {
      return this.$store.getters.isUserAdmin();
    },
    loginUrl() {
      return "/login";
      // return `/login${
      //   this.$route.path === '/login'
      //     ? ''
      //     : `?returnUrl=${encodeURIComponent(this.$route.fullPath)}`
      // }`
    },
  },
  methods: {
    ...mapActions({ signout: "AUTH_LOGOUT", closeFlyout: "closeFlyout" }),
    onSignout() {
      this.$store.dispatch("AUTH_LOGOUT").then(() => {
        this.closeFlyout();
        router.push("/Login");
      });

      // this.signout()
      // // console.log('close event called from signout')
      // this.$nextTick(() => {
      //   this.signout()
      //   this.closeFlyout()
      // })
    },
    close() {
      // console.log('close event called from close')
      this.closeFlyout();
    },
    closeEvent() {
      if (this.flyoutIsOpen) {
        // console.log('close event called from flyout')
        this.closeFlyout();
      }
    },
    toggleTheme() {
      this.$store.dispatch("TOGGLE_THEME").then(() => {
        this.closeFlyout();
        router.go();
      });
    },
  },
};
</script>
