<template>
  <!-- <nav v-if="showBreadcrumbs" data-init="auto" class="nav__level2" role="navigation" style> -->
  <div class="filter-breadcrumb">
    <div>
      <router-link class="nav-link" v-bind:to="'/'">
        <i class="icon icon-044-home icon--s2"></i>
      </router-link>
    </div>
    <div v-for="bcItem in BreadcrumbsItems" v-bind:key="bcItem.id">
      <router-link class="nav-link" v-bind:to="bcItem.link">
        <span class="text-underline">/{{ bcItem.name }}</span>
      </router-link>
    </div>
  </div>
  <!-- </nav> -->
</template>

<script>
import { getBreadcrumbsFromNavData } from "./navUtils.js";
import { mapGetters } from "vuex";
import { getNavIdsFromLink } from "@/components/wv_filter/WvQueryOption.js";

export default {
  computed: {
    ...mapGetters({
      isNavLoading: "nav/isDataLoading",
      isNavLoaded: "nav/isDataLoaded",
      navData: "nav/navFilteredData",
    }),
    BreadcrumbsItems() {
      if (this.isNavLoaded) {
        var ret = getBreadcrumbsFromNavData(
          this.navData,
          getNavIdsFromLink(this.$route.fullPath)
        );
        if (!!ret) {
          if (!!ret[2] && !!ret[3]) {
            document.title = ret[2].name + "/" + ret[3].name;
          }
          return ret;
        } else {
          return [];
        }
        return;
      }
      return [];
    },
  },
};
</script>
