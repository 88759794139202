<template>
  <div
    v-if="showBanner"
    class="bg bg--gray result-banner"
    :class="{ 'result-banner-success': reg_isSuccesfull }"
  >
    <div class="result-banner-left">
      <div
        v-if="reg_isSuccesfull"
        class="result-banner-icons result-banner-icons-plusminus"
        @click="toggleshowRowsInDiffOnly()"
      >
        <i
          class="icon wvfilter-icon"
          :class="{
            'icon-301_minus-box': !showRowsInDiffOnly,
            'icon-300_plus-box': showRowsInDiffOnly,
          }"
          aria-hidden="true"
        ></i>
        <span class="font font--light">{{ showRowsInDiffOnlyText }}</span>
      </div>
      <div v-else class="result-banner-icons">
        <i
          class="icon icon-026-exclamation-mark-circle wvfilter-icon"
          aria-hidden="true"
        ></i>
      </div>
    </div>
    <div class="result-banner-right">
      <div class="result-banner-messages">
        <ul>
          <li v-for="regMessage in reg_Messages">
            <p class="font font--light">{{ regMessage }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      showRowsInDiffOnly: true,
    };
  },
  computed: {
    ...mapGetters({
      _wvData: "wviewStore/wvData",
      reg_isLoading: "wviewStore/reg_isLoading",
      reg_isLoaded: "wviewStore/reg_isLoaded",
      reg_wvData: "wviewStore/reg_wvData",
      reg_wvInfo: "wviewStore/reg_wvInfo",
      reg_Messages: "wviewStore/reg_Messages",
      reg_isSuccesfull: "wviewStore/reg_isSuccesfull",
      showReg: "wviewStore/showReg",
    }),
    showBanner() {
      return this.showReg && this.reg_isLoaded;
    },
    showRowsInDiffOnlyText() {
      if (this.showRowsInDiffOnly) {
        return "Show rows without diff";
      } else {
        return "Remove rows without diff";
      }
    },
  },
  methods: {
    toggleshowRowsInDiffOnly() {
      this.showRowsInDiffOnly = !this.showRowsInDiffOnly;
      this.$emit("input", this.showRowsInDiffOnly);
    },
  },
};
</script>
