import axios from "axios";

const state = {
  rtwData: [],
  statusRtwData: "",
  expDestinationsData: [],
  statusExpDestinationsData: "",
  pferSent: [],
  statusSent: "",
};

const getters = {
  isRtwDataLoading() {
    return state.statusRtwData === "loading";
  },
  isRtwDataLoaded() {
    return state.statusRtwData === "loaded";
  },
  rtwData() {
    return state.rtwData;
  },
  isExpDestinationsDataLoading() {
    return state.statusExpDestinationsData === "loading";
  },
  isExpDestinationsDataLoaded() {
    return state.statusExpDestinationsData === "loaded";
  },
  expDestinationsData() {
    return state.expDestinationsData;
  },
  isSentLoading() {
    return state.statusSent === "loading";
  },
  isSentLoaded() {
    return state.statusSent === "loaded";
  },
  pfrrSent() {
    return state.pferSent;
  },
};

const mutations = {
  SET_RTW_DATA(state, data) {
    state.rtwData = data;
  },
  SET_STATUS_RTW_DATA_LOADING(state) {
    state.statusRtwData = "loading";
  },
  SET_STATUS_RTW_DATA_LOADED(state) {
    state.statusRtwData = "loaded";
  },
  SET_STATUS_RTW_DATA_ERROR(state) {
    state.statusRtwData = "error";
  },
  SET_EXPDESTINATIONS_DATA(state, data) {
    state.expDestinationsData = [];
    state.expDestinationsData.push({
      id: -1,
      Desc: "Download",
      label: "Download",
    });
    for (var i in data) {
      // data[i].label = data[i].Desc
      state.expDestinationsData.push({
        id: data[i].id,
        Desc: data[i].Desc,
        label: data[i].Desc,
      });
    }
  },
  SET_STATUS_EXPDESTINATIONS_DATA_LOADING(state) {
    state.statusExpDestinationsData = "loading";
  },
  SET_STATUS_EXPDESTINATIONS_DATA_LOADED(state) {
    state.statusExpDestinationsData = "loaded";
  },
  SET_STATUS_EXPDESTINATIONS_DATA_ERROR(state) {
    state.statusExpDestinationsData = "error";
  },
  PUSH_SENT(state, data) {
    state.pferSent.push(data);
  },
  SET_STATUS_SENT_LOADING(state) {
    state.statusSent = "loading";
  },
  SET_STATUS_SENT_LOADED(state) {
    state.statusSent = "loaded";
  },
};

const actions = {
  LOAD_RTW({ commit }) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_RTW_DATA_LOADING");
      axios
        .get("/api/pexprequest/ReportToWview")
        .then((response) => {
          commit("SET_RTW_DATA", response.data);
          commit("SET_STATUS_RTW_DATA_LOADED");
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_RTW_DATA_ERROR");
        });
    });
  },
  LOAD_EXPDESTINATIONS({ commit }) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_EXPDESTINATIONS_DATA_LOADING");
      axios
        .get("/api/pexprequest/Destinations")
        .then((response) => {
          commit("SET_EXPDESTINATIONS_DATA", response.data);
          commit("SET_STATUS_EXPDESTINATIONS_DATA_LOADED");
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_EXPDESTINATIONS_DATA_ERROR");
        });
    });
  },
  SEND_EXPORTREQUEST: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      // commit('SET_STATUS_SENT_LOADING')
      var requestConfig = {
        params: {},
      };
      if (process.env.NODE_ENV === "development") {
        requestConfig.params["TestingDB"] = "True"; //do not send request to prod if in development
      }
      axios
        .post("/api/PExpRequest", payload, requestConfig)
        .then((resp) => {
          const result = resp.data;
          console.log("send export request successfully: resulting object");
          console.log(result);
          commit("PUSH_SENT", resp);
          commit("SET_STATUS_SENT_LOADED");
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
