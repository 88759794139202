<template>
  <div>
    <div class="input-field dp-form" style="display: flex">
      <input
        type="text"
        v-model="inputText"
        class="dp-textinput"
        :class="{ invalid: !isValid2 }"
        @input="onChangeInputText($event.target.value)"
        @blur="validateInputText($event.target.value)"
        v-on:keyup.enter="onEnterInputText($event.target.value)"
      />
      <VueCtkDateTimePicker
        v-model="valueProxy"
        class="ctk"
        :no-value-to-custom-elem="true"
        :auto-close="true"
        :no-label="true"
        :only-date="true"
        :no-header="true"
        :locale="'de'"
        :format="format"
        :enabledDates="enabledDates"
        @input="onChangeDatePicker($event)"
      >
        <div
          ref="dpdiv"
          class="dp-calendar-div"
          :class="{ invalid: !isValid }"
          tabindex="0"
          v-on:keyup.enter="simulateClick()"
          v-on:keyup.space="simulateClick()"
        >
          <i class="icon icon-009-calendar icon--s1"></i>
        </div>
      </VueCtkDateTimePicker>
    </div>
    <div v-if="isDebug">
      <span>valueProxy:{{ valueProxy }}</span>
      <span>inputText:{{ inputText }}</span>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
// import { text } from '../../../../local_modules/sdx/dist/es6/DomFunctions'

export default {
  data() {
    return {
      inputTextHasChange: false,
      isValid: true,
      inputText: "",
      format: "DD.MM.YYYY",
      isDebug: false,
    };
  },
  computed: {
    valueProxy: {
      get() {
        //becomming Date from props
        var momentValue = moment(this.value);
        //props into inputText
        this.isValid = momentValue.isValid();
        this.inputText = momentValue.format(this.format);
        //returning string as ValueProxy (used by DatePicker)
        return momentValue.format(this.format);
      },
      set(text) {
        //becomming Date from DatePicker or bluredInputText
        var momentValue = moment(text, this.format, true);
        //emit if isValid
        if (momentValue.isValid()) {
          this.$emit("input", momentValue.toDate());
        }
      },
    },
    isValidAccordingToEnableDates() {
      if (this.enabledDates && this.enabledDates.length > 0) {
        var momentValue = moment(this.valueProxy, this.format, true);
        if (momentValue.isValid()) {
          return this.enabledDates.includes(momentValue.format("YYYY-MM-DD"));
        }
        return false;
      }
      return true;
    },
    isValid2() {
      return this.isValid && this.isValidAccordingToEnableDates;
    },
  },
  methods: {
    parseInputText(text) {
      var momentValue = moment(
        text,
        ["D.M.YYYY", "D.M.YY", "D.M", "D.M."],
        true
      );
      var momentNoTime = moment({
        year: momentValue.year(),
        month: momentValue.month(),
        day: momentValue.date(),
      });
      return momentNoTime;
    },
    onChangeDatePicker(text) {
      var momentValue = moment(text, this.format, true);
      if (momentValue.isValid()) {
        this.isValid = true;
        //synchronise to inputText
        this.inputText = momentValue.format(this.format);
      } else {
        this.isValid = false;
      }
    },
    onChangeInputText(text) {
      this.inputTextHasChange = true;
      var momentValue = this.parseInputText(text);
      this.isValid = momentValue.isValid();
    },
    onEnterInputText(text) {
      this.inputTextHasChange = true;
      this.validateInputText(text);
    },
    validateInputText(text) {
      if (this.inputTextHasChange) {
        var momentValue = this.parseInputText(text);
        if (momentValue.isValid()) {
          this.isValid = true;
          //when blured set the formated version and emit
          this.inputText = momentValue.format(this.format);
          this.valueProxy = momentValue.format(this.format);
          this.inputTextHasChange = false;
        } else {
          this.isValid = false;
        }
      }
    },
    simulateClick() {
      this.$refs.dpdiv.click();
    },
  },
  props: {
    //Javascript Date Type
    value: {
      type: Date,
      required: true,
    },
    enabledDates: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  components: {
    VueCtkDateTimePicker: VueCtkDateTimePicker,
  },
};
</script>
