<template>
  <div class="qsui-admin-container">
    <div v-if="showSpinner" class="loader-spinner loader-spinner--small"></div>
    <div v-else-if="!isElemLoaded">
      <div class="qsui-admin-form col-lg-12 col-xl-10">
        <div class="text-h4">Create New Benchmark</div>
        <div class="qsui-admin-subform">
          <div class="qsui-admin-subform-row">
            <sdx-textbox
              v-model="newElem.name"
              label="benchmark name"
              type="text"
              :showLabel="true"
              :regex="config.regex.notEmptySimpleName"
            />
          </div>
        </div>
      </div>
      <div class="qsui-admin-form col-lg-12 col-xl-10">
        <div class="text-h5">Benchmark Periods</div>
        <div class="qsui-admin-subform">
          <div>
            <i
              class="icon icon-061-plus wvfilter-icon wvfilter-green-button"
              title="Add Period"
              aria-hidden="true"
              @click="addPeriod(newElem)"
            ></i>
          </div>
          <div
            v-for="period in newElem.periods"
            v-bind:key="period.period_nr"
            class="qsui-admin-subform-row"
          >
            <div class="qsui-admin-subform-row qsui-admin-subform-row-line">
              <div class="text">Benchmark Period valid from: </div>
              <wv-date-input
                class="dp-div-standalone"
                v-model="period.niwDateFrom"
              />
              <div class="text">(delete this benchmark period </div>
              <div>
                <i
                  class="icon icon-008-bin wvfilter-icon wvfilter-green-button"
                  title="Remove Period"
                  aria-hidden="true"
                  @click="delPeriod(newElem, period)"
                ></i>
              </div>
              <div class="text">)</div>
            </div>

            <div
              v-for="pos in period.positions"
              v-bind:key="pos.id"
              class="qsui-admin-subform-row qsui-admin-subform-row-line"
            >
              <!-- <select-index
                class="input-field-large"
                label="index"
                :value="pos.index"
                @input="onIndexChange(pos, $event)"
              /> -->
              <sdx-textbox
                class="input-field-large"
                v-model="pos.index"
                title="IndexID (cf. wview MAP_INDEXDESC_VIEW)"
                label="Index ID"
                type="text"
                :showLabel="true"
                :regex="config.regex.notEmpty"
              />
              <sdx-textbox
                class="input-field-small"
                v-model="pos.weight"
                label="weight(%)"
                type="text"
                :showLabel="true"
                :regex="config.regex.decimal"
              />
              <sdx-textbox
                class="input-field-small"
                v-model="pos.daily_surcharge"
                label="surcharge(%)"
                type="text"
                :showLabel="true"
                :regex="config.regex.decimal"
              />
              <select-boolean
                class="input-field-small"
                label="interest"
                v-model="pos.interest_rate_index"
              />
              <div class="input-field">
                <i
                  class="icon icon-008-bin wvfilter-icon"
                  title="Remove Index"
                  aria-hidden="true"
                  @click="delPosition(period, pos)"
                ></i>
              </div>
            </div>
            <div class="qsui-admin-subform-row qsui-admin-subform-row-line">
              <i
                class="icon icon-061-plus wvfilter-icon wvfilter-blue-button"
                title="Add Index"
                aria-hidden="true"
                @click="addPosition(period)"
              ></i>
            </div>
            <div class="qsui-admin-subform-row qsui-admin-subform-row-line">
              <div class="text"
                >Sum of index weight:
                {{ getWeightSum(period.positions) }} %</div
              >
            </div>
          </div>
        </div>
      </div>
      <div class="qsui-admin-form col-lg-12 col-xl-10">
        <div class="qsui-admin-buttons">
          <a
            class="button button--secondary"
            href="javascript:void(0);"
            @click="onNewCancel"
            >cancel</a
          >
          <a
            class="button button--primary"
            href="javascript:void(0);"
            :class="{ disabled: !isElemValid(newElem) }"
            @click="onCreate(newElem)"
            >create</a
          >
        </div>
      </div>
    </div>
    <div v-else>
      <div class="qsui-admin-form col-lg-12 col-xl-10">
        <div class="text-h4">Benchmark: {{ elem.name.value }}</div>
      </div>
      <div class="qsui-admin-form col-lg-12 col-xl-10">
        <div class="text-h5">Benchmark Periods</div>
        <div class="qsui-admin-subform">
          <div>
            <i
              class="icon icon-061-plus wvfilter-icon wvfilter-green-button"
              title="Add Period"
              aria-hidden="true"
              @click="addPeriod(elem)"
            ></i>
          </div>
          <div
            v-for="period in elem.periods"
            v-bind:key="period.period_nr"
            class="qsui-admin-subform-row"
          >
            <div class="qsui-admin-subform-row qsui-admin-subform-row-line">
              <div class="text-h6">Benchmark Period valid from: </div>
              <wv-date-input
                class="dp-div-standalone"
                v-model="period.niwDateFrom"
              />
              <div class="text-h6">(delete this benchmark period </div>
              <div>
                <i
                  class="icon icon-008-bin wvfilter-icon wvfilter-green-button"
                  title="Remove Period"
                  aria-hidden="true"
                  @click="delPeriod(elem, period)"
                ></i>
              </div>
              <div class="text-h6">)</div>
            </div>

            <div
              v-for="pos in period.positions"
              v-bind:key="pos.id"
              class="qsui-admin-subform-row qsui-admin-subform-row-line"
            >
              <!-- <select-index
                class="input-field-large"
                label="index"
                :value="pos.index"
                @input="onIndexChange(pos, $event)"
              /> -->
              <sdx-textbox
                class="input-field-large"
                v-model="pos.index"
                title="IndexID (cf. wview MAP_INDEXDESC_VIEW)"
                label="Index ID"
                type="text"
                :showLabel="true"
                :regex="config.regex.notEmpty"
              />
              <sdx-textbox
                class="input-field-small"
                v-model="pos.weight"
                label="weight(%)"
                type="text"
                :showLabel="true"
                :regex="config.regex.decimal"
              />
              <sdx-textbox
                class="input-field-small"
                v-model="pos.daily_surcharge"
                label="surcharge(%)"
                type="text"
                :showLabel="true"
                :regex="config.regex.decimal"
              />
              <select-boolean
                class="input-field-small"
                label="interest"
                v-model="pos.interest_rate_index"
              />
              <div class="input-field">
                <i
                  class="icon icon-008-bin wvfilter-icon"
                  title="Remove Index"
                  aria-hidden="true"
                  @click="delPosition(period, pos)"
                ></i>
              </div>
            </div>
            <div class="qsui-admin-subform-row qsui-admin-subform-row-line">
              <i
                class="icon icon-061-plus wvfilter-icon wvfilter-blue-button"
                title="Add Index"
                aria-hidden="true"
                @click="addPosition(period)"
              ></i>
            </div>
            <div class="qsui-admin-subform-row qsui-admin-subform-row-line">
              <div class="text-h6"
                >Sum of index weight:
                {{ getWeightSum(period.positions) }} %</div
              >
            </div>
          </div>
        </div>
      </div>
      <div class="qsui-admin-form col-lg-12 col-xl-10">
        <div class="qsui-admin-buttons">
          <a
            class="button button--primary"
            :class="{ disabled: !isElemValid(elem) }"
            href="javascript:void(0);"
            @click="onModify(elem)"
            >save</a
          >
          <a
            class="button button--primary qsui-button-red"
            href="javascript:void(0);"
            @click="onDelete"
            >delete</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import config from "@/data/config";
import SDXTextbox from "@/components/ui_utilities/SDXTextbox";
import WvDateInput from "@/components/wv_filter/date_inputs/WvDateInput";
import selectBooleanVue from "./inputForm/selectBoolean.vue";
import moment from "moment";
export default {
  data() {
    return {
      config: config,
      elem: {
        name: { value: "", valid: undefined },
        periods: [],
      },
      newElem: {
        name: { value: "", valid: undefined },
        periods: [],
      },
    };
  },
  watch: {
    elemData: function (value) {
      if (!value.name) {
        this.elem = {
          name: { value: "", valid: undefined },
          periods: [],
        };
      }
      this.elem.name = { value: value.name, valid: true };
      this.elem.periods = [];
      if (!value.periods) return;
      value.periods.forEach((period) => {
        let poss = [];
        let i = 0;
        if (!period.positions) return;
        period.positions.forEach((pos) => {
          poss.push({
            id: "pos" + i,
            // index: pos.index,
            index: { value: pos.index, valid: true },
            weight: { value: pos.weight * 100, valid: true },
            daily_surcharge: { value: pos.daily_surcharge * 100, valid: true },
            interest_rate_index: pos.interest_rate_index,
          });
          ++i;
        });
        this.elem.periods.push({
          period_nr: period.period_nr,
          niwDateFrom: moment(period.niwDateFrom).toDate(),
          positions: poss,
        });
      });
    },
  },
  created() {
    if (!this.isIndicesLoaded) {
      this.$store.dispatch("fmdPerfBenchmark/LOAD_INDICES_DATA");
    }
  },
  computed: {
    ...mapGetters({
      elemData: "fmdPerfBenchmark/benchmarkData",
      isElemLoading: "fmdPerfBenchmark/isBenchmarkLoading",
      isIndicesLoaded: "fmdPerfBenchmark/isIndicesLoaded",
    }),
    isElemLoaded() {
      return !!this.elem && !!this.elem.name.value;
    },
    showSpinner() {
      return this.isElemLoading;
    },
  },
  methods: {
    isElemValid(elem) {
      if (!elem.name.valid || !elem.periods || elem.periods.length == 0)
        return false;
      for (let i = 0; i < elem.periods.length; i++) {
        const period = elem.periods[i];
        if (!this.isPeriodValid(period)) return false;
      }
      return true;
    },
    isPeriodValid(period) {
      if (!period || !period.positions || period.positions.length == 0)
        return false;
      if (!period.niwDateFrom) return false;
      for (let i = 0; i < period.positions.length; i++) {
        const pos = period.positions[i];
        if (!pos.index.valid) return false;
        if (!pos.weight.valid) return false;
        if (!pos.daily_surcharge.valid) return false;
      }
      return true;
    },
    getWeightSum(positions) {
      if (!positions) return 0;
      let sum = 0;
      positions.forEach((pos) => {
        sum += Number(pos.weight.value);
      });
      return sum;
    },
    onIndexChange(pos, index) {
      pos.index = index;
    },
    addPeriod(elem) {
      // add period in front of the array
      elem.periods.unshift({
        period_nr: 0,
        niwDateFrom: new Date(),
        positions: [],
      });
      let i = 0;
      elem.periods.forEach((period) => {
        period.period_nr = i;
        ++i;
      });
    },
    delPeriod(elem, period) {
      elem.periods = elem.periods.filter(
        (e) => e.period_nr !== period.period_nr
      );
      let i = 0;
      elem.periods.forEach((period) => {
        period.period_nr = i;
        ++i;
      });
    },
    addPosition(period) {
      period.positions.push({
        id: "pos" + period.positions.length,
        // index: "",
        index: { value: "", valid: true },
        weight: { value: 0, valid: true },
        daily_surcharge: { value: 0, valid: true },
        interest_rate_index: false,
      });
    },
    delPosition(period, pos) {
      period.positions = period.positions.filter((e) => e.index !== pos.index);
      let i = 0;
      period.positions.forEach((pos) => {
        pos.id = "pos" + i;
        i = i + 1;
      });
    },
    onNewCancel() {
      this.newElem = {
        name: { value: "", valid: undefined },
        periods: [],
      };
    },
    save(elem) {
      const payload = {
        name: elem.name.value,
        periods: [],
      };
      elem.periods.forEach((period) => {
        let poss = [];
        period.positions.forEach((pos) => {
          poss.push({
            index: pos.index.value,
            weight: pos.weight.value / 100,
            daily_surcharge: pos.daily_surcharge.value / 100,
            interest_rate_index: pos.interest_rate_index,
          });
        });
        payload.periods.push({
          period_nr: period.period_nr,
          niwDateFrom: moment(period.niwDateFrom).format("YYYY-MM-DD"),
          positions: poss,
        });
      });
      this.$store.dispatch("fmdPerfBenchmark/CREATE_BENCHMARK", payload);
    },
    onCreate(elem) {
      this.save(elem);
      this.onNewCancel();
    },
    onModify(elem) {
      this.save(elem);
    },
    onDelete() {
      this.$store.dispatch("fmdPerfBenchmark/DELETE_BENCHMARK", {
        name: this.elem.name.value,
      });
    },
  },
  components: {
    "sdx-textbox": SDXTextbox,
    "select-boolean": selectBooleanVue,
    WvDateInput,
  },
  created() {
    document.title = "Admin>Benchmarks";
  },
};
</script>

<style></style>
