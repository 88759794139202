export function getLimit_marketrisk(
  admission_country,
  law_type,
  riskclass_marketrisk
) {
  let ret = {
    abs_limit: -1,
    rel_limit: -1,
    stress_limit: -1,
  };
  if (admission_country == "DE" || !law_type_is_aif(law_type)) {
    switch (riskclass_marketrisk) {
      case 1:
        ret.abs_limit = 5;
        ret.rel_limit = 125;
        ret.stress_limit = 5;
        break;
      case 2:
        ret.abs_limit = 10;
        ret.rel_limit = 150;
        ret.stress_limit = 10;
        break;
      case 3:
        ret.abs_limit = 15;
        ret.rel_limit = 175;
        ret.stress_limit = 15;
        break;
      case 4:
        ret.abs_limit = 20;
        ret.rel_limit = 200;
        ret.stress_limit = 20;
        break;
    }
  } else {
    switch (riskclass_marketrisk) {
      case 1:
        ret.abs_limit = 10;
        ret.rel_limit = 150;
        ret.stress_limit = 5;
        break;
      case 2:
        ret.abs_limit = 20;
        ret.rel_limit = 200;
        ret.stress_limit = 10;
        break;
      case 3:
        ret.abs_limit = 30;
        ret.rel_limit = 300;
        ret.stress_limit = 15;
        break;
      case 4:
        ret.abs_limit = 50;
        ret.rel_limit = 400;
        ret.stress_limit = 20;
        break;
    }
  }
  return ret;
}

export function getLimit_creditrisk(law_type, riskclass_creditrisk) {
  switch (riskclass_creditrisk) {
    case 1:
      return !law_type_is_aif(law_type) ? 5 : 10;
    case 2:
      return !law_type_is_aif(law_type) ? 10 : 20;
    case 3:
      return !law_type_is_aif(law_type) ? 15 : 30;
    case 4:
      return !law_type_is_aif(law_type) ? 20 : 40;
    default:
      return 40;
  }
}

function law_type_is_aif(law_type) {
  const aifs = [2, 6, 7, 8, 9];
  if (!!law_type && aifs.includes(law_type)) return true;
}

export function getVal(input_value, default_value) {
  if (!!input_value) return input_value;
  else if (!!default_value) return default_value;
}

export function getValV(input_value, default_value) {
  if (!!input_value) return { value: input_value, valid: true };
  else if (!!default_value) return { value: default_value, valid: true };
  else {
    return { value: "", valid: true };
  }
}
