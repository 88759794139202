<template>
  <div class="cortana-side-nav bg bg--gray" :class="{ hidden: !isOpen }">
    <div class="cortana-side-nav__wrapper">
      <sdx-breadcrumb />
      <div class="viewfilter-button-row">
        <!-- Excel/CSV Export Button -->
        <excel-export
          :class="{
            invisible: isRegression || isWViewEligibleForExpPDFRequest,
          }"
          @onExcelDownload="loadExcel"
          @onQSXMLDownload="loadQSXML"
          @onCSVDownload="loadCSV"
        ></excel-export>
        <!-- Button View Data -->
        <div
          class="button-group qsui-play-button-group button-group--responsive"
        >
          <div
            class="loader-spinner loader-spinner--small"
            v-show="showSpinner"
          ></div>
          <div class title="Load Data" @click="loadData">
            <i
              class="icon icon-060-play-filled icon--s4"
              aria-hidden="true"
            ></i>
          </div>
          <div
            v-if="isRegression"
            title="Start Regression"
            @click="loadRegression"
          >
            <i
              class="icon icon-216-waypointer icon--s4 regression"
              aria-hidden="true"
            ></i>
          </div>
          <div v-if="isRegression" title="Load Data.r" @click="reg_loadData">
            <i
              class="icon icon-059-play icon--s4 regression"
              aria-hidden="true"
            ></i>
          </div>
        </div>
      </div>
      <hr />
      <qsui-accordion v-show="isInfoLoaded">
        <template v-for="critBox in getcritBoxes_visible">
          <accitem-crit-book
            v-if="getCritBoxType(critBox) === 'critBook'"
            :key="critBox.ColName"
            :huCol="critBox"
            :portalID="portalIDforBooks"
            @input="onCriteriaChange($event)"
            @removeCritBox="removeCritBox($event)"
            @onEnterKey="loadData()"
          />
          <accitem-crit-number
            v-else-if="getCritBoxType(critBox) === 'critNumber'"
            :key="critBox.ColName"
            :huCol="critBox"
            @input="onCriteriaChange($event)"
            @removeCritBox="removeCritBox($event)"
            @onEnterKey="loadData()"
          />
          <accitem-crit-date
            v-else-if="getCritBoxType(critBox) === 'critDate'"
            :key="critBox.ColName"
            :huCol="critBox"
            :initialDate="defDate"
            @input="onCriteriaChange($event)"
            @removeCritBox="removeCritBox($event)"
            @onEnterKey="loadData()"
          />
          <accitem-crit-datetime
            v-else-if="getCritBoxType(critBox) === 'critDateTime'"
            :key="critBox.ColName"
            :huCol="critBox"
            @input="onCriteriaChange($event)"
            @removeCritBox="removeCritBox($event)"
            @onEnterKey="loadData()"
          />
          <accitem-crit-string
            v-else
            :key="critBox.ColName"
            :huCol="critBox"
            @input="onCriteriaChange($event)"
            @removeCritBox="removeCritBox($event)"
            @onEnterKey="loadData()"
          />
        </template>
        <accitem-more
          :critBoxes="critBoxes_hidden"
          @add="addCritBox($event)"
          @reinitialiseCriteria="onReinitialiseCriteria"
          @clearCriteria="onClearCriteria"
        />
        <template
          v-if="isRegression"
          v-for="critBox in reg_getcritBoxes_visible"
        >
          <accitem-crit-book
            v-if="getCritBoxType(critBox) === 'critBook'"
            :key="critBox.ColName + '.r'"
            :huCol="critBox"
            :portalID="portalIDforBooks"
            :isRegression="isRegression"
            @input="reg_onCriteriaChange($event)"
            @removeCritBox="reg_removeCritBox($event)"
            @onEnterKey="loadData()"
          />
          <accitem-crit-number
            v-else-if="getCritBoxType(critBox) === 'critNumber'"
            :key="critBox.ColName + '.r'"
            :huCol="critBox"
            :isRegression="isRegression"
            @input="reg_onCriteriaChange($event)"
            @removeCritBox="reg_removeCritBox($event)"
            @onEnterKey="loadData()"
          />
          <accitem-crit-date
            v-else-if="getCritBoxType(critBox) === 'critDate'"
            :key="critBox.ColName + '.r'"
            :huCol="critBox"
            :isRegression="isRegression"
            @input="reg_onCriteriaChange($event)"
            @removeCritBox="reg_removeCritBox($event)"
            @onEnterKey="loadData()"
          />
          <accitem-crit-datetime
            v-else-if="getCritBoxType(critBox) === 'critDateTime'"
            :key="critBox.ColName + '.r'"
            :huCol="critBox"
            :isRegression="isRegression"
            @input="reg_onCriteriaChange($event)"
            @removeCritBox="reg_removeCritBox($event)"
            @onEnterKey="loadData()"
          />
          <accitem-crit-string
            v-else
            :key="critBox.ColName + '.r'"
            :huCol="critBox"
            :isRegression="isRegression"
            @input="reg_onCriteriaChange($event)"
            @removeCritBox="reg_removeCritBox($event)"
            @onEnterKey="loadData()"
          />
        </template>
        <accitem-more
          v-if="isRegression"
          :critBoxes="reg_critBoxes_hidden"
          :isRegression="isRegression"
          :startOpen="false"
          @add="reg_addCritBox($event)"
          @reinitialiseCriteria="reg_onReinitialiseCriteria"
          @clearCriteria="reg_onClearCriteria"
        />
        <accitem-crit-checkboxes
          v-if="isRegression"
          class="regression"
          :dataCheckBoxes="regDataCheckBoxes_DB"
          :desriptor="'Reg Options...'"
          :startOpen="true"
          @input="onRegCheckBoxesChange($event)"
        />
        <accitem-sort
          :critBoxes="critBoxes_sort"
          @changeSorting="onChangeSorting($event)"
          @clearSorting="onClearSorting"
          @reinitialiseSorting="onReinitialiseSorting"
        />

        <qsui-accordion-item :removable="false" :startOpen="false">
          <p slot="header">QSAPI request</p>
          <div slot="collapse" class="mandatSelect user-select-text">
            <div>
              <div>Wview={{ WViewName }}</div>
              <div>$filter={{ soData_filter }}</div>
              <div v-if="isRegression">$rfilter={{ reg_soData_filter }}</div>
              <div v-if="isRegression">
                regColsToIgnore={{ reg_soData_keysInCommon }}
              </div>
              <div>$orderby={{ soData_orderby }}</div>
            </div>
          </div>
        </qsui-accordion-item>
        <qsui-accordion-item :removable="false" :startOpen="false">
          <p slot="header">Description</p>
          <div slot="collapse" class="mandatSelect user-select-text">
            <div>
              <div>{{ wview_definition }}</div>
            </div>
          </div>
        </qsui-accordion-item>
        <accitem-crit-checkboxes
          v-if="canSeeAllBook"
          :dataCheckBoxes="regDataCheckBoxes_Activate"
          :desriptor="'Regression'"
          :startOpen="false"
          @input="toggleRegression($event)"
        />
      </qsui-accordion>
    </div>
  </div>
</template>

<script>
//import spinnerBounce from '@/components/ui_utilities/spinnerBounce'
import SdxBreadcrumb from "@/components/navigation/SdxBreadcrumb";
import parser from "odata-parser";
import { mapGetters } from "vuex";
import WVFilterAccItem_CritString from "./filter_items/WVFilterAccItem_CritString";
import WVFilterAccItem_CritNumber from "./filter_items/WVFilterAccItem_CritNumber";
import WVFilterAccItem_CritBook from "./filter_items/WVFilterAccItem_CritBook";
import WVFilterAccItem_CritDate from "./filter_items/WVFilterAccItem_CritDate";
import WVFilterAccItem_CritDateTime from "./filter_items/WVFilterAccItem_CritDateTime";
import WVFilterAccItem_More from "./filter_items/WVFilterAccItem_More";
import WVFilterAccItem_Sort from "./filter_items/WVFilterAccItem_Sort";
import WVFilterAccItem_CritCheckBoxes from "./filter_items/WVFilterAccItem_CritCheckBoxes";

// import WVFilterAccItem_ViewSelector from "./filter_items/WVFilterAccItem_ViewSelector";
import {
  getQueryOption_FilterString,
  reg_getQueryOption_FilterString,
  getQueryOption_OrderByString,
  getViewNameFromNavObject,
  isTestingDBinURL,
  REG_DATA_CHECKBOXES_TESTINGDB,
  REG_DATA_CHECKBOXES_PRODDB,
  REG_DATA_CHECKBOXES_ACTIVATE,
  getPortalIDFromLink,
} from "./WvQueryOption.js";

import ButtonsRow from "@/components/ui_utilities/ButtonsRow";
import ExcelExport from "@/components/export_excel_csv/ExcelExport";
import QsuiAccordion from "@/components/ui_utilities/QsuiAccordion";
import QsuiAccordionItem from "@/components/ui_utilities/QsuiAccordionItem";
import config from "@/data/config";

export default {
  data() {
    return {
      critBoxes_visible: [],
      critBoxes_hidden: [],
      critBoxes_sort: [],
      retFilter: {},
      reg_critBoxes_visible: [],
      reg_critBoxes_hidden: [],
      reg_retFilter: {},
      regCheckedBoxes: [],
      defDate: new Date(),
      email: {},
      config: config,
      initialisedFor: "",
      reg_initialisedFor: "",
      isRegression: false,
      loadedOnce: false,
    };
  },
  created() {
    this.defDate = this.getWorkingday(-1);
    this.loadNavIfneeded();
    this.loadInfo();
  },
  watch: {
    WViewName: function () {
      //clear and reload on WView change (probably only possible when manually editing the url)
      this.loadInfo();
    },
    soData_filter: function () {
      //auto Load data once at the beginnning if WView.autoLoad and once soData_filter is ready
      if (
        !this.loadedOnce &&
        this.info &&
        this.info.hu &&
        this.info.hu.autoLoad &&
        this.WViewName &&
        this.isInfoLoaded &&
        this.soData_filter &&
        this.soData_orderby
      ) {
        this.loadedOnce = true;
        this.loadData();
      }
    },
    orderBy_SQLtoAST: function () {
      this.initialiseSortingBoxes();
    },
  },
  computed: {
    ...mapGetters({
      isInfoLoading: "wviewStore/isInfoLoading",
      isInfoLoaded: "wviewStore/isInfoLoaded",
      isDataLoading: "wviewStore/isDataLoading",
      isHUPLoading: "wviewStore/isHUPLoading",
      isNavLoading: "nav/isDataLoading",
      reg_isLoading: "wviewStore/reg_isLoading",
      isWViewEligibleForExpPDFRequest:
        "wviewStore/isWViewEligibleForExpPDFRequest",
      info: "wviewStore/wvInfo",
    }),
    regDataCheckBoxes_DB() {
      if (this.isTestingDB) {
        return REG_DATA_CHECKBOXES_PRODDB;
      } else {
        return REG_DATA_CHECKBOXES_TESTINGDB;
      }
    },
    regDataCheckBoxes_Activate() {
      return REG_DATA_CHECKBOXES_ACTIVATE;
    },
    canSeeAllBook() {
      return this.$store.getters.canSeeAllBook();
    },
    showSpinner() {
      return (
        this.isDataLoading ||
        this.isHUPLoading ||
        this.isInfoLoading ||
        this.isNavLoading ||
        this.reg_isLoading
      );
    },
    wview_definition() {
      if (this.info && this.info.hu && this.info.hu.Title) {
        return this.info.hu.Title;
      } else {
        return "...";
      }
    },
    WViewName() {
      var ret = getViewNameFromNavObject(this.$route.fullPath);
      if (ret) {
        return ret;
      } else {
        this.clearWViewStore(); //reinitialse even if empty
        this.initialisedFor = "";
        return "";
      }
    },
    isTestingDB() {
      return isTestingDBinURL(this.$route.fullPath);
    },
    huColsS() {
      if (this.isInfoLoaded) return this.info.hu.colForSearch;
    },
    huColsL() {
      if (this.isInfoLoaded) return this.info.hu.colForList;
    },
    getcritBoxes_visible() {
      if (!this.isInfoLoading && this.isInfoLoaded) {
        if (this.info && this.info.hu.WVName != this.initialisedFor) {
          this.initialiseCritBoxes();
          // this.initialisedFor = this.info.hu.WVName
          this.initialiseSortingBoxes();
        }
        return this.critBoxes_visible;
      }
    },
    reg_getcritBoxes_visible() {
      if (this.isRegression && !this.isInfoLoading && this.isInfoLoaded) {
        if (this.info && this.info.hu.WVName != this.reg_initialisedFor) {
          this.reg_initialiseCritBoxes();
          this.reg_initialisedFor = this.info.hu.WVName;
        }
        return this.reg_critBoxes_visible;
      }
    },
    soData_filter() {
      if (this.retFilter && this.huColsS)
        return getQueryOption_FilterString(this.retFilter, this.huColsS);
    },
    reg_soData_filter_object() {
      if (this.retFilter && this.reg_retFilter && this.huColsS)
        return reg_getQueryOption_FilterString(
          this.retFilter,
          this.reg_retFilter,
          this.huColsS
        );
    },
    reg_soData_filter() {
      if (this.reg_soData_filter_object) {
        return this.reg_soData_filter_object.filter;
      }
    },
    reg_soData_keysInCommon() {
      if (this.reg_soData_filter_object) {
        return this.reg_soData_filter_object.keysInCommon;
      }
    },
    // parsedFilter() {
    //   // filter = "$filter=Name eq 'John' and LastName lt 'Doe'"
    //   // filter = "$filter=(date ge '2019-03-01')"
    //   var StringToParse = '$filter=' + this.soData_filter
    //   var result = parser.parse(StringToParse)
    //   return result
    // },
    orderBy_SQLtoAST() {
      let orderByString = "";
      if (this.isInfoLoaded) {
        if (this.isRegression) {
          if (this.info.hu.OrderByForRegression) {
            orderByString = this.info.hu.OrderByForRegression;
          } else if (this.info.hu.OrderBy) {
            orderByString = this.info.hu.OrderBy;
          } else {
            return null;
          }
        } else {
          if (this.info.hu.OrderBy) {
            orderByString = this.info.hu.OrderBy;
          } else {
            return null;
          }
        }
        orderByString = orderByString
          .replace(/^\s*\border\s*by\b\s*/gi, "")
          .replace("[", "")
          .replace("]", "");
        var StringToParse = "$orderby=" + orderByString;
        var result = parser.parse(StringToParse);
        return result.$orderby;
      }
      return null;
    },
    soData_orderby() {
      if (this.critBoxes_sort.length > 0)
        return getQueryOption_OrderByString(this.critBoxes_sort);
      return "";
    },
    portalIDforBooks() {
      if (this.info.hu.BookPermissionNeeded) {
        return getPortalIDFromLink(this.$route.fullPath);
      }
      return "";
    },
  },
  methods: {
    clearWViewStore() {
      this.$store.dispatch("wviewStore/CLEAR");
      this.$store.dispatch("wviewStoreB/CLEAR");
    },
    loadInfo() {
      this.clearWViewStore();
      if (this.WViewName) {
        this.$store.dispatch("wviewStore/LOAD_INFO", {
          vwiewName: this.WViewName,
        });
      }
    },
    loadNavIfneeded() {
      if (
        !this.isNavLoaded &&
        !this.isNavLoading &&
        this.$store.getters.isUserTokenHereAndStillValid()
      ) {
        this.$store.dispatch("nav/LOAD");
      }
    },
    loadData() {
      if (this.WViewName) {
        var queryParams = {
          $filter: this.soData_filter,
          $orderby: this.soData_orderby,
          byPassIsRequest: true,
        };
        if (this.isTestingDB) {
          queryParams.TestingDB = "true";
        }
        this.$store.dispatch("wviewStore/LOAD_DATA", {
          vwiewName: this.WViewName,
          queryParams: queryParams,
        });
        this.$store.dispatch("wviewStoreB/CLEAR");
      }
    },
    reg_loadData() {
      //loadData for .r side
      if (this.WViewName) {
        var queryParams = {
          $filter: this.reg_soData_filter,
          $orderby: this.soData_orderby,
          byPassIsRequest: true,
        };
        if (this.regCheckedBoxes.includes("isTestingDB")) {
          queryParams.TestingDB = "true"; //from Prod link to TestingDB
        } else if (this.regCheckedBoxes.includes("isProdDB")) {
          //from Test link to ProdDB
        } else {
          if (this.isTestingDB) {
            queryParams.TestingDB = "true"; //From Test Link to TestingDB
          }
        }
        this.$store.dispatch("wviewStore/LOAD_DATA", {
          vwiewName: this.WViewName,
          queryParams: queryParams,
        });
      }
    },
    loadRegression() {
      //load regression base <-> .r
      if (this.WViewName) {
        var queryParams = {
          $filter: this.soData_filter,
          $rfilter: this.reg_soData_filter,
          $orderby: this.soData_orderby,
          regColsToIgnore: this.reg_soData_keysInCommon,
          byPassIsRequest: true,
        };
        //Basis
        if (this.isTestingDB) {
          queryParams.TestingDB = "true";
        }
        //Regression
        if (this.regCheckedBoxes.includes("isTestingDB")) {
          queryParams.regTestingDB = "true"; //from Prod link to TestingDB
        } else if (this.regCheckedBoxes.includes("isProdDB")) {
          queryParams.regTestingDB = "false"; //from Test link to ProdDB
        } else {
          if (this.isTestingDB) {
            queryParams.regTestingDB = "true"; //From Test Link to TestingDB
          }
        }
        this.$store.dispatch("wviewStore/LOAD_REG", {
          vwiewName: this.WViewName,
          queryParams: queryParams,
        });
      }
    },
    loadExcel() {
      if (this.WViewName) {
        var queryParams = {
          $filter: this.soData_filter,
          $orderby: this.soData_orderby,
        };
        if (this.isTestingDB) {
          queryParams.TestingDB = "true";
        }
        this.$store.dispatch("wviewStore/LOAD_HUP_EXCEL", {
          vwiewName: this.WViewName,
          queryParams: queryParams,
        });
      }
    },
    loadQSXML() {
      if (this.WViewName) {
        var queryParams = {
          $filter: this.soData_filter,
          $orderby: this.soData_orderby,
        };
        if (this.isTestingDB) {
          queryParams.TestingDB = "true";
        }
        this.$store.dispatch("wviewStore/LOAD_HUP_QSXML", {
          vwiewName: this.WViewName,
          queryParams: queryParams,
        });
      }
    },
    loadCSV() {
      if (this.WViewName) {
        var queryParams = {
          $filter: this.soData_filter,
          $orderby: this.soData_orderby,
        };
        if (this.isTestingDB) {
          queryParams.TestingDB = "true";
        }
        this.$store.dispatch("wviewStore/LOAD_CSV", {
          vwiewName: this.WViewName,
          queryParams: queryParams,
        });
      }
    },
    resetBoxes() {
      this.retFilter = {};
      this.critBoxes_hidden = [];
      this.critBoxes_visible = [];
      // this.initialisedFor = ''
    },
    reg_resetBoxes() {
      if (this.isRegression) {
        this.reg_retFilter = {};
        this.reg_critBoxes_hidden = [];
        this.reg_critBoxes_visible = [];
      }
    },
    initialiseCritBoxes(noDefault = false) {
      this.resetBoxes();
      // console.log('outside nextTick callback:', this.$el.textContent)
      //put into next tick (= wait for the DOM to update the critBoxes)
      this.$nextTick(() => {
        // console.log('inside nextTick callback:', this.$el.textContent) // => 'not updated'
        if (this.isInfoLoaded) {
          //first try with SearchOrder < 10000
          for (var [key, value] of Object.entries(this.huColsS)) {
            if (value && !noDefault && value.SearchOrder < 1000) {
              this.critBoxes_visible.push(value);
            } else if (value) {
              this.critBoxes_hidden.push(value);
            }
          }
          //second try with hardcoded book/date (only works for risk)
          if (!noDefault && this.critBoxes_visible.length == 0) {
            for (var [key, value] of Object.entries(this.huColsS)) {
              if (
                value &&
                !noDefault &&
                (value.ColName === "book" || value.ColName === "date")
              ) {
                this.addCritBox(value);
              }
            }
          }
          //if not found take the first 2 crits to be defaultcrits (length == 0 is not good for other processes)
          if (!noDefault && this.critBoxes_visible.length == 0) {
            this.critBoxes_visible.push(this.critBoxes_hidden.shift());
            this.critBoxes_visible.push(this.critBoxes_hidden.shift());
          }
          this.initialisedFor = this.info.hu.WVName;
        }
      });
    },
    reg_initialiseCritBoxes(noDefault = false) {
      this.reg_resetBoxes();
      // console.log('outside nextTick callback:', this.$el.textContent)
      //put into next tick (= wait for the DOM to update the critBoxes)
      this.$nextTick(() => {
        // console.log('inside nextTick callback:', this.$el.textContent) // => 'not updated'
        if (this.isRegression && this.isInfoLoaded) {
          //everything in hidden for reg
          for (var [key, value] of Object.entries(this.huColsS)) {
            if (value) {
              this.reg_critBoxes_hidden.push(value);
            }
          }
        }
      });
    },
    addCritBox(value) {
      if (this.huColsS[value.ColName]) {
        this.critBoxes_visible.push(this.huColsS[value.ColName]);
        this.critBoxes_hidden = this.critBoxes_hidden.filter(function (el) {
          return el.ColName != value.ColName;
        });
      }
    },
    reg_addCritBox(value) {
      if (this.isRegression && this.huColsS[value.ColName]) {
        this.reg_critBoxes_visible.push(this.huColsS[value.ColName]);
        this.reg_critBoxes_hidden = this.reg_critBoxes_hidden.filter(function (
          el
        ) {
          return el.ColName != value.ColName;
        });
      }
    },
    removeCritBox(value) {
      if (this.huColsS[value.ColName]) {
        this.deleteFilterCriteria(value.ColName);
        this.critBoxes_hidden.push(this.huColsS[value.ColName]);
        this.critBoxes_visible = this.critBoxes_visible.filter(function (el) {
          return el.ColName != value.ColName;
        });
      }
    },
    reg_removeCritBox(value) {
      if (this.isRegression && this.huColsS[value.ColName]) {
        this.reg_deleteFilterCriteria(value.ColName);
        this.reg_critBoxes_hidden.push(this.huColsS[value.ColName]);
        this.reg_critBoxes_visible = this.reg_critBoxes_visible.filter(
          function (el) {
            return el.ColName != value.ColName;
          }
        );
      }
    },
    onCriteriaChange(filter) {
      this.retFilter[filter.name] = filter;
      //make a kind of copy to signal "computed" properties to update (needed as retfilter[retfilter.name] is a new "added" property that could not be tracked otherwise)
      this.retFilter = Object.assign({}, this.retFilter);
    },
    reg_onCriteriaChange(filter) {
      this.reg_retFilter[filter.name] = filter;
      //make a kind of copy to signal "computed" properties to update (needed as retfilter[retfilter.name] is a new "added" property that could not be tracked otherwise)
      this.reg_retFilter = Object.assign({}, this.reg_retFilter);
    },
    onRegCheckBoxesChange(event) {
      this.regCheckedBoxes = event;
    },
    deleteFilterCriteria(name) {
      delete this.retFilter[name];
      //make a kind of copy to signal "computed" properties to update (needed as retfilter[retfilter.name] is a new "added" property that could not be tracked otherwise)
      this.retFilter = Object.assign({}, this.retFilter);
    },
    reg_deleteFilterCriteria(name) {
      delete this.reg_retFilter[name];
      //make a kind of copy to signal "computed" properties to update (needed as retfilter[retfilter.name] is a new "added" property that could not be tracked otherwise)
      this.reg_retFilter = Object.assign({}, this.reg_retFilter);
    },
    onReinitialiseCriteria() {
      this.initialiseCritBoxes();
    },
    reg_onReinitialiseCriteria() {
      this.reg_initialiseCritBoxes();
    },
    onClearCriteria() {
      this.initialiseCritBoxes(true);
    },
    reg_onClearCriteria() {
      this.reg_initialiseCritBoxes(true);
    },
    initialiseSortingBoxes() {
      this.critBoxes_sort = [];
      //get the orderByStatement from info (parsed into AST)
      //lookup columns and bild the array for sort UI
      if (this.orderBy_SQLtoAST) {
        var colSortCandidate = [];
        for (var [key, value] of Object.entries(this.huColsL)) {
          colSortCandidate.push(value);
        }
        for (var i = 0; i < this.orderBy_SQLtoAST.length; i++) {
          var kyz = Object.keys(this.orderBy_SQLtoAST[i]);
          if (kyz.length === 1) {
            var colNFromSQL_LC = kyz[0].toLowerCase();
            var colNFromSQL = kyz[0];
            if (colNFromSQL_LC in this.info.hu.lookupColForListLowercase) {
              var colNFromLookup =
                this.info.hu.lookupColForListLowercase[colNFromSQL_LC];
            } else if (
              colNFromSQL_LC in this.info.hu.lookupSqlColForListLowercase
            ) {
              var colNFromLookup =
                this.info.hu.lookupSqlColForListLowercase[colNFromSQL_LC];
            }
            if (!!colNFromSQL_LC && colNFromLookup in this.huColsL) {
              var a = colSortCandidate.indexOf(this.huColsL[colNFromLookup]);
              if (a >= 0) {
                var sortCol = colSortCandidate.splice(a, 1)[0];
                // let sortCol = this.huColsL[colNFromLookup]
                if (this.orderBy_SQLtoAST[i][colNFromSQL] == "asc")
                  sortCol.sorting = 1;
                else if (this.orderBy_SQLtoAST[i][colNFromSQL] == "desc")
                  sortCol.sorting = -1;
                else sortCol.sorting = 0;
                this.critBoxes_sort.push(sortCol);
              }
            }
          }
        }
        for (var i = 0; i < colSortCandidate.length; i++) {
          var sortCol = colSortCandidate[i];
          sortCol.sorting = 0;
          this.critBoxes_sort.push(sortCol);
        }
      } else {
        // no info, push everything without sort..
        if (this.huColsL) {
          for (var [key, value] of Object.entries(this.huColsL)) {
            value.sorting = 0;
            this.critBoxes_sort.push(value);
          }
        }
      }
    },
    onChangeSorting(critBoxSort) {
      var a = this.critBoxes_sort.indexOf(critBoxSort);
      //remove and add at the same place, to induce event
      var sortCol = this.critBoxes_sort.splice(a, 1)[0];
      this.critBoxes_sort.splice(a, 0, critBoxSort);
    },
    onClearSorting() {
      this.initialiseSortingBoxes();
      for (let i = 0; i < this.critBoxes_sort.length; i++) {
        const critBox = this.critBoxes_sort[i];
        critBox.sorting = 0;
      }
    },
    onReinitialiseSorting(critBoxSort) {
      this.initialiseSortingBoxes();
    },
    getWorkingday(days) {
      let businessDays = Math.abs(days);
      let counter = Math.sign(days); // set to 1 to count from next business day
      let increment = Math.sign(days); // set to 1 to count from next business day
      while (businessDays > 0) {
        var tmp = new Date();
        tmp.setHours(0, 0, 0, 0);
        // console.log("before: " + tmp);
        tmp.setDate(tmp.getDate() + counter);
        counter += increment;
        switch (tmp.getDay()) {
          case 0:
          case 6:
            break; // sunday & saturday
          default:
            businessDays--;
        }
      }
      // console.log("after: " + tmp);
      return tmp;
    },
    isRiskBook(colName) {
      if (!!colName) {
        switch (colName.toLowerCase()) {
          case "book":
          case "account_id":
          case "account":
          case "mandat":
          case "opusnr":
            return true;
          default:
            return false;
        }
      }
    },
    getCritBoxType(pCritBox) {
      const critBox = pCritBox;
      if (!!critBox && !!critBox.ColType) {
        if (!!critBox.ColName) {
          if (this.isRiskBook(critBox.ColName)) {
            return "critBook";
          }
        }
        if (!!critBox.ColType) {
          switch (critBox.ColType) {
            case "name":
            case "text":
            case "bool":
              return "critString";
            case "number":
            case "perc":
            case "percmath":
              return "critNumber";
            case "date":
              return "critDate";
            case "logdate":
              return "critDateTime";
            case "date":
              return "critDate";
            default:
              return "critString";
          }
        }
      }
      return "critString";
    },
    toggleRegression(value) {
      this.isRegression = value.length > 0 ? true : false;
    },
  },
  components: {
    // spinnerBounce,
    SdxBreadcrumb,
    "accitem-crit-string": WVFilterAccItem_CritString,
    "accitem-crit-number": WVFilterAccItem_CritNumber,
    "accitem-crit-book": WVFilterAccItem_CritBook,
    "accitem-crit-date": WVFilterAccItem_CritDate,
    "accitem-crit-datetime": WVFilterAccItem_CritDateTime,
    "accitem-more": WVFilterAccItem_More,
    "accitem-sort": WVFilterAccItem_Sort,
    "accitem-crit-checkboxes": WVFilterAccItem_CritCheckBoxes,
    // "accitem-viewselector": WVFilterAccItem_ViewSelector,
    QsuiAccordion,
    QsuiAccordionItem,
    ButtonsRow,
    ExcelExport,
  },
  props: {
    isOpen: { type: Boolean, default: true },
  },
};
</script>

<style></style>
