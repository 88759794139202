<template>
  <div class="row StandardView">
    <comp-list :isOpen="filterIsOpen" />
    <toggle-button v-model="filterIsOpen" />
    <comp-form />
  </div>
</template>

<script>
import ToggleButton from "@/components/ui_utilities/ToggleButton";
import fmdFundsListVue from "./admin/fmdFundsList.vue";
import fmdFundFormVue from "./admin/fmdFundForm.vue";

export default {
  data() {
    return {
      filterIsOpen: true,
    };
  },
  components: {
    ToggleButton,
    "comp-list": fmdFundsListVue,
    "comp-form": fmdFundFormVue,
  },
};
</script>
