<template>
  <div
    class="cortana-side-nav bg bg--gray extra-width"
    :class="{ hidden: !isOpen }"
  >
    <div class="cortana-side-nav__wrapper qsui-admin-wrapper">
      <div class="text-h4">Group List:</div>
      <div class="qsui-admin-list-filerplus">
        <a
          class="button button__icon button--secondary qsui-admin-list-button"
          href="javascript:void(0);"
          @click="plusElem()"
        >
          <i class="icon icon-061-plus" aria-hidden="true"></i>
        </a>
      </div>
      <div class="qsui-admin-list-filerplus">
        <div>
          <sdx-textbox v-model="oValueFilter" label="filter..." type="text" />
        </div>
      </div>
      <div
        v-if="showSpinner"
        class="loader-spinner loader-spinner--small"
      ></div>
      <ul v-else class="qsui-admin-list">
        <div v-for="elem in filteredData">
          <li
            class="qsui-admin-list-item qsui-admin-list-item-click"
            @click="loadElem(elem)"
          >
            {{ elem.display }}
            <div class="qsui-admin-list-item-desc">{{ elem.Description }}</div>
          </li>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SDXTextbox from "@/components/ui_utilities/SDXTextbox";
export default {
  data() {
    return {
      oValueFilter: { value: "", valid: undefined },
      checkedActive: true,
    };
  },
  created() {
    this.$store.dispatch("adminGroup/LOAD_GROUPS_DATA");
  },
  computed: {
    ...mapGetters({
      groupsData: "adminGroup/groupsData",
      isGroupsLoading: "adminGroup/isGroupsLoading",
    }),
    filteredData() {
      if (this.groupsData) {
        const lowcasevalue = this.oValueFilter.value.toLowerCase();
        return this.groupsData.filter((elem) => {
          let filterFilter =
            !lowcasevalue ||
            elem.display.toLowerCase().includes(lowcasevalue) ||
            (!!elem.Description &&
              elem.Description.toLowerCase().includes(lowcasevalue));
          return filterFilter;
        });
        return this.groupsData;
      }
      return [];
    },
    showSpinner() {
      return this.isGroupsLoading;
    },
  },
  methods: {
    loadElem(elem) {
      this.$store.dispatch("adminGroup/LOAD_GROUP_DATA", {
        GroupID: elem.GroupID,
      });
    },
    plusElem() {
      //if nothing is loaded, create Form appears
      this.$store.dispatch("adminGroup/CLEAR_GROUP");
    },
  },
  props: {
    isOpen: { type: Boolean, default: true },
  },
  components: {
    "sdx-textbox": SDXTextbox,
  },
};
</script>
