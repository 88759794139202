<template>
  <div class="input-field-select">
    <label :for="id"
      ><span class="label">{{ label }}</span></label
    >
    <treeselect
      v-model="valueProxy"
      :clearOnSelect="true"
      :close-on-select="true"
      :clearable="true"
      :multiple="false"
      :async="true"
      :load-options="loadOptions"
      :defaultOptions="defaultOptions"
      :id="id"
      placeholder="type at least 3 characters or a space to start the search"
      loading-text="type at least 3 characters or a space to start the search"
    />
  </div>
</template>

<script>
import randomstring from "randomstring";
import Treeselect, { ASYNC_SEARCH } from "@riophae/vue-treeselect";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      id: randomstring.generate(10),
      defaultOptions: [],
    };
  },
  created() {
    if (!this.isBooksLoaded && !this.isBooksLoading) {
      this.$store.dispatch("adminBook/LOAD_BOOKS_DATA");
    } else {
      this.setDefaultOptions();
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.defaultOptions = null;
    });
  },
  computed: {
    ...mapGetters({
      booksData: "adminBook/booksData",
      isBooksLoading: "adminBook/isBooksLoading",
      isBooksLoaded: "adminBook/isBooksLoaded",
    }),
    valueProxy: {
      get() {
        if (this.value && this.isBooksLoaded) {
          return this.value && this.value != -1 ? this.value : undefined;
        }
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    setDefaultOptions() {
      if (this.value && this.value != -1 && this.isBooksLoaded)
        this.defaultOptions = this.getOptions(this.valueProxy);
    },
    getOption(adminBook) {
      if (adminBook) {
        return {
          id: adminBook.BookID,
          label: adminBook.display + " " + adminBook.Description,
        };
      }
      return { id: "unknown", label: "unknown" };
    },
    getOptions(filter) {
      if (this.isBooksLoaded) {
        var ret = [];
        if (!!filter) {
          this.booksData.forEach((element) => {
            let el = this.getOption(element);
            if (
              (!!el.id &&
                (el.id.toString() + "Q").toLowerCase().indexOf(filter) > -1) ||
              (!!el.label && el.label.toLowerCase().indexOf(filter) > -1)
            ) {
              ret.push(el);
            }
          });
        } else {
          this.booksData.forEach((element) => {
            ret.push(this.getOption(element));
          });
        }
        return ret;
      }
      return [];
    },
    loadOptions({ action, searchQuery, callback }) {
      if (action === ASYNC_SEARCH) {
        if (this.isBooksLoaded && !!searchQuery) {
          const query = searchQuery.toLowerCase();
          if (query.length > 2 || query.indexOf(" ") > -1) {
            const options = this.getOptions(searchQuery);
            callback(null, options);
          }
        }
      }
    },
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {},
  },
  components: {
    Treeselect,
  },
};
</script>
