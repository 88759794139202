import axios from "axios";
import store from "..";

const state = {
  fmdFundData: {},
  statusFmdFund: "",
  fsconfmap: [],
};

const getters = {
  isFmdFundLoading() {
    return state.statusFmdFund === "loading";
  },
  isFmdFundLoaded() {
    return state.statusFmdFund === "loaded";
  },
  fmdFundData() {
    return state.fmdFundData;
  },
  fsconfmap() {
    return state.fsconfmap;
  },
};

const mutations = {
  SET_FMDFUND_DATA(state, data) {
    state.fmdFundData = data;
    //set the default here (in store) as the select-box cannot defaults
    if (!state.fmdFundData.MasterFonds) {
      state.fmdFundData.MasterFonds = -1;
    }
    if (!state.fmdFundData.segmentof) {
      state.fmdFundData.segmentof = -1;
    }
  },
  SET_STATUS_FMDFUND_LOADING(state) {
    state.statusFmdFund = "loading";
  },
  SET_STATUS_FMDFUND_LOADED(state) {
    state.statusFmdFund = "loaded";
  },
  SET_STATUS_FMDFUND_ERROR(state) {
    state.statusFmdFund = "error";
  },
  SET_FS_CONFMAP_DATA(state, data) {
    state.fsconfmap = data;
  },
  CLEAR_DATA(state) {
    state.fmdFundData = [];
    state.statusFmdFund = "";
  },
  CLEAR_FMDFUND_DATA(state) {
    state.fmdFundData = [];
    state.statusFmdFund = "";
  },
};

const actions = {
  SAVE_FMDFUND: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("CLEAR_FMDFUND_DATA");
      commit("SET_STATUS_FMDFUND_LOADING");
      axios
        .post("/api/fmdfund", payload)
        .then((response) => {
          commit("SET_FMDFUND_DATA", response.data);
          commit("SET_STATUS_FMDFUND_LOADED");
          store.dispatch("notifStore/successCreated", "FundMasterData saved.");
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_FMDFUND_ERROR");
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          reject(err);
        });
    });
  },
  LOAD_FMDFUND_DATA({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_FMDFUND_LOADING");
      commit("CLEAR_FMDFUND_DATA");
      axios
        .get("/api/fmdfund/" + payload.book_id)
        .then((response) => {
          commit("SET_FMDFUND_DATA", response.data);
          commit("SET_STATUS_FMDFUND_LOADED");
          resolve();
        })
        .catch((err) => {
          if (err && err.response && err.response.status) {
            if (err.response.status == 404) {
              //not found = new one
              commit("SET_FMDFUND_DATA", {});
              commit("SET_STATUS_FMDFUND_LOADED");
            } else {
              store.dispatch(
                "notifStore/alert",
                err &&
                  err.response &&
                  err.response.data &&
                  err.response.data.Message
                  ? err.response.data.Message
                  : "Unknown Backend Error"
              );
              commit("SET_STATUS_FMDFUND_ERROR");
            }
          }
          // reject(err);
        });
    });
  },
  LOAD_FS_CONFMAP_DATA({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/fmdfund/confmap")
        .then((response) => {
          commit("SET_FS_CONFMAP_DATA", response.data);
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error while loading fmdFund/confmap"
          );
          reject(err);
        });
    });
  },
  CLEAR_STORE({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CLEAR_DATA");
    });
  },
  CLEAR_FMDFUND({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CLEAR_FMDFUND_DATA");
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
