import axios from "axios";

const state = {
  rtwData: [],
  statusRtwData: "",
  irsDestinationsData: [],
  statusIrsDestinationsData: "",
  irsTypesData: [],
  statusIrsTypesData: "",
};

const getters = {
  isRtwDataLoading() {
    return state.statusRtwData === "loading";
  },
  isRtwDataLoaded() {
    return state.statusRtwData === "loaded";
  },
  rtwData() {
    return state.rtwData;
  },
  isIrsDestinationsDataLoading() {
    return state.statusIrsDestinationsData === "loading";
  },
  isIrsDestinationsDataLoaded() {
    return state.statusIrsDestinationsData === "loaded";
  },
  irsDestinationsData() {
    return state.irsDestinationsData;
  },
  isIrsTypesDataLoading() {
    return state.statusIrsTypesData === "loading";
  },
  isIrsTypesDataLoaded() {
    return state.statusIrsTypesData === "loaded";
  },
  irsTypesData() {
    return state.irsTypesData;
  },
};

const mutations = {
  SET_RTW_DATA(state, data) {
    state.rtwData = data;
  },
  SET_STATUS_RTW_DATA_LOADING(state) {
    state.statusRtwData = "loading";
  },
  SET_STATUS_RTW_DATA_LOADED(state) {
    state.statusRtwData = "loaded";
  },
  SET_STATUS_RTW_DATA_ERROR(state) {
    state.statusRtwData = "error";
  },
  SET_IRSDESTINATIONS_DATA(state, data) {
    state.irsDestinationsData = [];
    state.irsDestinationsData.push({
      id: -1,
      Desc: "Download",
      label: "Download",
    });
    for (var i in data) {
      // data[i].label = data[i].Desc
      state.irsDestinationsData.push({
        id: data[i].id,
        Desc: data[i].Desc,
        label: data[i].Desc,
      });
    }
  },
  SET_STATUS_IRSDESTINATIONS_DATA_LOADING(state) {
    state.statusIrsDestinationsData = "loading";
  },
  SET_STATUS_IRSDESTINATIONS_DATA_LOADED(state) {
    state.statusIrsDestinationsData = "loaded";
  },
  SET_STATUS_IRSDESTINATIONS_DATA_ERROR(state) {
    state.statusIrsDestinationsData = "error";
  },
  SET_IRSTYPES_DATA(state, data) {
    state.irsTypesData = data;
  },
  SET_STATUS_IRSTYPES_DATA_LOADING(state) {
    state.statusIrsTypesData = "loading";
  },
  SET_STATUS_IRSTYPES_DATA_LOADED(state) {
    state.statusIrsTypesData = "loaded";
  },
  SET_STATUS_IRSTYPES_DATA_ERROR(state) {
    state.statusIrsTypesData = "error";
  },
};

const actions = {
  LOAD_RTW({ commit }) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_RTW_DATA_LOADING");
      axios
        .get("/api/irsrequest/ReportToWview")
        .then((response) => {
          commit("SET_RTW_DATA", response.data);
          commit("SET_STATUS_RTW_DATA_LOADED");
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_RTW_DATA_ERROR");
        });
    });
  },
  LOAD_IRSDESTINATIONS({ commit }) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_IRSDESTINATIONS_DATA_LOADING");
      axios
        .get("/api/irsrequest/Destinations")
        .then((response) => {
          commit("SET_IRSDESTINATIONS_DATA", response.data);
          commit("SET_STATUS_IRSDESTINATIONS_DATA_LOADED");
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_IRSDESTINATIONS_DATA_ERROR");
        });
    });
  },
  LOAD_IRSTYPES({ commit }) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_IRSTYPES_DATA_LOADING");
      axios
        .get("/api/irsrequest/types")
        .then((response) => {
          commit("SET_IRSTYPES_DATA", response.data);
          commit("SET_STATUS_IRSTYPES_DATA_LOADED");
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_IRSTYPES_DATA_ERROR");
        });
    });
  },
  SEND_IRSREQUEST: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      var requestConfig = {
        params: {},
      };
      axios
        .post("/api/irsrequest", payload, requestConfig)
        .then((resp) => {
          const result = resp.data;
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  GET_IRSREQUEST({ commit }, irs_id) {
    return new Promise((resolve, reject) => {
      var requestConfig = {
        params: {},
      };
      axios
        .get("/api/irsrequest/" + irs_id, requestConfig)
        .then((resp) => {
          const result = resp.data;
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  DOWNLOAD_DOCUMENT({ commit }, payload) {
    return new Promise((resolve, reject) => {
      var requestConfig = {
        responseType: "blob",
        params: {},
      };
      axios
        .get("/api/irsrequest/" + payload.IRSId + "/doc", requestConfig)
        .then((response) => {
          const downloadUrl = window.URL.createObjectURL(
            new Blob([response.data])
          );
          const link = document.createElement("a");
          link.href = downloadUrl;
          let fileName = "";
          if (!!response.headers["content-disposition"]) {
            fileName =
              response.headers["content-disposition"].split("filename=")[1];
          }
          if (!fileName) {
            fileName = "File.pdf";
          }
          link.setAttribute("download", fileName); //any other extension
          document.body.appendChild(link);
          link.click();
          setTimeout(() => {
            URL.revokeObjectURL(downloadUrl);
            link.remove();
          }, 15000);

          resolve(fileName);
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            "Error while Loading IRS Document"
          );
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
