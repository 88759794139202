<template>
  <qsui-accordion-item
    @removeCritBox="removeCritBox"
    :class="{ regression: isRegression }"
  >
    <p slot="header">{{ col.DisplayName }}{{ isRegression ? ".r" : "" }}</p>
    <div slot="collapse" class="mandatSelect acc-flex-crit-string">
      <div class="form" v-if="showAsCheckBoxes">
        <div
          v-for="dataCheckBox in dataCheckBoxes"
          class="checkbox input-field"
        >
          <input
            type="checkbox"
            :name="dataCheckBox.name"
            :id="dataCheckBox.name"
            :value="dataCheckBox.value"
            v-model="checkedNames"
            :disabled="dataCheckBox.disabled"
            @change="onChangeCheckBoxes()"
          />
          <label :for="dataCheckBox.name">{{ dataCheckBox.name }}</label>
          <!-- <p :id="'desc'+dataCheckBox.name">Supporting description</p> -->
        </div>
        <!-- <span>Checked names: {{ checkedNames }}</span> -->
      </div>
      <div class="form qx-flex-col" v-else>
        <div class="form acc-flex-crit-book">
          <treeselect
            v-model="valueBook"
            :clearOnSelect="true"
            :close-on-select="true"
            :clearable="true"
            :multiple="true"
            :async="true"
            :load-options="loadOptions"
            placeholder="type at least 3 characters or a space to start the search"
            loading-text="type at least 3 characters or a space to start the search"
          />
          <i
            v-if="additionalCriterias.length > 0"
            class="
              icon
              wvfilter-icon wvfilter-green-button wvfilter-text-button
            "
            :title="andOrTitle"
            aria-hidden="true"
            @click="toggleAndOr"
            >{{ charAndOr }}</i
          >
          <i
            v-else
            class="icon icon-061-plus wvfilter-icon"
            title="Add filter criteria"
            aria-hidden="true"
            @click="addCrit"
          ></i>
        </div>
        <crit-string
          v-for="crit in additionalCriterias"
          :key="crit.key"
          :crit="crit"
          :huCol="huCol"
          :isOr="isOr"
          :showExtendedOperator="true"
          @input="onInput($event)"
          @addCrit="addCrit"
          @toggleAndOr="toggleAndOr"
          @onEnterKey="$emit('onEnterKey')"
        ></crit-string>
      </div>
    </div>
  </qsui-accordion-item>
</template>

<script>
import QsuiAccordionItem from "@/components/ui_utilities/QsuiAccordionItem";
import CritString from "@/components/wv_filter/filter_items/CritString";
import Treeselect, { ASYNC_SEARCH } from "@riophae/vue-treeselect";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      filter: {
        name: "",
        binaryOperator: "or",
        criterias: [
          {
            key: -1,
            isLast: false,
            operator: "=",
            value: "",
          },
          {
            key: 1,
            isLast: true,
            operator: "=",
            value: undefined,
          },
        ],
      },
      valueOperatorAmount: "=",
      valueBook: [],
      checkedNames: [],
    };
  },
  watch: {
    valueBook: function () {
      if (this.valueBook) {
        //keep only additional criterias
        let additionalCriterias = this.filter.criterias.filter((crit) => {
          return crit.key >= 0;
        });
        this.filter.criterias = additionalCriterias;
        this.valueBook.forEach((element) => {
          this.filter.criterias.unshift({
            key: -1, //key -1 for crit coming from treeselect
            isLast: false,
            operator: "=",
            value: element,
          });
        });
        this.$emit("input", this.filter);
      }
    },
  },
  created() {
    if (
      !this.isBookLoaded &&
      !this.isBookLoading &&
      this.$store.getters.isUserTokenHereAndStillValid()
    ) {
      this.$store.dispatch("book/LOAD");
    }
  },
  computed: {
    ...mapGetters({
      isBookLoading: "book/isDataLoading",
      isBookLoaded: "book/isDataLoaded",
      bookData: "book/bookData",
      portalBookDataByPortalID: "book/portalBookDataByPortalID",
    }),
    books() {
      if (this.isBookLoaded) {
        if (!!this.portalID) {
          return this.portalBookDataByPortalID(this.portalID);
        } else {
          return this.bookData;
        }
      }
    },
    col() {
      this.filter.name = this.huCol.ColName;
      return this.huCol;
    },
    charAndOr() {
      return this.isOr ? "|" : "&";
    },
    isOr() {
      return this.filter.binaryOperator == "or" ? true : false;
    },
    andOrTitle() {
      return this.isOr ? "OR" : "AND";
    },

    additionalCriterias() {
      return this.filter.criterias.filter((crit) => {
        return crit.key >= 0;
      });
    },
    showAsCheckBoxes() {
      if (
        this.isBookLoaded &&
        this.books &&
        this.books.length > 0 &&
        this.books.length < 9
      ) {
        return true;
      }
      return false;
    },
    dataCheckBoxes() {
      var ret = [];
      if (this.showAsCheckBoxes) {
        this.books.forEach((ctBook) => {
          ret.push({
            name: ctBook.label,
            value: ctBook.id,
            checked: true,
            disabled: false,
          });
        });
      }
      return ret;
    },
  },
  methods: {
    onChangeCheckBoxes() {
      this.filter.criterias = [];
      this.checkedNames.forEach((element) => {
        this.filter.criterias.push({
          operator: "=",
          value: element,
        });
      });
      this.$emit("input", this.filter);
    },
    loadOptions({ action, searchQuery, callback }) {
      if (action === ASYNC_SEARCH) {
        if (this.isBookLoaded && !!searchQuery) {
          const query = searchQuery.toLowerCase();
          if (query.length > 2 || query.indexOf(" ") > -1) {
            const options = this.books.filter((el) => {
              return (
                (!!el.id && el.id.toLowerCase().indexOf(query) > -1) ||
                (!!el.label && el.label.toLowerCase().indexOf(query) > -1)
              );
            });
            callback(null, options);
          }
        }
      }
    },
    onInput(crit) {
      const critIndex = this.filter.criterias.findIndex((searchCrit) => {
        return searchCrit.key == crit.key;
      });
      this.filter.criterias[critIndex].value = crit.value;
      this.filter.criterias[critIndex].operator = crit.operator;
      this.$emit("input", this.filter);
    },
    addCrit() {
      //reset isLast
      this.filter.criterias.forEach((crit) => {
        crit.isLast = false;
      });
      let cnt = this.additionalCriterias.length;
      this.filter.criterias.push({
        key: cnt + 1,
        isLast: true,
        operator: "=",
        value: undefined,
      });
    },
    toggleAndOr() {
      if (this.filter.binaryOperator == "and") {
        this.filter.binaryOperator = "or";
      } else {
        this.filter.binaryOperator = "and";
      }
    },
    removeCritBox() {
      // this.resetCrit()
      this.$emit("removeCritBox", this.huCol);
    },
  },
  props: {
    huCol: {
      type: Object,
      default: function () {
        return {
          ColName: "DefaultValue",
          ColType: "name",
        };
      },
    },
    portalID: {
      type: String,
      default: function () {
        return "116";
      },
    },
    authorizedBooks: {
      type: Array,
      default: function () {
        // return ["=", ">", ">=", "<", "<=", "%", "!%"];
        return [
          {
            id: "198",
            label: "ethna defensiv",
            // children: null
          },
          {
            id: "199",
            label: "ethna my balance",
            // children: null
          },
          {
            id: "200",
            label: "ethna just fine",
            // children: null
          },
          {
            id: "10",
            label: "my favorite",
            // children: null
          },
        ];
      },
    },
    isRegression: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  components: {
    QsuiAccordionItem,
    Treeselect,
    CritString,
  },
};
</script>
