<template>
  <div
    class="qsui-accordion-item"
    :class="{ 'is-open': isOpen, regression: isRegression }"
  >
    <div class="qsui-accordion-toggle" @click="toggleIsOpen">
      <div class="qsui-accordion-toggle-main">
        <i
          class="icon icon-013-chevron-down icon--s1"
          :class="{ 'icon--flip-vertical': isOpen }"
        ></i>
        <div class="qsui-accordion-header">
          <p slot="header">add... {{ isRegression ? ".r" : "" }}</p>
        </div>
      </div>
    </div>
    <div class="qsui-accordion-collapse" :class="{ 'is-open': isOpen }">
      <div>
        <div>
          <ul>
            <li v-for="critBox in critBoxes" v-bind:key="critBox.key">
              <a v-on:click="onclick(critBox)">
                <i class="icon icon-061-plus icon--s1"></i>
                {{ critBox.DisplayName }}
              </a>
            </li>
          </ul>
        </div>
        <div>
          <i
            :class="{
              'icon icon-071-retry wvfilter-icon wvfilter-green-button': isOpen,
            }"
            @click="$emit('reinitialiseCriteria')"
          ></i>
          <i
            :class="{ 'icon icon-008-bin wvfilter-icon': isOpen }"
            @click="$emit('clearCriteria')"
          ></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    onclick(critBox) {
      this.$emit("add", critBox);
    },
    toggleIsOpen() {
      this.isOpen = !this.isOpen;
    },
  },
  created() {
    this.isOpen = this.startOpen;
  },
  props: {
    critBoxes: {
      type: Array,
      default: function () {
        // return ["=", ">", ">=", "<", "<=", "%", "!%"];
        return [
          {
            ColName: "npv",
            DisplayName: "npv",
            // children: null
          },
          {
            ColName: "limit",
            DisplayName: "limit",
            // children: null
          },
          {
            ColName: "ISIN",
            DisplayName: "ISIN",
            // children: null
          },
          {
            ColName: "var",
            DisplayName: "var",
            // children: null
          },
        ];
      },
    },
    isRegression: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    startOpen: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
};
</script>
