<template>
  <div>
    <div class="button-group exportButton button-group--responsive">
      <div
        class
        :class="{ disabled: !showButton }"
        title="Download QS_XML (QuantaxReporting)"
        @click="startExportQSXML"
      >
        <i
          class="icon exportImages icon-126-document-code icon--s3"
          aria-hidden="true"
        ></i>
      </div>
      <div
        class
        :class="{ disabled: !showButton }"
        title="Download Excel File"
        @click="startExportXLSX"
      >
        <i
          class="icon exportImages icon-120-document-excel icon--s3"
          aria-hidden="true"
        ></i>
      </div>
      <div
        class
        :class="{ disabled: !showButton }"
        title="Export Table to CSV"
        @click="startExportCSV"
      >
        <i
          class="icon exportImages icon-118-document icon--s3"
          aria-hidden="true"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import XLSX from 'xlsx'

export default {
  data() {
    return {
      isExcel: true,
      isExpanded: false,
    };
  },
  computed: {
    ...mapGetters({
      isInfoLoading: "wviewStore/isInfoLoading",
      isInfoLoaded: "wviewStore/isInfoLoaded",
      wvInfo: "wviewStore/wvInfo",
      isDataLoading: "wviewStore/isDataLoading",
      isDataLoaded: "wviewStore/isDataLoaded",
      wvData: "wviewStore/wvData",
    }),
    items() {
      if (this.isDataLoaded) {
        return this.wvData.slice(0, this.maxRows);
      }
    },
    showButton() {
      if (this.isInfoLoaded && this.isDataLoaded) {
        return true;
      }
    },
  },
  methods: {
    startExportXLSX() {
      this.isExcel = true;
      this.$emit("onExcelDownload");
    },
    startExportQSXML() {
      this.$emit("onQSXMLDownload");
    },
    startExportCSV() {
      this.$emit("onCSVDownload");
    },
  },
};
</script>
