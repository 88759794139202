<template>
  <div class="navedit navedit-menu">
    <div class="navedit navedit-menu-header">
      <i
        class="icon icon-013-chevron-down icon--s2 navedit-toggle-icon"
        :class="{ 'icon--flip-vertical': isOpen }"
        @click="toggleOpen"
      ></i>
      <input
        class="navedit-input"
        :value="menu.name"
        name="name"
        placeholder="Name"
        :disabled="!editMode"
        @change="onEdit($event.target)"
      />
      <input
        class="navedit-input navedit-input-short"
        :value="menu.sh"
        name="sh"
        placeholder="sh"
        spellcheck="false"
        :disabled="!editMode"
        @change="onEdit($event.target)"
      />
      <input
        class="navedit-input navedit-input-short"
        :value="menu.text"
        name="text"
        placeholder=""
        spellcheck="false"
        title="Display filter for qsui version: v1:qsui1 only, v2:qsui2 only, 'empty': no filter, qsui1 und qsui2 (default)"
        :disabled="!editMode"
        @change="onEdit($event.target)"
      />
      <!-- <i
        class="icon wvfilter-icon class-for-id-navedit-toggle-qsuionly"
        :class="[
          { 'wvfilter-icon-disabled': !editMode },
          isQsuiOnly ? 'icon-064-record-filled' : 'icon-063-record',
        ]"
        :title="
          isQsuiOnly
            ? 'this is a QSUI Menu, click to change'
            : 'this is a QxPortal Menu, click to change'
        "
        aria-hidden="true"
        @click="onEdit($event.target)"
      ></i> -->
      <i
        class="icon icon-008-bin wvfilter-icon"
        :class="{ 'wvfilter-icon-disabled': hasChild || !editMode }"
        title="Delete Menu (delete child(s) first!)"
        aria-hidden="true"
        @click="onDelete()"
      ></i>
      <i
        class="icon icon-061-plus wvfilter-icon wvfilter-green-button"
        :class="{ 'wvfilter-icon-disabled': !editMode }"
        title="Add Submenu"
        aria-hidden="true"
        @click="addSubmenu()"
      ></i>
    </div>
    <div v-if="isOpen" class="navedit navedit-menu-content">
      <edit-submenu
        v-for="submenu in menu.SubMenus"
        :key="submenu.id"
        :submenu="submenu"
        :editMode="editMode"
      />
    </div>
  </div>
</template>

<script>
import QxpNavEditSubMenu from "@/components/admin/QxpNav/QxpNavEditSubmenu";
import { mapGetters } from "vuex";
export default {
  created() {
    this.$store.dispatch("adminPortal/INIT_MENU_OPEN", this.menu.id);
  },
  computed: {
    ...mapGetters({
      openMenuData: "adminPortal/openMenuData",
    }),
    isOpen() {
      return this.openMenuData[this.menu.id];
    },
    isQsuiOnly() {
      return this.menu.text && this.menu.text.toLowerCase() == "qsui";
    },
    hasChild() {
      return this.menu && this.menu.SubMenus && this.menu.SubMenus.length > 0;
    },
  },
  methods: {
    toggleOpen() {
      this.$store.dispatch("adminPortal/TOGGLE_MENU_OPEN", this.menu.id);
    },
    addSubmenu() {
      if (!this.editMode) {
        return;
      }
      if (this.menu.id > 0) {
        this.$store.dispatch("adminPortal/CREATE_SUBMENU", {
          ParentID: this.menu.id,
          name: "new Submenu",
        });
        if (!this.isOpen) {
          this.toggleOpen();
        }
      }
    },
    onEdit(target) {
      if (!this.editMode) {
        return;
      }
      let ret = this.cloneProp();
      if (target.name == "name") {
        if (ret.name == target.value) {
          return;
        }
        ret.name = target.value;
      }
      if (target.name == "sh") {
        if (ret.sh == target.value) {
          return;
        }
        ret.sh = target.value;
      }
      if (target.name == "text") {
        if (ret.text == target.value) {
          return;
        }
        ret.text = target.value;
      }
      //   if (target.className.includes("class-for-id-navedit-toggle-qsuionly")) {
      //     if (ret.text && ret.text.toLowerCase() == "qsui") {
      //       ret.text = "";
      //     } else {
      //       ret.text = "QSUI";
      //     }
      //   }
      this.$store.dispatch("adminPortal/MODIFY_MENU", ret);
    },
    onDelete() {
      if (!this.editMode) {
        return;
      }
      if (this.menu) {
        if (this.menu.SubMenus && this.menu.SubMenus.length > 0) {
          this.$store.dispatch(
            "setError",
            "Could not delete, plz delete child(s) first"
          );
        } else {
          this.$store.dispatch("adminPortal/DELETE_MENU", {
            id: this.menu.id,
          });
        }
      }
    },
    cloneProp() {
      return {
        name: this.menu.name,
        text: this.menu.text,
        id: this.menu.id,
        sh: this.menu.sh,
      };
    },
  },
  props: {
    editMode: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    menu: {
      type: Object,
      default: function () {
        return {
          name: "MenuName",
          text: "QSUI",
          id: -1,
          sh: 4,
          SubMenus: [
            {
              name: "subMenuName",
              id: -1,
              sh: 4,
              Links: [
                {
                  id: 2,
                  name: "myName",
                  link: "myLink",
                  intWin: true,
                  sh: 5,
                },
                {
                  id: 3,
                  name: "myLinkNameB",
                  link: "myLinkB",
                  intWin: false,
                  sh: 4,
                },
              ],
            },
            {
              name: "subMenuNameB",
              id: 5,
              sh: 4,
              Links: [
                {
                  id: 2,
                  name: "myName",
                  link: "myLink",
                  intWin: true,
                  sh: 5,
                },
                {
                  id: 3,
                  name: "myLinkNameB",
                  link: "myLinkB",
                  intWin: false,
                  sh: 4,
                },
              ],
            },
          ],
        };
      },
    },
  },
  components: {
    "edit-submenu": QxpNavEditSubMenu,
  },
};
</script>

<style></style>
