<template>
  <div class="page-header">
    <div class="page-header-logo">
      <router-link class="logo" v-bind:to="'/'">
        <span v-if="isDarkTheme">
          <h1>IP.Risk online</h1>
        </span>
        <span v-else class="logo-type">
          <img src="@/assets/ipconcept_logo2.jpg" alt="IPConcept" />
        </span>
      </router-link>
    </div>
    <div class="page-header-right">
      <div class="page-header-search"
        ><input
          v-show="showNavigationSearch"
          type="text"
          class="input-field input-text"
          spellcheck="false"
          placeholder="navigation search"
          v-model="searchstring"
      /></div>
      <div class="page-header-icon">
        <ul>
          <login-partial />
        </ul>
        <login-partial-flyout />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoginPartial from "@/components/auth_login/LoginPartial";
import LoginPartialFlyout from "@/components/auth_login/LoginPartialFlyout";

export default {
  created() {
    if (
      !this.isNavLoaded &&
      !this.isNavLoading &&
      this.$store.getters.isUserTokenHereAndStillValid()
    ) {
      this.$store.dispatch("nav/LOAD");
    }
  },
  computed: {
    ...mapGetters({
      isNavLoading: "nav/isDataLoading",
      isNavLoaded: "nav/isDataLoaded",
      navData: "nav/navData",
      navSearchString: "nav/navSearchString",
    }),
    searchstring: {
      get() {
        return this.navSearchString;
      },
      set(value) {
        this.$store.dispatch("nav/PUSH_SEARCH_STRING", value);
      },
    },
    isDarkTheme: {
      cache: false,
      get() {
        return this.$store.getters.theme() != "light";
      },
    },
    showNavigationSearch() {
      return this.$route && this.$route.name == "QxpNav";
    },
    showSpinner() {
      return this.isNavLoading;
    },
  },
  components: {
    LoginPartial,
    LoginPartialFlyout,
  },
};
</script>
