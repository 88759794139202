import axios from "axios";
import store from "..";

const state = {
  PMSsData: [],
  statusPMSs: "",
  statusPMS: "",
};

const getters = {
  isPMSsLoading() {
    return state.statusPMSs === "loading";
  },
  isPMSsLoaded() {
    return state.statusPMSs === "loaded";
  },
  PMSsData() {
    return state.PMSsData;
  },
  isBMSLoading() {
    return state.statusPMS === "loading";
  },
  isBMSLoaded() {
    return state.statusPMS === "loaded";
  },
};

const mutations = {
  SET_PMSS_DATA(state, data) {
    state.PMSsData = data;
  },
  SET_STATUS_PMSS_LOADING(state) {
    state.statusPMSs = "loading";
  },
  SET_STATUS_PMSS_LOADED(state) {
    state.statusPMSs = "loaded";
  },
  SET_STATUS_PMSS_ERROR(state) {
    state.statusPMSs = "error";
  },
  SET_STATUS_PMS_LOADING(state) {
    state.statusPMS = "loading";
  },
  SET_STATUS_PMS_LOADED(state) {
    state.statusPMS = "loaded";
  },
  SET_STATUS_PMS_ERROR(state) {
    state.statusPMS = "error";
  },
  CLEAR_DATA(state) {
    state.PMSsData = [];
    state.statusPMSs = "";
    state.statusPMS = "";
  },
};

const actions = {
  LOAD_PMSS_DATA({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_PMSS_LOADING");
      axios
        .get("/api/adminportalmembership/all/" + payload.GroupID)
        .then((response) => {
          commit("SET_PMSS_DATA", response.data);
          commit("SET_STATUS_PMSS_LOADED");
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          commit("SET_STATUS_PMSS_ERROR");
          reject(err);
        });
    });
  },
  CREATE_PMS: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_PMS_LOADING");
      axios
        .post("/api/adminportalmembership", payload)
        .then((response) => {
          store.dispatch("notifStore/successAdded", "Portal membership added.");
          store.dispatch("adminPortalMembership/LOAD_PMSS_DATA", {
            GroupID: payload.GroupID,
          });
          resolve();
        })
        .catch((err) => {
          commit("SET_STATUS_PMS_ERROR");
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          reject(err);
        });
    });
  },
  DELETE_PMS({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit("SET_STATUS_PMS_LOADING");
      axios
        .post("/api/adminportalmembership/delete/" + payload.PortalMembershipID)
        .then((response) => {
          store.dispatch(
            "notifStore/successDeleted",
            "Portal membership deleted."
          );
          store.dispatch("adminPortalMembership/LOAD_PMSS_DATA", {
            GroupID: payload.GroupID,
          });
          resolve();
        })
        .catch((err) => {
          store.dispatch(
            "notifStore/alert",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.Message
              ? err.response.data.Message
              : "Unknown Backend Error"
          );
          reject(err);
        });
    });
  },
  CLEAR_STORE({ commit }) {
    return new Promise((resolve, reject) => {
      commit("CLEAR_DATA");
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
