<template>
  <li class="nav-item">
    <button id="userMenuIcon" @click="toggle">
      <i class="icon icon-058-person icon--s3"></i>
    </button>
  </li>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  methods: {
    ...mapActions({ toggleFlyout: "toggleFlyout" }),
    toggle() {
      // console.log('toggle from icon')
      this.toggleFlyout();
    },
  },
};
</script>
