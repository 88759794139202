<template>
  <div class="input-field dp-form" style="display: flex">
    <input
      type="text"
      v-model="dateInputText"
      class="dp-textinput"
      :class="{ invalid: !dateIsValid }"
      @input="onChangeInputText_Date($event.target.value)"
      @blur="validateInputText_Date($event.target.value)"
      v-on:keyup.enter="onEnterInputText_Date($event.target.value)"
    />
    <VueCtkDateTimePicker
      v-model="valueProxy"
      class="ctk"
      :no-value-to-custom-elem="true"
      :auto-close="true"
      :no-label="true"
      :only-date="true"
      :no-header="true"
      :locale="'de'"
      :format="dateTimeFormat"
      @input="onChangeDatePicker($event)"
    >
      <div
        ref="dpdiv"
        class="dp-calendar-div"
        :class="{ invalid: !dateIsValid }"
        tabindex="0"
        v-on:keyup.enter="simulateClickDate()"
        v-on:keyup.space="simulateClickDate()"
      >
        <i class="icon icon-009-calendar icon--s1"></i>
      </div>
    </VueCtkDateTimePicker>
    <input
      type="time"
      v-model="timeInputText"
      class="dp-textinput-time"
      :class="{ invalid: !timeIsValid }"
      @input="onChangeInputText_Time($event.target.value)"
      @blur="validateInputText_Time($event.target.value)"
      v-on:keyup.enter="onEnterInputText_Time($event.target.value)"
    />
  </div>
</template>

<script>
import moment from "moment";
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
// import { text } from '../../../../local_modules/sdx/dist/es6/DomFunctions'

export default {
  data() {
    return {
      dateInputTextHasChanged: false,
      dateIsValid: true,
      dateInputText: "",
      dateFormat: "DD.MM.YYYY",
      timeInputTextHasChanged: false,
      timeIsValid: true,
      timeInputText: "",
      timeFormat: "HH:mm",
    };
  },
  computed: {
    dateTimeFormat() {
      return this.dateFormat + " " + this.timeFormat;
    },
    valueProxy: {
      get() {
        //becomming Date from props
        var momentValue = moment(this.value);
        //props into inputText
        this.dateIsValid = momentValue.isValid();
        this.dateInputText = momentValue.format(this.dateFormat);
        this.timeInputText = momentValue.format(this.timeFormat);
        //returning string as ValueProxy (used by DatePicker)
        return momentValue.format(this.dateTimeFormat);
      },
      set(text) {
        //becomming Date from DatePicker or bluredInputText
        var momentValue = moment(text, this.dateTimeFormat, true);
        //emit if isValid
        if (momentValue.isValid()) {
          this.$emit("input", momentValue.toDate());
        }
      },
    },
  },
  methods: {
    parseInputText(textDate, textTime) {
      if (!textTime) {
        textTime = "00:00";
      }
      return moment(
        textDate + " " + textTime,
        ["D.M.YYYY HH:mm", "D.M.YY HH:mm", "D.M HH:mm"],
        true
      );
    },
    onChangeDatePicker(text) {
      var momentValue = moment(text, this.dateTimeFormat, true);
      if (momentValue.isValid()) {
        this.dateIsValid = true;
        //synchronise to inputText
        this.dateInputText = momentValue.format(this.dateFormat);
        // this.valueProxy = momentValue.format(this.dateTimeFormat)
      } else {
        this.dateIsValid = false;
      }
    },
    onChangeInputText_Date(text) {
      this.dateInputTextHasChanged = true;
      var momentValue = this.parseInputText(text, this.timeInputText);
      this.dateIsValid = momentValue.isValid();
    },
    onEnterInputText_Date(text) {
      this.dateInputTextHasChanged = true;
      this.validateInputText_Date(text);
    },
    validateInputText_Date(text) {
      if (this.dateInputTextHasChanged) {
        var momentValue = this.parseInputText(text, this.timeInputText);
        if (momentValue.isValid()) {
          this.dateIsValid = true;
          //when blured set the formated version and emit
          this.dateInputText = momentValue.format(this.dateFormat);
          this.valueProxy = momentValue.format(this.dateTimeFormat);
          this.dateInputTextHasChanged = false;
        } else {
          this.isValid = false;
        }
      }
    },
    onChangeInputText_Time(text) {
      this.timeInputTextHasChanged = true;
      var momentValue = this.parseInputText(this.dateInputText, text);
      this.timeIsValid = momentValue.isValid();
    },
    onEnterInputText_Time(text) {
      this.timeInputTextHasChanged = true;
      this.validateInputText_Time(text);
    },
    validateInputText_Time(text) {
      if (this.timeInputTextHasChanged) {
        var momentValue = this.parseInputText(this.dateInputText, text);
        if (momentValue.isValid()) {
          this.timeIsValid = true;
          //when blured set the formated version and emit
          this.timeInputText = momentValue.format(this.timeFormat);
          this.valueProxy = momentValue.format(this.dateTimeFormat);
          this.timeInputTextHasChanged = false;
        } else {
          this.timeIsValid = false;
        }
      }
    },
    simulateClickDate() {
      this.$refs.dpdiv.click();
    },
  },
  props: {
    //Javascript Date Type
    value: {
      type: Date,
      required: true,
    },
  },
  components: {
    VueCtkDateTimePicker: VueCtkDateTimePicker,
  },
};
</script>
