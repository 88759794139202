<template>
  <div class="row StandardView">
    <risk-benchmarks-list :isOpen="filterIsOpen" />
    <toggle-button v-model="filterIsOpen" />
    <risk-benchmark-form />
  </div>
</template>

<script>
import fmdRiskBenchmarksList from "@/components/admin/fmdRiskBenchmarksList.vue";
import fmdRiskBenchmarkForm from "@/components/admin/fmdRiskBenchmarkForm.vue";
import ToggleButton from "@/components/ui_utilities/ToggleButton.vue";

export default {
  data() {
    return {
      filterIsOpen: true,
    };
  },
  components: {
    "risk-benchmarks-list": fmdRiskBenchmarksList,
    "risk-benchmark-form": fmdRiskBenchmarkForm,
    ToggleButton,
  },
};
</script>
