<template>
  <div class="qsui-admin-form col-sm-12 col-md-10 col-lg-8 col-xl-7">
    <div class="text-h4">User Membership</div>
    <div class="qsui-admin-list-filerplus">
      <div>
        <sdx-textbox v-model="oValueFilter" label="filter..." type="text" />
      </div>
      <div class="checkbox input-field">
        <input
          type="checkbox"
          name="dummy"
          id="UMSactive"
          value="UMSactive"
          v-model="checkedActive"
        />
        <label for="UMSactive">active only</label>
      </div>
    </div>
    <div v-if="showSpinner" class="loader-spinner loader-spinner--small"></div>
    <ul v-else class="qsui-admin-list qsui-admin-list-bms">
      <li
        v-for="elem in filteredItems"
        class="qsui-admin-list-item"
        :class="{ inactive: elem.GroupID < 0 }"
      >
        {{ elem.display }}
        <i
          class="icon icon-300_plus-box icon--s1"
          :class="elem.GroupID < 0 ? 'icon-300_plus-box' : 'icon-301_minus-box'"
          :title="elem.GroupID < 0 ? 'add membership' : 'remove membership'"
          @click="toggleMembership(elem)"
        ></i>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SDXTextbox from "@/components/ui_utilities/SDXTextbox";
import ButtonsRow from "@/components/ui_utilities/ButtonsRow";
import config from "@/data/config";

export default {
  data() {
    return {
      oValueFilter: { value: "", valid: undefined },
      checkedActive: true,
    };
  },
  created() {
    this.$store.dispatch("adminUser/LOAD_DATA_USERS");
  },
  computed: {
    ...mapGetters({
      UMSsData: "adminUserMembership/UMSsData",
      isUMSsLoading: "adminUserMembership/isUMSsLoading",
      isUMSsLoaded: "adminUserMembership/isUMSsLoaded",
      usersData: "adminUser/usersData",
      isUsersLoading: "adminUser/isUsersLoading",
      isUsersLoaded: "adminUser/isUsersLoaded",
      groupData: "adminGroup/groupData",
      isGroupLoading: "adminGroup/isGroupLoading",
    }),
    filteredItems() {
      const lowcasevalue = this.oValueFilter.value.toLowerCase();
      const activeonly = this.checkedActive;
      return this.mergedItems.filter((elem) => {
        let statusFilter = (activeonly && elem.GroupID > 0) || !activeonly;
        let filterFilter =
          !lowcasevalue || elem.display.toLowerCase().includes(lowcasevalue);
        return statusFilter && filterFilter;
      });
    },
    mergedItems() {
      if (
        this.UMSsData &&
        this.isUMSsLoaded &&
        this.usersData &&
        this.isUsersLoaded
      ) {
        var ret = [];
        this.usersData.forEach((elem) => {
          if (elem && elem.status == 1) {
            // console.log(elem.Username + " " + elem.status);
            var pushItem = {
              UserID: elem.UserNr,
              display: elem.display,
            };
            const found = this.UMSsData.find((ms) => {
              return ms.UserID == elem.UserNr;
            });
            if (found) {
              pushItem.GroupID = found.GroupID;
              pushItem.UserMembershipID = found.UserMembershipID;
            } else {
              pushItem.GroupID = -1;
              pushItem.UserMembershipID = -1;
            }
            ret.push(pushItem);
          }
        });
        return ret;
      }
      return [];
    },
    showSpinner() {
      return this.isUMSsLoading || this.isUsersLoading;
    },
  },
  methods: {
    toggleMembership(elem) {
      if (
        elem &&
        elem.GroupID &&
        elem.UserID &&
        elem.UserMembershipID &&
        this.groupData &&
        this.groupData.GroupID > 0
      ) {
        if (elem.GroupID > 0 && elem.UserID > 0) {
          if (elem.UserMembershipID > 0) {
            this.$store.dispatch("adminUserMembership/DELETE_UMS", {
              UserMembershipID: elem.UserMembershipID,
              GroupID: this.groupData.GroupID,
            });
          }
        } else {
          this.$store.dispatch("adminUserMembership/CREATE_UMS", {
            GroupID: this.groupData.GroupID,
            UserID: elem.UserID,
          });
        }
      }
    },
  },
  components: {
    "sdx-textbox": SDXTextbox,
  },
};
</script>

<style lang="scss">
.qsui-admin-list-bms {
  max-height: 400px;
  overflow-y: auto;
}
</style>
