<template>
  <div class="row StandardView">
    <w-view-filter :isOpen="filterIsOpen" />
    <toggle-button v-model="filterIsOpen" />
    <grid-and-banners />
  </div>
</template>

<script>
import WViewFilter from "@/components/wv_filter/WViewFilter";
import GridAndBanners from "@/components/datagrid/GridAndBanners";
import ToggleButton from "@/components/ui_utilities/ToggleButton";

export default {
  data() {
    return {
      filterIsOpen: true,
    };
  },
  components: {
    WViewFilter,
    ToggleButton,
    GridAndBanners,
  },
};
</script>
