<template>
  <div class="row StandardView">
    <groups-list :isOpen="filterIsOpen" />
    <toggle-button v-model="filterIsOpen" />
    <group-form />
  </div>
</template>

<script>
import groupsList from "@/components/admin/groupsList";
import groupForm from "@/components/admin/groupForm";
import ToggleButton from "@/components/ui_utilities/ToggleButton";

export default {
  data() {
    return {
      filterIsOpen: true,
    };
  },
  components: {
    groupsList,
    groupForm,
    ToggleButton,
  },
};
</script>
